import { eventChannel } from "redux-saga";
import { put, call, fork, all } from "redux-saga/effects";

export const joinSagas = (sagas = []) => {
  if (typeof sagas === "function") return sagas;

  return function* combinedSagas() {
    yield all(sagas.map(saga => fork(saga)));
  };
};

export function* fetchEntity(entity, apiFn, id, url) {
  yield put(entity.request(id));
  const { response, error } = yield call(apiFn, url || id);
  if (response) {
    yield put(entity.success(id, response));
  } else {
    yield put(entity.failure(id, error));
  }
}

export function watchEvents(faye, channel) {
  return eventChannel((emitter) => {
    faye.subscribe(channel, (data) => {
      try {
        const payload = JSON.parse(data);
        if (payload.type) {
          return emitter(payload);
        }
        return emitter({ type: "WS_UNKNOWN_ACTION", payload: payload.payload });
      } catch (e) {
        console.error(e);
        return emitter({ type: "WS_BAD_RESPONSE", data });
      }
    });

    return () => {
      faye.disconnect();
    };
  });
}
