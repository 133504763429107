import min from 'lodash/min';

import {
  NOTIFICATION_CENTER_LOAD, NOTIFICATION_CENTER_UPDATE, NOTIFICATION_CENTER_READY,
} from '../../actions';

const defaultState = {
  loading: false,
  loaded: false,
  items: [],
  lastMinId: 0,
  nomore: false,
  hots: true,
};

const notificationCenterReducer = (state = defaultState, action) => {
  if (action.type === NOTIFICATION_CENTER_LOAD) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === NOTIFICATION_CENTER_UPDATE) {
    return {
      ...state,
      loading: true,
      lastMinId: 0,
    };
  }

  if (action.type === NOTIFICATION_CENTER_READY) {
    const { items, nomore } = action.response;
    let newItems = items;

    if (state.lastMinId > 0) {
      newItems = state.items.concat(items);
    }

    return {
      ...state,
      items: newItems,
      loading: false,
      loaded: true,
      lastMinId: min(newItems.map(i => i.id)) || 0,
      nomore,
    };
  }

  return state;
};

export default notificationCenterReducer;
