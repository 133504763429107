import React from 'react';
import { string } from 'prop-types';

const CatalogSmallPlaceHolder = ({ text }) => (
  <div className="ctg-content__no_items_small">{text}</div>
);

CatalogSmallPlaceHolder.propTypes = {
  text: string,
};

CatalogSmallPlaceHolder.defaultProps = {
  text: 'Контент отсутсвует',
};

export default CatalogSmallPlaceHolder;
