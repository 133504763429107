import React, {
  useRef,
  createRef,
  forwardRef,
  useState,
  useEffect,
  cloneElement,
  createElement,
  useContext,
  createContext,
  useImperativeHandle,
} from "react";
import styled, { css } from "styled-components";
import findIndex from "lodash/findIndex";

const TabsCntx = createContext();

function Tabs({ children }) {
  const tabs = children.filter(
    item => item.type && item.type.displayName === "Tab",
  );
  const contents = children.filter(
    item => item.type && item.type.displayName === "TabContent",
  );
  const tabsRefs = useRef(tabs.map(() => createRef()));
  const contentsRefs = useRef(contents.map(() => createRef()));

  let defaultActiveTabIndex = findIndex(tabs, tab => tab.props.defaultActive);
  const currActiveTabIndex = findIndex(
    tabsRefs.current,
    item => item.current && item.current._isActive(),
  );

  if (!defaultActiveTabIndex) {
    defaultActiveTabIndex = 0;
  }

  const onTabSelect = (index) => {
    const tabSiblings = tabsRefs.current.filter(
      (_item, itemIndex) => itemIndex !== index,
    );

    if (currActiveTabIndex < 0) {
      tabsRefs.current
        .filter((_item, itemIndex) => itemIndex === index)
        .pop()
        .current._setActive();
    }

    const contentSiblings = contentsRefs.current.filter((item) => {
      const itemIndex = item.current && item.current._getIndexOrder();
      return itemIndex !== index;
    });

    const contentCurrents = contentsRefs.current.filter((item) => {
      const itemIndex = item.current && item.current._getIndexOrder();
      return itemIndex === index;
    });

    tabSiblings.forEach(item => item.current && item.current._setInactive());
    contentSiblings.forEach(
      item => item.current && item.current._setInactive(),
    );
    contentCurrents.forEach(item => item.current && item.current._setActive());
  };

  useEffect(() => {
    if (currActiveTabIndex < 0) {
      onTabSelect(defaultActiveTabIndex);
    }
  }, [currActiveTabIndex]);

  return (
    <TabsCntx.Provider value={{ onTabSelect }}>
      <TabsComponent className="tabs-component">
        <div className="controls">
          <div className="controls-wrapper">
            {tabs.map((tab, key) => cloneElement(tab, {
              ...tab.props,
              key,
              indexOrder: key,
              ref: tabsRefs.current[key],
            }))}
          </div>
        </div>
        {contents.map((content, key) => {
          const { contentFor } = content.props;
          const index = contentFor ? parseInt(contentFor, 10) : key;

          return cloneElement(content, {
            ...content.props,
            key,
            indexOrder: key,
            ref: contentsRefs.current[key],
            defaultActive: defaultActiveTabIndex === index,
          });
        })}
      </TabsComponent>
    </TabsCntx.Provider>
  );
}

function Tab(props) {
  const {
    indexOrder,
    children,
    icon,
    link,
    defaultActive = false,
    hidden = false,
  } = props;
  const [hover, setHover] = useState(false);
  const [active, setActive] = useState(defaultActive);
  var onTabSelect = () => {};

  const gotoLink = (link) => {
    window.location = link;
  };

  let mouseEvents = {
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false),
  };

  if (useContext(TabsCntx)) {
    var { onTabSelect } = useContext(TabsCntx);
  }

  mouseEvents = {
    ...mouseEvents,
    onClick: () => (link ? gotoLink(link) : setActive(true) & onTabSelect(indexOrder)),
  };

  const normalIcon = icon
    && cloneElement(icon, {
      ...icon.props,
      color: "#2F80ED",
    });

  const hoverIcon = icon
    && cloneElement(icon, {
      ...icon.props,
      color: "#fff",
    });

  useImperativeHandle(props.forwardedRef, () => ({
    _setInactive: () => setActive(false),
    _setActive: () => setActive(true),
    _isActive: () => active && !hidden,
  }));

  return (
    <TabComponent
      active={active}
      hidden={hidden}
      ref={props.forwardedRef}
      className="control"
      {...mouseEvents}
    >
      {hoverIcon && (
        <div className="icon">{hover || active ? hoverIcon : normalIcon}</div>
      )}
      <div className="content">{children}</div>
    </TabComponent>
  );
}

function TabContent(props) {
  const {
    indexOrder,
    children,
    defaultActive = false,
    contentFor,
    useWrapper = false,
  } = props;
  const [active, setActive] = useState(defaultActive);

  useImperativeHandle(props.forwardedRef, () => ({
    _setInactive: () => setActive(false),
    _setActive: () => setActive(true),
    _getIndexOrder: () => {
      if (contentFor) {
        return parseInt(contentFor);
      }

      return indexOrder;
    },
  }));

  if (useWrapper) {
    return (
      <TabContentComponent
        active={active}
        ref={props.forwardedRef}
        className="content"
      >
        {children}
      </TabContentComponent>
    );
  }

  if (typeof children.type === "string") {
    return (
      <>
        {createElement(
          children.type,
          { style: { display: active ? "flex" : "none" } },
          children.props.children,
        )}
      </>
    );
  }

  return (
    <>
      {cloneElement(children, { active })}
    </>
  );
}

const forwardRefTabs = forwardRef((props, ref) => <Tabs {...props} forwardedRef={ref} />);

const forwardRefTab = forwardRef((props, ref) => <Tab {...props} forwardedRef={ref} />);

const forwardRefTabContent = forwardRef((props, ref) => <TabContent {...props} forwardedRef={ref} />);

forwardRefTabs.displayName = "Tabs";
forwardRefTab.displayName = "Tab";
forwardRefTabContent.displayName = "TabContent";

export {
  forwardRefTabs as Tabs,
  forwardRefTab as Tab,
  forwardRefTabContent as TabContent,
  TabsCntx,
};

const TabsComponent = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;

  .controls {
    display: inline-flex;
    align-self: flex-start;
    border: #f2f2f2 solid 1px;
    margin-right: 20px;
    border-radius: 4px;
    overflow: hidden;
    user-select: none;
  }
`;

const TabComponent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  font: 14px/1 Roboto;
  color: #2f80ed;
  padding: 0 24px;
  min-height: 52px;
  background: #fff;
  cursor: pointer;
  border-bottom: #f2f2f2 solid 1px;

  .icon {
    font-size: 18px;
    margin-right: 16px;
  }

  &:hover {
    background: #2f80ed;
    color: #fff;
  }

  ${props => props.active
    && css`
      background: #2f80ed;
      color: #fff;
    `}

  ${props => props.hidden
    && css`
      display: none !important;
      visibility: hidden !important;
    `}

  &:last-child {
    border: none 0;
  }
`;

const TabContentComponent = styled.div`
  position: relative;
  display: none;

  ${props => props.active
    && css`
      display: block;
    `}
`;
