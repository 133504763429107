import React, { useEffect, useState } from "react";
import { bool, func, object } from "prop-types";

import ExerciseInfo from "./ExerciseInfo";
import Status from "./Status";
import ReviewForm from "./ReviewForm";

const HomeworkHeader = ({
  isTeacher,
  homework,
  isGifConverting,
  convertToGif,
  onUpdateReview,
  closeHomework,
}) => {
  const [shownReviewForm, setShownReviewForm] = useState(false);

  const onShowReviewForm = (e) => {
    e.stopPropagation();
    setShownReviewForm(prevState => !prevState);
  };

  const handleAnyClick = () => {
    setShownReviewForm(false);
  };

  useEffect(() => {
    window.addEventListener('click', handleAnyClick);

    return () => {
      window.removeEventListener('click', handleAnyClick);
    };
  }, []);

  return (
    <div className="modal-header modal-hw-header">
      <a href={`/user/${homework.user.id}`}>
        <img className="modal-hw-avatar" src={homework.user.avatar} alt="" />
      </a>

      <div className="modal-hw-info">
        <p className="modal-hw-name">
          <a href={`/user/${homework.user.id}`}>{homework.user.nickname}</a>
        </p>

        <ExerciseInfo
          hasLink={isTeacher}
          flowId={homework.flow.id || 0}
          flowTitle={homework.course.title}
          exerciseTitle={homework.exercise.title}
          lessonId={(homework.lesson && homework.lesson.id) || undefined}
          lessonTitle={(homework.lesson && homework.lesson.title) || undefined}
        />
      </div>

      {isTeacher && homework.type === "video" && (
        <button
          type="button"
          disabled={isGifConverting}
          onClick={() => convertToGif(homework.id)}
          className="btn btn-primary btn-sm modal-hw-get-gif"
        >
          {isGifConverting ? "Конвертируем..." : "Скачать GIF"}
        </button>
      )}

      {isTeacher && homework.type !== "text" && (
        <a
          className="btn btn-primary btn-sm modal-hw-open-origin"
          href={homework.imageFull}
          target="_blank"
          rel="noopener noreferrer"
          role="button"
        >
          Открыть оригинал
        </a>
      )}

      <Status
        status={homework.status}
        isTeacher={isTeacher}
        shownReviewForm={shownReviewForm}
        onShowReviewForm={onShowReviewForm}
      />

      {isTeacher && shownReviewForm && (
        <ReviewForm
          homeworkId={homework.id}
          onUpdateReview={onUpdateReview}
          closeHomework={closeHomework}
        />
      )}
    </div>
  );
};

HomeworkHeader.propTypes = {
  isTeacher: bool.isRequired,
  homework: object.isRequired,
  isGifConverting: bool.isRequired,
  convertToGif: func.isRequired,
  onUpdateReview: func.isRequired,
  closeHomework: func.isRequired,
};

export default HomeworkHeader;
