import { any, array, bool, func, number, object, string } from 'prop-types';
import React, { useEffect } from 'react';

import CourseNavigation from './CourseNavigation';
import CourseBanner from './CourseBanner';
import CourseInfo from './CourseInfo';
import CourseAbout from './CourseAbout';
import CourseTeachers from './CourseTeachers';
import CoursePrice from "./CoursePrice";

const CourseLayout = ({
  CoursePractice, CourseDetails, course, lessons, icons,
  currentUserId, subscribtions, freeSubscribeClick, observe,
  loading, error, homeworks, toggleLike, reduxCatalogKey,
}) => {
  useEffect(() => {
    observe();
  }, [observe]);

  const hasSubscribeAbility = (
    course.free_flow_exists && (!currentUserId || !subscribtions.includes(course.id))
  );

  const showLookInsideCourse = (
    !course.free_flow_exists && (!currentUserId || !subscribtions.includes(course.id))
  );

  const showPracticeBlock = (
    course.practice_descr1 || course.practice_descr2 || course.work_examples.length > 0
  );

  return (
    <>
      <CourseNavigation
        hasSubscribeAbility={hasSubscribeAbility}
        freeSubscribeClick={freeSubscribeClick}
      />

      <CourseBanner banner={course.cover} />

      <CourseInfo course={course} icons={icons} />

      <CourseDetails
        lessons={lessons}
        hasSubscribeAbility={hasSubscribeAbility}
        freeSubscribeClick={freeSubscribeClick}
        loading={loading}
        error={error}
        homeworks={homeworks}
        toggleLike={toggleLike}
        reduxCatalogKey={reduxCatalogKey}
      />

      {course.text && (
        <CourseAbout
          text={course.text}
          demoUrl={course.demo_lessons_url}
          showLookInsideCourse={showLookInsideCourse}
        />
      )}

      {showPracticeBlock && (
        <CoursePractice
          descr1={course.practice_descr1}
          descr2={course.practice_descr2}
          workExamples={course.work_examples}
        />
      )}

      <CourseTeachers
        teacher={course.teacher}
        methodist={course.methodist}
        curators={course.curators}
        curatorsDescr={course.curators_descr}
      />

      <CoursePrice
        hasSubscribeAbility={hasSubscribeAbility}
        price={course.price}
        freeSubBlock1={course.free_sub_block1}
        freeSubBlock2={course.free_sub_block2}
        freeSubBlock3={course.free_sub_block3}
        socials={course.socials}
        priceDescr={course.price_descr}
        enrollDescr={course.enroll_descr}
        freeSubscribeClick={freeSubscribeClick}
      />
    </>
  );
};

CourseLayout.propTypes = {
  currentUserId: number,
  reduxCatalogKey: string,
  course: object.isRequired,
  lessons: array.isRequired,
  icons: object.isRequired,
  subscribtions: array.isRequired,
  freeSubscribeClick: func,
  toggleLike: func,
  observe: func,
  CoursePractice: any,
  CourseDetails: any.isRequired,
  homeworks: object,
  loading: bool,
  error: bool,
};

CourseLayout.defaultProps = {
  currentUserId: null,
  reduxCatalogKey: "",
  freeSubscribeClick: () => {},
  observe: () => {},
  toggleLike: () => {},
  CoursePractice: null,
  loading: false,
  error: false,
  homeworks: {},
};

export default CourseLayout;
