import { START_CONVERTING_MP4_TO_GIF, SUCCESS } from "../../actions";

const currentUser = (state = {}, action) => {
  const notifNum = action.response && action.response.unread_notifications_number;

  if (typeof notifNum === 'number') {
    return {
      ...state,
      unread_notifications_number: notifNum,
    };
  }

  if (action.type === START_CONVERTING_MP4_TO_GIF[SUCCESS]) {
    return {
      ...state,
      mp4ToGifConverting: true,
    };
  }

  return state;
};

export default currentUser;
