import React, { Component } from "react";
import { Provider } from "react-redux";

import initializeStore from "Core/store/initializeStore";
import MyHomeworksCatalog from "./MyHomeworksCatalog";

const getStore = initializeStore();

class MyHomeworksCatalogRoot extends Component {
  render() {
    return (
      <Provider store={getStore()}>
        <MyHomeworksCatalog {...this.props} />
      </Provider>
    );
  }
}

export default MyHomeworksCatalogRoot;
