import React from 'react';

export default function () {
  return (
    <div className="chat_loader_wrapper">
      <div className="chat_loader_content">
        <div className="loadersmall" />
        <span className="chat_loader_text">Загружаем чат...</span>
      </div>
    </div>
  );
}
