import merge from 'lodash/merge';
import { normalize } from "normalizr";
import { humps, getRandomInt, getUniqueArrayElements } from 'Core/services/tools';

import { homeworkSchema, articleSchema, contestantSchema } from '../../services/api';
import {
  CATALOG_PREPARE, CATALOG_ITEMS, CATALOG_LIKE_TOGGLE, REQUEST, SUCCESS, FAILURE,
  STUDENT_WORK_PREPARE, STUDENT_WORK_LOAD_DONE, LOAD_HOMEWORK_DONE,
  CONTEST_WORK_PREPARE, CONTEST_LOAD_DONE, CONTEST_WORK_LOAD_DONE, CONTEST_WORKS,
  CONTEST_WORK_PRIZE_PLACE_SELECT_DONE, CONTEST_WORK_PUBLISHED, LESSON_TEST_COMPLETE,
} from "../../actions";

/*
  В каталоге, помимо самих элементов и пагинации хранится инфа об активной модалки
  (перенесено из локальных редюсеров), поэтому каталог обрабатывает и кастомные события
  на открытие модалки (в будущем можно объединить под экшены каталога).

  Также возможна ситуация открытия модалки вне каталога, в таком случае будет храниться
  только инфа об активной модалке без элементов и пагинации.
*/

export const getDefaultCatalogEntity = () => ({
  activeModal: null,
  items: [],
  pagination: {
    firstItemsLoaded: false,
    loading: false,
    error: false,
    hasMore: false,
    page: 1,
    sortType: 0,
    category: 'all',
    randNumber: getRandomInt(10000, 20000),
    sortFilters: [],
    responseMessage: '',
  },
  customOptions: {},
});

const entitySchemes = {
  homeworks: homeworkSchema,
  articles: articleSchema,
  contestants: contestantSchema,
};

const getSchemaByEntity = (entity) => {
  const schema = entitySchemes[entity];

  if (!schema) {
    throw Error(`No schema defined for "${entity}" entity!`);
  }

  return schema;
};

const normalizeAndGetMergedState = (state, data, schema) => {
  let normalizedData;

  if (Array.isArray(data)) {
    normalizedData = normalize(data, [schema]);
  } else {
    normalizedData = normalize(data, schema);
  }

  const entities = merge({}, state, normalizedData.entities);

  return entities;
};

const reduxContestantsEntity = 'contestants';
const reduxContestCatalogKey = 'contest';

function homeworkLoadDoneHandler(state, action) {
  const entities = normalizeAndGetMergedState(state, action.response, homeworkSchema);
  const catKey = action.payload.reduxCatalogKey;

  if (!entities.catalog[catKey]) {
    entities.catalog[catKey] = getDefaultCatalogEntity();
  }

  entities.catalog[catKey].activeModal = action.response.id;

  return entities;
}

const catalogHandlers = {
  // инициализация сущности каталога
  [CATALOG_PREPARE]: (state, action) => {
    const { payload: { reduxCatalogKey: catKey, pagination = null } } = action;

    const entities = merge({}, state);
    entities.catalog[catKey] = getDefaultCatalogEntity();

    if (pagination) {
      entities.catalog[catKey].pagination = {
        ...entities.catalog[catKey].pagination,
        ...pagination,
      };
    }

    return entities;
  },

  // запрос элементов каталога
  [CATALOG_ITEMS[REQUEST]]: (state, action) => {
    const { payload: {
      page,
      reduxCatalogKey: catKey,
    } } = action;
    const entities = merge({}, state);

    const { pagination } = entities.catalog[catKey];

    if (page === 1) {
      entities.catalog[catKey].items = [];
    }

    entities.catalog[catKey].pagination = {
      ...pagination,
      loading: true,
      error: false,
      responseMessage: '',
    };

    return entities;
  },

  // успех запроса элементов каталога
  [CATALOG_ITEMS[SUCCESS]]: (state, action) => {
    const { payload: {
      reduxCatalogKey: catKey,
      page,
      sortType,
      category,
      entity,
      response: {
        filters = [],
        has_more: hasMore,
        message: responseMessage = "",
        custom_options: customOptions = null,
      },
    } } = action;
    const schema = getSchemaByEntity(entity);
    let newItems = action.payload.response.items;

    // пока что для конкурсов используется везде camelCase
    if (entity === 'contestants') {
      newItems = humps(newItems);
    }

    const entities = normalizeAndGetMergedState(state, newItems, schema);

    const newItemIds = newItems.map(item => item.id);
    const { pagination, items } = entities.catalog[catKey];

    if (items.length === 0) {
      entities.catalog[catKey].items = newItemIds;
    } else {
      const resultItems = [...items, ...newItemIds];
      // оставляем только уникальные элементы
      entities.catalog[catKey].items = getUniqueArrayElements(resultItems);
    }

    entities.catalog[catKey].pagination = {
      ...pagination,
      page,
      loading: false,
      firstItemsLoaded: true,
      error: false,
      hasMore,
      sortType,
      category,
      responseMessage,
    };

    if (customOptions && Object.keys(customOptions).length > 0) {
      entities.catalog[catKey].customOptions = humps(customOptions);
    }

    if (filters.length !== 0) {
      entities.catalog[catKey].pagination.sortFilters = filters;
    }

    return entities;
  },

  // неудачная подгрузка элементов каталога
  [CATALOG_ITEMS[FAILURE]]: (state, action) => {
    const { payload: { reduxCatalogKey: catKey } } = action;
    const entities = merge({}, state);

    const { pagination } = entities.catalog[catKey];

    entities.catalog[catKey].pagination = {
      ...pagination,
      loading: false,
      error: true,
    };

    return entities;
  },

  // лайк был поставлен или убран
  [CATALOG_LIKE_TOGGLE[SUCCESS]]: (state, action) => {
    const { payload: {
      response,
      entity,
    } } = action;
    const schema = getSchemaByEntity(entity);
    const data = {
      ...response,
      likesNumber: response.likes_number,
      alreadyLiked: response.already_liked,
    };

    return normalizeAndGetMergedState(state, data, schema);
  },

  // сброс активной студенческой модалки
  [STUDENT_WORK_PREPARE]: (state, action) => {
    const entities = merge({}, state);
    const catKey = action.payload.reduxCatalogKey;

    if (!entities.catalog[catKey]) {
      entities.catalog[catKey] = getDefaultCatalogEntity();
    }

    entities.catalog[catKey].activeModal = null;

    return entities;
  },

  // загрузка модалки студенческой работы
  [STUDENT_WORK_LOAD_DONE]: homeworkLoadDoneHandler,

  // загрузка модалки домашней работы
  [LOAD_HOMEWORK_DONE]: homeworkLoadDoneHandler,

  // скрываем показ модалки с тестом после прохождения теста
  [LESSON_TEST_COMPLETE[SUCCESS]]: (state, action) => {
    const { lessonId, reduxCatalogKey: catKey } = action.payload;

    if (!catKey || !state.catalog[catKey]) return state;

    const entities = merge({}, state);
    const { lessons } = entities.catalog[catKey].customOptions;

    entities.catalog[catKey].customOptions.lessons = lessons.map((lesson) => {
      if (lesson.id === lessonId) {
        return {
          ...lesson,
          isShowTest: false,
        };
      }

      return lesson;
    });

    return entities;
  },

  // успешная загрузка конкурса, в нем есть только победители,
  // остальные участники подгружаются отжельно и у них есть пагинация
  [CONTEST_LOAD_DONE]: (state, action) => {
    const winners = humps(action.response.winners) || [];
    const catKey = reduxContestCatalogKey;

    const entities = normalizeAndGetMergedState(state, winners, contestantSchema);

    // при открытии нового конкурса обновляем пагинацию
    if (!entities.catalog[catKey]) {
      entities.catalog[catKey] = getDefaultCatalogEntity();
    }

    if (!Array.isArray(winners) || winners.length === 0) return entities;

    const { items } = entities.catalog[catKey];
    const winnerIds = winners.map(winner => winner.id);

    if (items.length === 0) {
      entities.catalog[catKey].items = winnerIds;

      return entities;
    }

    const resItems = items.concat(winnerIds);
    entities.catalog[catKey].items = getUniqueArrayElements(resItems);

    return entities;
  },

  [CONTEST_WORK_LOAD_DONE]: (state, action) => {
    const contestant = humps(action.response);
    const catKey = action.payload.reduxCatalogKey;

    const schema = getSchemaByEntity(reduxContestantsEntity);
    const entities = normalizeAndGetMergedState(state, contestant, schema);
    entities.contestants[contestant.id].prizePlaces = contestant.prizePlaces;

    entities.catalog[catKey].activeModal = contestant.id;

    return entities;
  },

  // сброс активной конкурсной работы
  [CONTEST_WORK_PREPARE]: (state, action) => {
    const catKey = action.payload.reduxCatalogKey;
    if (!state.catalog[catKey]) return;

    const entities = merge({}, state);

    if (!entities.catalog[catKey]) {
      entities.catalog[catKey] = getDefaultCatalogEntity();
    }

    entities.catalog[catKey].activeModal = null;

    return entities;
  },

  // подгрузка участников конкурса
  [CONTEST_WORKS[REQUEST]]: (state) => {
    const catKey = reduxContestCatalogKey;
    const entities = merge({}, state);

    entities.catalog[catKey].pagination.loading = true;

    return entities;
  },

  // успешная подгрузка участников конкурса
  [CONTEST_WORKS[SUCCESS]]: (state, action) => {
    const catKey = reduxContestCatalogKey;
    const response = humps(action.response);

    const entities = normalizeAndGetMergedState(state, response.items, contestantSchema);

    const { pagination } = entities.catalog[catKey];

    entities.catalog[catKey].pagination = {
      ...pagination,
      firstItemsLoaded: true,
      page: action.payload.page,
      loading: false,
      error: false,
      hasMore: response.hasMore,
    };

    const { items } = entities.catalog[catKey];
    const newItemIds = response.items.map(item => item.id);

    if (items.length === 0) {
      entities.catalog[catKey].items = newItemIds;

      return entities;
    }

    const resItems = items.concat(newItemIds);
    entities.catalog[catKey].items = getUniqueArrayElements(resItems);

    return entities;
  },

  [CONTEST_WORK_PRIZE_PLACE_SELECT_DONE]: (state, action) => {
    const contestant = humps(action.response.contestant);

    const entities = normalizeAndGetMergedState(state, contestant, contestantSchema);
    entities.contestants[contestant.id].prizePlaces = contestant.prizePlaces;

    return entities;
  },

  // конкурсная работа была опубликована
  [CONTEST_WORK_PUBLISHED]: (state, action) => {
    const contestant = humps(action.response.contestant);

    return normalizeAndGetMergedState(state, contestant, contestantSchema);
  },
};

export default catalogHandlers;
