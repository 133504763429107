import React from 'react';
import { func, bool, string, element, oneOf, oneOfType } from 'prop-types';

export default function RadioItem(props) {
  const { checkedType, checked, disabled, onChange } = props;

  return (
    <div className={`pixel-radio-item ${checked ? '--selected' : ''} mb-3`} onClick={disabled ? null : onChange}>
      { !checked && <i className="fa fa-circle-o" /> }
      { checked && checkedType === 'correct' && <i className="fa fa-check-circle" /> }
      { checked && checkedType === 'wrong' && <i className="fa fa-times-circle" /> }
      { checked && checkedType === 'checked' && <i className="fa fa-circle" /> }
      <div>
        <span>{props.label}</span>
        {
          checked && props.hint && <div className={`mt-1 --${checkedType}`}>{props.hint}</div>
        }
      </div>
    </div>
  );
}

RadioItem.propTypes = {
  onChange: func.isRequired,
  label: string,
  checked: bool,
  checkedType: oneOf(['correct', 'wrong', 'checked']),
  hint: oneOfType([string, element]),
  disabled: bool,
};

RadioItem.defaultProps = {
  checkedType: 'checked',
  checked: false,
  label: '',
  hint: '',
  disabled: false,
};
