/* eslint-disable react/no-danger */
import React from 'react';
import { bool, string, func, object } from 'prop-types';
import cn from "classnames";

import FontAwesomeBrandIcon from '../ui/FontAwesomeBrandIcon';
import FreeSubscribeButton from './FreeSubscribeButton';

const CoursePrice = ({
  hasSubscribeAbility, price, priceDescr, freeSubscribeClick,
  freeSubBlock1, freeSubBlock2, freeSubBlock3, socials, enrollDescr,
}) => {
  const socialKeys = Object.keys(socials);

  const renderFreeSubscribeBlock = () => {
    const [title1, content1] = freeSubBlock1.split("\n", 2);
    const [title2, content2] = freeSubBlock2.split("\n", 2);
    const [title3, content3] = freeSubBlock3.split("\n", 2);

    return (
      <div style={{ marginTop: 90 }}>
        <div className="course-lesson-blocks d-flex justify-content-between">
          <div className="course-lesson-block">
            <div className="course-page-title">{title1}</div>
            <p className="mb-3">{content1}</p>
            <FreeSubscribeButton onClick={freeSubscribeClick} />
          </div>

          <div className="course-lesson-block">
            <div className="course-page-title">{title2}</div>
            <p>{content2}</p>
          </div>

          <div className="course-lesson-block">
            <div className="course-page-title">{title3}</div>
            <p>{content3}</p>
          </div>
        </div>

        <ul className="course-lessons-progress d-flex justify-content-between mt-4">
          {[0, 1, 2, 3].map(el => <li key={el} />)}
          <img className="lozad" data-src="/images/course/flag.png" alt="" />
        </ul>
      </div>
    );
  };

  return (
    <section id="course-price" className="d-flex">
      <div className="m-auto text-center">
        <div className={cn('price m-auto', { 'price-wide': hasSubscribeAbility })}>
          <div className="course-page-title">
            Стоимость курса
            {' '}
            {price}
            <i className="fa fa-rub rubl" />
          </div>

          {priceDescr && (
            <p className="mt-3" dangerouslySetInnerHTML={{ __html: priceDescr }} />
          )}

          {hasSubscribeAbility && renderFreeSubscribeBlock()}
        </div>

        {socialKeys.length > 0 && (
          <div className="enroll">
            <div className="course-page-title">
              Записаться на курс:
            </div>
            {enrollDescr && (
              <p className="enroll-descr" dangerouslySetInnerHTML={{ __html: enrollDescr }} />
            )}

            <div className="d-flex justify-content-center mt-3">
              {socialKeys.map(socialName => (
                <a
                  className={cn('ui button subscribe-link', {
                    'variant-green': socialName === 'WhatsApp',
                  })}
                  key={socialName}
                  href={socials[socialName]}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => window.captureOutboundLink(socials[socialName])}
                >
                  <FontAwesomeBrandIcon link={socials[socialName]} />
                  <span>{socialName}</span>
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

CoursePrice.propTypes = {
  hasSubscribeAbility: bool.isRequired,
  price: string.isRequired,
  socials: object.isRequired,
  freeSubBlock1: string,
  freeSubBlock2: string,
  freeSubBlock3: string,
  priceDescr: string,
  freeSubscribeClick: func.isRequired,
  enrollDescr: string,
};

CoursePrice.defaultProps = {
  freeSubBlock1: '',
  freeSubBlock2: '',
  freeSubBlock3: '',
  priceDescr: '',
  enrollDescr: '',
};

export default CoursePrice;
