import React from "react";
import { func, string, bool } from "prop-types";
import ReactPlayer from "react-player";

const ContestWorkViewBody = ({
  contestWorkType,
  contestWorkUrl,
  showNextWork,
  showPrevWork,
  sliderEnabled,
  onContentLoaded,
}) => {
  const renderArrows = () => {
    if (!sliderEnabled) return null;

    return (
      <>
        <div className="out-next-slide" onClick={showNextWork}>
          <div className="out-next-slide__content" />
        </div>
        <div className="out-prev-slide" onClick={showPrevWork}>
          <div className="out-prev-slide__content" />
        </div>
      </>
    );
  };

  const renderWorkBody = () => {
    if (contestWorkType === "video") {
      return (
        <ReactPlayer
          url={contestWorkUrl}
          onReady={onContentLoaded}
          controls
          playing
          loop
          width="860px"
        />
      );
    }

    if (contestWorkType === "image") {
      return (
        <img
          alt=""
          className="modal-hw-version"
          src={contestWorkUrl}
          onLoad={onContentLoaded}
          onError={onContentLoaded}
        />
      );
    }

    return null;
  };

  return (
    <div className="modal-body modal-hw-body">
      <div className="modal-hw-gallery-container">
        <div className="modal-hw-gallery">
          <div className="gallery-item gallery-item-active">
            {renderWorkBody()}
          </div>
        </div>
      </div>
      {renderArrows()}
    </div>
  );
};

ContestWorkViewBody.propTypes = {
  contestWorkType: string.isRequired,
  contestWorkUrl: string.isRequired,
  onContentLoaded: func.isRequired,
  showNextWork: func.isRequired,
  showPrevWork: func.isRequired,
  sliderEnabled: bool.isRequired,
};

export default ContestWorkViewBody;
