import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { bool, string } from 'prop-types';

export default function CourseAbout({ text, showLookInsideCourse, demoUrl }) {
  let lookInsideHtml = "";
  let resultText = text;

  if (showLookInsideCourse) {
    lookInsideHtml = (
      <div className="d-flex look-in-course">
        <a href={demoUrl} className="ui button">Заглянуть внутрь курса</a>
      </div>
    );

    resultText += ReactDOMServer.renderToStaticMarkup(lookInsideHtml);
  }

  return (
    <section id="course-about">
      {/* eslint-disable-next-line react/no-danger */}
      <div className="wide-block-wrapper" dangerouslySetInnerHTML={{ __html: resultText }} />
    </section>
  );
}

CourseAbout.propTypes = {
  demoUrl: string.isRequired,
  text: string.isRequired,
  showLookInsideCourse: bool.isRequired,
};
