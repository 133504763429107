import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { array, func } from 'prop-types';

import moment from "moment";
import cn from 'classnames';

import { formatCommentTime } from 'Core/services/tools';

const CommentsList = ({ comments, deleteComment, updateComment }) => {
  const [editableComment, setEditableComment] = useState(null);
  const currentUserId = useSelector(state => state.local.currentUser.id);
  const isTeacher = useSelector(state => state.local.currentUser.is_teacher);
  const txtareaRef = useRef();

  const handleChangeComment = (e) => {
    const { value } = e.target;
    setEditableComment(state => ({ ...state, text: value }));
  };

  const handleUpdateComment = (e) => {
    e.preventDefault();

    const { id, text } = editableComment;

    updateComment({ id, text: text.trim() });
    setEditableComment(null);
  };

  const handleCommentChangeKeydown = (e) => {
    if (e.keyCode === 13) handleUpdateComment(e);
  };

  const renderComment = (comment) => {
    if (!editableComment || editableComment.id !== comment.id) {
      return (
        <div className="comment__text">
          {comment.text}
          {comment.edited && (
            <span className="comment__text_edited">(ред.)</span>
          )}
        </div>
      );
    }

    return (
      <form
        className="comment__text --edit_mode"
        onSubmit={handleUpdateComment}
      >
        <textarea
          ref={txtareaRef}
          rows="1"
          type="text"
          placeholder="Режим редактирования"
          defaultValue={comment.text}
          onChange={handleChangeComment}
          onFocus={handleChangeComment}
          onKeyDown={handleCommentChangeKeydown}
        />
        <button type="button" onClick={() => setEditableComment(null)} className="cancel">
          Отмена
        </button>
        <button
          className="save"
          disabled={editableComment.text.trim() === '' || editableComment.text.trim() === comment.text}
          type="submit"
        >
          Сохранить
        </button>
      </form>
    );
  };

  return (
    <div className="comments-component__list">
      {comments.map((comment, index) => (
        <div
          key={comment.id}
          className="comments-component__list-item comment"
        >
          <a className="comment__avatar" href={`/user/${comment.user.id}`}>
            <img
              className={cn("user-avatar", { "rounded-0": comment.user.avatar.endsWith("_cat_avatar.png") })}
              src={comment.user.avatar}
              alt=""
            />
          </a>

          <div className="comment__content">
            <a className="comment__nickname" href={`/user/${comment.user.id}`}>
              {comment.user.nickname}
            </a>

            {renderComment(comment)}

            <div className="comment__time">
              {formatCommentTime(comment.time)}
            </div>
          </div>

          <div className="comment__actions">
            {currentUserId === comment.user.id && moment().unix() - comment.time <= 86400 && (
              <i
                className="fa fa-pencil"
                onClick={() => setEditableComment(comment)}
              />
            )}

            {(currentUserId === comment.user.id || isTeacher) && (
              <i
                className="fa fa-close"
                onClick={() => deleteComment(comment.id)}
              />
            )}
          </div>

          {comments.length - 1 !== index && <hr />}
        </div>
      ))}
    </div>
  );
};

CommentsList.propTypes = {
  deleteComment: func.isRequired,
  updateComment: func.isRequired,
  comments: array.isRequired,
};

export default CommentsList;
