import React from "react";
import { bool, func, number, string } from "prop-types";

import Like from "../Homework/Like";
import CopyCodeButton from "../Homework/CopyCodeButton";
import ShowOnCourseButtom from "../Homework/ShowOnCourseButton";

const StudentWorkViewActions = ({
  isTeacher,
  type,
  text,
  alreadyLiked,
  likesNumber,
  onToggleLike,
  onShowOnCourse,
  isShowOnCourse,
}) => (
  <div className="modal-hw-actions">
    <Like
      onToggleLike={onToggleLike}
      alreadyLiked={alreadyLiked}
      likesNumber={likesNumber}
    />

    <div className="modal-hw-actions-right">
      {type === "text" && <CopyCodeButton text={text} />}

      {isTeacher && (
        <ShowOnCourseButtom
          onShowOnCourse={onShowOnCourse}
          showOnCourse={isShowOnCourse}
        />
      )}
    </div>
  </div>
);

StudentWorkViewActions.propTypes = {
  isTeacher: bool.isRequired,
  type: string.isRequired,
  text: string,
  alreadyLiked: bool.isRequired,
  likesNumber: number.isRequired,
  onToggleLike: func.isRequired,
  onShowOnCourse: func.isRequired,
  isShowOnCourse: bool,
};

StudentWorkViewActions.defaultProps = {
  text: null,
  isShowOnCourse: false,
};

export default StudentWorkViewActions;
