import React, { Component } from "react";
import { number } from "prop-types";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

import initializeStore from "Core/store/initializeStore";
import { history } from "Core/services";
import contestSaga from "Core/sagas/contest";
import wsContestSaga from "Core/sagas/ws-contest";

import Toast from '../components/layouts/Toast';
import Contests from "./Contests";
import Contest from "./Contest";

const sagas = [contestSaga, wsContestSaga];
const getStore = initializeStore(sagas);

class ContestsRoot extends Component {
  render() {
    const { contestId } = this.props;

    return (
      <Provider store={getStore()}>
        <ToastProvider components={{ Toast }}>
          <Router history={history}>
            {contestId ? <Contest id={contestId} /> : <Contests />}
          </Router>
        </ToastProvider>
      </Provider>
    );
  }
}

ContestsRoot.propTypes = {
  contestId: number,
};

ContestsRoot.defaultProps = {
  contestId: null,
};

export default ContestsRoot;
