import React from 'react';
import { func, arrayOf, bool, string } from 'prop-types';
import Modal from "Main/components/layouts/modals/Modal";

function StudentTestCompleted({ answers, onClose, successMess, failureMess }) {
  const test_passed = !answers.includes(false);
  const wrong_number = answers.filter(a => !a).length;

  // eslint-disable-next-line no-nested-ternary
  const pluralize = (num, vars) => (num === 1 ? vars[0] : (num > 4 ? vars[2] : vars[1]));

  return (
    <Modal
      showCross={false}
      showTimes
      closeModal={onClose}
    >
      <div className="modal-content lesson-test-modal lesson-test-modal-complete p-5 text-center">
        {
          test_passed && (
            <div>
              <div className="mx-3 my-0 d-flex justify-content-center">
                <img alt="" className="completed-image mt-4 lesson-test-modal-header" src="/images/lesson_test/complete_cat.png" />
              </div>
              <h5 className="mt-3">Все ответы правильные</h5>
              <p>
                {successMess}
              </p>
            </div>
          )
        }
        {
          !test_passed && (
            <div>
              <div className="mx-3 my-0 d-flex justify-content-center">
                <img alt="" src="/images/lesson_test/wrong_cat.png" />
              </div>
              <h5 className="completed-image mt-4 lesson-test-modal-header">Тест не сдан</h5>
              <p>
                Вы ответили неправильно на
                {' '}
                {wrong_number}
                {' '}
                {pluralize(wrong_number, ['вопрос', 'вопроса', 'вопросов'])}
                {' '}
                из
                {' '}
                {answers.length}
                .
                {' '}
                {failureMess}
              </p>
            </div>
          )
        }
      </div>
    </Modal>
  );
}

StudentTestCompleted.propTypes = {
  onClose: func.isRequired,
  answers: arrayOf(bool).isRequired,
  successMess: string.isRequired,
  failureMess: string.isRequired,
};

export default StudentTestCompleted;
