import React from "react";

function HomeworkFameHallSent() {
  return (
    <svg viewBox="0 0 30 30" width="30" height="30">
      <g
        fill="none"
        stroke="#ffc217"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      >
        <path strokeWidth={1.7} d="M15 23.7L4.5 28.5v-27h21v27l-5.1-2.3" />
        <path
          d="M15.5 6.6L17 9.9c.1.2.2.3.4.3l3.4.5c.4.1.6.6.3 1l-2.5 2.5c-.1.1-.2.3-.2.5l.6 3.6c.1.5-.4.8-.8.6l-3.1-1.7c-.2-.1-.3-.1-.5 0l-3.1 1.7c-.4.2-.9-.1-.8-.6l.6-3.6c0-.2 0-.4-.2-.5l-2.5-2.5c-.3-.3-.1-.9.3-1l3.4-.5c.2 0 .3-.1.4-.3l1.5-3.3c.5-.4 1.1-.4 1.3 0z"
          strokeWidth={1.5}
        />
      </g>
    </svg>
  );
}

function HomeworkLiked() {
  return (
    <svg viewBox="0 0 30 30" width="30" height="30">
      <path
        d="M27.8 13.9C29.9 8.7 27 4.4 23 3.1c-2.9-1-6.7.4-8 5.4 0 .1 0 .1 0 0-1.3-5-5.1-6.3-8-5.4C3 4.4.1 8.7 2.2 13.9 3.3 16.8 6 19.7 8.6 22c3 2.7 5.9 4.7 6.4 5.2h0c.5-.5 3.7-2.7 6.8-5.6"
        fill="none"
        stroke="#f43b4b"
        strokeWidth={1.7}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
    </svg>
  );
}

function HomeworkCommented() {
  return (
    <svg viewBox="0 0 30 30" width="30" height="30" xmlSpace="preserve">
      <style>{".svg_hw_commented{fill:#488ecc}"}</style>
      <path
        d="M24.5 21.8c2.3-1.4 3.8-4 3.8-6.9V9.5c0-4.5-3.6-8.1-8.1-8.1H9.8C5.3 1.4 1.7 5 1.7 9.5v5.4c0 4.5 3.6 8.1 8.1 8.1h2.4l2.8 5.6 2.8-5.6"
        fill="none"
        stroke="#488ecc"
        strokeWidth={1.7}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <ellipse
        className="svg_hw_commented"
        cx={7.9}
        cy={12.4}
        rx={1.8}
        ry={1.9}
      />
      <ellipse
        className="svg_hw_commented"
        cx={15}
        cy={12.4}
        rx={1.8}
        ry={1.9}
      />
      <ellipse
        className="svg_hw_commented"
        cx={22.1}
        cy={12.4}
        rx={1.8}
        ry={1.9}
      />
    </svg>
  );
}

function HomeworkStatusChangedUnverified() {
  return (
    <svg viewBox="0 0 30 30" width="30" height="30" xmlSpace="preserve">
      <style>
        {
          ".svg_hw_status_changed_unverified{fill:none;stroke:#b4b4b5;stroke-width:1.7;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
        }
      </style>
      <path
        className="svg_hw_status_changed_unverified"
        d="M22.5 3.8c3.6 2.4 5.9 6.5 5.9 11.2 0 7.5-6 13.5-13.5 13.5S1.6 22.5 1.6 15s6-13.5 13.5-13.5"
      />
      <path
        className="svg_hw_status_changed_unverified"
        d="M22.8 20.6a9.35 9.35 0 01-8.7 3.9c-5.2-.5-9-5.2-8.5-10.4S10.8 5 15.9 5.5s9.1 5.2 8.6 10.4"
      />
      <path
        fill="none"
        stroke="#b4b4b5"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M14.6 9.4v5.9l4.7 1.3"
      />
    </svg>
  );
}

function HomeworkStatusChangedAccepted() {
  return (
    <svg viewBox="0 0 30 30" width="30" height="30" xmlSpace="preserve">
      <style>
        {
          ".svg_hw_status_changed_accepted{fill:none;stroke:#8cdb0f;stroke-width:1.7;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
        }
      </style>
      <path
        className="svg_hw_status_changed_accepted"
        d="M2.6 20.5C.4 15.6 1.4 9.6 5.4 5.5 10.7.2 19.2.2 24.5 5.5s5.3 13.8 0 19.1-13.8 5.3-19.1 0"
      />
      <path
        className="svg_hw_status_changed_accepted"
        d="M20.4 13.4L14.9 20l-5.4-8.7"
      />
    </svg>
  );
}

function HomeworkStatusChangedRejected() {
  return (
    <svg viewBox="0 0 30 30" width="30" height="30" xmlSpace="preserve">
      <style>
        {
          ".svg_hw_status_changed_rejected{fill:none;stroke:#ff6010;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
        }
      </style>
      <path
        d="M23.7 25.3c-3.3 2.8-7.9 4-12.4 2.7C4.2 26 0 18.5 2 11.4S11.5 0 18.6 2C25.8 4 30 11.5 28 18.6"
        fill="none"
        stroke="#ff6010"
        strokeWidth={1.7}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <path
        className="svg_hw_status_changed_rejected"
        d="M13.8 20.8l-4.2-3.4 8-9.7c.7-.9 2-1 2.9-.3l1 .8c.9.7 1 2 .3 2.9l-8 9.7z"
      />
      <path
        className="svg_hw_status_changed_rejected"
        d="M8.8 22.7l.8-5.3 4.2 3.4zM16 9.6l4.1 3.3"
      />
    </svg>
  );
}

function HomeworkStatusChangedWaitingNextStage() {
  return (
    <svg viewBox="0 0 30 30" width="30" height="30" xmlSpace="preserve">
      <style>
        {
          ".svg_hw_status_changed_waiting_next_stage{fill:none;stroke:#ffa317;stroke-width:1.7;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
        }
      </style>
      <path
        className="svg_hw_status_changed_waiting_next_stage"
        d="M4 18.6V3.2h23.6l-6.4 7.7 6.4 7.7H10.8M4 28.5v-27"
      />
    </svg>
  );
}

function ComicsNewEpisodeAvailable() {
  return (
    <svg viewBox="0 0 30 30" width="30" height="30" xmlSpace="preserve">
      <style>
        {
          ".svg_comics_new_episode_available,.svg_comics_new_episode_available2{fill:none;stroke:#238bf2;stroke-width:1.7;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.svg_comics_new_episode_available2{stroke-width:1.3}"
        }
      </style>
      <path
        className="svg_comics_new_episode_available"
        d="M19.9 20.1l-6.3 7.2c-.9 1-2.4 1.1-3.3.2l-6-5.2c-.5-.5-.8-1.1-.8-1.8M8.7 13.5l4.7-5.3c1.3-1.5 3.5-1.6 5-.3l4.2 3.7c.6.5 1 1.3 1.1 2"
      />
      <path
        transform="rotate(41.327 22.002 8.054)"
        className="svg_comics_new_episode_available"
        d="M19.4 5.9h5.3v4.4h-5.3z"
      />
      <path
        fill="none"
        stroke="#238bf2"
        strokeWidth={1.50009}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M24.729 2.035l2.929 2.576-2.774 3.154-2.929-2.576z"
      />
      <path className="svg_comics_new_episode_available" d="M20.9 4.2L26 8.6" />
      <path
        transform="rotate(28.164 14.259 17.371)"
        className="svg_comics_new_episode_available2"
        d="M12.7 11.1h3.2v12.5h-3.2z"
      />
      <path
        className="svg_comics_new_episode_available2"
        d="M1.5 16.8h7M20.4 16.8h8.1"
      />
    </svg>
  );
}

export {
  HomeworkFameHallSent,
  HomeworkLiked,
  HomeworkCommented,
  HomeworkStatusChangedAccepted,
  HomeworkStatusChangedUnverified,
  HomeworkStatusChangedRejected,
  HomeworkStatusChangedWaitingNextStage,
  ComicsNewEpisodeAvailable,
};
