import React, { Component } from 'react';
import { connect } from 'react-redux';
import { object, func, array } from 'prop-types';
import get from 'lodash/get';

import { getStudentWorkCoversFields } from 'Core/reducers/selectors';

import { loadCatalogItems, toggleLikeCatalogItem, prepareCatalog, showOnCourse } from '../../core/actions';
import CatalogDropdownFilters from '../components/Catalog/CatalogDropdownFilters';
import TeacherHomework from '../components/Homework/TeacherHomework';
import ListWithInfinityScroll from '../components/layouts/ListWithInfinityScroll';
import Spinner from '../components/layouts/Spinner';
import { CatalogFiltersWrapper } from '../components/Catalog/CatalogFiltersWrapper';
import { DatePicker } from '../components/ui/DatePicker';
import CatalogMultiselectFilters from '../components/Catalog/CatalogMultiselectFilters';

const entity = 'homeworks';
const reduxCatalogKey = 'teacherHomeworks';

const statuses = [
  ['unverified', 'Проверяется'],
  ['rejected', 'Нужно доработать'],
  ['accepted', 'Принято'],
  ['waiting_next_stage', 'Ждем следующий этап'],
];

const workModes = [['all', 'Все работы']];
const workModesAll = [...workModes, ['fame_hall', 'Только зал славы']];

class TeacherHomeworksCatalog extends Component {
  state = {
    activeStatus: 'unverified',
    activeCourses: ['all'],
    activeFlow: 'all',
    activeLesson: 'all',
    activeWorksMode: 'all',
    dateBefore: '',
    dateAfter: '',
  }

  componentDidMount() {
    const { pagination } = this.props;

    if (pagination.firstItemsLoaded) {
      return this.handleLoadItems({}, 1);
    }

    this.props.prepareCatalog({
      entity,
      reduxCatalogKey,
      filters: 1,
      customParams: this.getCustomParams(),
    });
  }

  handleLoadItems = (options = {}, filters = null) => {
    this.props.loadItems({
      entity,
      reduxCatalogKey,
      page: 1,
      filters,
      customParams: {
        ...this.getCustomParams(),
        ...options,
      },
    });
  }

  handleLoadMoreItems = () => {
    this.props.loadItems({
      entity,
      reduxCatalogKey,
      page: this.props.pagination.page + 1,
      customParams: this.getCustomParams(),
    });
  }

  handleToggleLike = (itemId) => {
    this.props.toggleLike({ itemId, entity });
  }

  getCustomParams = () => ({
    status: this.state.activeStatus,
    courseIds: this.state.activeCourses.join(','),
    flowId: this.state.activeFlow,
    lessonId: this.state.activeLesson,
    worksMode: this.state.activeWorksMode,
    dateBefore: this.state.dateBefore,
    dateAfter: this.state.dateAfter,
    teacherHomeworks: 1,
  });

  handleChangeStatus = (status) => {
    this.setState({ activeStatus: status });
    this.handleLoadItems({ status });
  }

  handleChangeCourse = (courseIds) => {
    this.setState({
      activeCourses: courseIds,
      activeLesson: 'all',
      activeFlow: 'all',
    });
  }

  handleChangeFlow = (flowId) => {
    this.setState({ activeFlow: flowId });
    this.handleLoadItems({ flowId });
  }

  handleChangeLesson = (lessonId) => {
    this.setState({ activeLesson: lessonId });
    this.handleLoadItems({ lessonId });
  }

  handleChangeMode = (worksMode) => {
    this.setState({ activeWorksMode: worksMode });
    this.handleLoadItems({ worksMode });
  }

  handleChangeDateBefore = (dateBefore) => {
    this.setState({ dateBefore });
    this.handleLoadItems({ dateBefore });
  }

  handleChangeDateAfter = (dateAfter) => {
    this.setState({ dateAfter });
    this.handleLoadItems({ dateAfter });
  }

  renderFilters() {
    const {
      courseFilters,
      flowFilters,
      lessonFilters,
    } = this.props;

    return (
      <div className="pt-3">
        <CatalogFiltersWrapper>
          {/* statuses */}
          <CatalogDropdownFilters
            filters={statuses}
            activeFilter={this.state.activeStatus}
            onSwitch={this.handleChangeStatus}
          />

          {/* courses */}
          <CatalogMultiselectFilters
            filters={[['all', 'Все курсы']].concat(courseFilters)}
            activeFilters={this.state.activeCourses}
            onSelect={this.handleChangeCourse}
            onCollapse={() => this.handleLoadItems({}, 1)}
          />

          {/* flows */}
          <CatalogDropdownFilters
            filters={[['all', 'Все потоки']].concat(flowFilters)}
            activeFilter={this.state.activeFlow}
            onSwitch={this.handleChangeFlow}
          />

          {/* lessons */}
          <CatalogDropdownFilters
            filters={[['all', 'Все уроки']].concat(lessonFilters)}
            activeFilter={this.state.activeLesson}
            onSwitch={this.handleChangeLesson}
          />

          {/* work modes */}
          <CatalogDropdownFilters
            filters={this.state.activeStatus === 'accepted' ? workModesAll : workModes}
            activeFilter={this.state.activeWorksMode}
            onSwitch={this.handleChangeMode}
          />

          {/* work after date */}
          <DatePicker
            label="Дата после"
            onChangeDate={this.handleChangeDateAfter}
            value={this.state.dateAfter}
          />

          {/* work before date */}
          <DatePicker
            label="Дата до"
            onChangeDate={this.handleChangeDateBefore}
            value={this.state.dateBefore}
          />
        </CatalogFiltersWrapper>
      </div>
    );
  }

  renderContent() {
    if (!this.props.pagination.firstItemsLoaded) {
      return <Spinner className="p-5" />;
    }

    const { items, pagination } = this.props;

    const compItems = items.map((item, index) => (
      <div key={item.id} className="catalog-item cell cell_33">
        <TeacherHomework
          onToggleLike={this.handleToggleLike}
          toggleFameHall={this.props.toggleFameHall}
          reduxCatalogKey={reduxCatalogKey}
          indexOrder={index}
          isUseHomeworkComponent
          {...item}
        />
      </div>
    ));

    return (
      <div className="catalog__items ctg-content my-3">
        <ListWithInfinityScroll
          items={compItems}
          loading={pagination.loading}
          error={pagination.error}
          spinner={<Spinner className="mt-3" />}
          hasMore={pagination.hasMore}
          loadMore={this.handleLoadMoreItems}
          noItemsText="Работы отсутствуют"
        />
      </div>
    );
  }

  render() {
    return (
      <div className="catalog">
        {this.renderFilters()}

        {this.renderContent()}
      </div>
    );
  }
}

TeacherHomeworksCatalog.propTypes = {
  // MAP STATE PROPS
  pagination: object.isRequired,
  items: array,
  courseFilters: array,
  flowFilters: array,
  lessonFilters: array,
  // MAP DISPATCH PROPS
  loadItems: func.isRequired,
  toggleLike: func.isRequired,
  prepareCatalog: func.isRequired,
  toggleFameHall: func.isRequired,
};

TeacherHomeworksCatalog.defaultProps = {
  courseFilters: [],
  flowFilters: [],
  lessonFilters: [],
  items: [],
};

const mapStateToProps = (state) => {
  const defaultState = { pagination: { firstItemsLoaded: false }, items: [], customOptions: null };

  const {
    pagination,
    customOptions,
    items,
  } = get(state.entities.catalog, reduxCatalogKey, defaultState);

  if (!customOptions) {
    return {
      pagination,
    };
  }

  return {
    pagination,
    items: getStudentWorkCoversFields(state, items),
    ...customOptions,
  };
};

export default connect(mapStateToProps, {
  loadItems: loadCatalogItems,
  toggleLike: toggleLikeCatalogItem,
  prepareCatalog,
  toggleFameHall: showOnCourse,
})(TeacherHomeworksCatalog);
