import React, { useState, useEffect } from "react";
import { bool, func, number, object, string } from "prop-types";
import cn from "classnames";

import { getIconInfoByHomeworkStatus } from "Core/services/chat";

import HomeworkView from "Main/containers/HomeworkView";

export default function TeacherHomework(props) {
  const {
    id,
    status,
    alreadyLiked,
    likesNumber,
    commentsNumber,
    isShowOnCourse,
    image,
    user: { avatar, nickname },
    type,
    text,
    onToggleLike,
    toggleFameHall,
    reduxCatalogKey,
  } = props;

  const [isViewMode, toggleViewMode] = useState(false);

  useEffect(() => {
    window.observer.observe();
  }, [image]);

  const { className: statusIcon, text: statusText } = getIconInfoByHomeworkStatus(status);

  return (
    <div className="student-work">
      {isViewMode && (
        <HomeworkView
          id={id}
          closeModal={() => toggleViewMode(false)}
          reduxCatalogKey={reduxCatalogKey}
          showOpenChatButton
        />
      )}
      <div className="card_with_border_extended">
        <div
          className={cn('homework_extended homework_exists', {
            homework_text: type === "text",
            lozad: type !== "text",
          })}
          id={id}
          onClick={() => toggleViewMode(true)}
          data-background-image={image}
        >
          <div className={`homework_status_circle -${status}`}>
            <i className={`fa ${statusIcon}`} />
            <span>{statusText}</span>
          </div>

          {type === "text" && <div className="homework_text_cover">{text}</div>}
          {type === "gif" && <div className="homework_gif_tag">GIF</div>}
          {type === "video" && <div className="homework_video_tag">VIDEO</div>}
        </div>

        <hr />
        <div className="homework_flex_info">
          <div className="homework_social_info">
            <div className="homework_user">
              <div className="homework_avatar">
                <img
                  className={cn("lozad", { "rounded-0": avatar.endsWith("_cat_avatar.png") })}
                  data-src={avatar}
                  alt=""
                />
              </div>
              <div className="homework_user_name">
                {nickname.split(" ").join("\n")}
              </div>
            </div>
            <div className="homework_counters">
              {status === 'accepted' && (
                <div
                  className={cn("homework_show_on_course", {
                    "-remove": isShowOnCourse,
                  })}
                  onClick={() => toggleFameHall(id)}
                >
                  {isShowOnCourse && (
                    <i className="fa fa-minus-circle" />
                  )}

                  {!isShowOnCourse && (
                    <i className="fa fa-star-o" />
                  )}
                </div>
              )}

              {commentsNumber > 0 && (
                <div className="homework_comment_count">
                  <i className="fa fa-comment-o" />
                  <span>{commentsNumber}</span>
                </div>
              )}

              {status === 'accepted' && (
                <div
                  className={cn('homework_like_count', {
                    homework_like_count_active: alreadyLiked,
                  })}
                  onClick={() => onToggleLike(id)}
                >
                  <i className={`fa fa-heart${alreadyLiked ? "" : "-o"}`} />
                  &nbsp;
                  <span>{likesNumber || ""}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TeacherHomework.propTypes = {
  id: number.isRequired,
  alreadyLiked: bool,
  isShowOnCourse: bool.isRequired,
  likesNumber: number.isRequired,
  commentsNumber: number.isRequired,
  status: string.isRequired,
  image: string,
  user: object.isRequired,
  text: string,
  type: string.isRequired,
  onToggleLike: func.isRequired,
  toggleFameHall: func.isRequired,
  reduxCatalogKey: string,
};

TeacherHomework.defaultProps = {
  alreadyLiked: false,
  image: null,
  text: null,
  reduxCatalogKey: null,
};
