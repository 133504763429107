import React from "react";
import moment from "moment";
import "moment/locale/ru";

import ItemMessage from "./ItemMessage";
import ItemHomework from "./ItemHomework";
import ChatItemIcon from './ChatItemIcon';

export default function ItemBody(props) {
  const { chat, flow, message, homework, user, course, is_teacher } = props;

  const consultationTimeIsUp = moment(user.consultation_last_date) < moment().startOf("day");
  const chatItemIcon = (
    <ChatItemIcon
      course={course}
      consultationTimeIsUp={consultationTimeIsUp}
      isFreeFlow={flow.free}
      isTeacher={is_teacher}
    />
  );

  if (homework) {
    return (
      <ItemHomework
        {...props}
        consultationTimeIsUp={consultationTimeIsUp}
        unread={message && message.unread}
      />
    );
  }

  if (message) {
    const is_teacher_message = message.user !== chat.user;
    const isUnreadMy = (is_teacher && is_teacher_message)
      || (!is_teacher && !is_teacher_message);
    const unreadMyClassName = message && message.unread && isUnreadMy ? "-unread_my" : "";

    return (
      <div className={`chat_message ${unreadMyClassName}`}>
        {chatItemIcon}
        {is_teacher && is_teacher_message && (
          <img
            src={course.avatar}
            width="25"
            height="25"
            className="chat_message_avatar"
            alt=""
          />
        )}
        {!is_teacher && !is_teacher_message && (
          <img
            src={user.avatar}
            width="25"
            height="25"
            className={`chat_message_avatar ${
              user.avatar.endsWith("_cat_avatar.png")
                ? "border_radius_none"
                : ""
            }`}
            alt=""
          />
        )}
        <ItemMessage {...props} />
      </div>
    );
  }

  return (
    <div className="chat_message">
      {chatItemIcon}
      Пока нет сообщений
    </div>
  );
}
