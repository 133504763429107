import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, object, bool } from "prop-types";
import { withRouter } from "react-router-dom";
import Modal from "Main/components/layouts/modals/Modal";
import { authorize } from "Core/actions";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: "",
      password: "",
      passwordUse: true,
      phoneUse: false,
    };
  }

  handleMethodSwitch = (e) => {
    e.preventDefault();
    this.setState(state => ({ passwordUse: !state.passwordUse }));
    this.props.reset_error();

    return false;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.props.reset_error();
  };

  handleSubmitPhone = (e) => {
    e.preventDefault();
    const { passwordUse, login, password } = this.state;
    let user = { login };

    this.props.reset_error();

    if (passwordUse) {
      user = { ...user, password };
    }

    const { auth, setLogin } = this.props;

    auth(user).then((response) => {
      setLogin(login);
      localStorage.setItem("login", login);

      if (passwordUse && "userId" in response) {
        const afterSignInPath = this.props.afterSignInPath || response.afterSignInPath || '/courses/my';
        this.props.history.push(afterSignInPath);
        setTimeout(() => { document.location.href = afterSignInPath; }, 250);

        return;
      }

      if ("message" in response) {
        return this.props.history.push("/account-confirm");
      }

      if ("passwd_changing" in response) {
        this.props.history.push("/password-change", {
          ignorePasswordOld: true,
        });
      }
    }).catch(() => {});
  };

  render() {
    const { phoneUse, passwordUse, login, password } = this.state;
    const { afterSignInPath } = this.props;

    return (
      <Modal closeModal={() => this.props.history.goBack()} showCross={false}>
        <div className="auth-popup-form phone-input-step">
          <h3>Вход</h3>
          <header className="d-flex flex-column">
            <div className="component-wrapper">
              <a
                className="ui button vk"
                href={`/users/auth/vkontakte?origin=${afterSignInPath}`}
              >
                <i className="fa fa-vk" />
                <span>Войти через ВКонтакте</span>
              </a>
            </div>
            {/* <div className="component-wrapper">
              <a
                className="ui button fb"
                href={`/users/auth/facebook?origin=${afterSignInPath}`}
              >
                <i className="fa fa-facebook" />
                <span>Войти через Facebook</span>
              </a>
            </div> */}
            {!phoneUse && (
              <div className="text-center mt-2">
                <span
                  className="navigate-btn"
                  onClick={() => this.setState({ phoneUse: true })}
                >
                  Войти с помощью телефона
                </span>
              </div>
            )}
          </header>
          {phoneUse && (
            <div>
              <hr />
              <form
                onSubmit={this.handleSubmitPhone}
                className="d-flex flex-column"
                autoComplete="off"
              >
                <div className="component-wrapper">
                  <input
                    type="text"
                    name="login"
                    placeholder="Номер телефона"
                    defaultValue={login}
                    onChange={this.handleChange}
                    onFocus={this.handleChange}
                    autoComplete="off"
                  />
                </div>
                {passwordUse && (
                  <div className="component-wrapper">
                    <input
                      type="password"
                      name="password"
                      placeholder="Пароль"
                      defaultValue={password}
                      onChange={this.handleChange}
                      onFocus={this.handleChange}
                      autoComplete="off"
                    />
                  </div>
                )}
                <div className="component-wrapper">
                  <button
                    className="ui button text-center"
                    placeholder="submit"
                    type="submit"
                  >
                    {passwordUse ? "Войти" : "Получить SMS-код"}
                  </button>
                </div>
              </form>
              <footer>
                <span
                  className="auth-method navigate-btn"
                  onClick={this.handleMethodSwitch}
                >
                  Использовать
                  {' '}
                  {!passwordUse ? "пароль" : "SMS-код"}
                </span>
              </footer>
              {this.props.error && <p className="error">{this.props.error}</p>}
              {this.props.loading && (
                <div className="load-indicator">
                  <div className="loadersmall modal-hw-loader" />
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

Login.propTypes = {
  reset_error: func.isRequired,
  auth: func.isRequired,
  setLogin: func.isRequired,
  history: object.isRequired,
  afterSignInPath: string,
  error: string,
  loading: bool,
};

Login.defaultProps = {
  error: false,
  loading: false,
  afterSignInPath: '',
};

const mapStateToProps = state => ({
  loading: state.local.userAuthorizeReducer.loading,
  error: state.local.userAuthorizeReducer.error,
});

const LoginConnected = connect(mapStateToProps, {
  auth: params => dispatch => new Promise((resolve, reject) => {
    dispatch(authorize.request({ ...params, resolve, reject }));
  }),
  reset_error: () => authorize.reset_error(),
})(Login);

export default withRouter(LoginConnected);
