/* eslint-disable no-constant-condition */
import { take, put, call, fork, all, select } from "redux-saga/effects";
import { api } from "../services";
import * as actions from "../actions";

function* watchLoadContest() {
  while (true) {
    const { id } = yield take(actions.CONTEST_LOAD);
    const { response } = yield call(api.fetchContest, id);

    if (response) {
      yield put({
        type: actions.CONTEST_LOAD_DONE,
        response,
      });
    }
  }
}

function* watchLoadContests() {
  while (true) {
    yield take(actions.CONTESTS_LOAD);
    const { response } = yield call(api.fetchContests);

    if (response) {
      yield put({
        type: actions.CONTESTS_LOAD_DONE,
        response,
      });
    }
  }
}

function* watchOldContestsLoad() {
  while (true) {
    yield take(actions.OLD_CONTESTS_LOAD[actions.REQUEST]);

    const { oldContests } = yield select(state => state.local.contestsReducer.contests);
    const sortTime = oldContests.reduce(
      (accumulator, contest) => Math.min(accumulator, contest.time),
      oldContests[0].time,
    );

    const sameTimeContestIds = oldContests.filter(c => c.time === sortTime).map(c => c.id);

    const { response, error } = yield call(api.loadMoreOldContests, sortTime, sameTimeContestIds);

    if (response) {
      yield put({
        type: actions.OLD_CONTESTS_LOAD[actions.SUCCESS],
        response,
      });
    } else {
      yield put({
        type: actions.OLD_CONTESTS_LOAD[actions.FAILURE],
      });
      alert('Произошла ошибка во время запроса');
      console.error(error);
    }
  }
}

export default function* root() {
  yield all([
    fork(watchLoadContests),
    fork(watchLoadContest),
    fork(watchOldContestsLoad),
  ]);
}
