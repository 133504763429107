import React from 'react';
import { number } from 'prop-types';

const OpenChatButton = ({ homeworkId }) => (
  <div className="modal-hw-open-chat">
    <a
      href={`/homeworks/${homeworkId}/open-chat`}
      rel="noreferrer"
      target="_blank"
      className="modal-hw-open-chat-link"
    >
      Открыть в чате
    </a>
  </div>
);

OpenChatButton.propTypes = {
  homeworkId: number.isRequired,
};

export default OpenChatButton;
