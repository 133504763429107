import React, { Component } from "react";
import { bool, func, object } from "prop-types";
import { connect } from "react-redux";

import { loadContests, loadMoreOldContest as loadMoreOldContestAction } from "Core/actions";

import ActiveContests from '../components/Contests/ActiveContests';
import ListWithInfinityScroll from '../components/layouts/ListWithInfinityScroll';
import ContestCompletedCard from "../components/Contests/ContestCompletedCard";
import Spinner from '../components/layouts/Spinner';

class Contests extends Component {
  componentDidMount() {
    this.props.loadContests();
  }

  renderOldContests = () => {
    const { contests: { oldContests } } = this.props;

    if (oldContests.length === 0) return null;

    const { loading, hasMore, error } = this.props;
    const compItems = oldContests.map(contest => (
      <ContestCompletedCard key={contest.id} {...contest} />
    ));

    return (
      <>
        <h3 className="section-title">Прошедшие конкурсы</h3>
        <div className="completed-contests-list">
          <ListWithInfinityScroll
            items={compItems}
            loading={loading}
            error={error}
            spinner={<Spinner className="mt-5" />}
            hasMore={hasMore}
            loadMore={this.props.loadMoreOldContest}
          />
        </div>
      </>
    );
  }

  render() {
    if (!this.props.loaded) {
      return <Spinner className="my-3" />;
    }

    const { tags, contests: { activeContests } } = this.props;

    return (
      <div className="contests-comp">
        <h1 className="page-title">Конкурсы</h1>
        <ActiveContests contests={activeContests} tags={tags} />

        {this.renderOldContests()}
      </div>
    );
  }
}

Contests.propTypes = {
  tags: object,
  contests: object,
  loadContests: func.isRequired,
  loadMoreOldContest: func.isRequired,
  loaded: bool.isRequired,
  loading: bool.isRequired,
  hasMore: bool.isRequired,
  error: bool.isRequired,
};

Contests.defaultProps = {
  tags: {},
  contests: {},
};

const mapStateToProps = (state) => {
  const props = state.local.contestsReducer;
  return props;
};

export default connect(mapStateToProps, {
  loadContests,
  loadMoreOldContest: loadMoreOldContestAction,
})(Contests);
