import React from "react";
import { string, number } from "prop-types";

import StudentTest from "Main/containers/StudentTest";

const StudentTestModule = ({ id, title, successMess, failureMess }) => (
  <StudentTest {...{ id, title, failureMess, successMess }} />
);

StudentTestModule.propTypes = {
  title: string.isRequired,
  id: number.isRequired,
  successMess: string.isRequired,
  failureMess: string.isRequired,
};

export default StudentTestModule;
