import React from "react";
import styled from "styled-components";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartFilled } from "@fortawesome/free-solid-svg-icons";

export default function Likes(props) {
  const { id, likesNumber, alreadyLiked, onToggleLike } = props;

  return (
    <LikeComponent
      className={cn("counter likes", { liked: alreadyLiked })}
      onClick={() => onToggleLike(id)}
    >
      <FontAwesomeIcon icon={alreadyLiked ? faHeartFilled : faHeart} />
      &nbsp;
      <span>Мне нравится</span>
      &nbsp;
      {likesNumber > 0 && <span>{likesNumber}</span>}
    </LikeComponent>
  );
}

const LikeComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #2f80ed;
  cursor: pointer;

  &:hover,
  &.liked {
    color: #eb5757;
  }
`;
