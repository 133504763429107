import React, { useState, useEffect } from "react";
import { bool, number, string } from "prop-types";

import { getIconInfoByHomeworkStatus } from "Core/services/chat";
import HomeworkView from "Main/containers/HomeworkView";
import HomeworkOldView from "Main/containers/HomeworkOldView";
import PlayVideoTag from "./PlayVideoTag";

const Homework = (props) => {
  const {
    id,
    status,
    image,
    type,
    text,
    reduxCatalogKey,
    useHomeworkOldComponent,
  } = props;

  const [isViewMode, toggleViewMode] = useState(false);

  useEffect(() => {
    window.observer.observe();
  }, [image]);

  const renderContent = () => {
    if (status === 'processing') {
      return (
        <div className={`hw-cover-${status}`}>
          <i className="fa fa-spinner fa-pulse" />
          <span className="ml-1">Обрабатываем</span>
        </div>
      );
    }

    if (status === 'error_processing') {
      return (
        <div className={`hw-cover-${status}`}>
          <span className="ml-1">Ошибка обработки</span>
        </div>
      );
    }

    const { className, text: statusText } = getIconInfoByHomeworkStatus(status);

    return (
      <div
        className={type === "text" ? "homework_text" : ""}
      >
        <div className={`homework_status_circle -${status}`}>
          <i className={`fa ${className}`} />
          <span>{statusText}</span>
        </div>

        {type !== "text" && (
          <img className="lozad card_homework_img" data-src={image} alt="" />
        )}

        {type === "text" && <div className="homework_text_cover">{text}</div>}
        {type === "gif" && <div className="homework_gif_tag">GIF</div>}
        {type === "video" && (
          <>
            <PlayVideoTag />
            <div className="homework_video_tag">VIDEO</div>
          </>
        )}
      </div>
    );
  };

  const HomeworkViewComponent = useHomeworkOldComponent ? HomeworkOldView : HomeworkView;

  return (
    <div className="student-work card_with_border w-max">
      {isViewMode && (
        <HomeworkViewComponent
          id={id}
          closeModal={() => toggleViewMode(false)}
          reduxCatalogKey={reduxCatalogKey}
        />
      )}
      <div
        className="homework_exists"
        onClick={() => toggleViewMode(true)}
      >
        {renderContent()}
      </div>
    </div>
  );
};

Homework.propTypes = {
  id: number.isRequired,
  status: string.isRequired,
  type: string.isRequired,
  image: string,
  text: string,
  reduxCatalogKey: string.isRequired,
  useHomeworkOldComponent: bool,
};

Homework.defaultProps = {
  image: null,
  text: null,
  useHomeworkOldComponent: false,
};

export default Homework;
