import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";

export default function ContestActiveCard(props) {
  const {
    id,
    title,
    deadlineStr,
    cover,
    themeBackgroundColor: bgColor,
    themeTextColor: txtColor,
    cardComment,
  } = props;

  const cardCommentsCount = cardComment.split(",").length;

  return (
    <ContestActiveCardComponent
      className="contest-card"
      theme={{ bgColor, txtColor }}
      cardCommentsCount={cardCommentsCount}
      image={cover}
      href={`/contest/${id}`}
    >
      <header>
        <div className="contest-title">{title}</div>
        <div className="contest-deadline">
          <FontAwesomeIcon icon={faCalendarAlt} color="#828282" />
          До
          {' '}
          {deadlineStr}
          {' '}
          (Мск)
        </div>
      </header>
      <div className="contest-cover" />
      {cardComment && (
        <footer
          className={cn({
            middle: cardCommentsCount >= 3,
            small: cardCommentsCount >= 5,
          })}
        >
          {parse(
            cardComment
              .replace(/\*([^*]+?)\*/g, "<b>$1</b>")
              .replace(/([^,]+)/g, "<span>$1</span>")
              .replace(/\,/g, "")
              .replace(/\s/g, "&nbsp;"),
          )}
        </footer>
      )}
    </ContestActiveCardComponent>
  );
}

const ContestActiveCardComponent = styled.a`
  position: relative;
  width: 316px;
  background: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  justify-self: center;
  align-self: center;
  transition: all 150ms ease-out;
  transition-property: box-shadow;
  outline: none 0 !important;

  &:hover {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
  }

  header {
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }

  footer {
    height: 90px;
    background: ${props => props.theme.bgColor || "#fff"};
    color: ${props => props.theme.txtColor || "#000"};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row;

    b {
      font-size: 30px;
      font-weight: bold;
    }

    span {
      display: flex;
      flex-flow: column;
      justify-content: center;
      line-height: 1.25;
      margin: 0 15px;
      font-size: 15px;
    }

    &.middle {
      flex-wrap: wrap;

      b {
        font-size: 18px;
      }

      span {
        margin: 0px 10px;
        font-size: 13px;
        line-height: 1.1;
      }
    }
  }

  .contest-cover {
    height: 174px;
    background-image: url(${props => props.image});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
  }

  .contest-title {
    font: 22px/1.25 "Montserrat bold";
    max-width: 250px;
    color: #333;
  }

  .contest-deadline {
    color: #828282;
    font-size: 15px;
    margin-top: 12px;

    svg {
      margin-right: 8px;
    }
  }
`;
