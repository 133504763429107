import React, { useEffect } from 'react';
import { array, bool, func } from 'prop-types';
import cn from 'classnames';

import Spinner from '../layouts/Spinner';
import ListWithInfinityScroll from '../layouts/ListWithInfinityScroll';

/* global addHoverAnimation */

const WebinarItems = ({
  loading,
  nomore,
  loadMore,
  items,
  onToggleLike,
  currentUserExists,
}) => {
  useEffect(() => {
    addHoverAnimation();
  }, []);

  useEffect(() => {
    if (loading) return;

    addHoverAnimation();
  }, [loading]);

  const handleLoadMore = () => loadMore(items);

  const handleToggleLike = (e, itemId) => {
    e.preventDefault();
    e.stopPropagation();

    onToggleLike(itemId);
  };

  const compItems = items.map(item => (
    <div
      className="catalog__item cell cell_33"
      key={`catalog__item-${item.id}`}
    >
      <a className="p-card medium course_card" href={item.url}>
        <div
          className="catalog-item-wrapper"
        >
          <div
            className={`p-card-img-top ${item.animated ? 'preserve_animation' : ''}`}
            data-name="webinar"
            data-id={item.id}
            data-animated={item.animated}
            style={{
              backgroundSize: "cover",
              backgroundImage: `url(${item.statical}`,
            }}
          />
          <div className="p-card-body">
            <div className="p-card-title primary">{item.name}</div>
          </div>
          <footer className="d-flex justify-content-end">
            <button
              type="button"
              className={cn('like-toggle', {
                active: item.already_liked,
                'like-toggle-hover': currentUserExists,
              })}
              onClick={e => handleToggleLike(e, item.id)}
            >
              <i className={`fa fa-heart${item.already_liked ? "" : "-o"}`} />
                &nbsp;
              <span>{item.likes_number || ""}</span>
            </button>
          </footer>
        </div>
      </a>
    </div>
  ));

  return (
    <div className="catalog__items ctg-content">
      <ListWithInfinityScroll
        items={compItems}
        loading={loading}
        spinner={<Spinner className="mt-3" />}
        hasMore={!nomore}
        loadMore={handleLoadMore}
      />

      <div className="pt-4" />
    </div>
  );
};

WebinarItems.propTypes = {
  loadMore: func.isRequired,
  onToggleLike: func.isRequired,
  items: array,
  loading: bool.isRequired,
  nomore: bool.isRequired,
  currentUserExists: bool.isRequired,
};

WebinarItems.defaultProps = {
  items: [],
};

export default WebinarItems;
