import React from 'react';
import { func, number } from 'prop-types';

import Status from './Status';

const ReviewForm = ({
  homeworkId,
  closeHomework,
  onUpdateReview,
}) => {
  const handleSubmit = (status) => {
    onUpdateReview(status);
    closeHomework();
  };

  const statuses = ['rejected', 'accepted', 'waiting_next_stage', 'unverified'];

  return (
    <div
      className="modal-hw-review"
      onClick={e => e.stopPropagation()}
    >
      <p className="modal-hw-review-title">Поставить оценку:</p>

      {statuses.map(status => (
        <div key={`${status}_${homeworkId}`} onClick={() => handleSubmit(status)}>
          <Status
            status={status}
            isTeacher={false}
            shownReviewForm
          />
        </div>
      ))}
    </div>
  );
};

ReviewForm.propTypes = {
  homeworkId: number.isRequired,
  onUpdateReview: func.isRequired,
  closeHomework: func.isRequired,
};

export default ReviewForm;
