import React from "react";
import { bool } from "prop-types";
import styled from "styled-components";
import Dropdown, { DropdownItem } from "UI/Dropdown";
import Like from "UI/Like";

export default function ContestWorkViewActions(props) {
  const {
    id,
    likesNumber,
    alreadyLiked,
    prizePlaces,
    contestJudge = false,
    isWinner = false,
  } = props;

  const handlePrizeSelection = (prizeId) => {
    props.assignPrize({ contestantId: id, prizeId });
  };

  return (
    <div className="modal-hw-actions">
      <Like
        onToggleLike={() => props.toggleLike(id)}
        id={id}
        likesNumber={likesNumber}
        alreadyLiked={alreadyLiked}
      />

      <div className="modal-hw-actions-right">
        {contestJudge
          && (!isWinner ? (
            prizePlaces.length > 0 && (
              <Dropdown
                onSelect={({ attrs: { id: prizeId } }) => handlePrizeSelection(prizeId)}
                label="Выбрать как победителя"
              >
                {prizePlaces.map(place => (
                  <DropdownItem key={place.id} id={place.id}>
                    {place.title}
                  </DropdownItem>
                ))}
              </Dropdown>
            )
          ) : (
            <Button
              className="ml-3"
              onClick={() => handlePrizeSelection(null)}
            >
              Убрать из победителей
            </Button>
          ))}
      </div>
    </div>
  );
}

ContestWorkViewActions.propTypes = {
};

const Button = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  color: #ef0000;
  border: #ef0000 solid 1px;
  border-radius: 4px;
  height: 28px;
  padding: 0 7px;
  font: 15px/1 Roboto;
  cursor: pointer;
  transition: all 100ms;
  user-select: none;

  &:hover {
    background: #ef0000;
    color: #fff;
  }
`;
