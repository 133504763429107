/* eslint-disable no-constant-condition */
import { take, put, call, fork, all } from "redux-saga/effects";
import { shuffle } from 'Core/services/tools';

import { api } from "../services";
import * as actions from "../actions";

function* watchLessonTest() {
  while (true) {
    const { payload } = yield take(actions.REQUEST_LESSON_TEST);

    const { response } = yield call(api.fetchLessonTest, payload);

    if (response) {
      yield put({
        type: actions.SUCCESS_LESSON_TEST,
        payload: response.questions.map(question => ({
          ...question,
          options: shuffle(question.options),
        })),
      });
    }
  }
}

function* watchLessonTestComplete() {
  while (true) {
    const { payload } = yield take(
      actions.LESSON_TEST_COMPLETE[actions.REQUEST],
    );

    const { response, error } = yield call(api.sendLessonTestResult, payload.lessonId);

    if (response) {
      yield put({
        type: actions.LESSON_TEST_COMPLETE[actions.SUCCESS],
        response,
        payload,
      });
    } else {
      yield put({
        type: actions.LESSON_TEST_COMPLETE[actions.FAILURE],
        error,
      });
    }
  }
}

export default function* root() {
  yield all([
    fork(watchLessonTest),
    fork(watchLessonTestComplete),
  ]);
}
