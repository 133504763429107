/* eslint no-mixed-operators: ["error", {"allowSamePrecedence": true}] */
import React, { Component } from 'react';
import ImageModalWrapper from './ImageModalWrapper';

class ImageModal extends Component {
  padding = 20

  initialWidth = 0

  initialHeight = 0

  // x, y = 0, 0 (center of the screen)
  // scale = 1 (масштабирование изображения)
  state = {
    x: 0,
    y: 0,
    scale: 1,
  }

  // ----------------- calculations -----------------

  getSizes(scale = this.state.scale) {
    return {
      img: {
        w: this.initialWidth * scale,
        h: this.initialHeight * scale,
      },
      global: {
        w: window.innerWidth,
        h: window.innerHeight,
      },
    };
  }

  moveImage(diff) {
    const { img, global } = this.getSizes();

    const canMoveByX = diff.x && img.w > global.w;
    const canMoveByY = diff.y && img.h > global.h;

    this.updateToFitBoundaries({
      x: canMoveByX ? (this.state.x + diff.x) : this.state.x,
      y: canMoveByY ? (this.state.y + diff.y) : this.state.y,
      scale: this.state.scale,
    });
  }

  updateToFitBoundaries({ x, y, scale }) {
    /* eslint-disable no-param-reassign */
    const { img, global } = this.getSizes(scale);
    // если изображение маленькое
    if (img.w < global.w) {
      this.setState({ x: 0 });
    } else {
      const left = (global.w / 2) - (img.w / 2) + x;
      // если левый край изображения ушел далеко вправо
      if (left > this.padding) {
        x = (img.w / 2) - (global.w / 2) + this.padding;
      }

      const right = (global.w / 2) + (img.w / 2) + x;
      // если правый край изображения ушел далеко влево
      if (right < (global.w - (this.padding * 2))) {
        x = (global.w / 2) - (img.w / 2) - this.padding;
      }
      this.setState({ x });
    }

    // если изображение маленькое
    if (img.h < global.h) {
      this.setState({ y: 0 });
    } else {
      // если верхний край изображения ушел далеко вниз
      const top = (global.h / 2) - (img.h / 2) + y;
      if (top > this.padding) {
        y = (img.h / 2) - (global.h / 2) + this.padding;
      }

      const bottom = (global.h / 2) + (img.h / 2) + y;
      // если нижний край изображения ушел далеко вверх
      if (bottom < (global.h - this.padding)) {
        y = (global.h / 2) - (img.h / 2) - this.padding;
      }
      this.setState({ y });
    }
  }

  scaleImage(scaleDiff = 0, event) {
    let scale = this.state.scale + Math.sign(scaleDiff) * 0.2;
    const { img, global } = this.getSizes(scale);

    if (!scaleDiff) {
      // начальный зум для изображения, если изображение больше экрана
      const innerW = global.w - (this.padding * 2);
      const innerH = global.h - (this.padding * 2);

      const scaleX = img.w > innerW ? innerW / img.w : 1;
      const scaleY = img.h > innerH ? innerH / img.h : 1;
      scale = Math.min(scaleX, scaleY);
      this.setState({ scale });
    } else {
      // Restrict scale
      scale = Math.min(Math.max(0.25, scale), 3);

      if (scale < this.state.scale) {
        this.updateToFitBoundaries({ ...this.state, scale });
        this.setState({ scale });
      }

      if (scale > this.state.scale && event) {
        const current_img = this.getSizes().img;

        const proportions = scale / this.state.scale;
        // координаты внутри изображения
        const pointCoords = {
          x: event.pageX - this.state.x - this.img.offsetLeft,
          y: event.pageY - this.state.y - this.img.offsetTop,
        };
        // считаем на сколько они изменятся, если изображение увеличить
        const newPointCoords = {
          x: pointCoords.x * proportions,
          y: pointCoords.y * proportions,
        };

        // смещение верха/левого края изображения при увеличении
        const offsetDiff = {
          x: (current_img.w - img.w) / 2,
          y: (current_img.h - img.h) / 2,
        };

        const diff = {
          x: pointCoords.x - newPointCoords.x - offsetDiff.x,
          y: pointCoords.y - newPointCoords.y - offsetDiff.y,
        };
        this.setState({ scale }, () => { this.moveImage(diff); });
      }
    }
  }

  // --------------------- end of calculations ----------------------

  componentDidMount() {
    this.img.onload = () => {
      this.initialWidth = this.img.width;
      this.initialHeight = this.img.height;

      this.scaleImage();
    };
    this.ghostImage = document.createElement("img");
    this.ghostImage.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
  }

  calculateStyles = () => {
    const { scale, x, y } = this.state;
    const { img, global } = this.getSizes();

    const left = (global.w - img.w) / 2;
    const top = (global.h - img.h) / 2;

    // left, top - центр изображения ставит в центр экрана
    // transform - ставит смещение изображения от центра экрана (зависит от x, y)
    return {
      left,
      top,
      transform: `translate3d(${x}px, ${y}px, 0) scale3d(${scale}, ${scale}, 1)`,
      visibility: 'visible',
      backgroundImage: `url(${this.props.image_src})`,
    };
  }

  handleOnWheel = (e) => {
    if (e.ctrlKey) {
      e.preventDefault();
      this.moveImage({ x: 0, y: e.deltaY });
    } else {
      this.scaleImage(e.deltaY * -0.001, e);
    }
  }

  handleOnDragStart = (e) => {
    e.nativeEvent.dataTransfer.setDragImage(this.ghostImage, 0, 0);
    // bypass for "drag" event in Mozilla Firefox
    document.addEventListener("dragover", this.onDocumentDragOver);

    this.stateOnStart = {
      pageX: e.pageX,
      pageY: e.pageY,
      x: this.state.x,
      y: this.state.y,
    };
  }

  onDocumentDragOver = (e) => {
    this.moveImage({
      x: (e.clientX - this.stateOnStart.pageX) + this.stateOnStart.x - this.state.x,
      y: (e.clientY - this.stateOnStart.pageY) + this.stateOnStart.y - this.state.y,
    });
  }

  handleDragEnd = (e) => {
    document.removeEventListener("dragover", this.onDocumentDragOver);
  }

  setRef = (img_el) => {
    if (!img_el) return;

    if (this.img) {
      this.img.removeEventListener("wheel", this.handleOnWheel);
    }
    this.img = img_el;
    this.img.addEventListener("wheel", this.handleOnWheel, { passive: false });
  }

  handleModalClick = (e) => {
    if (e.target != this.img) this.props.closeImageModal(e);
  }

  componentWillUnmount() {
    this.img.removeEventListener("wheel", this.handleOnWheel);
    document.removeEventListener("dragover", this.onDocumentDragOver);
  }

  render() {
    const { image_src, closeImageModal } = this.props;
    const styles = this.calculateStyles();

    return (
      <ImageModalWrapper closeModal={closeImageModal}>

        <div className="d-flex justify-content-center modal-image" onClick={this.handleModalClick}>
          <img
            onDragStart={this.handleOnDragStart}
            onDragEnd={this.handleDragEnd}
            style={styles}
            src={image_src}
            alt="Изображение в модальном окне"
            ref={this.setRef}
          />
          <a id="download-image" href={image_src} download>
            <i className="fa fa-download" />
            Скачать
          </a>
        </div>
      </ImageModalWrapper>
    );
  }
}
export default ImageModal;
