import React from "react";

// eslint-disable-next-line react/prop-types
export default function VkFreeSubscribeModule({ id, type }) {
  return (
    <div className="vk-free-subscribe">
      <h1 className="block-title">
        Регистрация на бесплатный курс по
        {' '}
        {type}
      </h1>

      <a
        className="ui button"
        href={`/users/auth/vkontakte?origin=${id}-course-vk-free-subscribe`}
      >
        Регистрация
      </a>
    </div>
  );
}
