import { call, put, take, takeEvery } from "redux-saga/effects";
import {
  UPLOAD_REQUEST,
  IMAGE_UPLOAD,
  uploadProgress,
  uploadSuccess,
  uploadFailure,
  uploadImageProgress,
  uploadImageSuccess,
  uploadImageFailure,
  uploadTypeError,
} from "../actions";
import { createUploadFileChannel } from "./createUploadFileChannel";
import { createUploadImageChannel } from "./uploadImageChannel";

// Upload the specified file
export function* uploadFileSaga(file, opt) {
  const { flow_id, lesson_id, exercise_id } = opt;

  const channel = yield call(
    createUploadFileChannel,
    `/flow/${flow_id}/lesson/${lesson_id}/exercise/${exercise_id}/homework/upload`,
    file,
  );

  while (true) {
    const payload = yield take(channel);
    const { progress = 0, err, message, success } = payload;

    if (err) {
      alert(message);
      yield put(uploadFailure(file, err));
      return;
    }
    if (success) {
      yield put(uploadSuccess(file));
      window.mixpanelTrackHomeworkUpload(opt);
      return;
    }
    yield put(uploadProgress(file, progress));
  }
}
export function* uploadImageSaga(file) {
  const channel = yield call(createUploadImageChannel, "/image/upload", file);

  while (true) {
    const { progress = 0, err, success, response } = yield take(channel);
    if (err) {
      yield put(uploadImageFailure(err));
      return;
    }
    if (success) {
      const resp_json = JSON.parse(response);

      if (resp_json.typeError) {
        yield put(uploadTypeError());
        return;
      }
      yield put(uploadImageSuccess(resp_json));
      return;
    }
    yield put(uploadImageProgress(progress));
  }
}

function* watchUploadImageSaga(action) {
  yield call(uploadImageSaga, action.payload);
}

function* watchUploadSaga(action) {
  const { file, opt } = action.payload;

  yield call(uploadFileSaga, file, opt);
}

// Watch for an upload request and then
// defer to another saga to perform the actual upload
export default function* uploadRequestWatcherSaga() {
  yield takeEvery(UPLOAD_REQUEST, watchUploadSaga);
  yield takeEvery(IMAGE_UPLOAD, watchUploadImageSaga);
}
