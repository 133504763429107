import React from "react";

export default function EnvelopeWithClockIcon(props) {
  return (
    <div className="tooltip_icon" onClick={props.onClick}>
      <svg
        width="26"
        height="21"
        viewBox="0 0 26 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 7.81343V19.5H20.0947V7.81368L11.5109 14.5581C11.2389 14.7718 10.8561 14.7718 10.5842 14.5581L2 7.81343ZM18.6636 7.03052H3.43145L11.0475 13.0146L18.6636 7.03052ZM0.5 6.78259C0.5 6.09109 1.06057 5.53052 1.75207 5.53052H20.3427C21.0342 5.53052 21.5947 6.09109 21.5947 6.78259V19.7479C21.5947 20.4394 21.0342 21 20.3427 21H1.75207C1.06057 21 0.5 20.4394 0.5 19.7479V6.78259Z"
          fill="#2196F3"
        />
        <circle
          cx="19.7835"
          cy="6.27108"
          r="4.78549"
          fill="white"
          stroke="#2196F3"
          strokeWidth="1.25207"
        />
        <path
          d="M19.5093 3.81543V6.62806H22.3219"
          stroke="#2196F3"
          strokeWidth="1.25207"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span className="tooltiptext_icon">{props.tooltip}</span>
    </div>
  );
}
