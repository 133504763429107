import React, { Component } from 'react';
import { connect } from "react-redux";
import { func, object, string, elementType, array, number } from 'prop-types';
import get from 'lodash/get';

import { history } from "Core/services";
import { getCatalogCoversFields } from "Core/reducers/selectors";
import { loadCatalogItems, toggleLikeCatalogItem, prepareCatalog } from '../../core/actions';

import CatalogDropdownFilters from "../components/Catalog/CatalogDropdownFilters";
import CatalogDropdownOrderFilters from "../components/Catalog/CatalogDropdownOrderFilters";
import Spinner from '../components/layouts/Spinner';
import ListWithInfinityScroll from '../components/layouts/ListWithInfinityScroll';
import { CatalogFiltersWrapper } from '../components/Catalog/CatalogFiltersWrapper';

class CatalogLayout extends Component {
  componentDidMount() {
    const { pagination, entity, reduxCatalogKey, customParams } = this.props;

    if (pagination.firstItemsLoaded) return;

    this.props.prepareCatalog({ entity, reduxCatalogKey, filters: 1, customParams });
  }

  handleLoadItems = (options) => {
    const { pagination, entity, reduxCatalogKey, customParams } = this.props;

    this.props.loadItems({
      sortType: pagination.sortType,
      category: pagination.category,
      page: pagination.page,
      randNumber: pagination.randNumber,
      entity,
      reduxCatalogKey,
      ...options,
      customParams,
    });
  }

  onLoadMoreItems = () => {
    const { page } = this.props.pagination;
    this.handleLoadItems({ page: page + 1 });
  }

  onChangeCategory = (category) => {
    this.handleLoadItems({ category, page: 1 });
  }

  onChangeSortType = sortType => this.handleLoadItems({ sortType, page: 1 });

  handleToggleLike = (itemId) => {
    if (!this.props.currentUserId) {
      history.push('/login');
      return;
    }

    const { entity } = this.props;

    this.props.toggleLike({ itemId, entity });
  }

  renderHeaderActions = () => {
    const { pagination: { firstItemsLoaded } } = this.props;

    if (!firstItemsLoaded) return null;

    const {
      options: { header: { categoriesShown, sortsShown } }, pagination, items,
    } = this.props;

    const hideAllFilters = pagination.category === "all" && items.length === 0 && !pagination.loading;

    return (
      <div className="ctg-header__actions">
        <CatalogFiltersWrapper>
          {categoriesShown && !hideAllFilters && (
            <CatalogDropdownFilters
              filters={pagination.sortFilters}
              activeFilter={pagination.category}
              onSwitch={this.onChangeCategory}
            />
          )}
          {sortsShown && !hideAllFilters && (
            <CatalogDropdownOrderFilters
              onSwitch={this.onChangeSortType}
              activeFilter={pagination.sortType}
            />
          )}
        </CatalogFiltersWrapper>
      </div>
    );
  }

  renderHeader = () => {
    const { options: { header } } = this.props;

    if (!header) return null;

    return (
      <header className="catalog__header ctg-header">
        {header.title && header.banner && (
          <div>
            <h1 className="ctg-header__title">{header.title}</h1>
            <img className="ctg-header__banner" src={header.banner} alt="" />
          </div>
        )}
        {this.renderHeaderActions()}
      </header>
    );
  }

  renderContent = () => {
    const { pagination } = this.props;

    if (!pagination.firstItemsLoaded) {
      return <Spinner className="mt-3" />;
    }

    const { items, ItemComponent, reduxCatalogKey } = this.props;
    const compItems = items.map((item, index) => (
      <ItemComponent
        key={`${pagination.entity}_${item.id}`}
        onToggleLike={this.handleToggleLike}
        reduxCatalogKey={reduxCatalogKey}
        indexOrder={index}
        {...item}
      />
    ));

    return (
      <>
        {this.renderHeader()}

        <div className="catalog__items ctg-content my-3">
          <ListWithInfinityScroll
            items={compItems}
            loading={pagination.loading}
            error={pagination.error}
            spinner={<Spinner className="mt-3" />}
            hasMore={pagination.hasMore}
            loadMore={this.onLoadMoreItems}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="catalog">
        {this.renderContent()}
      </div>
    );
  }
}

CatalogLayout.propTypes = {
  // OWN PROPS
  entity: string.isRequired,
  defaultAllFilterName: string, // eslint-disable-line react/no-unused-prop-types
  reduxCatalogKey: string,
  ItemComponent: elementType.isRequired,
  options: object,
  customParams: object,
  // MAP STATE PROPS
  currentUserId: number,
  pagination: object.isRequired,
  items: array,
  toggleLike: func.isRequired,
  loadItems: func.isRequired,
  prepareCatalog: func.isRequired,
};

CatalogLayout.defaultProps = {
  currentUserId: null,
  defaultAllFilterName: 'Все категории',
  reduxCatalogKey: null,
  options: {},
  items: [],
  customParams: {},
};

const mapStateToProps = (state, ownProps) => {
  const defaultState = { pagination: { sortFilters: [] }, items: [] };
  const { pagination, items } = get(state.entities.catalog, ownProps.reduxCatalogKey, defaultState);

  return {
    pagination: {
      ...pagination,
      sortFilters: [['all', ownProps.defaultAllFilterName], ...pagination.sortFilters],
    },
    currentUserId: state.local.currentUser.id,
    items: getCatalogCoversFields(state, items, ownProps.entity),
  };
};

export default connect(mapStateToProps, {
  loadItems: loadCatalogItems,
  toggleLike: toggleLikeCatalogItem,
  prepareCatalog,
})(CatalogLayout);
