import React from 'react';
import { any } from 'prop-types';
import styled from 'styled-components';

export const CatalogFiltersWrapper = props => <FiltersWrapper>{props.children}</FiltersWrapper>;

CatalogFiltersWrapper.propTypes = {
  children: any,
};

CatalogFiltersWrapper.defaultProps = {
  children: "",
};

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;
