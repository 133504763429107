import React from 'react';
import { func, string } from 'prop-types';

const FileUploadError = ({ uploadImage, hideUploadError, status }) => (
  <div className="d-flex">
    <div className="upload_file_error">
      <span>
        <i className="fa fa-exclamation-circle" style={{ fontSize: '18px' }} />
      </span>
      {' '}
      &ensp;
      <span onClick={hideUploadError}>
        {status === 'type_error' && 'Нельзя загружать файлы данного типа.'}
        {status === 'error' && 'Ошибка загрузки.'}
        {status === 'exceeded_limit' && 'Размер файла должен быть не больше 70 Mb'}
        <i className="fa fa-times" />
      </span>
      {' '}
      &nbsp;
    </div>

    {status === 'error'
    && (
      <span id="upload_file_attempt" onClick={uploadImage}>
        Попробовать снова
      </span>
    )}
  </div>
);

FileUploadError.propTypes = {
  uploadImage: func.isRequired,
  hideUploadError: func.isRequired,
  status: string.isRequired,
};

export default FileUploadError;
