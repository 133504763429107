import React from "react";
import { Provider } from "react-redux";
import { Route, Router, Switch, useParams } from "react-router-dom";

import initializeStore from "Core/store/initializeStore";
import { history } from "Core/services";

import StudentWorkView from "./StudentWorkView";

const getStore = initializeStore();

function StudentWorkRouted() {
  const { id } = useParams();

  return (
    <StudentWorkView
      id={parseInt(id, 10)}
      reduxCatalogKey="otherWorks"
      closeModal={() => (history.location.state
        ? history.goBack()
        : history.push("/student-works"))}
    />
  );
}

class StudentWorkRoot extends React.Component {
  render() {
    return (
      <Provider store={getStore()}>
        <Router history={history}>
          <Switch>
            <Route
              exact
              path="/student-work/:id"
              component={StudentWorkRouted}
            />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default StudentWorkRoot;
