import React from "react";
import { array, func, number, oneOfType, string } from "prop-types";
import cn from "classnames";

// filters --> [['all', 'Все'], ...]
const CatalogFilters = ({ filters, onSwitch, activeFilter }) => {
  const onSwitchHandle = (index) => {
    const [nextFilter] = filters[index];

    if (nextFilter === activeFilter) return;

    onSwitch(nextFilter);
  };

  return (
    <div className="cell d-flex flex-wrap">
      {filters.map(([filterKey, filterValue], index) => (
        <div
          key={filterKey}
          className={cn("ctg-filter mr-2 mt-2", { active: activeFilter === filterKey })}
          onClick={() => onSwitchHandle(index)}
        >
          {filterValue}
        </div>
      ))}
    </div>
  );
};

CatalogFilters.propTypes = {
  activeFilter: oneOfType([string, number]),
  onSwitch: func.isRequired,
  filters: array.isRequired,
};

CatalogFilters.defaultProps = {
  activeFilter: 'all',
};

export default CatalogFilters;
