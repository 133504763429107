import React, { useEffect } from 'react';
import { array, func, object, string } from 'prop-types';

import CatalogDropdownFilters from '../Catalog/CatalogDropdownFilters';
import { CatalogFiltersWrapper } from '../Catalog/CatalogFiltersWrapper';

const CoursesLayout = ({ title, banner, filters, onSwitchCategory, children, activeFilter }) => {
  useEffect(() => {
    if (typeof window.observer === 'object') {
      window.observer.observe();
    }
  }, []);

  return (
    <div className="catalog">
      <header className="catalog__header ctg-header">
        {/* Header title + Banner */}
        <div>
          <h1 className="ctg-header__title">{title}</h1>
          <img className="ctg-header__banner lozad" data-src={banner} alt="" />
        </div>

        {/* Filters + Sorts */}
        <div className="ctg-header__actions">
          <CatalogFiltersWrapper>
            <CatalogDropdownFilters
              filters={filters}
              activeFilter={activeFilter}
              onSwitch={onSwitchCategory}
            />
          </CatalogFiltersWrapper>
        </div>
      </header>
      {children}
    </div>
  );
};

CoursesLayout.propTypes = {
  onSwitchCategory: func.isRequired,
  children: object,
  filters: array.isRequired,
  banner: string.isRequired,
  title: string.isRequired,
  activeFilter: string.isRequired,
};

CoursesLayout.defaultProps = {
  children: null,
};

export default CoursesLayout;
