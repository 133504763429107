import React, {
  forwardRef,
  useState,
} from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { string } from "prop-types";

function Checkbox(props) {
  const {
    label,
    name = "unnamed",
    value,
    checked: defaultChecked = false,
    onChange = () => {},
  } = props;

  const [checked, setCheck] = useState(defaultChecked);

  const handleClick = () => {
    setCheck(!checked);
    onChange(!checked);
  };

  return (
    <CheckboxComponent
      ref={props.forwardedRef}
      onClick={handleClick}
      className="checkbox-component"
    >
      <Check>
        <FontAwesomeIcon icon={checked ? faCheckSquare : faSquare} />
      </Check>
      <Label>{label}</Label>
      <input
        type="checkbox"
        checked={checked}
        name={name}
        value={value}
        onChange={() => onChange(!checked)}
      />
    </CheckboxComponent>
  );
}

const forwardRefWrapper = forwardRef((props, ref) => <Checkbox {...props} forwardedRef={ref} />);

forwardRefWrapper.displayName = "Checkbox";

Checkbox.propTypes = {
  label: string,
};

Checkbox.defaultProps = {
  label: 'Без названия',
};

export default forwardRefWrapper;

const CheckboxComponent = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 4px 6px;
  cursor: pointer;
  user-select: none;

  input[type="checkbox"] {
    display: none;
    visibility: hidden;
  }
`;

const Check = styled.div`
  position: relative;
  color: #2f80ed;
  font-size: 18px;
`;

const Label = styled.label`
  position: relative;
  margin: 0 0 0 16px;
  cursor: pointer;
`;
