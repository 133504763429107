import React from 'react';
import { string } from 'prop-types';

const Breadcrumbs = ({ title }) => (
  <div className="breadcrumbs-comp">
    <a href="/contests">Конкурсы</a>
    <span className="breadcrumbs-comp__splitter">/</span>
    <span>{title}</span>
  </div>
);

Breadcrumbs.propTypes = {
  title: string.isRequired,
};

export default Breadcrumbs;
