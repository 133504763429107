import React from "react";
import { number, string } from "prop-types";

import CatalogRoot from '../mainApp/containers/CatalogRoot';
import ArticleCover from '../mainApp/components/Article/Cover';

const ArticleModule = ({ articleType, title, banner, filterName }) => (
  <CatalogRoot
    options={{
      header: {
        title,
        banner,
        categoriesShown: true,
        sortsShown: true,
      },
    }}
    defaultAllFilterName={filterName}
    entity="articles"
    reduxCatalogKey={`articles_${articleType}`}
    ItemComponent={ArticleCover}
    customParams={{ articleType }}
  />
);

ArticleModule.propTypes = {
  articleType: number.isRequired,
  title: string.isRequired,
  banner: string.isRequired,
  filterName: string.isRequired,
};

export default ArticleModule;
