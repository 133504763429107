import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateRouterState } from 'Core/actions';

class MainApp extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
      location: { pathname },
    } = this.props;

    this.props.updateRouterState({ pathname, params });
  }

  shouldComponentUpdate(nextProps) {
    const { location: { pathname } } = this.props;

    if (pathname !== nextProps.location.pathname) {
      this.props.updateRouterState({
        pathname: nextProps.location.pathname,
        params: nextProps.match.params,
      });
    }

    return true;
  }

  render() {
    return (
      <>
        {this.props.children}
      </>
    );
  }
}

MainApp.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  updateRouterState: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = state => ({
  prevPathname: state.router.pathname,
});

const MainAppConnected = connect(mapStateToProps, {
  updateRouterState,
})(MainApp);

export default withRouter(MainAppConnected);
