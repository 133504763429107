import React, { Component } from "react";
import { bool, func, number } from "prop-types";

export default class Like extends Component {
  render() {
    const { onToggleLike, likesNumber, alreadyLiked } = this.props;
    const likeClass = "modal-hw-like";
    const likesNumberRes = likesNumber === 0 ? "" : likesNumber;

    return (
      <div className="modal-hw-counters">
        {alreadyLiked && (
          <div
            className={`${likeClass} ${likeClass}-active`}
            onClick={onToggleLike}
          >
            <i className="fa fa-heart" />
            <span className="modal-hw-like-text">Мне нравится</span>
            &nbsp;
            <span className="modal-hw-like-count">{likesNumberRes}</span>
            &nbsp;
          </div>
        )}

        {!alreadyLiked && (
          <div
            className={`${likeClass} ${likeClass}-hover`}
            onClick={onToggleLike}
          >
            <i className="fa fa-heart-o" />
            <span className="modal-hw-like-text">Мне нравится </span>
            &nbsp;
            <span className="modal-hw-like-count">{likesNumberRes}</span>
            &nbsp;
          </div>
        )}
      </div>
    );
  }
}

Like.propTypes = {
  onToggleLike: func.isRequired,
  likesNumber: number,
  alreadyLiked: bool,
};

Like.defaultProps = {
  likesNumber: 0,
  alreadyLiked: false,
};
