import { combineReducers } from "redux";
import omit from 'lodash/omit';

import { humps } from 'Core/services/tools';
// import mobileReducers from "Mobile/reducers";
import uploadFileReducer from "Core/gears/uploadFile/reducers";
import uploadReducer from "Core/gears/upload/reducers";
import * as ActionTypes from "../actions";
import paginate from './paginate';
import {
  notificationCenter,
  currentUser,
  contestsInfo,
  userAuthorize,
  contestReducer,
} from "./local";

import entitiesReducer from './entities';

import * as selectors from "./selectors";

export { selectors };

// Updates the pagination data for different actions.
const pagination = combineReducers({
  chats: paginate({
    mapActionToKey: action => (action.id ? String(action.id) : "all"),
    types: [
      ActionTypes.CHAT.REQUEST,
      ActionTypes.CHAT.SUCCESS,
      ActionTypes.CHAT.FAILURE,
    ],
  }),
});

function router(state = { pathname: "/" }, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_ROUTER_STATE:
      return action.state;
    default:
      return state;
  }
}
function open_image_modal(state = null, action) {
  switch (action.type) {
    case ActionTypes.OPEN_IMAGE_MODAL:
      return action.src;
    case ActionTypes.CLOSE_IMAGE_MODAL:
      return null;
    default:
      return state;
  }
}
function open_personal_remark_modal(state = false, action) {
  switch (action.type) {
    case ActionTypes.OPEN_PERSONAL_REMARK_MODAL:
      return true;
    case ActionTypes.CLOSE_PERSONAL_REMARK_MODAL:
      return false;
    default:
      return state;
  }
}
function open_consultation_payment_modal(state = false, action) {
  switch (action.type) {
    case ActionTypes.OPEN_CONSULTATION_PAYMENT_MODAL:
      return true;
    case ActionTypes.CLOSE_CONSULTATION_PAYMENT_MODAL:
      return false;
    default:
      return state;
  }
}
function open_consultation_success_payment_modal(state = false, action) {
  switch (action.type) {
    case ActionTypes.OPEN_CONSULTATION_SUCCESS_PAYMENT_MODAL:
      return true;
    case ActionTypes.CLOSE_CONSULTATION_SUCCESS_PAYMENT_MODAL:
      return false;
    default:
      return state;
  }
}

function passLessonTestModal(state = { open: false }, action) {
  switch (action.type) {
    case ActionTypes.OPEN_PASS_LESSON_TEST_MODAL:
      return {
        open: true,
        lessonId: action.payload.lessonId,
        lessonTitle: action.payload.lessonTitle,
        reduxCatalogKey: action.payload.reduxCatalogKey,
      };
    case ActionTypes.CLOSE_PASS_LESSON_TEST_MODAL:
      return { open: false };
    default:
      return state;
  }
}

function lessonTestModal(state = { open: false }, action) {
  switch (action.type) {
    case ActionTypes.OPEN_LESSON_TEST_MODAL:
      return {
        open: true,
        lessonId: action.payload.lessonId,
        reduxCatalogKey: action.payload.reduxCatalogKey,
      };
    case ActionTypes.CLOSE_LESSON_TEST_MODAL:
      return { open: false };
    default:
      return state;
  }
}

function constant(state = false) {
  return state;
}

const value = (actionType, defaultState = "") => (
  state = defaultState,
  action,
) => {
  if (action.type === actionType) {
    return action.value;
  }
  if (action.type === ActionTypes.CHAT_COMPONENT_UNMOUNT) {
    return { ...state, page: 0 };
  }
  return state;
};

const value_response = (actionType, defaultState = "") => (
  state = defaultState,
  action,
) => {
  if (action.type === actionType) {
    return action.response.value;
  }
  return state;
};

const message_upload_image_id = () => {
  // по дефолту -2, для удаления картинки -1
  const reducer = value_response(ActionTypes.IMAGE_UPLOAD_SUCCESS, -2);
  const { IMAGE_UPLOAD_REMOVE } = ActionTypes;

  return (state = -2, action) => {
    if (action.type === IMAGE_UPLOAD_REMOVE) {
      return -1;
    }
    return reducer(state, action);
  };
};

function imageUploadingState(state = { progress: null, status: null }, action) {
  switch (action.type) {
    case ActionTypes.IMAGE_UPLOAD:
      return { ...state, status: "loading" };
    case ActionTypes.IMAGE_UPLOAD_SUCCESS:
      return { ...state, status: "success" };
    case ActionTypes.IMAGE_UPLOAD_FAILURE:
      return { ...state, status: "error" };
    case ActionTypes.IMAGE_UPLOAD_TYPE_ERROR:
      return { ...state, status: "type_error" };
    case ActionTypes.IMAGE_UPLOAD_REMOVE:
      return { progress: null, status: null };
    case ActionTypes.IMAGE_EXCEEDED_LIMIT:
      return { ...state, status: "exceeded_limit" };
    case ActionTypes.IMAGE_UPLOAD_PROGRESS:
      return { ...state, progress: action.payload };
    default:
      return state;
  }
}

const defaultRequestState = {
  is_fetching: false,
  is_loaded: false,
  ids: [],
  error: false,
};

const requestStateObject = actionType => (
  state = defaultRequestState,
  action,
) => {
  switch (action.type) {
    case actionType[ActionTypes.REQUEST]:
      return {
        ...state,
        is_fetching: true,
        error: false,
      };
    case actionType[ActionTypes.FAILURE]:
      return {
        ...state,
        is_fetching: false,
        error: true,
      };
    case actionType[ActionTypes.SUCCESS]:
      return {
        ...state,
        is_fetching: false,
        is_loaded: true,
        error: false,
        ids:
          typeof action.options.page !== "undefined"
            ? [...(action.response.result.ids || []), ...state.ids]
            : [...(action.response.result.ids || [])],
        total: action.response.result.total,
      };
    default:
      return state;
  }
};

const requestByIds = actionType => (state = {}, action) => {
  const context_key = JSON.stringify(action.options, (k, v) => (k === "page" ? undefined : v),
  );

  switch (action.type) {
    case actionType[ActionTypes.REQUEST]:
    case actionType[ActionTypes.FAILURE]:
    case actionType[ActionTypes.SUCCESS]:
      return {
        ...state,
        [context_key]: requestStateObject(actionType)(
          state[context_key],
          action,
        ),
      };
    default:
      return state;
  }
};

const chatsCountersReducer = (state = { total: 0, unread: 0 }, action) => {
  if (action.type === ActionTypes.COUNTERS[ActionTypes.SUCCESS]) {
    return action.response.result.meta;
  }

  // обновляем счетчик не прочитанных чатов при создании или
  // прочтении сообщения
  const { MESSAGE_CREATED, MESSAGE_READED } = ActionTypes;
  if ([MESSAGE_CREATED, MESSAGE_READED].includes(action.type)) {
    return Object.assign(state, action.meta.chatsCounters);
  }
  return state;
};

// example state = [{ chat_id, user_id, name }]
const blockedChatIdsReducer = (state = {}, action) => {
  let payload;
  let chat_id;
  let user_id;

  switch (action.type) {
    case ActionTypes.TEACHER_STARTED_TYPING:
      ({ payload } = action);
      return { ...state, [payload.chat_id]: payload };
    case ActionTypes.TEACHER_STOPPED_TYPING:
      ({
        payload: { chat_id, user_id },
      } = action);

      if (state[chat_id] && state[chat_id].user_id === user_id) {
        return omit(state, [chat_id]);
      }
      return state;
    default:
      return state;
  }
};

const lessonTests = (
  state = { loading: false, questions: [], error: null },
  action,
) => {
  switch (action.type) {
    case ActionTypes.REQUEST_LESSON_TEST:
      return { ...state, loading: true, questions: [] };
    case ActionTypes.SUCCESS_LESSON_TEST:
      return {
        ...state,
        loading: false,
        questions: action.payload,
        error: null,
      };
    case ActionTypes.FAILURE_LESSON_TEST:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

const eduCertificateReducer = (
  state = {
    loaded: false,
    issuancing: false,
  },
  action,
) => {
  switch (action.type) {
    case ActionTypes.EDU_CERTIFICATE_TEMPLATE_LOAD:
      return {
        ...state,
        userId: action.userId,
        courseId: action.courseId,
      };
    case ActionTypes.EDU_CERTIFICATE_ISSUANCE:
      return {
        ...state,
        issuancing: true,
      };
    case ActionTypes.EDU_CERTIFICATE_TEMPLATE_LOAD_DONE:
      return {
        ...state,
        ...humps(action.response),
        loaded: true,
      };
    case ActionTypes.EDU_CERTIFICATE_ISSUANCE_DONE:
      return {
        ...state,
        ...humps(action.response),
        issuancing: false,
      };

    case ActionTypes.EDU_CERTIFICATE_ISSUANCED:
      return {
        loaded: false,
        issuancing: false,
      };
    default:
      return state;
  }
};

const userProfileReducer = (
  state = {
    loading: true,
  },
  action,
) => {
  switch (action.type) {
    case ActionTypes.USER_PROFILE_LOAD_DONE:
    case ActionTypes.USER_PROFILE_UPDATE_DONE:
      return {
        ...state,
        ...humps(action.response),
        loading: false,
      };
    default:
      return state;
  }
};

const changeUserPasswdReducer = (
  state = { loading: false, error: null },
  action,
) => {
  switch (action.type) {
    case ActionTypes.CHANGE_USER_PASSWD[ActionTypes.REQUEST]:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.CHANGE_USER_PASSWD[ActionTypes.SUCCESS]:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ActionTypes.CHANGE_USER_PASSWD[ActionTypes.FAILURE]:
      return {
        ...state,
        loading: false,
        error: action.exception.error,
      };
    case ActionTypes.CHANGE_USER_PASSWD_RESET_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

const utils = combineReducers({
  uploadFileReducer,
  uploadReducer,
});

const meta = combineReducers({
  pagination,
  chatsByContext: requestByIds(ActionTypes.CHATS),
  chatsCounters: chatsCountersReducer,
  // chatByContext: requestByIds(ActionTypes.CHAT),
  // messagesByDate: requestByChat(ActionTypes.CHAT),
});

const local = combineReducers({
  userAuthorizeReducer: userAuthorize,
  changeUserPasswdReducer,
  open_image_modal,
  open_personal_remark_modal,
  open_consultation_payment_modal,
  open_consultation_success_payment_modal,
  passLessonTestModal,
  lessonTestModal,
  is_teacher: constant,
  is_admin: constant,
  channel: constant,
  me: constant,
  nickname: constant,
  avatar: constant,
  currentUser,
  message_upload_image_id: message_upload_image_id(),
  imageUploadingState,
  chats: combineReducers({
    context: value(ActionTypes.CHATS_SET_CONTEXT, {
      all: true,
      page: 0,
      search: "",
    }),
  }),
  current_chat: combineReducers({
    context: value(ActionTypes.CHAT_SET_CONTEXT, { page: 0 }),
  }),
  blocked_chats: blockedChatIdsReducer,

  // CONTESTS
  contestsReducer: contestsInfo,
  contestReducer,

  // TEST LESSON
  lessonTests,
  // NOTIFICATION_CENTER
  notificationCenterReducer: notificationCenter,

  // USER_PROFILE
  userProfileReducer,

  // EDU_CERTIFICATE
  eduCertificateReducer,
});

const rootReducer = combineReducers({
  // mobile: mobileReducers,
  local,
  meta,
  entities: entitiesReducer,
  router,
  utils,
});

export default rootReducer;
