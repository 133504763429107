import { arrayOf, string, number, bool, shape } from 'prop-types';

const option = shape({
  id: number.isRequired,
  text: string.isRequired,
  correct: bool.isRequired,
  comment: string,
});

option.defaults = {
  text: '',
};

const question = shape({
  id: number.isRequired,
  text: string.isRequired,
  lesson_id: number.isRequired,
  comment: string,
  image: string,
  options: arrayOf(option),
});

const studentQuestion = shape({
  id: number.isRequired,
  text: string.isRequired,
  comment: string,
  image: string,
  options: arrayOf(option),
});

export { option, question, studentQuestion };
