import React from 'react';
import { string, func } from 'prop-types';

export default function InputFile(props) {
  const { className, faIcon, setRef, ...inputProps } = props;

  return (
    <div className={`input_file_field ${className}`}>
      <i className={`fa ${faIcon}`} />
      <input type="file" ref={setRef} {...inputProps} />
    </div>
  );
}

InputFile.propTypes = {
  className: string,
  faIcon: string,
  setRef: func,
};

InputFile.defaultProps = {
  className: '',
  faIcon: 'fa-paperclip',
};
