import React from "react";
import { func, string } from "prop-types";

export default function RemarkBlueIcon(props) {
  return (
    <div className="tooltip_icon" onClick={props.onClick}>
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="18"
          height="17"
          rx="1.5"
          fill="white"
          stroke="#2196F3"
        />
        <rect
          x="0.75"
          y="0.75"
          width="17.5"
          height="16.5"
          rx="1.25"
          stroke="#2196F3"
          strokeWidth="1.5"
        />
        <mask id="path-3-inside-1" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 5H4V6.50442H15V5ZM9 8H4V9.50442H9V8ZM10 8H15V9.50442H10V8ZM15 11H4V12.5044H15V11Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 5H4V6.50442H15V5ZM9 8H4V9.50442H9V8ZM10 8H15V9.50442H10V8ZM15 11H4V12.5044H15V11Z"
          fill="#B4B4B4"
        />
        <path
          d="M4 5V4H3V5H4ZM15 5H16V4H15V5ZM4 6.50442H3V7.50442H4V6.50442ZM15 6.50442V7.50442H16V6.50442H15ZM4 8V7H3V8H4ZM9 8H10V7H9V8ZM4 9.50442H3V10.5044H4V9.50442ZM9 9.50442V10.5044H10V9.50442H9ZM15 8H16V7H15V8ZM10 8V7H9V8H10ZM15 9.50442V10.5044H16V9.50442H15ZM10 9.50442H9V10.5044H10V9.50442ZM4 11V10H3V11H4ZM15 11H16V10H15V11ZM4 12.5044H3V13.5044H4V12.5044ZM15 12.5044V13.5044H16V12.5044H15ZM4 6H15V4H4V6ZM5 6.50442V5H3V6.50442H5ZM15 5.50442H4V7.50442H15V5.50442ZM14 5V6.50442H16V5H14ZM4 9H9V7H4V9ZM5 9.50442V8H3V9.50442H5ZM9 8.50442H4V10.5044H9V8.50442ZM8 8V9.50442H10V8H8ZM15 7H10V9H15V7ZM16 9.50442V8H14V9.50442H16ZM10 10.5044H15V8.50442H10V10.5044ZM9 8V9.50442H11V8H9ZM4 12H15V10H4V12ZM5 12.5044V11H3V12.5044H5ZM15 11.5044H4V13.5044H15V11.5044ZM14 11V12.5044H16V11H14Z"
          fill="#2196F3"
          mask="url(#path-3-inside-1)"
        />
      </svg>
      <span className="tooltiptext_icon">{props.tooltip}</span>
    </div>
  );
}

RemarkBlueIcon.propTypes = {
  onClick: func,
  tooltip: string,
};
