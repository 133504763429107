import React, { useState } from "react";
import { bool, func, object } from "prop-types";

import ExerciseInfo from "./ExerciseInfo";
import Status from "./Status";

const HomeworkOldHeader = ({
  isTeacher,
  homework,
  isGifConverting,
  convertToGif,
}) => {
  const [shownReviewForm, setShownReviewForm] = useState(false);

  const onShowReviewForm = () => {
    setShownReviewForm(prevState => !prevState);
  };

  return (
    <div className="modal-header modal-hw-header">
      <a href={`/user/${homework.user.id}`}>
        <img className="modal-hw-avatar" src={homework.user.avatar} alt="" />
      </a>

      <div className="modal-hw-info">
        <p className="modal-hw-name">
          <a href={`/user/${homework.user.id}`}>{homework.user.nickname}</a>
        </p>

        <ExerciseInfo
          flowTitle={homework.course.title}
          exerciseTitle={homework.exercise.title}
        />
      </div>

      {isTeacher && homework.type === "video" && (
        <button
          type="button"
          disabled={isGifConverting}
          onClick={() => convertToGif(homework.id)}
          className="btn btn-primary btn-sm modal-hw-get-gif"
        >
          {isGifConverting ? "Конвертируем..." : "Скачать GIF"}
        </button>
      )}

      {isTeacher && homework.type !== "text" && (
        <a
          className="btn btn-primary btn-sm modal-hw-open-origin"
          href={homework.imageFull}
          target="_blank"
          rel="noopener noreferrer"
          role="button"
        >
          Открыть оригинал
        </a>
      )}

      <Status
        status={homework.status}
        isTeacher={isTeacher}
        shownReviewForm={shownReviewForm}
        onShowReviewForm={onShowReviewForm}
      />
    </div>
  );
};

HomeworkOldHeader.propTypes = {
  isTeacher: bool.isRequired,
  homework: object.isRequired,
  isGifConverting: bool.isRequired,
  convertToGif: func.isRequired,
};

export default HomeworkOldHeader;
