import { object } from 'prop-types';
import React from 'react';

export default function CourseInfo({ icons, course }) {
  return (
    <section id="course-info">
      <div className="wide-block-wrapper">
        <h1>{course.title}</h1>

        <ul className="d-flex justify-content-center align-items-center">
          <li>
            <img src={icons.lesson_icon} alt="" />
            <span>{course.lessons_number}</span>
          </li>

          <li>
            <img src={icons.exercise_icon} alt="" />
            <span>{course.exercises_number}</span>
          </li>

          <li>
            <img src={icons.subject_icon} alt="" />
            <span>{course.subjects_number}</span>
          </li>

          <li>
            <img src={icons.course_duration_icon} alt="" />
            <span>{course.course_duration}</span>
          </li>

          {course.text_ahead && (
            // eslint-disable-next-line react/no-danger
            <section className="welcome-info" dangerouslySetInnerHTML={{ __html: course.text_ahead }} />
          )}
        </ul>
      </div>
    </section>
  );
}

CourseInfo.propTypes = {
  course: object.isRequired,
  icons: object.isRequired,
};
