import React, { Component } from "react";
import { Provider } from "react-redux";

import initializeStore from "Core/store/initializeStore";
import MyFlowWorksCatalog from "./MyFlowWorksCatalog";

const getStore = initializeStore();

class MyFlowWorksCatalogRoot extends Component {
  render() {
    return (
      <Provider store={getStore()}>
        <MyFlowWorksCatalog {...this.props} />
      </Provider>
    );
  }
}

export default MyFlowWorksCatalogRoot;
