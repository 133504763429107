import React, { Component } from "react";
import { Provider } from "react-redux";

import initializeStore from "Core/store/initializeStore";
import IndexBestWorksCatalog from "./IndexBestWorksCatalog";

const getStore = initializeStore();

class IndexBestWorksCatalogRoot extends Component {
  render() {
    return (
      <Provider store={getStore()}>
        <IndexBestWorksCatalog {...this.props} />
      </Provider>
    );
  }
}

export default IndexBestWorksCatalogRoot;
