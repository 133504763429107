import React from "react";

function CoursesCountIcon() {
  return (
    <svg viewBox="0 0 30 30" width="30" height="30" xmlSpace="preserve">
      <style>
        {
          ".svg_profile_courses_count1{stroke-width:1.2}.svg_profile_courses_count1,.svg_profile_courses_count2,.svg_profile_courses_count4{fill:none;stroke:#2095f4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.svg_profile_courses_count4{stroke-width:1.5}"
        }
      </style>
      <path
        d="M24.1 4.1c0-1.6-1.3-2.8-2.8-2.8H4.5c-1.6 0-3 1.3-3 3v3.9h19.1l3.5-4.1z"
        opacity={0.42}
        fill="#2095f4"
      />
      <path
        className="svg_profile_courses_count1"
        d="M24.3 20v5.8c0 1.6-1.3 3-3 3H4.5c-1.6 0-3-1.3-3-3V4.2c0-1.6 1.3-3 3-3h16.8c1.6 0 2.8 1.3 2.8 2.8"
      />
      <path
        className="svg_profile_courses_count2"
        d="M20.4 20.3l-4-3.3L24 7.8c.7-.9 2-1 2.9-.3l.8.7c.9.7 1 2 .3 2.9l-7.6 9.2z"
      />
      <path
        className="svg_profile_courses_count2"
        d="M15.5 22.1l.9-5.1 4 3.3z"
      />
      <path
        fill="none"
        stroke="#2095f4"
        strokeWidth={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M16 19.5l2.1 1.6"
      />
      <path className="svg_profile_courses_count2" d="M22.5 9.6l4 3.1" />
      <path className="svg_profile_courses_count1" d="M1.5 7.6h19.2" />
      <path className="svg_profile_courses_count2" d="M8.7 11.1h7.4" />
      <path className="svg_profile_courses_count4" d="M5.4 11.1" />
      <g>
        <path className="svg_profile_courses_count2" d="M8.7 15.7h4.9" />
        <path className="svg_profile_courses_count4" d="M5.4 15.7" />
      </g>
      <g>
        <path className="svg_profile_courses_count2" d="M8.7 20.3h4.9" />
        <path className="svg_profile_courses_count4" d="M5.4 20.3" />
      </g>
      <g>
        <path className="svg_profile_courses_count2" d="M8.7 24.9H20" />
        <path className="svg_profile_courses_count4" d="M5.4 24.9" />
      </g>
    </svg>
  );
}

function WorksCountIcon() {
  return (
    <svg viewBox="0 0 30 30" width="30" height="30" xmlSpace="preserve">
      <style>
        {
          ".svg_profile_works_count0,.svg_profile_works_count1{fill:none;stroke:#2095f4;stroke-width:1.2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.svg_profile_works_count1{stroke-width:.75}"
        }
      </style>
      <path
        className="svg_profile_works_count0"
        d="M3.4 20.5c-1.1 0-1.9-.9-1.9-1.9v-15c0-1.1.9-1.9 1.9-1.9h15.2c1.1 0 1.9.9 1.9 1.9v15.1c0 1-.8 1.8-1.8 1.8H3.4z"
      />
      <path
        className="svg_profile_works_count0"
        d="M9.4 15.9l6.9-7.6 4.1 4.6"
      />
      <path
        className="svg_profile_works_count0"
        d="M1.9 15.7l3.5-4.4 5.7 6.5M24.7 7.8V22c0 1.5-1.2 2.7-2.7 2.7H7.3"
      />
      <path
        className="svg_profile_works_count0"
        d="M28.5 11.4v14.2c0 1.5-1.2 2.7-2.7 2.7H11"
      />
      <path
        className="svg_profile_works_count1"
        d="M12.2 9.1s-1-.2-2.6 1.7c0 0-.9-1.8-2.4-1.8M8.1 5s-.6-.1-1.7 1.1c0 0-.6-1.2-1.6-1.2"
      />
      <path
        opacity={0.42}
        fill="#2095f4"
        d="M1.9 15.3l3.5-4 4 4.6 6.9-7.6 4.2 5-.1 6.2-.6 1h-17l-.8-.6-.5-1.3z"
      />
    </svg>
  );
}

export { CoursesCountIcon, WorksCountIcon };
