import React, { useState, useEffect } from "react";
import { array, bool, func, number, object, string } from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useToasts } from "react-toast-notifications";
import SwiperCore, { Navigation, Pagination, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import cn from "classnames";
import parse from "html-react-parser";
import textFit from "textfit";

import useStateCallback from "Core/hooks/useStateCallback";
import { history } from "Core/services";
import { getContestantCoversFields } from "Core/reducers/selectors";
import { loadContest, setContestWorkAddInProgress, loadContestWorks, toggleLikeCatalogItem } from 'Core/actions';

import UploadFile from "../components/ui/UploadFile";
import { CardWork } from "../components/CardWork";
import ContestCompletedCard from "../components/Contests/ContestCompletedCard";
import ImageModal from "../components/layouts/modals/ImageModal";
import { Breadcrumbs, DeadlineCountdowner } from "../components/Contest";
import { ContestWorkView } from "../components/ContestWorkView";
import Spinner from '../components/layouts/Spinner';
import ListWithInfinityScroll from '../components/layouts/ListWithInfinityScroll';

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/lazy/lazy.scss";

SwiperCore.use([Navigation, Pagination, Lazy]);

function Contest(props) {
  /* eslint-disable react/prop-types */
  const {
    id, title, pageCover, isCompleted, deadlineRemaining, themeTextColor, taskDescription,
    taskMaterials, taskMaterialsLink, prizePlaces, winners, isWinnerSelection, contestants,
    contestWorkAddInProgress, firstUpload, currentUserId, contestsCompleted,
    allowedWorkTypesMessage, allowedWorkTypes, contestJudge = false, messageCongratulations,
    messageWinnersSelection, loaded, pagination,
  } = props;
  /* eslint-enable react/prop-types */

  const [contestWorkViewId, setContestWorkViewId] = useStateCallback(null);
  const listOfWorkIds = winners.concat(contestants).map(c => c.id);
  const activeWorkIndex = listOfWorkIds.findIndex(workId => workId === contestWorkViewId);
  const [contestMaterialFullsized, setContestMaterialFullsized] = useState(null);
  const { addToast } = useToasts();
  const catalogOpt = { entity: 'contestants', reduxCatalogKey: 'contest' };

  const updateContestWorkViewId = (nextId) => {
    // пришлось использовать таку магию, так как ContestWorkView
    // рендерится со старым contestWorkViewId при простои
    // setContestWorkViewId(nextId)
    setContestWorkViewId(null, () => setContestWorkViewId(nextId));
  };

  const handleShowNextWork = () => {
    if (activeWorkIndex + 1 === listOfWorkIds.length) {
      return updateContestWorkViewId(listOfWorkIds[0]);
    }

    updateContestWorkViewId(listOfWorkIds[activeWorkIndex + 1]);
  };

  const handleShowPrevWork = () => {
    if (activeWorkIndex - 1 === -1) {
      return updateContestWorkViewId(listOfWorkIds[listOfWorkIds.length - 1]);
    }

    updateContestWorkViewId(listOfWorkIds[activeWorkIndex - 1]);
  };

  const handleToggleLike = (itemId) => {
    if (!currentUserId) {
      history.push('/login');
      return;
    }

    props.toggleLike({ itemId, ...catalogOpt });
  };

  const handleLoadItems = (counter = 1) => {
    props.loadItems({
      page: pagination.page + counter,
      customParams: { contestId: id },
      ...catalogOpt,
    });
  };

  useEffect(() => {
    props.loadContest(id);
  }, []);

  useEffect(() => {
    const prizePlaceTitles = document.querySelectorAll('prize-place-title');
    const prizePlaceWinners = document.querySelectorAll('prize-place-winner');

    if (prizePlaceTitles.length) {
      textFit(prizePlaceTitles, {
        maxFontSize: 30,
      });
    }

    if (prizePlaceWinners.length) {
      textFit(prizePlaceWinners, {
        maxFontSize: 20,
      });
    }

    if (loaded && !pagination.firstItemsLoaded) {
      handleLoadItems(0);
    }
  }, [loaded]);

  const closeContestWorkModal = () => setContestWorkViewId(null);

  const renderContestants = () => {
    if (!loaded) return null;

    const compItems = contestants.map(item => (
      <div
        key={item.id}
        className="catalog-item cell cell_33"
      >
        <CardWork
          id={item.id}
          cover={item.cover}
          commentsCount={item.commentsNumber}
          likesCount={item.likesNumber}
          alreadyLiked={item.alreadyLiked}
          userAvatar={item.user.avatar}
          userName={item.user.nickname}
          onToggleLike={handleToggleLike}
          onClick={setContestWorkViewId}
          isCompleted={isCompleted}
        />
      </div>
    ));

    return (
      <div className="contest-works">
        <ListWithInfinityScroll
          items={compItems}
          loading={pagination.loading}
          hasMore={pagination.hasMore}
          error={pagination.error}
          spinner={<Spinner className="mt-3" />}
          loadMore={handleLoadItems}
          noItemsText="Работы отсутствуют"
        />
      </div>
    );
  };

  const renderUploadFileInput = () => {
    // если конкурс завершен, то загружать рабогты нельзя
    if (isCompleted) {
      return null;
    }

    // если не авторизован, то просим это сделать
    if (!currentUserId) {
      return (
        <div className="contest-comp__warn">
          Авторизуйтесь, чтобы загрузить работу на конкурс
        </div>
      );
    }

    // если работа еще обрабатывается, то дожидаем окончания
    if (contestWorkAddInProgress) {
      return (
        <div className="contest-comp__warn">
          <FontAwesomeIcon icon={faSpinner} pulse size="2x" color="#2c4373" />
          Обрабатываем вашу работу
        </div>
      );
    }

    // если не проверяющий и первая загрузка, то отображаем file input
    if (!contestJudge && firstUpload) {
      return (
        <>
          <UploadFile
            label=""
            name="file"
            uploadPath={`/contest/${id}/upload-work`}
            allowedTypes={allowedWorkTypes}
            onStart={() => props.setContestWorkAddInProgress()}
            onError={message => addToast(message, {
              appearance: "error",
              autoDismiss: true,
              autoDismissTimeout: 3000,
            })}
          >
            <div className="contest-comp__button">Загрузить работу</div>
          </UploadFile>
          <p className="allowed-files">{allowedWorkTypesMessage}</p>
        </>
      );
    }

    return null;
  };

  const renderContestWork = () => {
    if (!contestWorkViewId) return null;

    return (
      <ContestWorkView
        id={contestWorkViewId}
        closeModal={closeContestWorkModal}
        showNextWork={handleShowNextWork}
        showPrevWork={handleShowPrevWork}
        sliderEnabled={listOfWorkIds.length > 1}
        reduxCatalogKey="contest"
      />
    );
  };

  if (!loaded) {
    return <Spinner className="my-3" />;
  }

  return (
    <div className="contest-comp">
      {renderContestWork()}

      <Breadcrumbs title={title} />

      <h1 className="page-title">{title}</h1>
      {pageCover && !isCompleted && (
        <div className="page-cover" style={{ backgroundImage: `url(${pageCover})` }}>
          <span>До конца конкурса:</span>
          <DeadlineCountdowner
            startFrom={deadlineRemaining}
            theme={{ txtColor: themeTextColor }}
            onFinish={() => props.loadContest(id)}
          />
        </div>
      )}
      <section className="contest-task-section">
        <h3 className="section-title">Задание</h3>
        <div className="task-materials">
          <Swiper
            slidesPerView={1}
            navigation
            watchOverflow
            pagination={{ clickable: true }}
          >
            {taskMaterials.map(url => (
              <SwiperSlide key={`task_m_${url}`}>
                <div onClick={({ target: { src } }) => setContestMaterialFullsized(src)}>
                  <img alt="" src={url} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="task-description">{parse(taskDescription)}</div>
        <a className="contest-comp__download-materials" rel="noreferrer" target="_blank" href={taskMaterialsLink}>
          <span>Скачать материалы конкурса</span>
        </a>
      </section>

      {!isWinnerSelection && winners.length > 0 && (
        <section className="winners-section">
          <h3 className="section-title">Победители</h3>
          <div className="section-wrapper">
            {winners.map(
              (
                { id: winnerId, user: { nickname, avatarBig }, prizePlaceTitle },
              ) => (
                <div className="contest-comp__winner" key={winnerId}>
                  <header className="prize-place-title">
                    {prizePlaceTitle}
                  </header>
                  <img
                    alt=""
                    src={avatarBig}
                    className={cn({
                      "missed-avatar": avatarBig.endsWith("_cat_avatar.png"),
                    })}
                  />
                  <footer className="prize-place-winner">
                    {parse(nickname.replace(/\s+/g, " ").replace(/\s/g, "<br>"))}
                  </footer>
                </div>
              ),
            )}
            <div className="winners-warn">
              {messageCongratulations || "Поздравляем всех победителей! :)"}
            </div>
          </div>
          <div className="winners-work">
            {winners.map(winner => (
              <div
                key={winner.id}
                className="catalog-item cell cell_33"
              >
                <CardWork
                  id={winner.id}
                  cover={winner.cover}
                  commentsCount={winner.commentsNumber}
                  likesCount={winner.likesNumber}
                  alreadyLiked={winner.alreadyLiked}
                  userAvatar={winner.user.avatar}
                  userName={winner.user.nickname}
                  onToggleLike={handleToggleLike}
                  onClick={setContestWorkViewId}
                  isCompleted={isCompleted}
                />
              </div>
            ))}
          </div>
        </section>
      )}
      {isWinnerSelection && prizePlaces.length > 0 && (
        <section className="prize-places-section">
          <div className="section-wrapper">
            <h3 className="section-title">Призы</h3>
            {prizePlaces.map(({ title: prizeTitle, icon, prize }, idx) => (
              <div className="contest-comp__prize-place" key={`prize_${idx + 1}`}>
                <header className="prize-place-title">{prizeTitle}</header>
                <img src={icon} alt="" />
                <footer>{prize}</footer>
              </div>
            ))}
            <div className="winners-warn">
              {messageWinnersSelection
                || "Пока что мы выбираем победителей :)"}
            </div>
          </div>
        </section>
      )}
      <section className="contest-works-section">
        {renderUploadFileInput()}

        <h3 className="section-title">Конкурсные работы</h3>

        {loaded && renderContestants()}
      </section>
      {contestsCompleted.length > 0 && (
        <section className="contests-completed-section">
          <h3 className="section-title">Прошедшие конкурсы</h3>
          <div className="completed-contests-list">
            {contestsCompleted.map(contest => (
              <ContestCompletedCard key={contest.id} {...contest} />
            ))}
          </div>
          <div className="d-flex justify-content-center mt-5">
            <a
              className="ui button"
              href="/contests"
              rel="noopener noreferrer"
              role="button"
            >
              Все конкурсы
            </a>
          </div>
        </section>
      )}
      {contestMaterialFullsized && (
        <ImageModal
          image_src={contestMaterialFullsized}
          closeImageModal={() => setContestMaterialFullsized(null)}
        />
      )}
    </div>
  );
}

Contest.propTypes = {
  id: number.isRequired,
  title: string,
  currentUserId: number,
  winners: array,
  contestants: array,
  contestsCompleted: array,
  prizePlaces: array,
  taskMaterials: array,
  loadContest: func.isRequired,
  setContestWorkAddInProgress: func.isRequired,
  loadItems: func.isRequired,
  toggleLike: func.isRequired,
  loaded: bool,
  contestJudge: bool,
  pagination: object.isRequired,
};

Contest.defaultProps = {
  title: '',
  winners: [],
  contestants: [],
  currentUserId: null,
  contestsCompleted: [],
  prizePlaces: [],
  taskMaterials: [],
  loaded: false,
  contestJudge: false,
};

const mapStateToProps = (state) => {
  const catKey = 'contest';
  const contest = state.entities.catalog[catKey];

  let pagination = { page: 1 };

  if (contest) {
    pagination = contest.pagination;
  }

  const contestInfo = state.local.contestReducer;
  const { isWinnerSelection } = contestInfo;

  const { winners, contestants } = getContestantCoversFields(state, isWinnerSelection);

  return {
    ...contestInfo,
    currentUserId: state.local.currentUser && state.local.currentUser.id,
    winners,
    contestants,
    pagination,
  };
};

const ContestConnected = connect(mapStateToProps, {
  loadContest,
  setContestWorkAddInProgress,
  loadItems: loadContestWorks,
  toggleLike: toggleLikeCatalogItem,
})(Contest);

export default ContestConnected;
