import React, { Component } from 'react';
import { string } from 'prop-types';

export default class CopyCodeButton extends Component {
  state = {
    tooltipText: 'Скопировать код',
  };

  setDefaultTooltipText = () => {
    this.setState({ tooltipText: 'Скопировать код' });
  }

  copyText = () => {
    const { text } = this.props;

    if (window.clipboardData && window.clipboardData.setData) {
      // IE specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData('Text', text);
    } if (this.copySupported) {
      const textarea = document.createElement('textarea');
      textarea.textContent = text;
      textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand('copy'); // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn('Copy to clipboard failed.', ex);
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }

  copyCode = () => {
    const result = this.copyText();
    if (result) {
      this.setState({ tooltipText: 'Код скопирован' });
    }
  }

  copySupported = document.queryCommandSupported && document.queryCommandSupported('copy');

  render() {
    return (
      <div className="tooltip_icon">
        <div className="modal-hw-copy-code" onClick={this.copySupported ? this.copyCode : () => {}} onBlur={this.setDefaultTooltipText} onMouseOut={this.setDefaultTooltipText}>
          <i className="fa fa-files-o" />
          <span>Скопировать код</span>
        </div>
        <span className="tooltiptext_icon modal-hw-tooltiptext">
          {this.state.tooltipText}
        </span>
      </div>
    );
  }
}

CopyCodeButton.propTypes = {
  text: string.isRequired,
};
