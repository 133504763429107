import { normalize } from "normalizr";
import merge from 'lodash/merge';
import omitBy from 'lodash/omitBy';

import { commentsSchema } from '../../services/api';
import { COMMENTS_LOAD, COMMENT_CREATE, COMMENT_DESTROY, COMMENT_UPDATE, REQUEST, SUCCESS, FAILURE } from "../../actions";

const getPaginationKey = (entity, id) => `${entity}_${id}`;

const oldFirstType = 0;
const newFirstType = 1;

const defaultPagination = {
  sortType: oldFirstType,
  sortId: 0,
  loading: false,
  firstItemsLoaded: false,
  error: false,
  hasMore: false,
  showPreviousComments: false,
};

const getSortIdByType = (intType, comments) => {
  if (![oldFirstType, newFirstType].includes(intType)) return 0;

  if (intType === oldFirstType) {
    return comments.reduce((acc, comment) => Math.max(acc, comment.id), 0);
  }

  // иначе сначала новые
  return comments.reduce((acc, comment) => Math.min(acc, comment.id), comments[0].id || 0);
};

const setCommentsNumber = ({
  entities, entity, paginationKey, commentsNumber, commentableId,
}) => {
  /* eslint-disable no-param-reassign */
  // если есть нормализованная сущность (объект), то обновляем кол-во комментариев
  if (entities[entity] && entities[entity][commentableId]) {
    entities[entity][commentableId].comments_number = commentsNumber;
    entities[entity][commentableId].commentsNumber = commentsNumber;
  }

  // обновлям кол-во комментов в пагинации
  entities.commentsPagination[paginationKey].commentsNumber = commentsNumber;

  /* eslint-enable no-param-reassign */
};

const commentHandlers = {
  // подгрузка комментов
  [COMMENTS_LOAD[REQUEST]]: (state, action) => {
    const entities = merge({}, state);

    const { entity, sortId, commentableId, sortType, revertSortType = null } = action.payload;

    const paginationKey = getPaginationKey(entity, commentableId);
    const pagination = entities.commentsPagination[paginationKey] || { ...defaultPagination };

    let { showPreviousComments } = pagination;
    // при переключении фильтра сбрасываем показ предыдущих комментов
    if (sortType !== pagination.sortType) {
      showPreviousComments = false;
    } else if (revertSortType) {
      showPreviousComments = true;
    }

    entities.commentsPagination[paginationKey] = {
      ...pagination,
      sortType,
      sortId,
      hasMore: true,
      loading: true,
      error: false,
      showPreviousComments,
    };

    return entities;
  },

  // успешная подгрузка комментов
  [COMMENTS_LOAD[SUCCESS]]: (state, action) => {
    const {
      entity,
      commentableId,
      sortType,
      revertSortType = null,
      response: {
        comments,
        comments_number: commentsNumber,
        has_more: hasMore,
      },
    } = action.payload;

    let entities = merge({}, state);

    const paginationKey = getPaginationKey(entity, commentableId);
    const pagination = entities.commentsPagination[paginationKey];

    // при переключении сортировки отбрасываем предыдущие комменты
    if (pagination.sortId === 0) {
      entities.comments = omitBy(entities.comments, c => c.commentable_id === commentableId);
    }

    const normalizedData = normalize(comments, commentsSchema);
    entities = merge(normalizedData.entities, entities);

    entities.commentsPagination[paginationKey] = {
      ...pagination,
      sortId: getSortIdByType(revertSortType || sortType, comments),
      hasMore,
      loading: false,
      error: false,
      firstItemsLoaded: true,
    };

    setCommentsNumber({
      entities, entity, paginationKey, commentsNumber, commentableId,
    });

    return entities;
  },

  [COMMENTS_LOAD[FAILURE]]: (state, action) => {
    const entities = merge({}, state);

    const { entity, commentableId } = action.payload;

    const paginationKey = getPaginationKey(entity, commentableId);
    const pagination = entities.commentsPagination[paginationKey];

    entities.commentsPagination[paginationKey] = {
      ...pagination,
      loading: false,
      error: true,
    };

    return entities;
  },

  // успешное создание комментария
  [COMMENT_CREATE[SUCCESS]]: (state, action) => {
    const {
      payload: {
        entity,
        commentableId,
        sortType,
        revertSortType = null,
        response: {
          comments,
          comments_number: commentsNumber,
          has_more: hasMore,
        },
      },
    } = action;

    let entities = merge({}, state);
    const paginationKey = getPaginationKey(entity, commentableId);
    let pagination = entities.commentsPagination[paginationKey];
    const normalizedData = normalize(comments, commentsSchema);

    // отбрасываем предыдущие комменты если сортировка была сначала старые
    // так как получаем новый коммент в контексте с последними
    if (sortType === oldFirstType) {
      entities.comments = omitBy(entities.comments, c => c.commentable_id === commentableId);
      pagination = { ...pagination, showPreviousComments: true };
    }

    entities.comments = merge(entities.comments, normalizedData.entities.comments);

    const commentableComments = omitBy(entities.comments, c => c.commentable_id !== commentableId);
    entities.commentsPagination[paginationKey] = {
      ...pagination,
      hasMore,
      sortId: getSortIdByType(revertSortType || sortType, Object.values(commentableComments)),
    };

    entities = merge(normalizedData.entities, entities);

    setCommentsNumber({
      entities, entity, paginationKey, commentsNumber, commentableId,
    });

    return entities;
  },

  // успешное удаление комментария
  [COMMENT_DESTROY[SUCCESS]]: (state, action) => {
    const {
      payload: {
        entity,
        commentableId,
        response: { id },
      },
    } = action;

    const entities = merge({}, state);
    delete entities.comments[id];

    const paginationKey = getPaginationKey(entity, commentableId);
    const { commentsNumber } = entities.commentsPagination[paginationKey];
    const commNum = commentsNumber - 1;

    setCommentsNumber({
      entities, entity, paginationKey, commentsNumber: commNum, commentableId,
    });

    return entities;
  },

  // успешное обновление комментария
  [COMMENT_UPDATE[SUCCESS]]: (state, action) => {
    const {
      payload: {
        response: { id, text },
      },
    } = action;

    const entities = merge({}, state);
    entities.comments[id] = {
      ...entities.comments[id],
      text,
      edited: true,
    };

    return entities;
  },
};

export default commentHandlers;
