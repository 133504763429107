import React, { Component } from "react";
import { Provider } from "react-redux";

import initializeStore from "Core/store/initializeStore";
import CatalogLayout from "./CatalogLayout";

const getStore = initializeStore();

class CatalogRoot extends Component {
  render() {
    return (
      <Provider store={getStore()}>
        <CatalogLayout {...this.props} />
      </Provider>
    );
  }
}

export default CatalogRoot;
