import React from "react";
import { string } from "prop-types";

export default function RemarkGrayIcon(props) {
  return (
    <div className="chat_avatar_remark_icon tooltip_icon">
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="19" height="18" rx="2" fill="white" />
        <rect
          x="0.75"
          y="0.75"
          width="17.5"
          height="16.5"
          rx="1.25"
          stroke="#B4B4B4"
          strokeWidth="1.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 5H3.99996V6.50442H15V5ZM8.99996 8H3.99996V9.50442H8.99996V8ZM9.99996 8H15V9.50442H9.99996V8ZM15 11H3.99996V12.5044H15V11Z"
          fill="#B4B4B4"
        />
      </svg>
      <span className="tooltiptext_icon"> {props.tooltip} </span>
    </div>
  );
}

RemarkGrayIcon.propTypes = {
  tooltip: string.isRequired,
};
