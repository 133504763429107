import React, { Component } from "react";
import { array, func, number } from "prop-types";
import { Carousel } from "react-responsive-carousel";
import cn from "classnames";

import { formatTimeForWorkThumb } from "Core/services/chat";
import SyntaxHighlighter from "../layouts/SyntaxHighlighter";
import PlyrPlayer from "./PlyrPlayer";
import PlayVideoTag from "./PlayVideoTag";

class HomeworkBody extends Component {
  constructor(props) {
    super(props);

    this.versionRefs = {};
    this.players = {};
    this.activePlayer = null;
    this.thumbs = null;
  }

  componentDidMount() {
    this.plyrPlayerRef = React.createRef();
    const worksCount = this.props.versions.length;

    // завершилась загрузка версий дз, ставим активную версию по центру
    if (worksCount > 1) {
      setTimeout(() => {
        this.centerActiveThumb(this.props.activeVersionIndex);
      }, 200);
    }
  }

  onChangeVersion = (index) => {
    if (index === this.props.activeVersionIndex) return;

    const version = this.props.versions[index];

    if (this.activePlayer) {
      this.players[this.activePlayer].pause();
      this.activePlayer = null;
    }
    if (version.type === "image") {
      const el = this.versionRefs[index];

      if (el && el.dataset.src) {
        el.src = el.dataset.src;
      }
    } else if (version.type === "video" && this.players[version.id]) {
      this.players[version.id].play();
      this.activePlayer = version.id;
    }
    this.props.changeActiveVersion(index);
    this.centerActiveThumb(index);
  };

  centerActiveThumb = (activeThumb) => {
    const offsetDiff = this.thumbs.offsetWidth / 2 - 20;
    const activeOffset = this[`thumb${activeThumb}`].offsetLeft;
    this.thumbs.scrollLeft = activeOffset - offsetDiff;
  };

  defineRefPlayer(node, version, index) {
    this.players[version.id] = node.plyr;
    if (
      index === this.props.activeVersionIndex
      && node
      && this.activePlayer !== version.id
    ) {
      this.players[version.id].play();
      this.activePlayer = version.id;
    }
  }

  defineRefImage(el, index) {
    this.versionRefs[index] = el;

    if (index === this.props.activeVersionIndex && el) {
      // eslint-disable-next-line no-param-reassign
      el.src = el.dataset.src;
    }
  }

  renderThumbDate(time) {
    const { type } = this.props.versions[this.props.activeVersionIndex];

    return (
      <div
        className={cn("modal-hw-thumb-date", {
          "modal-hw-thumb-date-text": type === "text",
        })}
      >
        <span>{formatTimeForWorkThumb(time)}</span>
      </div>
    );
  }

  renderThumbContent(version) {
    if (version.status === "processing") {
      return (
        <>
          <div className="hw-cover-processing">
            <i className="fa fa-cog fa-spin" />
          </div>
          {this.renderThumbDate(version.time)}
        </>
      );
    }

    if (version.status === "error_processing") {
      return (
        <>
          <div className="hw-cover-error_processing">
            <i className="fa fa-times" />
          </div>
          {this.renderThumbDate(version.time)}
        </>
      );
    }

    return (
      <>
        {version.type === "video" && <PlayVideoTag />}

        {version.type !== "text" && (
          <img
            className="modal-hw-thumb-img"
            src={version.image}
            alt=""
          />
        )}
        {this.renderThumbDate(version.time)}
      </>
    );
  }

  render() {
    const { versions, activeVersionIndex } = this.props;
    const activeVersion = versions[activeVersionIndex];

    return (
      <div className="modal-body modal-hw-body">
        <div className="modal-hw-versions">
          <Carousel
            transitionTime={0}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            onChange={this.onChangeVersion}
            selectedItem={this.props.activeVersionIndex}
            ref={(el) => {
              this.Carousel = el;
            }}
          >
            {versions.map((version, index) => {
              if (['processing', 'error_processing'].includes(version.status)) {
                return (
                  <div key={version.id} className={`hw-content-${version.status}`}>
                    {version.status === 'processing' ? (
                      <>
                        <i className="fa fa-spinner fa-pulse" />
                        <span className="ml-2">Обрабатываем вашу работу</span>
                      </>
                    ) : <span>Ошибка обработки</span>}
                  </div>
                );
              }

              if (version.type === "text") {
                return (
                  <SyntaxHighlighter
                    code={version.text}
                    key={version.id}
                    onCodeLoaded={this.props.onContentLoaded}
                    elementClass="modal-hw-version-code"
                  />
                );
              }

              if (version.type === "video") {
                return (
                  <PlyrPlayer
                    ref={(node) => {
                      this.props.onContentLoaded();
                      this.defineRefPlayer(node, version, index);
                    }}
                    key={version.id}
                    source={version.imageFull}
                    versionId={version.id}
                  />
                );
              }

              if (['image', 'gif'].includes(version.type)) {
                return (
                  <img
                    key={version.id}
                    className="modal-hw-version"
                    data-src={version.imageFull}
                    onLoad={this.props.onContentLoaded}
                    onError={this.props.onContentLoaded}
                    src=""
                    alt={index}
                    ref={el => this.defineRefImage(el, index)}
                  />
                );
              }

              return (
                <div className="modal-hw-version d-flex justify-content-center align-items-center">
                  Домашняя работа не найдена
                </div>
              );
            })}
          </Carousel>

          {versions.length > 1 && (
            <div
              className="modal-hw-thumbs"
              ref={(el) => {
                this.thumbs = el;
              }}
            >
              {versions.map((version, index) => (
                <div
                  onClick={() => this.Carousel.moveTo(index)}
                  key={version.id}
                  ref={(el) => {
                    this[`thumb${index}`] = el;
                  }}
                  className={cn("modal-hw-thumb", {
                    "modal-hw-thumb-active": activeVersionIndex === index,
                    "modal-hw-thumb-text": activeVersion.type === "text",
                  })}
                >
                  {this.renderThumbContent(version)}
                </div>
              ))}
            </div>
          )}

          {versions.length === 1 && (
            <div className="modal-hw-thumbs">
              <div
                className={cn("modal-hw-thumb", {
                  "modal-hw-thumb-text": activeVersion.type === "text",
                })}
                style={{ cursor: "default" }}
              >
                <div
                  className={cn("modal-hw-thumb-date modal-hw-thumb-date-single", {
                    "modal-hw-thumb-date-text": activeVersion.type === "text",
                  })}
                >
                  <span>{formatTimeForWorkThumb(activeVersion.time)}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

HomeworkBody.propTypes = {
  versions: array.isRequired,
  activeVersionIndex: number.isRequired,
  changeActiveVersion: func.isRequired,
  onContentLoaded: func.isRequired,
};

export default HomeworkBody;
