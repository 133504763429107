import React from "react";
import { func, number } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAmountDown, faRandom } from "@fortawesome/free-solid-svg-icons";

import Dropdown, { DropdownItem } from "Main/components/ui/Dropdown";

const CatalogDropdownOrderFilters = ({ onSwitch, activeFilter }) => (
  <Dropdown onSelect={({ index }) => onSwitch(index)}>
    <DropdownItem selected={activeFilter === 0}>
      <FontAwesomeIcon icon={faSortAmountDown} color="#bdbdbd" />
      {' '}
      По новым
    </DropdownItem>
    <DropdownItem selected={activeFilter === 1}>
      <FontAwesomeIcon icon={faSortAmountDown} color="#bdbdbd" />
      {' '}
      По лайкам
    </DropdownItem>
    <DropdownItem selected={activeFilter === 2}>
      <FontAwesomeIcon icon={faRandom} color="#bdbdbd" />
      {' '}
      Случайные
    </DropdownItem>
  </Dropdown>
);

CatalogDropdownOrderFilters.propTypes = {
  activeFilter: number,
  onSwitch: func.isRequired,
};

CatalogDropdownOrderFilters.defaultProps = {
  activeFilter: 0,
};

export default CatalogDropdownOrderFilters;
