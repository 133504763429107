import React, { Component } from 'react';
import { func, string } from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from "react-router-dom";
import Login from "Main/components/Auth/Login";
import SmsConfirm from "Main/components/Auth/SmsConfirm";
import PasswordChange from "Main/components/Auth/PasswordChange";

import { history } from "Core/services";
import { setAfterSignInPath } from '../../core/actions';

class AuthSwitch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: "",
    };

    const sbarItems = document.querySelectorAll(".sidebar-item");

    sbarItems.forEach((item) => {
      item.addEventListener("click", (e) => {
        if ("popupPath" in item.dataset) {
          const { popupPath, afterSignInPath } = item.dataset;
          if (typeof afterSignInPath === 'string' && afterSignInPath !== '') {
            this.props.setAfterSignInPath(afterSignInPath);
          }

          history.push(popupPath);
          e.preventDefault();
          return false;
        }
      });
    });
  }

  setLogin = login => this.setState({ login });

  render() {
    return (
      <Switch>
        <Route exact path="/" />
        <Route
          exact
          path="/login"
          render={props => (
            <Login
              {...props}
              login={this.state.login}
              setLogin={this.setLogin}
              afterSignInPath={this.props.afterSignInPath}
            />
          )}
        />
        <Route
          exact
          path="/account-confirm"
          render={props => (
            <SmsConfirm
              {...props}
              login={this.state.login}
            />
          )}
        />
        <Route
          exact
          path="/password-change"
          render={props => (
            <PasswordChange {...props} login={this.state.login} />
          )}
        />
      </Switch>
    );
  }
}

AuthSwitch.propTypes = {
  afterSignInPath: string.isRequired,
  setAfterSignInPath: func.isRequired,
};

const mapStateToProps = (state) => {
  const { afterSignInPath } = state.local.userAuthorizeReducer;

  return {
    afterSignInPath,
  };
};

export default connect(mapStateToProps, {
  setAfterSignInPath,
})(AuthSwitch);
