import React from "react";
import { Provider } from "react-redux";
import { Router, Route } from "react-router";
import { history } from "Core/services";
import moment from "moment";
import initializeStore from "Core/store/initializeStore";
import chatsSaga from "Core/sagas/chats";
import wsChatSaga from "Core/sagas/ws-chat";

import App from "./App";
import ChatList from "./ChatList";
import Chat from "./Chat";

const sagas = [chatsSaga, wsChatSaga];
const getStore = initializeStore(sagas);

moment.locale("ru");

export default function Root() {
  const reactRoutes = [
    "/messages",
    "/messages/:channel/:id",
  ];

  return (
    <Provider store={getStore()}>
      <Router history={history}>
        <Route exact path={reactRoutes}>
          <App>
            <Route exact path="/messages" component={ChatList} />
            <Route exact path="/messages/:channel/:id" component={Chat} />
          </App>
        </Route>
      </Router>
    </Provider>
  );
}
