import { take, put, call, fork, select } from "redux-saga/effects";
import { watchEvents } from './helpers';

function* websocketSaga(faye, channel) {
  const channelAction = yield call(watchEvents, faye, channel);
  while (true) {
    const action = yield take(channelAction);
    yield put(action);
  }
}

export default function* websocketSagas() {
  const { faye } = window.CONF;
  const { is_teacher, channel } = yield select(state => state.local.currentUser);

  yield fork(websocketSaga, faye, channel);

  // если учитель, то подписываем дополнительно на учительский канал
  if (is_teacher) {
    yield fork(websocketSaga, faye, "/teacher_channel");
  }
}
