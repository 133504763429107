import { call, put, take, takeEvery } from "redux-saga/effects";
import { FILES_UPLOAD_REQUEST } from "./actionTypes";
import {
  uploadFilesProgress,
  uploadFilesSuccess,
  uploadFilesFailure,
} from "./actions";
import { createUploadFilesChannel } from "./createUploadFilesChannel";

export function* uploadFilesSaga(files, uploadPath) {
  if (!uploadPath) {
    yield put(
      uploadFilesFailure(files, { error: "Не указан путь к скрипту загрузки" }),
    );
    return;
  }

  const channel = yield call(createUploadFilesChannel, uploadPath, files);

  while (true) {
    const { progress = 0, err, success, message } = yield take(channel);

    if (err) {
      yield put(uploadFilesFailure(files, err, message));
      return;
    }

    if (success) {
      yield put(uploadFilesSuccess(files));
      return;
    }

    yield put(uploadFilesProgress(files, progress));
  }
}

export default function* uploadFilesRequestWatcherSaga() {
  yield takeEvery(FILES_UPLOAD_REQUEST, function* (action) {
    const { files, uploadPath } = action.payload;
    yield call(uploadFilesSaga, files, uploadPath);
  });
}
