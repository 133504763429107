import { string, number, shape } from 'prop-types';

const Homework = shape({
  id: number.isRequired,
  image: string,
  type: string,
  full_image: string,
  status: string,
  flow_id: number,
  lesson_id: number,
});

Homework.defaults = {
  id: -1,
  image: '',
  full_image: '',
  status: '',
  flow_id: -1,
  lesson_id: -1,
  type: '',
};

export default Homework;
