import React from "react";
import { bool, object } from 'prop-types';
import moment from "moment";
import "moment/locale/ru";

export default function ItemHeader(props) {
  const {
    is_teacher,
    user: { nickname },
    flow: { date },
    course: { title },
    chat: { last_curator_name }
  } = props;

  let lastCuratorName;

  if (typeof last_curator_name === 'string') {
    const splitName = last_curator_name.split(' ');

    [lastCuratorName] = splitName;
    const lastName = splitName[1];

    if (lastName) lastCuratorName += ` ${lastName[0]}.`;
  }

  if (!is_teacher) {
    return (
      <div className="chat_title">
        <strong>{title}</strong>
        <div className="chat_title_flow">&zwnj;</div>
      </div>
    );
  }

  return (
    <div className="chat_title">
      <div className="d-flex">
        <strong>{nickname}</strong>
        {lastCuratorName && (
          <div style={{ fontSize: 15, color: '#9b9b9b' }} className="ml-4">
            {lastCuratorName}
          </div>
        )}
      </div>
      <div className="chat_title_flow">
        {title}
        {' '}
        | Поток с
        {' '}
        {moment(date).format("D MMMM Y")}
      </div>
    </div>
  );
}

ItemHeader.propTypes = {
  is_teacher: bool.isRequired,
  user: object.isRequired,
  flow: object.isRequired,
  course: object.isRequired,
};
