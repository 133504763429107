import React, { Component } from "react";
import { arrayOf, number, PropTypes } from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import DateLine from "Main/components/Chat/DateLine";
import Message from "./Message";

class MessageGroupContainer extends Component {
  renderDateLine() {
    const { message, prev_message } = this.props;
    const time = moment.unix(message.time);
    if (prev_message !== undefined) {
      const prevTime = moment.unix(prev_message.time);
      const isNewDay = time.isAfter(prevTime, "day");
      if (isNewDay) {
        return <DateLine time={time} />;
      }
    } else {
      return <DateLine time={time} />;
    }
  }

  renderUserName() {
    const { user, is_teacher_message, course } = this.props;
    if (is_teacher_message) {
      return <strong>{course.title}</strong>;
    }
    return <strong>{user.nickname}</strong>;
  }

  renderUserNameInfo() {
    const { user, is_teacher, is_teacher_message, course: { show_courator_names } } = this.props;
    if ( is_teacher_message && (show_courator_names || is_teacher)) {
      return <span className="message_title_info">({user.nickname})</span>;
    }
  }

  renderUserAvatar() {
    const { user, is_teacher_message, course } = this.props;
    const cover = is_teacher_message ? course.avatar : user.avatar;
    return cover ? (
      <img
        src={cover}
        width="50"
        height="50"
        className={`message_avatar ${
          cover.endsWith("_cat_avatar.png") ? "border_radius_none" : ""
        }`}
        alt=""
      />
    ) : null;
  }

  render() {
    const {
      message_group_ids,
      message,
      onEditingMessage,
      onDeleteMessage,
    } = this.props;
    return (
      <div>
        {this.renderDateLine()}
        <div className="message">
          <div className="message_info">
            {this.renderUserAvatar()}
            <div
              className="message_time"
              title={moment(message.time * 1000).format("D MMMM YYYY, H:mm")}
            >
              {moment(message.time * 1000).format("H:mm")}
            </div>
          </div>

          <div className="message_container">
            <div className="message_title">
              <div>
                {this.renderUserName()}
                {this.renderUserNameInfo()}
              </div>
            </div>
            <div className="message_content">
              {message_group_ids.map((el, i, arr) => {
                const prev_message = i !== 0 ? arr[i - 1] : null;
                return (
                  <Message
                    key={el}
                    message_id={arr[i]}
                    prev_message_id={prev_message}
                    onEditingMessage={onEditingMessage}
                    onDeleteMessage={onDeleteMessage}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
MessageGroupContainer.propTypes = {
  message_group_ids: arrayOf(number),
  prev_message_group_ids: arrayOf(number),
  message: PropTypes.any.isRequired,
};

MessageGroupContainer.defaultProps = {
  message_group_ids: [],
  course: false,
};
const mapStateToProps = (
  state,
  { message_group_ids, prev_message_group_ids },
) => {
  const {
    entities: {
      users,
      messages,
      homeworks,
      lessons,
      exercises,
      chats,
      courses,
    },
    local: { me, is_teacher },
  } = state;
  let prev_message;
  if (prev_message_group_ids !== undefined) {
    prev_message = messages[prev_message_group_ids.slice(-1)[0]];
  }
  const message = messages[message_group_ids[0]];
  const user = users[message.user];
  const chat = chats[message.chat_id];
  const course = courses[chat.course];

  const homework = homeworks[message.homework_id];
  if (homework) {
    homework.lesson = lessons[homework.lesson_id];
    homework.exercise = exercises[homework.exercise_id];
  }

  return {
    me,
    chat,
    user,
    message,
    prev_message,
    homework,
    course,
    is_teacher_message: message.user != chat.user,
    is_teacher: !!is_teacher,
  };
};

export default connect(mapStateToProps, {})(MessageGroupContainer);
