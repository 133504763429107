import React from "react";
import { func, number } from "prop-types";
import moment from "moment";
import Modal from "Main/components/layouts/modals/Modal";

export default function PaymentModal(props) {
  const { closeModal, chat_id } = props;
  const [nextMonth, nextWeek] = [new Date(), new Date()];
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  nextWeek.setDate(nextWeek.getDate() + 7);

  const tariffs = [
    // {
    //   enPeriod: "week",
    //   extensionPeriod: "1 неделя",
    //   extensionDate: `${moment(nextWeek).format("D MMMM YYYY")}`,
    //   price: "1200",
    // },
    {
      enPeriod: "month",
      extensionPeriod: "1 месяц",
      extensionDate: `${moment(nextMonth).format("D MMMM YYYY")}`,
      price: "3900",
    },
  ];

  return (
    <Modal closeModal={closeModal} showCross={false}>
      <div className="extend_consultation">
        <i onClick={closeModal} className="fa fa-times" aria-hidden="true" />
        <div className="extend_consultation_info">
          <h2>Продлить консультации</h2>
          <img src="/images/chat/extend_consultations_cat.png" alt="" />
          <p>
            В консультации входит возможность сдавать домашние работы и получать
            по ним обратную связь. После оплаты необходимо нажать на кнопку
            {" "}
            &quot;
            Вернуться в магазин
            &quot;
            .
          </p>
        </div>
        <div className="extend_consultation_tariffs">
          {tariffs.map(tariff => (
            <div key={tariff.enPeriod} className="extend_consultation_tariff">
              <h3>{tariff.extensionPeriod}</h3>
              <p>
                Единоразования оплата. Консультации и возможность загрузки
                домашних работ будет продлена до
                {' '}
                {tariff.extensionDate}
                {' '}
                года.
              </p>
              <div className="extend_consultation_price">
                <p>
                  {tariff.price}
                  {' '}
                  ₽
                </p>
                <div className="extend_consultation_button">
                  <a
                    href={`/payment/robokassa/consultations?chat_id=${chat_id}&&period=${tariff.enPeriod}`}
                  >
                    Оплатить
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

PaymentModal.propTypes = {
  closeModal: func.isRequired,
  chat_id: number.isRequired,
};
