import React, { Component } from "react";
import initializeStore from "Core/store/initializeStore";
import { Provider } from "react-redux";
import testLessonSaga from "Core/sagas/test-lesson";
import LessonTest from "Main/containers/LessonTest";

const getStore = initializeStore(testLessonSaga);

class LessonTestRoot extends Component {
  render() {
    return (
      <Provider store={getStore()}>
        <LessonTest />
      </Provider>
    );
  }
}

export default LessonTestRoot;
