import React, { useState, useRef } from "react";

export default function AdminSidebarItem(props) {
  const [open, setOpen] = useState(false);
  const itemRef = useRef();

  const handleClick = () => {
    if (props.children) setOpen(!open);
    else props.onClick();
  };

  const onDragStart = () => {
    setTimeout(() => itemRef.current.classList.add("-dragged"), 1);
    if (props.onDragStart) props.onDragStart();
  };

  const onDragOver = (e) => {
    if (props.onDragOver) props.onDragOver();
    e.preventDefault();
  };

  const onDragEnd = () => {
    itemRef.current.classList.remove("-dragged");
    if (props.onDragEnd) props.onDragEnd();
  };

  const draggableProps = props.draggable
    ? { draggable: true, onDragStart, onDragOver, onDragEnd }
    : {};

  const item = (
    <div
      ref={itemRef}
      title={props.title}
      onClick={() => handleClick()}
      className={`chats_sidebar_item_block ${props.active ? "-active" : ""}`}
      {...draggableProps}
    >
      {props.children && (
        <svg
          className={open ? "-expanded" : ""}
          width="5"
          height="7"
          viewBox="0 0 5 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 3.5L0 7V0L5 3.5Z"
            fill="#979797"
          />
        </svg>
      )}
      <div className="chats_sidebar_item_content">
        <div className="chats_sidebar_item_text">{props.text}</div>
        {!!props.badge && (
          <div className="chats_sidebar_item_counter">{props.badge}</div>
        )}
      </div>
    </div>
  );

  if (props.children && open) {
    return (
      <div className="chats_sidebar_item_container">
        {item}
        {props.children}
      </div>
    );
  }

  return item;
}
