import React from "react";

import { StudentWork } from "Main/components/StudentWork";
import CatalogRoot from '../mainApp/containers/CatalogRoot';

const StudentWorkWrapper = props => (
  <div className="catalog-item cell cell_33">
    <StudentWork {...props} />
  </div>
);

const StudentWorksModule = () => (
  <CatalogRoot
    options={{
      header: {
        title: 'Работы учеников',
        banner: '/images/banners/students_illustrations.jpg',
        categoriesShown: true,
        sortsShown: true,
      },
    }}
    defaultAllFilterName="Все работы"
    entity="homeworks"
    reduxCatalogKey="studentWorks"
    ItemComponent={StudentWorkWrapper}
  />
);

export default StudentWorksModule;
