import {
  FILES_UPLOAD_REQUEST,
  FILES_UPLOAD_PROGRESS,
  FILES_UPLOAD_SUCCESS,
  FILES_UPLOAD_FAILURE,
  FILES_UPLOAD_CLEAN_STORE,
} from "./actionTypes";

export const uploadFilesRequest = ({ files, ...payload }) => ({
  type: FILES_UPLOAD_REQUEST,
  payload: { files, ...payload },
});

export const uploadFilesProgress = (files, progress) => ({
  type: FILES_UPLOAD_PROGRESS,
  payload: { progress },
  meta: { files },
});

export const uploadFilesSuccess = files => ({
  type: FILES_UPLOAD_SUCCESS,
  meta: { files },
});

export const uploadFilesFailure = (files, error) => ({
  type: FILES_UPLOAD_FAILURE,
  payload: { error },
  error: true,
  meta: { files },
});

export const cleanStore = () => ({
  type: FILES_UPLOAD_CLEAN_STORE,
});
