import { bool, object, string } from "prop-types";
import React from "react";

import ExerciseInfo from "../Homework/ExerciseInfo";

const StudentWorkViewHeader = (props) => {
  const {
    isTeacher,
    type,
    imageFull,
    user,
    course,
    flow,
    lesson,
    exercise,
  } = props;

  return (
    <div className="modal-header modal-hw-header">
      <a href={`/user/${user.id}`}>
        <img className="modal-hw-avatar" src={user.avatar} alt="" />
      </a>
      <div className="modal-hw-info">
        <p className="modal-hw-name">
          <a href={`/user/${user.id}`}>{user.nickname}</a>
        </p>
        <ExerciseInfo
          flowId={flow.id || 0}
          flowTitle={course.title}
          exerciseTitle={exercise.title}
          lessonId={(lesson && lesson.id) || undefined}
          lessonTitle={(lesson && lesson.title) || undefined}
        />
      </div>

      {isTeacher && type !== "text" && (
        <a
          className="btn btn-primary btn-sm modal-hw-open-origin"
          href={imageFull}
          target="_blank"
          rel="noopener noreferrer"
          role="button"
        >
          Открыть оригинал
        </a>
      )}
    </div>
  );
};

StudentWorkViewHeader.propTypes = {
  isTeacher: bool.isRequired,
  type: string.isRequired,
  imageFull: string.isRequired,
  user: object.isRequired,
  course: object.isRequired,
  flow: object.isRequired,
  lesson: object.isRequired,
  exercise: object.isRequired,
};

export default StudentWorkViewHeader;
