import React, { useEffect } from "react";
import { string } from "prop-types";

import ReactSyntaxHighlighter from "react-syntax-highlighter";
import { routeros } from "react-syntax-highlighter/dist/esm/styles/hljs";

export default function SyntaxHighlighter(props) {
  const { onCodeLoaded = () => {} } = props;
  useEffect(() => onCodeLoaded(), [props.code]);

  return (
    <ReactSyntaxHighlighter
      className={`${props.elementClass} react-syntax-highlighter-customized`}
      style={routeros}
      showLineNumbers
      showInlineLineNumbers
      wrapLines
      language={props.language || "cs"}
    >
      {props.code}
    </ReactSyntaxHighlighter>
  );
}

SyntaxHighlighter.propTypes = {
  code: string.isRequired,
  language: string,
  elementClass: string,
};

SyntaxHighlighter.defaultProps = {
  language: null,
  elementClass: "",
};
