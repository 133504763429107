import React from "react";
import ChatItemIcon from './ChatItemIcon';

export default function ItemHomework(props) {
  const {
    course: { use_consultations_feature },
    is_teacher,
    homework: { comment },
    consultationTimeIsUp,
    unread,
  } = props;
  const isUnread = unread && !is_teacher ? "-unread_my" : "";

  return (
    <div>
      <div
        style={{
          display: "flex",
          height: "25px",
          lineHeight: "25px",
          alignItems: "center",
        }}
      >
        <ChatItemIcon
          course={props.course}
          consultationTimeIsUp={consultationTimeIsUp}
          isFreeFlow={props.flow.free}
          isTeacher={is_teacher}
        />
        <div style={{ width: "100%" }} className={isUnread}>
          Домашняя работа
        </div>
      </div>
      <div className="chat_text_comment">{comment}</div>
    </div>
  );
}
