import React from "react";
import ContestWork from "Main/containers/ContestWorkRoot";

class ContestWorkModule extends React.Component {
  render() {
    return <ContestWork />;
  }
}

export default ContestWorkModule;
