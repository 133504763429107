import React, { useEffect } from "react";
import { func, any, bool } from "prop-types";
import cross from "App/assets/images/chat/cross.svg";

export default function Modal(props) {
  document.body.style.overflow = "hidden";

  useEffect(() => () => {
    document.body.removeAttribute("style");
  }, []);

  const { closeModal, children, showCross, showTimes } = props;

  return (
    <div onClick={closeModal} className="modal-base-wrapper">
      {showCross && (
        <div className="modal-base-cross">
          <img src={cross} alt="cross icon" />
        </div>
      )}

      <div className="modal-base-content" onClick={e => e.stopPropagation()}>
        {showTimes && <i className="fa fa-times" onClick={closeModal} aria-hidden="true" />}
        {children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  closeModal: func.isRequired,
  children: any.isRequired,
  showCross: bool,
  showTimes: bool,
};

Modal.defaultProps = {
  showCross: true,
  showTimes: false,
};
