import React from "react";

const fileTypes = ["image/png", "image/jpeg", "image/gif", "image/jpg"];

export default function MessageFileAttachment(props) {
  const {
    message: { image, image_content_type, image_file_name, image_preview },
    openImageModal,
  } = props;

  if (!image) return null;

  if (!fileTypes.includes(image_content_type)) {
    return (
      <div>
        <span>
          <i className="fa fa-file" />
          &ensp;
          <a href={image} target="_blank" rel="noopener noreferrer">
            {image_file_name}
          </a>
        </span>
      </div>
    );
  }

  if (image_preview) {
    return (
      <div className="message_image" onClick={() => openImageModal(image)}>
        <img src={image_preview} alt="" />
      </div>
    );
  }
}
