import React, { useEffect } from "react";
import { array, bool, func, number } from "prop-types";
import { connect } from "react-redux";
import { loadNotificationsCenterData } from "Core/actions";
import NotifyItem from "Main/components/NotificationCenter/NotifyItem";

function NotificationCenterPage(props) {
  const { loading, items, nomore } = props;

  useEffect(() => {
    props.loadHotsNotifications();
  }, []);

  return (
    <div className="notifications-page">
      <h5>Уведомления</h5>
      <div className="wrapper">
        {items.length ? (
          items.map(item => <NotifyItem key={item.id} {...item} />)
        ) : (
          <div className="notifications-empty">Нет новых уведомлений</div>
        )}
      </div>
      {loading ? (
        <div className="load-indicator d-flex justify-content-center my-3">
          <div className="loadersmall modal-hw-loader" />
        </div>
      ) : (
        !nomore
        && items.length > 0 && (
          <button
            type="button"
            className="show-more"
            onClick={() => props.loadHotsNotifications()}
          >
            Показать еще
          </button>
        )
      )}
    </div>
  );
}

NotificationCenterPage.propTypes = {
  loading: bool,
  unreadCount: number,
  items: array,
  loadHotsNotifications: func.isRequired,
  nomore: bool,
};

NotificationCenterPage.defaultProps = {
  loading: false,
  unreadCount: 0,
  items: [],
  nomore: false,
};

const mapStateToProps = (state) => {
  const props = state.local.notificationCenterReducer;
  return props;
};

export default connect(mapStateToProps, {
  loadHotsNotifications: loadNotificationsCenterData,
})(NotificationCenterPage);
