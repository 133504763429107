import React from 'react';
import { string } from 'prop-types';

const CatalogPlaceholder = ({ text }) => (
  <div className="ctg-content__no_items">{text}</div>
);

CatalogPlaceholder.propTypes = {
  text: string,
};

CatalogPlaceholder.defaultProps = {
  text: 'Контент отсутсвует',
};

export default CatalogPlaceholder;
