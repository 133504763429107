import { humps } from 'Core/services/tools';
import { CONTESTS_LOAD_DONE, FAILURE, OLD_CONTESTS_LOAD, REQUEST, SUCCESS } from "../../actions";

const defaultState = {
  loaded: false,
  loading: false,
  error: false,
  hasMore: true,
  tags: {},
  contests: {
    all: [],
    active: [],
    completed: [],
  },
};

const timeDescOrder = (a, b) => b.time - a.time;

const handlers = {
  [CONTESTS_LOAD_DONE]: (state, action) => {
    const { tags, activeContests, oldContests } = humps(action.response);

    return {
      ...state,
      loaded: true,
      error: false,
      tags,
      contests: {
        activeContests,
        oldContests: oldContests.sort(timeDescOrder),
      },
    };
  },

  [OLD_CONTESTS_LOAD[REQUEST]]: state => ({
    ...state,
    error: false,
    loading: true,
  }),

  [OLD_CONTESTS_LOAD[SUCCESS]]: (state, action) => {
    const { oldContests } = humps(action.response);

    return {
      ...state,
      loading: false,
      error: false,
      hasMore: action.response.has_more,
      contests: {
        ...state.contests,
        oldContests: [
          ...state.contests.oldContests,
          ...oldContests,
        ].sort(timeDescOrder),
      },
    };
  },

  [OLD_CONTESTS_LOAD[FAILURE]]: state => ({
    ...state,
    loading: false,
    hasMore: false,
    error: true,
  }),

  default: state => state,
};

const contestsReducer = (state = defaultState, action) => {
  const calcState = handlers[action.type] || handlers.default;

  return calcState(state, action);
};

export default contestsReducer;
