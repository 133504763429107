import React from "react";
import { func } from "prop-types";

export default function DoubleCheckIcon(props) {
  return (
    <div className="tooltip_icon" onClick={props.onClick}>
      <svg
        width="18"
        height="9"
        viewBox="0 0 18 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.38805 6.17999L12.8501 0.706358L12.1422 0L6.6838 5.47002L4.9317 3.70369L4.22173 4.40793L5.97743 6.17788L4.57732 7.58096L0.707855 3.7033L0 4.40966L4.22339 8.642L4.57732 8.99668L4.93125 8.642L6.68168 6.88785L8.41997 8.64027L8.77495 8.99813L9.12994 8.64027L17.0016 0.704627L16.2917 0.000386L8.77495 7.57816L7.38805 6.17999Z"
          fill="#2196F3"
        />
      </svg>
      <span className="tooltiptext_icon">{props.tooltip}</span>
    </div>
  );
}

DoubleCheckIcon.propTypes = {
  onClick: func.isRequired,
};
