import React, { Component } from 'react';
import { any, bool, func, string } from 'prop-types';
import FileUploadError from './FileUploadError';

const fileTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/jpg'];

export default class InputAttachmentZone extends Component {
  componentDidMount() {
    this.renderAttachment();
  }

  componentDidUpdate() {
    this.renderAttachment();
  }

  isImageFile = image => fileTypes.includes(image.type)

  renderAttachment() {
    const { status, isImageBlobUrl, imageBlob } = this.props;
    if (['error', 'type_error', 'exceeded_limit'].includes(status)) return;

    if (isImageBlobUrl || (imageBlob && this.isImageFile(imageBlob))) {
      const { canvas } = this;
      const ctx = canvas.getContext('2d');

      // Create an image to render the blob on the canvas
      const img = new Image();
      // Once the image loads, render the img on the canvas
      img.onload = function onload() {
        // Update dimensions of the canvas with the dimensions of the image
        let aspectRatio = (this.width / this.height);
        let k;
        if (aspectRatio < 1) {
          k = 10 - (aspectRatio * 10).toFixed();
        } else {
          k = 1;
        }
        aspectRatio *= k;
        canvas.width = 100 / k; // this.width;
        canvas.height = 100 / aspectRatio; // this.height;
        if (status === 'loading') {
          ctx.globalAlpha = 0.5;
        }
        ctx.drawImage(img, 0, 0, 100 / k, 100 / aspectRatio);
      };

      if (isImageBlobUrl) {
        img.src = this.props.editingMessage.image_preview;
      } else {
        // Crossbrowser support for URL
        const URLObj = window.URL || window.webkitURL;

        // Creates a DOMString containing a URL representing the object given in the parameter
        // namely the original Blob
        img.src = URLObj.createObjectURL(imageBlob);
      }
    }
  }

  render() {
    const { status, displayUploadError, imageBlob, onRemoveAttachment, editingMessage } = this.props;
    const is_upload_error = ['error', 'type_error', 'exceeded_limit'].includes(status) && displayUploadError;

    if (is_upload_error) {
      return (
        <FileUploadError
          hideUploadError={this.props.hideUploadError}
          status={status}
          uploadImage={this.props.onUploadImage}
        />
      );
    }

    if ((imageBlob && !this.isImageFile(imageBlob)) || this.props.isFileUrl) {
      const name = imageBlob ? imageBlob.name : editingMessage.image_file_name;

      return (
        <div className="chat_input_attachment_filename">
          {status === 'loading' ? <div className="spinner_load_file" /> : <span><i className="fa fa-file" /></span>}&ensp;
          <span>{name}</span>&ensp;
          <div className="attachment_filename_remove" onClick={onRemoveAttachment}>&times;</div>
        </div>
      );
    }
    return (
      <div className="chat_input_attachment">
        <canvas ref={(canvas) => { this.canvas = canvas; }} />
        <div className="chat_input_attachment_remove" onClick={onRemoveAttachment}>&times;</div>
        {status === 'loading' && <div className="spinner" />}
      </div>);
  }
}

InputAttachmentZone.propTypes = {
  status: string,
  onUploadImage: func.isRequired,
  displayUploadError: bool.isRequired,
  hideUploadError: func.isRequired,
  imageBlob: any,
  isFileUrl: bool.isRequired,
  editingMessage: any.isRequired,
  onRemoveAttachment: func.isRequired,
  isImageBlobUrl: bool.isRequired,
};
