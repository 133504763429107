import React, { Component } from 'react';
import { func, bool, array, string } from 'prop-types';

import Modal from "Main/components/layouts/modals/Modal";
import Spinner from 'Main/components/layouts/Spinner';
import StudentTestQuestion from './StudentTestQuestion';
import StudentTestCompleted from './StudentTestCompleted';

class StudentTestModal extends Component {
  state = {
    progress: 0,
    answers: [],
    show_results: false,
  }

  onAnswer = (is_correct) => {
    this.setState(state => ({ answers: [...state.answers, is_correct] }));
  }

  nextQuestion = () => {
    if (this.testIsFinished()) {
      this.setState({ show_results: true });
    } else {
      this.setState(state => ({ progress: state.progress + 1 }));
    }
  }

  testIsFinished = () => this.props.questions.length === (this.state.progress + 1)

  render() {
    const { questions, loading } = this.props;
    const current = questions[this.state.progress];
    const questionIsAnswered = this.state.answers.length === (this.state.progress + 1);
    const isLastQuestion = questions.length === (this.state.progress + 1);

    // if (questionIsAnswered && isLastQuestion) {
    //   const testPassed = !this.state.answers.includes(false);

    //   if (testPassed) {
    //     this.props.onCompleteStudentTest();
    //   }
    // }

    if (this.state.show_results) {
      return (
        <StudentTestCompleted
          answers={this.state.answers}
          onClose={this.props.closeStudentTestModal}
          successMess={this.props.successMess}
          failureMess={this.props.failureMess}
        />
      );
    }

    return (
      <Modal
        showCross={false}
        showTimes
        closeModal={this.props.closeStudentTestModal}
      >
        <div className="modal-content lesson-test-modal p-5">
          <div className="text-muted text-center">
            {this.state.progress + 1}
            /
            {questions.length}
          </div>
          { loading && <div className="mx-auto"><Spinner /></div> }
          { !loading && !!current && (
            <div>
              <StudentTestQuestion question={current} onAnswer={this.onAnswer} />
              {
                questionIsAnswered
                  && (
                    <div className="lesson-test-modal-next-question-btn d-flex justify-content-center">
                      <button type="button" className="pixel-btn" onClick={this.nextQuestion}>
                        {isLastQuestion ? 'Просмотреть результаты' : 'Следующий вопрос'}
                      </button>
                    </div>
                  )
              }
            </div>
          )}
          { !loading && !current && <div>Что-то пошло не так... мы не нашли вопросов.</div> }
        </div>
      </Modal>
    );
  }
}

StudentTestModal.propTypes = {
  // onCompleteStudentTest: func.isRequired,
  closeStudentTestModal: func.isRequired,
  questions: array,
  loading: bool.isRequired,
  successMess: string.isRequired,
  failureMess: string.isRequired,
};

StudentTestModal.defaultProps = {
  questions: [],
};

export default StudentTestModal;
