import React from 'react';
import { string } from 'prop-types';

const iconStatuses = {
  unverified: 'fa fa-clock',
  accepted: 'fa fa-check',
  rejected: 'fa fa-pencil',
  waiting_next_stage: 'fa fa-flag',
  processing: 'fa fa-cog',
  error_processing: 'fa a-times',
};
export default function Icon({ status }) {
  return (
    <i className={iconStatuses[status]} />
  );
}

Icon.propTypes = {
  status: string.isRequired,
};
