/* eslint-disable no-constant-condition */
import { take, put, call, fork, all } from "redux-saga/effects";
import { api } from "../services";
import * as actions from "../actions";

import UserProfileWatchers from './user-profile';
import SharedCommentsWatchers from './shared-comments';
import CatalogWatchers from './catalog';
import HomeworkWathers from './homework';

function* watchStartConveringMp4ToGif() {
  while (true) {
    const { id } = yield take(actions.START_CONVERTING_MP4_TO_GIF[actions.REQUEST]);
    const { response, error } = yield call(api.startConvertingMp4ToGif, id);

    if (response) {
      yield put({ type: actions.START_CONVERTING_MP4_TO_GIF[actions.SUCCESS] });
    } else {
      yield put({ type: actions.START_CONVERTING_MP4_TO_GIF[actions.FAILURE] });
      console.error(error);
      alert('Произошла ошибка во время конвертирования');
    }
  }
}

function* watchDownloadConvertedGif() {
  while (true) {
    yield take(actions.DOWNLOAD_CONVERTED_GIF[actions.REQUEST]);

    const { response, error } = yield call(api.downloadConvertedGif);

    if (response) {
      yield put({ type: actions.START_CONVERTING_MP4_TO_GIF[actions.SUCCESS] });
    } else {
      yield put({ type: actions.START_CONVERTING_MP4_TO_GIF[actions.FAILURE] });
      console.error(error);
      alert('Произошла ошибка во время конвертирования');
    }
  }
}

export default function* root() {
  yield all([
    // CATALOG SAGAS
    fork(CatalogWatchers),

    // USER PROFILE SAGAS
    fork(UserProfileWatchers),

    // convert mp4 to gif
    fork(watchStartConveringMp4ToGif),
    fork(watchDownloadConvertedGif),

    // Shared Comments
    fork(SharedCommentsWatchers),

    // Homework
    fork(HomeworkWathers),
  ]);
}
