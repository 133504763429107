import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import cn from "classnames";

export default function ContestCompletedCard(props) {
  const {
    id,
    title,
    cover,
    winners = [],
    isWinnerSelection,
    themeBackgroundColor: bgColor,
    themeTextColor: txtColor,
  } = props;

  return (
    <ContestCompletedCardComponent
      image={cover}
      theme={{ bgColor, txtColor }}
      href={`/contest/${id}`}
    >
      <section className="contest-cover" />
      <section className="contest-winners">
        <div className="contest-title">{title}</div>
        {isWinnerSelection && (
          <WinnerSelectionTitle>
            Для этого конкурса мы пока определяем победителей
            {' '}
            <span role="img" aria-label=";)">😉</span>
          </WinnerSelectionTitle>
        )}
        {winners.map(({ id: winnerId, user: { avatarBig, nickname }, prizePlaceIcon }) => (
          <div key={winnerId} className="contest-winner">
            <img
              src={avatarBig}
              className={cn("user-avatar", {
                "missed-avatar": avatarBig.endsWith("_cat_avatar.png"),
              })}
              alt=""
            />
            <div className="name">
              {parse(nickname.replace(/\s+/g, " ").replace(/\s/g, "<br>"))}
            </div>

            <img className="prize-place-icon " src={prizePlaceIcon} alt="" />
          </div>
        ))}
      </section>
    </ContestCompletedCardComponent>
  );
}

const ContestCompletedCardComponent = styled.a`
  width: 100%;
  position: relative;
  display: flex;
  align-items: stretch;
  height: 272px;
  background: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 10px;
  transition: all 150ms ease-out;
  transition-property: box-shadow;
  outline: none 0 !important;
  margin: 0 0 20px;
  text-align: center;

  &:hover {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
  }

  &:last-child {
    margin: 0;
  }

  .contest-cover {
    width: 48%;
    background-image: url(${props => props.image});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
  }

  .contest-winners {
    display: flex;
    flex: 1;
    flex-flow: row;
    align-items: start;
    justify-content: center;
    align-self: center;
    flex-wrap: wrap;
  }

  .contest-title {
    color: ${props => props.theme.txtColor || "#333"};
    font: 22px/1.25 "Montserrat bold";
    width: 100%;
  }

  .contest-winner {
    position: relative;
    margin: 30px 20px 0;

    .name {
      color: #434343;
      font: 15px/1.35 Roboto;
      margin: 10px 0 0;
    }

    .user-avatar {
      height: 80px;
      width: auto;
      border-radius: 999px;

      &.missed-avatar {
        border-radius: 0;
      }
    }
  }

  .prize-place-icon {
    position: absolute;
    top: -10px;
    right: -10px;
    max-width: 40px;
  }
`;

const WinnerSelectionTitle = styled.div`
  color: #2c4373;
  font-size: 16px;
  max-width: 320px;
  background: #ebe6f7;
  border-radius: 12px;
  padding: 8px;
  margin-top: 16px;
`;
