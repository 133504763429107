import React, { Component } from 'react';
import { array, bool, element, func, string } from 'prop-types';
import cn from "classnames";
import { hasVerticalScrollbar } from 'Core/services/tools';

import CatalogSmallPlaceHolder from '../Catalog/CatalogSmallPlaceHolder';
import CatalogPlaceholder from '../Catalog/CatalogPlaceholder';

class ListWithInfinityScroll extends Component {
  state = { documentScrollable: false };

  componentDidMount() {
    this.forceLoadMoreIfNeed();

    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items.length !== this.props.items.length) {
      this.forceLoadMoreIfNeed();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  checkDocumentScrollable = () => {
    const hasScroll = hasVerticalScrollbar();

    if (hasScroll) {
      this.setState({ documentScrollable: true });
    }

    return hasScroll;
  };

  forceLoadMoreIfNeed = () => {
    const { loading, hasMore } = this.props;
    const { documentScrollable } = this.state;

    if (loading || !hasMore || documentScrollable || this.checkDocumentScrollable()) {
      return;
    }

    this.props.loadMore();
  }

  handleScroll = () => {
    const { loading, hasMore, loadMore, error } = this.props;
    // lastChildHeight будет равен 0 если нет ни одного элемента
    // или все элементы скрыты со страницы,
    // например включена другая вкладка (страница профиля юзера)
    const lastChildHeight = this.listRef.lastChild ? this.listRef.lastChild.offsetHeight : 0;

    if (loading || !hasMore || error || lastChildHeight === 0) return;

    const winInnnerHeight = window.innerHeight;
    const lastChildBottom = this.listRef.lastChild.getBoundingClientRect().bottom;

    // подгружаем еще если показался последний элемент списка
    if (lastChildBottom - lastChildHeight - winInnnerHeight < 0) {
      loadMore();
    }
  }

  renderPlaceholder = () => {
    const { placeholder, noItemsText } = this.props;

    if (placeholder === 'small') {
      return <CatalogSmallPlaceHolder text={noItemsText} />;
    }

    return <CatalogPlaceholder text={noItemsText} />;
  }

  render() {
    const { items, loading, spinner } = this.props;

    return (
      <div ref={(el) => { this.listRef = el; }} className="d-flex flex-wrap">
        {items}

        {loading && (
          <div className={cn("w-100 mx-auto", { "py-4": !items.length })}>
            {spinner}
          </div>
        )}

        {!loading && !items.length && this.renderPlaceholder()}
      </div>
    );
  }
}

ListWithInfinityScroll.propTypes = {
  items: array.isRequired,
  loading: bool.isRequired,
  hasMore: bool.isRequired,
  loadMore: func.isRequired,
  spinner: element.isRequired,
  noItemsText: string,
  placeholder: string,
  error: bool,
};

ListWithInfinityScroll.defaultProps = {
  noItemsText: 'Контент отсутствует',
  placeholder: 'big',
  error: false,
};

export default ListWithInfinityScroll;
