import React, { forwardRef } from "react";
import { useField } from "formik";
import styled, { css } from "styled-components";
import cn from "classnames";

function Input(props) {
  const { label = "", disabled } = props;
  const [field, meta] = useField(props);

  const [rand, date] = [Math.random(), Date.now()];
  const inputId = (rand.toString(36) + date.toString(36)).substr(2, 10);

  if (!field.value) {
    field.value = "";
  }

  return (
    <InputComponentWrapper
      disabled={disabled}
      ref={props.forwardedRef}
      className={cn("input-component", { disabled })}
    >
      <div className="wrapper">
        {label.length > 0 && (
          <label htmlFor={`input-component-${inputId}`}>{label}</label>
        )}
        <InputComponent
          {...field}
          {...props}
          autoComplete="none"
          id={`input-component-${inputId}`}
        />
      </div>
      {meta.error && (
        <div className="error">
          <div className="arrow-wrapper">{meta.error}</div>
        </div>
      )}
    </InputComponentWrapper>
  );
}

const forwardRefWrapper = forwardRef((props, ref) => <Input {...props} forwardedRef={ref} />);

forwardRefWrapper.displayName = "Input";
export default forwardRefWrapper;

const InputComponentWrapper = styled.div`
  position: relative;
  align-items: center;

  .wrapper {
    display: flex;
    align-items: center;
  }

  .error {
    text-align: right;
    color: #f25e5e;
    font-size: 13px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
    width: 200px;
    margin-left: 28px;

    .arrow-wrapper {
      position: relative;
      box-sizing: border-box;
      background: #fffafa;
      border-radius: 4px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
      padding: 7px 10px;
      min-height: 50px;

      &:after {
        z-index: -10;
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        margin-left: 0;
        bottom: 0;
        top: calc(50% - 14px);
        left: 0;
        box-sizing: border-box;
        border: 10px solid #fffafa;
        border-color: transparent transparent #fffafa #fffafa;
        transform-origin: 0 0;
        transform: rotate(45deg);
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
      }

      &:before {
        z-index: 10;
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        margin-left: 0;
        bottom: 0;
        top: calc(50% - 14px);
        left: 0;
        box-sizing: border-box;
        border: 10px solid black;
        border-color: transparent transparent #fffafa #fffafa;
        transform-origin: 0 0;
        transform: rotate(45deg);
      }
    }
  }

  label {
    margin: 0 20px 0 0;
  }

  ${props => props.disabled
    && css`
      label {
        color: rgba(0, 0, 0, 0.3);
      }
    `}
`;

const InputComponent = styled.input.attrs(props => ({
  type: props.type || "text",
  name: props.name,
  disabled: props.disabled,
  placeholder: props.placeholder,
  ...props,
}))`
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  outline: none 0 !important;
  border-radius: 4px;
  background: #fff;
  height: 38px;
  padding: 0 14px;
  font: normal 15px/1 Roboto;
  transition: 0.2s all;
  width: ${props => props.width || "350px"};
  text-align: ${(props) => {
    if (["left", "right", "center"].includes(props.textAlign)) { return props.textAlign; }

    return "left";
  }};

  &:focus {
    border-color: #d7d7d7;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  &::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  ${props => props.disabled
    && css`
      color: rgba(0, 0, 0, 0.3) !important;
      user-select: none !important;
    `}
`;
