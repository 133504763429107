import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { func, bool, string, number, array } from "prop-types";
import moment from "moment";
import cn from "classnames";

import { history } from "Core/services";
import {
  loadHomework,
  prepareStudentWork,
  updateReview,
  showOnCourse,
  toggleLikeCatalogItem,
  uploadRequest,
  uploadTextHomeworkVersion,
  startConvertingMp4ToGif,
} from "Core/actions";
import { getHomeworkVersions } from "Core/reducers/selectors";
import SharedComments from "./SharedComments";

import Modal from "../components/layouts/modals/Modal";
import Spinner from "../components/layouts/Spinner";

import PaymentModal from "../components/Chat/PaymentModal";
import UploadTextHomeworkModal from "../components/Homework/UploadTextHomeworkModal";

import HomeworkHeader from "../components/Homework/HomeworkHeader";
import HomeworkBody from "../components/Homework/HomeworkBody";
import HomeworkActions from "../components/Homework/HomeworkActions";
import OpenChatButton from '../components/Homework/OpenChatButton';

const HomeworkView = ({
  // own props
  id,
  closeModal,
  reduxCatalogKey,
  showOpenChatButton,
  // map dispatch functions
  loadWork,
  prepareWork,
  updateStatus,
  toggleFameHall,
  toggleLike,
  uploadVersion,
  uploadTextVersion,
  convertToGif,
  // map state work fields
  currentUserId,
  isTeacher,
  isGifConverting,
  loaded,
  versions,
}) => {
  const [textModalShown, setTextModalShown] = useState(false);
  const [paymentModalSHown, setPaymentModalShown] = useState(false);
  const [activeVersionIndex, setActiveVersionIndex] = useState(0);

  const activeWork = versions[activeVersionIndex];

  const [modalAnimateShown, setModalAnimateShow] = useState(false);
  const [isBodyContentLoaded, setBodyContentLoadedState] = useState(false);

  // после монтирования загружаем работу
  useEffect(() => {
    loadWork({ id, reduxCatalogKey });

    return () => prepareWork({ id, reduxCatalogKey });
  }, []);

  useEffect(() => {
    if (!loaded) return;

    setActiveVersionIndex(versions.findIndex(h => h.id === id));
  }, [loaded]);

  // после подгрузки картинки показываем модалку
  useEffect(() => {
    const isWrongStatus = activeWork && ['processing', 'error_processing'].includes(activeWork.status);

    if (isWrongStatus && !isBodyContentLoaded && loaded) {
      setBodyContentLoadedState(true);
    }

    if (!loaded || !isBodyContentLoaded) return;

    setModalAnimateShow(true);
  }, [loaded, isBodyContentLoaded]);

  const changeActiveVersion = index => setActiveVersionIndex(index);

  const handleToggleLike = () => {
    if (!currentUserId) {
      history.push('/login');
      return;
    }

    toggleLike({ itemId: activeWork.id, entity: "homeworks" });
  };

  const handleUpdateReview = (status) => {
    updateStatus(activeWork.id, {
      status,
      chat_id: activeWork.chatId,
    });
  };

  const isShowPaymentModal = () => {
    const {
      course: { use_consultations_feature: isConsultationsAvailable },
      flow,
      user,
    } = activeWork;
    const { consultation_last_date: date } = user;
    const isConsultationEnded = moment(date) < moment().startOf("day");

    return !flow.free && flow.available && isConsultationsAvailable && isConsultationEnded;
  };

  const handleVersionUploadClick = (inputRef) => {
    if (!activeWork.flow.available) {
      // eslint-disable-next-line no-alert
      return alert("Извините, курс завершен");
    }

    if (isShowPaymentModal()) {
      return setPaymentModalShown(true);
    }

    const { chatWorkTime } = activeWork;

    if (chatWorkTime.isUp && chatWorkTime.blockHomeworkLoading) {
      // eslint-disable-next-line no-alert
      return alert("Загружать домашние работы можно в рабочее время чата");
    }

    if (activeWork.type === "text") return setTextModalShown(true);

    inputRef.click();
  };

  const handleVersionUpload = (file) => {
    uploadVersion(file, {
      course_id: activeWork.course.id,
      flow_id: activeWork.flow.id,
      lesson_id: activeWork.lesson.id,
      exercise_id: activeWork.exercise.id,
    });

    closeModal();
  };

  const handleTextVersionUpload = (text) => {
    uploadTextVersion(text, {
      course_id: activeWork.course.id,
      flow_id: activeWork.flow.id,
      lesson_id: activeWork.lesson.id,
      exercise_id: activeWork.exercise.id,
    });
  };

  const handleShowOnCourse = () => {
    toggleFameHall(activeWork.id);
  };

  const renderContent = () => {
    if (!loaded || activeVersionIndex === null) {
      return <Spinner />;
    }

    if (textModalShown) {
      return (
        <UploadTextHomeworkModal
          backToHomework={() => setTextModalShown(false)}
          onTextVersionUpload={handleTextVersionUpload}
          closeHomework={closeModal}
        />
      );
    }

    return (
      <div
        className={cn(
          "modal-content",
          "modal-hw-content",
          "new-modal-hw-content",
          {
            "animate-shown": modalAnimateShown,
          },
        )}
        style={{ paddingBottom: activeWork.status === "accepted" ? 0 : "26px" }}
      >
        <HomeworkHeader
          isTeacher={isTeacher}
          homework={activeWork}
          isGifConverting={isGifConverting}
          convertToGif={convertToGif}
          onUpdateReview={handleUpdateReview}
          closeHomework={closeModal}
        />

        <HomeworkBody
          versions={versions}
          activeVersionIndex={activeVersionIndex}
          changeActiveVersion={changeActiveVersion}
          onContentLoaded={() => setBodyContentLoadedState(true)}
        />

        <hr style={{ marginTop: "10px" }} />

        <HomeworkActions
          finalStatus={versions.find(h => h.final).status}
          activeWork={activeWork}
          isTeacher={isTeacher}
          isFreeFlow={activeWork.flow.free}
          isFreeLesson={activeWork.lesson.has_free_access}
          isShowOnCourse={activeWork.isShowOnCourse}
          onShowOnCourse={handleShowOnCourse}
          uploadVersion={handleVersionUpload}
          onToggleLike={handleToggleLike}
          handleVersionUploadClick={handleVersionUploadClick}
          showChatButton={showOpenChatButton && (
            <OpenChatButton
              homeworkId={activeWork.id}
            />
          )}
        />

        {activeWork.status === "accepted" && (
          <footer>
            <hr style={{ marginTop: "10px" }} />
            <SharedComments
              entity="homeworks"
              commentableId={activeWork.id}
              commentsNumber={activeWork.commentsNumber}
              insideModal
            />
          </footer>
        )}
      </div>
    );
  };

  if (paymentModalSHown) {
    return (
      <PaymentModal
        chat_id={activeWork.chatId}
        closeModal={() => setPaymentModalShown(false)}
      />
    );
  }

  return (
    <Modal closeModal={closeModal}>
      {renderContent()}
    </Modal>
  );
};

HomeworkView.propTypes = {
  // OWN PROPS
  id: number.isRequired,
  closeModal: func.isRequired,
  reduxCatalogKey: string.isRequired,
  showOpenChatButton: bool,
  // MAP STATE TO PROPS
  loaded: bool.isRequired,
  currentUserId: number,
  isTeacher: bool,
  isGifConverting: bool,
  versions: array.isRequired,
  // MAP DISPATCH TO PROPS
  loadWork: func.isRequired,
  prepareWork: func.isRequired,
  updateStatus: func.isRequired,
  toggleFameHall: func.isRequired,
  toggleLike: func.isRequired,
  uploadVersion: func.isRequired,
  uploadTextVersion: func.isRequired,
  convertToGif: func.isRequired,
};

HomeworkView.defaultProps = {
  currentUserId: null,
  isTeacher: false,
  isGifConverting: false,
  showOpenChatButton: false,
};

function mapStateToProps(state, { reduxCatalogKey: catKey }) {
  const catEntity = state.entities.catalog[catKey];
  const homeworkId = catEntity ? catEntity.activeModal : null;

  if (!homeworkId) return { loaded: false, versions: [] };

  return {
    loaded: true,
    currentUserId: state.local.currentUser.id,
    isGifConverting: !!state.local.currentUser.mp4ToGifConverting,
    isTeacher: !!state.local.currentUser.is_teacher,
    versions: getHomeworkVersions(state, homeworkId),
  };
}

export default connect(mapStateToProps, {
  loadWork: loadHomework,
  prepareWork: prepareStudentWork,
  updateStatus: updateReview,
  toggleLike: toggleLikeCatalogItem,
  toggleFameHall: showOnCourse,
  uploadVersion: uploadRequest,
  uploadTextVersion: uploadTextHomeworkVersion,
  convertToGif: startConvertingMp4ToGif,
})(HomeworkView);
