import React from "react";
import omit from "lodash/omit";
import { func, bool, object, number } from "prop-types";

import Item from "./AdminSidebarItem";
import AdminSidebarCourses from "./AdminSidebarCourses";

export default function AdminSidebar(props) {
  const { unread, total, show_counters, unanswered } = props;

  const contextProps = context => ({
    active:
        JSON.stringify(context)
        === JSON.stringify(omit(props.context, ["page", "search"])),
    onClick: () => props.setContext(context),
  });

  return (
    <div className="admin_chats_layout_sidebar">
      <Item
        text="Все"
        badge={show_counters ? total : null}
        {...contextProps({ all: true })}
      />
      <Item
        text="Непрочитанные"
        badge={unread}
        {...contextProps({ unread: true })}
      />
      <Item
        text="Неотвеченные"
        badge={unanswered}
        {...contextProps({ unanswered: true })}
      />
      <div className="chats_sidebar_filter_divider" />
      <AdminSidebarCourses {...props} contextProps={contextProps} />
    </div>
  );
}

AdminSidebar.propTypes = {
  context: object.isRequired,
  setContext: func.isRequired,
  show_counters: bool.isRequired,
  unread: number.isRequired,
  total: number,
  unanswered: number,
};

AdminSidebar.defaultProps = {
  total: null,
  unanswered: null,
};
