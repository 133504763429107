import React from 'react';
import { bool, func, string } from 'prop-types';

import { getIconInfoByHomeworkStatus } from 'Core/services/chat';

const HomeworkStatus = ({
  status,
  isTeacher,
  onShowReviewForm,
  shownReviewForm,
}) => {
  const renderStatus = () => {
    const statusInfo = getIconInfoByHomeworkStatus(status);

    return (
      <div className={`homework_status_circle -${status}`}>
        <i className={`fa ${statusInfo.className}`} />
        <span>{statusInfo.text}</span>
      </div>
    );
  };

  const renderTeacherStatus = () => {
    const reviewClass = shownReviewForm ? 'modal-hw-status-active' : '';

    return (
      <div className={`modal-hw-review-action ${reviewClass}`} onClick={onShowReviewForm}>
        <i className={`fa fa-angle-${shownReviewForm ? 'down' : 'up'} modal-arrow-down`} />
        {renderStatus(status)}
      </div>
    );
  };

  return (
    <div className="modal-hw-status">
      { isTeacher ? renderTeacherStatus() : renderStatus() }
    </div>
  );
};

HomeworkStatus.propTypes = {
  status: string.isRequired,
  isTeacher: bool.isRequired,
  shownReviewForm: bool,
  onShowReviewForm: func,
};

HomeworkStatus.defaultProps = {
  shownReviewForm: false,
  onShowReviewForm: null,
};

export default HomeworkStatus;
