import React from 'react';
import { string } from 'prop-types';

export default function Spinner({ className }) {
  return (
    <div className={`d-flex justify-content-center ${className}`}>
      <div className="loadersmall" />
    </div>
  );
}

Spinner.propTypes = {
  className: string,
};

Spinner.defaultProps = {
  className: '',
};
