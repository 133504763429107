import React, { Component } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { openPassLessonTestModal, openLessonTestModal, closeLessonTestModal, loadLessonTest, onCompleteLessonTest } from 'Core/actions';

import { LessonTestModal, PassLessonTestModal } from '../components/LessonTest';

class LessonTest extends Component {
  componentDidMount() {
    document.body.addEventListener('open-pass-lesson-test-modal', this.openPassTestHandler);
    document.body.addEventListener('open-lesson-test-modal', this.openTestHandler);
  }

  componentWillUnmount() {
    document.body.removeEventListener('open-pass-lesson-test-modal', this.openPassTestHandler);
    document.body.removeEventListener('open-lesson-test-modal', this.openTestHandler);
  }

  openPassTestHandler = e => this.props.openPassLessonTestModal(e.detail);

  openTestHandler = e => this.props.openLessonTestModal(e.detail);

  render() {
    if (this.props.lessonTestModal.open) {
      return <LessonTestModal {...this.props} />;
    }

    if (this.props.passLessonTestModal.open) {
      return <PassLessonTestModal {...this.props} />;
    }

    return false;
  }
}

LessonTest.propTypes = {
  closeLessonTestModal: func.isRequired,
  loadLessonTest: func.isRequired,
  openPassLessonTestModal: func.isRequired,
  openLessonTestModal: func.isRequired,
  passLessonTestModal: object,
  lessonTestModal: object,
};

LessonTest.defaultProps = {
  passLessonTestModal: {},
  lessonTestModal: {},
};

const mapStateToProps = state => ({
  lessonTestModal: state.local.lessonTestModal,
  lessonTests: state.local.lessonTests,
  passLessonTestModal: state.local.passLessonTestModal,
});

export default connect(mapStateToProps, {
  closeLessonTestModal,
  loadLessonTest,
  openPassLessonTestModal,
  openLessonTestModal,
  onCompleteLessonTest,
})(LessonTest);
