/* eslint-disable no-constant-condition */
import { take, put, call, fork, all } from "redux-saga/effects";
import { api } from "../services";
import { COMMENTS_LOAD, COMMENT_CREATE, COMMENT_DESTROY, COMMENT_UPDATE, REQUEST, SUCCESS, FAILURE } from "../actions";

function* watchCommentsLoadRequest() {
  while (true) {
    const { payload } = yield take(COMMENTS_LOAD[REQUEST]);

    const { response, error } = yield call(api.loadComments, payload);

    if (response) {
      yield put({
        type: COMMENTS_LOAD[SUCCESS],
        payload: { ...payload, response },
      });
    } else {
      yield put({
        type: COMMENTS_LOAD[FAILURE],
        payload,
      });
      console.error(error);
    }
  }
}

function* watchCommentCreateRequest() {
  while (true) {
    const { payload } = yield take(COMMENT_CREATE[REQUEST]);

    const { response, error } = yield call(api.createComment, payload);

    if (response) {
      yield put({
        type: COMMENT_CREATE[SUCCESS],
        payload: { ...payload, response },
      });
    } else {
      yield put({
        type: COMMENT_CREATE[FAILURE],
        payload,
      });
      console.error(error);
    }
  }
}

function* watchCommentDeleteRequest() {
  while (true) {
    const { payload } = yield take(COMMENT_DESTROY[REQUEST]);

    const { response, error } = yield call(api.deleteComment, payload.id);

    if (response) {
      yield put({
        type: COMMENT_DESTROY[SUCCESS],
        payload: { ...payload, response },
      });
    } else {
      yield put({
        type: COMMENT_DESTROY[FAILURE],
        payload,
      });
      console.error(error);
    }
  }
}

function* watchCommentUpdateRequest() {
  while (true) {
    const { payload } = yield take(COMMENT_UPDATE[REQUEST]);

    const { response, error } = yield call(api.updateComment, payload);

    if (response) {
      yield put({
        type: COMMENT_UPDATE[SUCCESS],
        payload: { ...payload, response },
      });
    } else {
      yield put({
        type: COMMENT_UPDATE[FAILURE],
        payload,
      });
      console.error(error);
    }
  }
}

export default function* root() {
  yield all([
    fork(watchCommentsLoadRequest),
    fork(watchCommentCreateRequest),
    fork(watchCommentDeleteRequest),
    fork(watchCommentUpdateRequest),
  ]);
}
