import React from 'react';
import { array, object } from 'prop-types';

const CourseItems = ({ items, icons }) => (
  <div className="d-flex flex-wrap my-3">
    {items.map(item => (
      <div
        className="catalog__item cell cell_50"
        key={`catalog__item-${item.id}`}
      >
        <a className="p-card medium course_card" href={item.url}>
          <div
            className="catalog-item-wrapper"
          >
            <div
              className="p-card-img-top course_card_img"
              style={{
                backgroundImage: `url(${item.cover})`,
                backgroundSize: "cover",
              }}
            />
            <div className="p-card-body">
              <div className="p-card-title primary course_card_title_center">
                {item.cover_name}
              </div>

              <div className="course_card_info">
                <div className="course_card_info_item">
                  <img src={icons.lesson_icon} alt="" />
                  <span>{item.lessons_number}</span>
                </div>
                <div className="course_card_info_item">
                  <img src={icons.exercise_icon} alt="" />
                  <span>{item.exercises_number}</span>
                </div>
                <div className="course_card_info_item">
                  <img src={icons.subject_icon} alt="" />
                  <span>{item.subjects_number}</span>
                </div>
                <div className="course_card_info_item">
                  <img src={icons.course_duration_icon} alt="" />
                  <span>{item.course_duration}</span>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    ))}
  </div>
);

CourseItems.propTypes = {
  items: array.isRequired,
  icons: object.isRequired,
};

export default CourseItems;
