/*
  Редактируя этот файл не забудь внести соответствующие изменения в CourseDetailsServer,
  это необходимо для синхронизация серверного рендеринга с клиентским (react on rails)
*/

import React, { useState, useEffect } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import cn from "classnames";

import { StudentWork } from "Main/components/StudentWork";
import FreeSubscribeBlock from './FreeSubscribeBlock';

SwiperCore.use([Navigation, Pagination]);

const CourseDetailsClient = ({
  lessons, hasSubscribeAbility, freeSubscribeClick,
  loading, error, homeworks, toggleLike, reduxCatalogKey,
}) => {
  const [mounted, setMounted] = useState(false);
  let lessonNumber = 0;

  useEffect(() => {
    setMounted(true);
  }, []);

  const renderSliderByLesson = (lesson) => {
    const works = homeworks[lesson.id];

    if (typeof works === "undefined" || works.length === 0 || error) return null;

    return (
      <Swiper
        pagination={{
          el: document.querySelector(`.course-bestworks-${lesson.id} .swiper-pagination`),
          clickable: true,
        }}
        navigation={{
          nextEl: document.querySelector(`.course-bestworks-${lesson.id} .swiper-button-next`),
          prevEl: document.querySelector(`.course-bestworks-${lesson.id} .swiper-button-prev`),
        }}
        slidesPerView={3}
        spaceBetween={3}
        slidesPerGroup={3}
        mousewheel={false}
        watchOverflow
      >
        {works.map(work => (
          <SwiperSlide key={work.id}>
            <div className="catalog-item">
              <StudentWork
                {...work}
                reduxCatalogKey={reduxCatalogKey}
                onToggleLike={() => toggleLike(work.id)}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  const renderLessonNumber = (lesson) => {
    if (lesson.landing_image) {
      if (lessonNumber !== 0) lessonNumber += 1;

      return <div className="course-lesson_image lozad" data-background-image={lesson.landing_image} />;
    }

    lessonNumber += 1;

    return <div className="course-lesson_num">{lessonNumber}</div>;
  };

  return (
    <section id="course-details">
      <div className="wide-block-wrapper">
        <div className="wrapper">
          {hasSubscribeAbility && (
            <div style={{ marginBottom: 50 }}>
              <FreeSubscribeBlock freeSubscribeClick={freeSubscribeClick} />
            </div>
          )}

          <div className="course-page-title">
            Программа курса
          </div>

          <div className="lessons-wrapper">
            {lessons.map((lesson) => {
              const works = homeworks[lesson.id];

              return (
                <div key={lesson.id}>
                  <div className="course-lesson d-flex align-items-center justify-content-start">
                    {renderLessonNumber(lesson)}

                    <div className="course-lesson_details">
                      <span className="title">
                        {lesson.title}
                      </span>
                      {lesson.tags.trim() && (
                        <div className="tags">
                          {lesson.tags.trim().split(',').map(tag => (
                            <div key={tag} className="tag">{tag.trim()}</div>
                          ))}
                        </div>
                      )}
                      <p>{lesson.description}</p>
                    </div>
                  </div>

                  {loading && !works && !error && (
                    <div className="d-flex justify-content-center load-indicator">
                      <div className="loadersmall modal-hw-loader" />
                    </div>
                  )}

                  <div
                    className={cn(`course-bestworks course-bestworks-${lesson.id}`, {
                      hidden: (error && !works) || !mounted || (mounted && !works),
                    })}
                  >
                    <div className={cn('ui carousel', { 'no-pagination': works && works.length < 3 })}>
                      <div className="carousel-wrapper">
                        {mounted && renderSliderByLesson(lesson)}

                        <div className="swiper-button-prev" />
                        <div className="swiper-button-next" />
                      </div>

                      <div className="swiper-pagination" />
                    </div>

                    <div className="course-bestworks-label">
                      Работы учеников по
                      {' '}
                      {lessons.landing_image ? 'этому уроку' : `уроку №${lessonNumber}`}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

CourseDetailsClient.propTypes = {
  lessons: array.isRequired,
  hasSubscribeAbility: bool.isRequired,
  freeSubscribeClick: func.isRequired,
  toggleLike: func.isRequired,
  loading: bool.isRequired,
  error: bool.isRequired,
  homeworks: object.isRequired,
  reduxCatalogKey: string.isRequired,
};

export default CourseDetailsClient;
