import React from "react";
import StudentWorkRoot from "Main/containers/StudentWorkRoot";

class StudentWorkModule extends React.Component {
  render() {
    return <StudentWorkRoot />;
  }
}

export default StudentWorkModule;
