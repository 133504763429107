import React from "react";
import { func, string } from "prop-types";
import styled from "styled-components";

export default function Button({ caption, onClick, ...props }) {
  return (
    <ButtonComponent
      onClick={e => onClick(e)}
      className="button-component"
      {...props}
    >
      <span>{caption}</span>
    </ButtonComponent>
  );
}

Button.displayName = "Button";

Button.propTypes = {
  onClick: func,
  caption: string,
};

Button.defaultProps = {
  onClick: () => {},
  caption: 'Сохранить',
};

const ButtonComponent = styled.button.attrs(props => ({
  type: props.type || "submit",
}))`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  min-height: 34px;
  font: 500 15px/1 Roboto;
  background: #3190ed;
  color: #fff;
  border-radius: 4px;
  border: none 0;
  outline: none 0 !important;
  transition: 0.2s all;

  &:hover {
    filter: contrast(130%);
  }
`;
