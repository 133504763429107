import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
} from "react";
import { object, string, number } from "prop-types";
import Plyr from "plyr";
// import styles from 'plyr/dist/plyr.css';

function PlyrComponent(props) {
  const { forwardedRef, source, versionId, options } = props;
  const videoRef = useRef();
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    const plyr = new Plyr(videoRef.current, options);
    plyr.on("ready", () => {
      setPlayer(plyr);
      forwardedRef(videoRef.current);
    });
  }, []);

  useEffect(() => () => player && player.destroy(), [player]);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      className="modal-hw-player"
      id={`player${versionId}`}
      controls
      loop
      ref={videoRef}
    >
      <source src={source} />
    </video>
  );
}

export default forwardRef((props, ref) => (
  <PlyrComponent {...props} forwardedRef={ref} />
));

PlyrComponent.propTypes = {
  options: object, // this is an options object from the docs
  source: string.isRequired, // this is a source object from the docs
  versionId: number.isRequired,
};

PlyrComponent.defaultProps = {
  options: {},
};
