import React, { PureComponent } from 'react';
import { func } from 'prop-types';
import { question as questionType } from 'Main/types';

import Checkbox from 'Main/components/layouts/forms/Checkbox';
import RadioItem from 'Main/components/layouts/forms/RadioItem';

export default class LessonTestQuestion extends PureComponent {
  state = {
    selected: [],
    answered: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.question.id !== this.props.question.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selected: [], answered: false });
      this.answers = this.getAnswers();
    }
  }

  getSelectedComments = () => this.props.question.options.filter(
    o => this.state.selected.includes(o.id),
  ).map(o => o.comment).filter(hint => !!hint)

  findImageComment = () => {
    const { options } = this.props.question;
    const { selected } = this.state;
    const option = options.find(o => selected.includes(o.id) && !!o.image_comment);

    if (!option) return null;

    return option.image_comment;
  }

  getAnswers = () => this.props.question.options.filter(o => o.correct).map(o => o.id)

  getCheckboxType = (correct) => {
    let checkboxType = 'checked';

    if (this.state.answered) {
      checkboxType = correct ? 'correct' : 'wrong';
    }

    return checkboxType;
  };

  handleOnAnswer = () => {
    if (this.state.answered) {
      this.props.onAnswer(this.isAnswerCorrect());
    } else {
      this.setState({ answered: true }, this.props.onAnswer(this.isAnswerCorrect()));
    }
  }

  isSelected = option => this.state.selected.includes(option.id)

  isAnswerCorrect = () => this.state.selected.sort().join('') === this.answers.sort().join('')

  handleOnChangeRadioItem = (option) => {
    this.setState({
      selected: [option.id],
      answered: true,
    }, this.handleOnAnswer);
  }

  handleOnChangeCheckbox = (option) => {
    // если ученик выбрал 1 и более вариантов
    // то кнопка ответить становиться активной

    this.setState((state) => {
      const inx = state.selected.indexOf(option.id);
      let selected;

      if (inx === -1) {
        selected = [...state.selected, option.id];
      } else {
        selected = state.selected.filter(el => el !== option.id);
      }

      return { selected };
    });
  }

  answers = this.getAnswers();

  defaultComment = () => {
    const answerTexts = this.props.question.options.filter(o => o.correct).map(o => o.text.trim());
    const answer_correct = this.isAnswerCorrect();

    if (answerTexts.length > 1) {
      return answer_correct ? 'Верно!' : `Неверно! Правильные ответы - «${answerTexts.join('», «')}»`;
    }
    return answer_correct ? 'Верно!' : `Неверно! Правильный ответ - «${answerTexts.join()}»`;
  }

  renderRadioOptionComment = (option) => {
    if (option.comment) return option.comment;

    if (!option.image_comment) return this.defaultComment(option);

    return (
      <div>
        <div>{this.defaultComment(option)}</div>
        <img className="mt-1" src={option.image_comment} alt="" />
      </div>
    );
  };

  renderQuestionComment = () => (
    <p className="lesson-test-question-comment">
      {this.props.question.comment}
    </p>
  );

  renderRadioItems = () => (
    <>
      {this.props.question.options.map(option => (
        <RadioItem
          key={option.id}
          checked={this.isSelected(option)}
          label={option.text}
          checkedType={option.correct ? 'correct' : 'wrong'}
          hint={this.renderRadioOptionComment(option)}
          onChange={() => this.handleOnChangeRadioItem(option)}
          disabled={this.state.answered}
        />
      ))}
      {this.props.question.comment && this.renderQuestionComment()}
    </>
  );

  renderCheckboxes = () => {
    const { question } = this.props;

    return (
      <>
        {(
          question.options.map(option => (
            <Checkbox
              key={option.id}
              onChange={() => this.handleOnChangeCheckbox(option)}
              label={option.text}
              checkedType={this.getCheckboxType(option.correct)}
              checked={this.isSelected(option)}
              disabled={this.state.answered}
            />
          ))
        )}

        {question.comment && this.renderQuestionComment()}

        {!this.state.answered && (
          <div className="lesson-test-modal-next-question-btn d-flex justify-content-center">
            <button
              type="button"
              className="pixel-btn mx-auto"
              onClick={this.handleOnAnswer}
              disabled={this.state.selected.length === 0}
            >
              Ответить
            </button>
          </div>
        )}
      </>
    );
  }

  renderCheckboxesComments = () => {
    const imgCommentUrl = this.findImageComment();
    const comments = this.getSelectedComments();
    const addClass = !!comments.length || imgCommentUrl ? 'mb-0' : '';

    return (
      <div>
        <p className={`${addClass} ${this.isAnswerCorrect() ? 'text-success' : 'text-error'}`}>
          {this.defaultComment()}
        </p>
        {!!comments.length && (
          <div className="lesson-test-selected_comments">
            {this.getSelectedComments().map((comment, idx) => (
              <p key={`${comment}-${idx + 1}`}>
                {comment}
              </p>
            ))}
          </div>
        )}
        {imgCommentUrl && (
          <img className="mt-1" src={imgCommentUrl} alt="" />
        )}
      </div>
    );
  };

  render() {
    const { question } = this.props;
    const only_one_answer = this.answers.length === 1;

    return (
      <div>
        <h6 className="lesson-test-modal-header my-4">{question.text}</h6>
        { question.image && (
          <div className="d-flex justify-content-center mb-3">
            <img alt="" src={question.image} />
          </div>
        )}
        {only_one_answer ? this.renderRadioItems() : this.renderCheckboxes()}

        {this.state.answered && !only_one_answer && this.renderCheckboxesComments()}
      </div>
    );
  }
}

LessonTestQuestion.propTypes = {
  onAnswer: func.isRequired,
  question: questionType.isRequired,
};
