import React from "react";
import { RemarkGrayIcon } from "Main/components/layouts/icons/";

export default function ItemUserAvatar(props) {
  const { user, course, is_teacher } = props;
  const cover = is_teacher ? user.avatar : course.avatar;

  return (
    <div className="chat_avatar">
      <img
        src={cover}
        className={`message_avatar ${
          cover.endsWith("_cat_avatar.png") ? "border_radius_none" : ""
        }`}
        alt=""
      />

      {is_teacher && user.personal_remark && (
        <RemarkGrayIcon tooltip={user.personal_remark} />
      )}
    </div>
  );
}
