import { func, string } from 'prop-types';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import "react-datepicker/dist/react-datepicker.css";

registerLocale('ru', ru);
setDefaultLocale('ru');

const formatDate = d => `${(`0${d.getDate()}`).slice(-2)}-${(`0${d.getMonth() + 1}`).slice(-2)}-${
  d.getFullYear()}`;

// eslint-disable-next-line react/prop-types
const CalendarContainer = ({ children, className }) => {
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className={className} onClick={onClick}>{children}</div>
  );
};

export const DatePicker = ({ label, onChangeDate, value }) => {
  const datePickerRef = useRef();
  const [date, setDate] = useState(null);

  const openDatePicker = () => {
    if (datePickerRef.current.state.isOpen) return;

    datePickerRef.current.setOpen(true);
  };

  const handleChagneDate = (selectedDate) => {
    onChangeDate(formatDate(new Date(selectedDate)));
    setDate(selectedDate);
  };

  return (
    <div className="dropdown-comp" onClick={openDatePicker}>
      {label}
      :&nbsp;

      <div className="dropdown-comp__label">
        {value || 'дата'}
      </div>

      <ReactDatePickerComponent
        ref={datePickerRef}
        selected={date}
        onChange={handleChagneDate}
        calendarContainer={CalendarContainer}
      />
    </div>
  );
};

DatePicker.propTypes = {
  label: string.isRequired,
  onChangeDate: func.isRequired,
  value: string.isRequired,
};

const ReactDatePickerComponent = styled(ReactDatePicker)`
  opacity: 0;
  z-index: -1;
  position: absolute;
`;
