import React from "react";
import ContestsRoot from "Main/containers/ContestsRoot";

class ContestsModule extends React.Component {
  render() {
    const { contestId } = this.props;
    return <ContestsRoot contestId={contestId} />;
  }
}

export default ContestsModule;
