import React from "react";
import { string } from "prop-types";
import { extractRootDomain } from 'Core/services/parse-url';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faVk,
  faBehance,
  faDribbble,
  faYoutube,
  faArtstation,
  faFacebookF,
  faInstagram,
  faTelegram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const icons = {
  "vk.com": faVk,
  "facebook.com": faFacebookF,
  "behance.net": faBehance,
  "dribbble.com": faDribbble,
  "youtube.com": faYoutube,
  "artstation.com": faArtstation,
  "instagram.com": faInstagram,
  "whatsapp.com": faWhatsapp,
  "t.me": faTelegram,
  "telegram.org": faTelegram,
  default: faQuestionCircle,
};

export default function FontAwesomeBrandIcon({ link, ...restProps }) {
  const rootDomain = extractRootDomain(link);
  const icon = icons[rootDomain] || icons.default;

  return <FontAwesomeIcon icon={icon} {...restProps} />;
}

FontAwesomeBrandIcon.propTypes = {
  link: string.isRequired,
};
