import React, { useRef } from 'react';
import { any, bool, func, object, string } from 'prop-types';

import Like from './Like';
import CopyCodeButton from './CopyCodeButton';
import ShowOnCourseButtom from './ShowOnCourseButton';

const HomeworkActions = ({
  finalStatus,
  activeWork,
  isTeacher,
  isFreeFlow,
  isFreeLesson,
  isShowOnCourse,
  onShowOnCourse,
  uploadVersion,
  onToggleLike,
  handleVersionUploadClick,
  showChatButton,
}) => {
  const inputRef = useRef();

  return (
    <div className="modal-hw-actions">
      {activeWork.status === 'accepted' && (
        <Like
          onToggleLike={onToggleLike}
          likesNumber={activeWork.likesNumber}
          alreadyLiked={activeWork.alreadyLiked}
        />
      )}

      <div className="modal-hw-actions-right">
        {activeWork.text && <CopyCodeButton text={activeWork.text} />}

        {!isTeacher && finalStatus !== 'processing' && (
          <div
            className={`${isFreeFlow && !isFreeLesson ? 'disabled' : ''} modal-hw-upload-new-version`}
            onClick={() => handleVersionUploadClick(inputRef.current)}
          >
            <div className="p-btn">
              <div className="p-btn-text">
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={inputRef}
                  onChange={(e) => { uploadVersion(e.target.files[0]); }}
                  disabled={isFreeFlow && !isFreeLesson}
                />
                Загрузить новую версию
              </div>
            </div>
          </div>
        )}

        {isTeacher && activeWork.status === 'accepted' && (
          <ShowOnCourseButtom
            onShowOnCourse={onShowOnCourse}
            showOnCourse={isShowOnCourse}
          />
        )}

        {showChatButton || null}
      </div>
    </div>
  );
};

HomeworkActions.propTypes = {
  finalStatus: string.isRequired,
  activeWork: object.isRequired,
  isTeacher: bool.isRequired,
  isFreeFlow: bool.isRequired,
  isFreeLesson: bool.isRequired,
  isShowOnCourse: bool.isRequired,
  onShowOnCourse: func.isRequired,
  uploadVersion: func.isRequired,
  onToggleLike: func.isRequired,
  handleVersionUploadClick: func.isRequired,
  showChatButton: any.isRequired,
};

export default HomeworkActions;
