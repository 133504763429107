import React, { useState } from 'react';
import { array, object } from 'prop-types';

import cn from "classnames";
import merge from "lodash/merge";
import TabsSwitcher from "UI/TabsSwitcher";

import ContestActiveCard from "./ContestActiveCard";

const ActiveContests = ({ contests, tags }) => {
  const [activeTag, setActiveTag] = useState(0);

  if (contests.length === 0) {
    return (
      <div className="contests-comp__no-content">
        Активные конкурсы отсутствуют
      </div>
    );
  }

  const getActiveContests = () => {
    const tag = parseInt(activeTag, 10);

    if (tag === 0) return contests;

    return contests.filter(c => c.tag === tag);
  };

  return (
    <>
      <div className="active-contests-filter-tags">
        <TabsSwitcher
          defaultActive="0"
          items={merge({ 0: "Все" }, tags)}
          onSwitch={setActiveTag}
        />
      </div>
      <div
        className={cn("active-contests-list", {
          "items-align-center": contests.length < 3,
        })}
      >
        {getActiveContests().map(contest => (
          <ContestActiveCard key={contest.id} {...contest} />
        ))}
      </div>
    </>
  );
};

ActiveContests.propTypes = {
  contests: array.isRequired,
  tags: object.isRequired,
};

export default ActiveContests;
