import React from "react";
import { Provider } from "react-redux";
import { Route, Router, useParams } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

import initializeStore from "Core/store/initializeStore";
import contestWorkSaga from "Core/sagas/contest-work";
import { history } from "Core/services";

import { ContestWorkView } from '../components/ContestWorkView';
import Toast from '../components/layouts/Toast';

const getStore = initializeStore(contestWorkSaga);

function ContestWorkRouted() {
  const { id } = useParams();

  return (
    <ContestWorkView
      id={parseInt(id, 10)}
      closeModal={() => (history.location.state ? history.goBack() : history.push("/contests"))}
      reduxCatalogKey="contest"
    />
  );
}

class ContestWorkRoot extends React.Component {
  render() {
    return (
      <Provider store={getStore()}>
        <ToastProvider components={{ Toast }}>
          <Router history={history}>
            <Route
              exact
              path="/contest-work/:id"
              component={ContestWorkRouted}
            />
          </Router>
        </ToastProvider>
      </Provider>
    );
  }
}

export default ContestWorkRoot;
