import React, { Component } from 'react';
import { object, number, func, array, string } from 'prop-types';
import { connect } from 'react-redux';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import lozad from "lozad";

import { getStudentWorkCoversFields } from "Core/reducers/selectors";
import { history } from "Core/services";
import {
  setAfterSignInPath, prepareCatalog, toggleLikeCatalogItem,
} from '../../core/actions';
import { freeSubscribeToCourse } from '../../core/services/api';
import { CourseLayout } from '../components/Course';
import CoursePracticeClient from '../components/Course/CoursePracticeClient';
import CourseDetailsClient from '../components/Course/CourseDetailsClient';

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/lazy/lazy.scss";

class CourseClientContainer extends Component {
  observe = lozad().observe;

  componentDidMount() {
    const { pagination, lessons, entity, reduxCatalogKey } = this.props;

    if (lessons.length === 0 || pagination.firstItemsLoaded) return;

    const catalogOptions = {
      reduxCatalogKey,
      entity,
      customParams: {
        courseBestWorks: 1,
        lessonIds: lessons.map(l => l.id),
      },
    };

    this.props.prepareCatalog(catalogOptions);
  }

  handleToggleLike = (itemId) => {
    if (!this.props.currentUserId) {
      history.push('/login');
      return;
    }

    const { entity } = this.props;

    this.props.toggleLike({ itemId, entity });
  }

  getLessonIds = () => this.props.lessons.map(l => l.id);

  freeSubscribeClick = () => {
    const courseId = this.props.course.id;

    window.mixpanelTrack('Начать бесплатно', {
      courseId,
      payload: 'Request free access',
    });

    const gaCourseIds = [5, 16, 3];
    const isSentGA = gaCourseIds.includes(courseId);

    if (isSentGA) {
      window.captureOutboundLink('Trial course #{@course.name}');
    }

    // если юзер не авторизован
    if (!this.props.currentUserId) {
      // устанавливаем путь после авторизации
      this.props.setAfterSignInPath(`/course/${courseId}/restrict-subscribe`);
      // показываем форму авторизации
      history.push('/login');
      return;
    }

    freeSubscribeToCourse(courseId);

    return false;
  }

  render() {
    const { pagination, reduxCatalogKey, ...courseLayoutProps } = this.props;
    return (
      <CourseLayout
        {...courseLayoutProps}
        reduxCatalogKey={reduxCatalogKey}
        loading={pagination.loading}
        error={pagination.error}
        observe={this.observe}
        freeSubscribeClick={this.freeSubscribeClick}
        CoursePractice={CoursePracticeClient}
        CourseDetails={CourseDetailsClient}
        toggleLike={this.handleToggleLike}
      />
    );
  }
}

CourseClientContainer.propTypes = {
  entity: string.isRequired,
  reduxCatalogKey: string.isRequired,
  course: object.isRequired,
  currentUserId: number,
  setAfterSignInPath: func,
  prepareCatalog: func.isRequired,
  toggleLike: func.isRequired,
  pagination: object.isRequired,
  lessons: array.isRequired,
};

CourseClientContainer.defaultProps = {
  currentUserId: null,
  setAfterSignInPath: () => {},
};

const mapStateToProps = (state, ownProps) => {
  const entity = 'homeworks';
  const reduxCatalogKey = `course${ownProps.course.id}BestWorks`;
  const defaultState = { pagination: { sortFilters: {} }, items: [] };

  const { pagination, items } = get(state.entities.catalog, reduxCatalogKey, defaultState);

  const works = getStudentWorkCoversFields(state, items);
  const homeworks = groupBy(works, h => h.lessonId);

  return {
    entity,
    reduxCatalogKey,
    currentUserId: state.local.currentUser.id || null,
    pagination,
    homeworks,
  };
};

export default connect(mapStateToProps, {
  setAfterSignInPath,
  prepareCatalog,
  toggleLike: toggleLikeCatalogItem,
})(CourseClientContainer);
