import React, { useState } from "react";
import { number, string } from 'prop-types';

import { fetchStudentTest } from 'Core/services/api';
import { shuffle } from 'Core/services/tools';
import StudentTestModal from '../components/StudentTest';

const StudentTestContainer = ({ id, title, successMess, failureMess }) => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [testOpen, setTestOpen] = useState(false);

  const onOpenTest = async (notLoadQuestions) => {
    if (notLoadQuestions) return setTestOpen(true);

    setLoading(true);
    setTestOpen(true);
    const { response, error } = await fetchStudentTest(id);

    if (error) {
      setLoading(false);
      setTestOpen(false);
      alert('Ошибка загрузки');
      return;
    }

    setQuestions(response.questions);
    setLoading(false);
  };

  const closeStudentTestModal = () => setTestOpen(false);

  if (testOpen) {
    const shuffled = questions.map((question) => {
      const { options } = question;

      return {
        ...question,
        options: shuffle(options),
      };
    });

    return (
      <StudentTestModal
        loading={loading}
        questions={shuffled}
        successMess={successMess}
        failureMess={failureMess}
        closeStudentTestModal={closeStudentTestModal}
      />
    );
  }

  return (
    <div className="st-wrapper">
      <h1>{title}</h1>
      <button
        type="button"
        className="pixel-btn mt-4 d-flex justify-content-center"
        onClick={() => {
          const notLoadQuestions = questions.length !== 0;
          onOpenTest(notLoadQuestions);
        }}
      >
        Пройти тест
      </button>
    </div>
  );
};

StudentTestContainer.propTypes = {
  id: number.isRequired,
  title: string.isRequired,
  successMess: string.isRequired,
  failureMess: string.isRequired,
};

export default StudentTestContainer;
