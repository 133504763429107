import React, { Component } from "react";
import moment from "moment";
import { func, string, bool } from "prop-types";
import { connect } from "react-redux";
import {
  loadChats,
  navigate,
  onListContext,
  onChatContext,
  toggleChatAnswered,
  openPersonalRemarkModal,
  closePersonalRemarkModal,
  updatePersonalRemark,
} from "Core/actions";
import { selectors } from "Core/reducers";
import { Header } from "Main/components/Chat";

class ChatHeaderContainer extends Component {
  handleBack = () => {
    this.props.navigate("/messages");
  };

  handleChatContext = () => {
    const { page } = this.props.chatContext;
    return this.props.onChatContext({ page: "all" });
  };

  render() {
    const {
      title,
      personal_remark,
      subtitle,
      cover,
      chat,
      is_teacher,
      flow_id,
      chat_answered,
      chat_id,
      toggleChatAnswered,
      completing_progress,
      open_personal_remark_modal,
      onChatContext,
    } = this.props;

    if (completing_progress) {
      let all_previous_accepted = true;

      // Последним открытым уроком считается урок:
      // 1. если урок по дате уже стартовал
      // 2. Если урок еще не стартовал, но ученик выполнил все задания до этого со статусом "accepted"

      const last_opened_lesson_id = completing_progress.reduce((acc, ex) => {
        if (!moment().isBefore(ex.startLessonDate, "day")) {
          // урок уже стартовал
          if (ex.status !== "accepted") all_previous_accepted = false;
          return ex.lesson_id;
        }
        // урок еще не стартовал, а все задания до этого сделаны
        if (all_previous_accepted) {
          if (ex.status !== "accepted") all_previous_accepted = false;
          return ex.lesson_id;
        }
        return acc;
      }, -1);

      if (last_opened_lesson_id != -1) {
        completing_progress.forEach((ex) => {
          if (ex.lesson_id == last_opened_lesson_id && ex.status !== "accepted") ex.last = true;
        });
      }
    }

    return (
      <Header
        onBack={this.handleBack}
        onChatContext={this.handleChatContext}
        title={title}
        personal_remark={personal_remark}
        subtitle={subtitle}
        cover={cover}
        flow_id={flow_id}
        chat_answered={chat_answered}
        toggleChatAnswered={toggleChatAnswered}
        chat_id={chat_id}
        is_teacher={is_teacher}
        chat={chat}
        completing_progress={completing_progress}
        open_personal_remark_modal={open_personal_remark_modal}
        openPersonalRemarkModal={this.props.openPersonalRemarkModal}
        closePersonalRemarkModal={this.props.closePersonalRemarkModal}
        updatePersonalRemark={this.props.updatePersonalRemark}
      />
    );
  }
}

ChatHeaderContainer.propTypes = {
  title: string,
  personal_remark: string,
  cover: string,
  subtitle: string,
  navigate: func.isRequired,

  open_personal_remark_modal: bool,
  openPersonalRemarkModal: func.isRequired,
  closePersonalRemarkModal: func.isRequired,
  updatePersonalRemark: func.isRequired,
};

ChatHeaderContainer.defaultProps = {
  open_personal_remark_modal: false,
  personal_remark: "",
};

const mapStateToProps = (state, { chat_id }) => {
  const {
    entities: { chats, flows, courses, users },
    local: {
      is_teacher,
      open_personal_remark_modal,
      current_chat: { context },
    },
  } = state;

  const chat = chats[chat_id];

  let title;
  let personal_remark = "";
  let cover;
  let flow_id;
  let subtitle;
  let chat_answered;
  let completing_progress;

  if (chat) {
    flow_id = flows[chat.flow].id;
    const user = users[chat.user];
    const course = courses[chat.course];
    const flow = flows[chat.flow];

    title = is_teacher ? user.nickname : course.title;
    // eslint-disable-next-line prefer-destructuring
    personal_remark = is_teacher ? user.personal_remark : "";
    cover = is_teacher ? user.avatar : course.avatar;
    chat_answered = chat.answered;

    const consultation_last_date = moment(
      users[chat.user].consultation_last_date,
    ).format("D MMMM YYYY");

    if (is_teacher) {
      subtitle = `${course.title} | поток с ${moment(flow.date).format(
        "D MMMM YYYY",
      )}`;

      if (flow.free) {
        subtitle += ' | Курс не куплен, ограниченный доступ';
      } else if (course.use_consultations_feature) {
        subtitle += ` | Консультации до ${consultation_last_date}`;
      }

      completing_progress = selectors.getHomeworkStatusForChat(state, chat);
    } else if (flow.free) {
      subtitle = 'Курс не куплен, ограниченный доступ';
    } else if (course.use_consultations_feature) {
      subtitle = `Консультации до ${consultation_last_date}`;
    }
  }

  return {
    chat,
    chatContext: context,
    title,
    personal_remark,
    subtitle,
    cover,
    flow_id,
    chat_answered,
    chat_id,
    is_teacher,
    completing_progress,

    open_personal_remark_modal,
  };
};

export default connect(mapStateToProps, {
  loadChats,
  navigate,
  onListContext,
  onChatContext: params => dispatch => new Promise((resolve) => {
    dispatch(onChatContext({ ...params, resolve }));
  }),
  toggleChatAnswered,

  openPersonalRemarkModal,
  closePersonalRemarkModal,
  updatePersonalRemark,
})(ChatHeaderContainer);
