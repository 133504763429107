/* eslint-disable react/no-danger */
import React from 'react';
import { array, object, string } from 'prop-types';

import FontAwesomeBrandIcon from '../ui/FontAwesomeBrandIcon';

const CourseTeachers = ({ teacher, methodist, curators, curatorsDescr }) => (
  <section id="course-teachers">
    <div className="wide-block-wrapper">
      <div className="row no-gutters">
        {/* Teacher */}
        <div className="col">
          <div className="d-flex justify-content-start align-items-start pr-5">
            <img className="teacher-avatar lozad" data-src={teacher.avatar} alt="" />

            <div className="teacher-info ml-3">
              {/* Teacher title */}
              <span className="teacher-title">
                Курс ведет
                <br />
                {teacher.name}
              </span>
              {/* Teacher descr */}
              <p className="teacher-descr" dangerouslySetInnerHTML={{ __html: teacher.description }} />
              {/* Teacher socials */}
              {teacher.socials.length > 0 && (
                <div className="d-flex">
                  {teacher.socials.map(social => (
                    <a key={social.id} href={social.link} target="_blank" rel="noreferrer" className="ui link-social">
                      <FontAwesomeBrandIcon link={social.link} />
                    </a>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Methodist */}
        {methodist && (
          <div className="col">
            <div className="d-flex justify-content-end align-items-start text-right flex-row-reverse pl-5">
              <img className="teacher-avatar lozad" data-src={methodist.avatar} alt="" />

              <div className="teacher-info mr-3">
                <span className="teacher-title">
                  Методист курса
                  <br />
                  {methodist.name}
                </span>
                <p className="teacher-descr">
                  {methodist.description}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Curators */}
      {curators.length > 0 && (
        <>
          <hr />
          <div className="curators text-center">
            <span className="teacher-title">
              Кураторы проверки
            </span>

            <p>{curatorsDescr}</p>

            <div className="curators-wrapper d-flex flex-wrap justify-content-center">
              {curators.map(curator => (
                <div key={curator.id} className="curator">
                  <img data-src={curator.avatar} alt="" className="curator-avatar lozad" />
                  <div className="curator-name">
                    {curator.name.split(' ')[0]}
                    <br />
                    {curator.name.split(' ')[1]}
                  </div>

                  {!!curator.portfolio_link && (
                    <a className="ui button mt-2" href={curator.portfolio_link} target="_blank" rel="noreferrer">Портфолио</a>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  </section>
);

CourseTeachers.propTypes = {
  teacher: object.isRequired,
  methodist: object,
  curators: array.isRequired,
  curatorsDescr: string.isRequired,
};

CourseTeachers.defaultProps = {
  methodist: null,
};

export default CourseTeachers;
