import React, { useEffect, useRef, useState } from 'react';
import { array, func, object } from 'prop-types';
import { Route, Switch } from 'react-router';

import { WebinarsLayout, WebinarItems } from '../components/Webinars';

const WebinarsSharedRouter = ({
  currentUser, pageInfo, pagination: initPagination,
  items: elements, fetchWebinars, toggleLike, history,
}) => {
  const [pagination, setPagination] = useState(initPagination);
  const paginationRef = useRef(initPagination);
  const catalogRef = useRef(null);
  const [items, setItems] = useState(elements);
  const [moreLoading, setMoreLoading] = useState(false);
  const [sortLoading, setSortLoading] = useState(false);

  useEffect(() => {
    paginationRef.current = pagination;
  }, [pagination]);

  const onSwitchSort = async (nextSort) => {
    setSortLoading(true);
    const pag = paginationRef.current;

    const { response, err } = await fetchWebinars({
      page: 1,
      sort: nextSort,
      rand_number: pag.rand_number,
      tag: pag.active_filter,
    });

    if (err) {
      setSortLoading(false);
      return alert('Произошла ошибка');
    }

    setItems(response.items);
    setPagination({
      ...pag,
      page: 1,
      sort: nextSort,
      nomore: response.nomore,
    });
    setSortLoading(false);
  };

  const onSwitchCategory = async (nextCategory) => {
    setSortLoading(true);
    const pag = paginationRef.current;

    const { response, err } = await fetchWebinars({
      page: 1,
      sort: pag.sort,
      rand_number: pag.rand_number,
      tag: nextCategory,
    });

    if (err) {
      setSortLoading(false);
      return alert('Произошла ошибка');
    }

    setItems(response.items);
    setPagination({
      ...pag,
      page: 1,
      active_filter: nextCategory,
      nomore: response.nomore,
    });
    setSortLoading(false);
  };

  const onLoadMore = async (elems) => {
    setMoreLoading(true);
    const pag = paginationRef.current;

    const { response, err } = await fetchWebinars({
      page: pag.page + 1,
      sort: pag.sort,
      rand_number: pag.rand_number,
      tag: pag.active_filter,
    });

    if (err) {
      setMoreLoading(false);
      return alert('Произошла ошибка');
    }

    setItems([...elems, ...response.items]);
    setPagination({
      ...pag,
      page: pag.page + 1,
      nomore: response.nomore,
    });
    setMoreLoading(false);
  };

  const handleToggleLike = (itemId) => {
    if (!currentUser) {
      history.push('/login');
      return;
    }

    (async () => {
      const { response, err } = await toggleLike({ itemId, entity: 'videos' });

      if (err) {
        return alert('Произошла ошибка');
      }

      setItems(prevItems => prevItems.map((prevItem) => {
        if (prevItem.id !== itemId) return prevItem;

        return {
          ...prevItem,
          likes_number: response.likes_number,
          already_liked: response.already_liked,
        };
      }));
    })();
  };

  return (
    <WebinarsLayout
      pageInfo={pageInfo}
      activeFilter={pagination.active_filter}
      activeSort={pagination.sort}
      loading={sortLoading}
      onSwitchCategory={onSwitchCategory}
      onSwitchSort={onSwitchSort}
      getRef={catalogRef}
    >
      <Switch>
        <Route
          render={() => (
            <WebinarItems
              onToggleLike={handleToggleLike}
              items={items}
              nomore={pagination.nomore}
              loadMore={onLoadMore}
              loading={moreLoading}
              currentUserExists={!!currentUser}
            />
          )}
        />
        {/* {Object.entries(pageInfo.filters).map(([key, filter]) => (
          <Route
            key={key}
            path={filter.url}
            exact
            render={() => (
              <WebinarItems
                onToggleLike={onToggleLike}
                items={items}
                nomore={pagination.nomore}
                loadMore={onLoadMore}
                loading={moreLoading}
              />
            )}
          />
        ))} */}
      </Switch>
    </WebinarsLayout>
  );
};

WebinarsSharedRouter.propTypes = {
  fetchWebinars: func,
  currentUser: object,
  pageInfo: object.isRequired,
  pagination: object.isRequired,
  items: array,
  toggleLike: func,
  history: object,
};

WebinarsSharedRouter.defaultProps = {
  fetchWebinars: () => {},
  toggleLike: () => {},
  currentUser: null,
  items: [],
  history: {},
};

export default WebinarsSharedRouter;
