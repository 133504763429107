import _InputArea from "./InputArea";
import _Layout from "./Layout";
import _Message from "./Message";
import _List from "./List";
import _Header from "./Header";
import _ChatWorkTimeIsUp from "./ChatWorkTimeIsUp";
import _WelcomeMessage from "./WelcomeMessage";
import _PaymentModal from "./PaymentModal";
import _PaymentSuccessModal from "./PaymentSuccessModal";
import _ConsultationEnded from "./ConsultationEnded";

export const InputArea = _InputArea;
export const Layout = _Layout;
export const Message = _Message;
export const List = _List;
export const Header = _Header;
export const ChatWorkTimeIsUp = _ChatWorkTimeIsUp;
export const WelcomeMessage = _WelcomeMessage;
export const PaymentModal = _PaymentModal;
export const PaymentSuccessModal = _PaymentSuccessModal;
export const ConsultationEnded = _ConsultationEnded;
