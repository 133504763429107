import { func } from 'prop-types';
import React from 'react';

const FreeSubscribeButton = ({ onClick }) => (
  <div
    className="ui button variant-green"
    onClick={onClick}
  >
    Начать учиться бесплатно
  </div>
);

FreeSubscribeButton.propTypes = {
  onClick: func.isRequired,
};

export default FreeSubscribeButton;
