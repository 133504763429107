import React, { useRef, useEffect } from 'react';
import { bool, func } from 'prop-types';

import SubscribeButton from './FreeSubscribeButton';

const CourseNavigation = ({
  hasSubscribeAbility, freeSubscribeClick,
}) => {
  const courseNav = useRef(null);

  useEffect(() => {
    // плавный переход к блокам
    document.querySelectorAll('#course-navig > ul > li > a').forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();

        const hrefTag = link.getAttribute('href');
        const scrollElId = hrefTag.replace(/#/, '');
        const scrollEl = document.getElementById(scrollElId);

        if (!scrollEl) return;

        scrollEl.scrollIntoView({ behavior: "smooth" });
        // window.location.hash = hrefTag;
      });
    });

    // добвление stiky при скроле
    const mainContent = document.getElementById("main_wrapper");
    window.addEventListener("scroll", () => {
      const navElCls = courseNav.current.classList;
      const navHeight = courseNav.current.offsetHeight;
      const scrollTop = window.pageYOffset;

      if (scrollTop > 0) {
        navElCls.add('sticky');
        mainContent.style.marginTop = `${navHeight}px`;
      } else {
        navElCls.remove('sticky');
        mainContent.style.marginTop = '0px';
      }
    });
  }, []);

  return (
    <nav ref={courseNav} id="course-navig" className="d-flex justify-content-center">
      <ul className="d-flex justify-content-center align-items-center align-self-center">
        <li>
          <a href="#course-about" data-padding="50">О курсе</a>
        </li>

        <li>
          <a href="#course-details" data-padding="40">Программа</a>
        </li>

        <li>
          <a href="#course-practice">Обратная связь</a>
        </li>

        <li>
          <a href="#course-teachers">Преподаватель</a>
        </li>

        <li>
          {
            hasSubscribeAbility
              ? <SubscribeButton onClick={freeSubscribeClick} />
              : <a className="ui button variant-green" href="#course-price">Записаться</a>
          }
        </li>
      </ul>
    </nav>
  );
};

CourseNavigation.propTypes = {
  hasSubscribeAbility: bool.isRequired,
  freeSubscribeClick: func.isRequired,
};

export default CourseNavigation;
