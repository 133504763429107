import React from "react";
import { array, func, number, oneOfType, string, arrayOf } from "prop-types";

import MultiSelect, { MultiSelectItem } from "Main/components/ui/MultiSelect";

// filters --> [['all', 'Все'], ...]
const CatalogMultiselectFilters = ({ filters, onSelect, activeFilters, onCollapse }) => {
  const onSwitchHandle = (index) => {
    const [nextFilter] = filters[index];
    const shouldDeleteFelter = activeFilters.includes(nextFilter);

    if (nextFilter === 'all') {
      return onSelect(['all']);
    }

    if (activeFilters.length === 1 && shouldDeleteFelter) {
      return onSelect(['all']);
    }

    const nextFilters = activeFilters.filter(f => f !== 'all');

    if (shouldDeleteFelter) {
      return onSelect(nextFilters.filter(f => f !== nextFilter));
    }

    onSelect([...nextFilters, nextFilter]);
  };

  const label = activeFilters[0] === 'all' ? 'все' : activeFilters.length;

  return (
    <MultiSelect
      label={label}
      onSelect={({ index }) => onSwitchHandle(index)}
      onCollapse={onCollapse}
    >
      {filters.map(([filterKey, filterValue]) => (
        <MultiSelectItem key={filterKey} selected={activeFilters.includes(filterKey)}>
          {filterValue}
        </MultiSelectItem>
      ))}
    </MultiSelect>
  );
};

CatalogMultiselectFilters.propTypes = {
  activeFilters: arrayOf(oneOfType([string, number])).isRequired,
  onCollapse: func.isRequired,
  onSelect: func.isRequired,
  filters: array.isRequired,
};

export default CatalogMultiselectFilters;
