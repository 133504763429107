import React, { useEffect, useState } from "react";
import { func, string, number } from "prop-types";
import Modal from "Main/components/layouts/modals/Modal";

export default function PersonalRemarkModal(props) {
  const [areaValue, setAreaValue] = useState("");
  const { user_id, personal_remark, closeModal, updatePersonalRemark } = props;
  const isSaveButtonActive = areaValue === "" || areaValue === personal_remark;

  const setFocusOnAfterOpenModal = ({ contentEl }) => {
    const area = contentEl.querySelector('.chat_header_remark_modal_area');
    area.focus();
    area.setSelectionRange(area.value.length, area.value.length);
  };

  useEffect(() => {
    setAreaValue(personal_remark);
  }, [personal_remark]);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setAreaValue(inputValue);
  };

  const handleUpdateRemark = () => {
    updatePersonalRemark({ personal_remark: areaValue, user_id });
    closeModal();
  };

  const handleDeleteRemark = () => {
    updatePersonalRemark({ personal_remark: "", user_id });
    closeModal();
  };

  return (
    <Modal onAfterOpen={setFocusOnAfterOpenModal} closeModal={closeModal} showCross={false}>
      <div className="chat_header_remark_modal">
        <i onClick={closeModal} className="fa fa-times" aria-hidden="true" />
        <p className="chat_header_remark_modal_title">
          Добавить заметку об ученике
        </p>
        <textarea
          className="chat_header_remark_modal_area"
          onChange={handleChange}
          value={areaValue}
          placeholder="Текст заметки"
          name="name"
          cols="77"
          rows="8"
        />
        <div className="chat_header_remark_modal_actions">
          <button
            type="button"
            onClick={handleUpdateRemark}
            className="chat_header_remark_modal_btn btn btn-primary"
            disabled={isSaveButtonActive}
          >
            Сохранить
          </button>

          <button
            onClick={handleDeleteRemark}
            type="button"
            disabled={personal_remark === ""}
            className="chat_header_delete_remark_modal_btn btn btn-danger"
          >
            Удалить
          </button>
        </div>
      </div>
    </Modal>
  );
}

PersonalRemarkModal.propTypes = {
  closeModal: func.isRequired,
  updatePersonalRemark: func.isRequired,
  user_id: number,
  personal_remark: string,
};

PersonalRemarkModal.defaultProps = {
  personal_remark: "",
  user_id: null,
};
