import React from "react";
import { object, bool, func } from "prop-types";
import Icon from "../Homework/Icon";

export const QuickHomeworkAssessment = (props) => {
  const {
    homework: { status },
    is_teacher,
    onUpdateReview,
  } = props;

  if (!is_teacher || status !== "unverified") return null;

  const ACTIONS = [
    {
      status: "rejected",
      text: "Нужно доработать",
      handler: () => onUpdateReview("rejected"),
    },
    {
      status: "waiting_next_stage",
      text: "Ждём на следующий этап",
      handler: () => onUpdateReview("waiting_next_stage"),
    },
    {
      status: "accepted",
      text: "Принято",
      handler: () => onUpdateReview("accepted"),
    },
  ];

  return (
    <div className="quick-assessment">
      <div className="quick-assessment__triangle" />
      <div className="quick-assessment__actions-area">
        <div className="actions-area__text">Поставьте оценку:</div>
        <div className="actions-area__buttons">
          {ACTIONS.map(action => (
            <button
              type="button"
              key={action.status}
              className={`homework_status_circle -${action.status}`}
              onClick={action.handler}
            >
              <Icon status={action.status} />
              <span>{action.text}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

QuickHomeworkAssessment.propTypes = {
  homework: object.isRequired,
  is_teacher: bool.isRequired,

  onUpdateReview: func.isRequired,
};
