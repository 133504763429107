import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { func, string, number, bool } from "prop-types";
import cn from "classnames";

import {
  loadStudentWork,
  prepareStudentWork,
  showOnCourse,
  toggleLikeCatalogItem,
} from "Core/actions";
import { history } from "Core/services";
import { getStudentWorkFields } from "Core/reducers/selectors";

import SharedComments from "./SharedComments";

import Modal from "../components/layouts/modals/Modal";
import StudentWorkViewHeader from "../components/StudentWork/StudentWorkViewHeader";
import StudentWorkViewBody from "../components/StudentWork/StudentWorkViewBody";
import StudentWorkViewActions from "../components/StudentWork/StudentWorkViewActions";
import Spinner from "../components/layouts/Spinner";

function StudentWorkView({
  loaded,
  id,
  closeModal,
  reduxCatalogKey,
  currentUserId,
  isTeacher,
  // map dispatch functions
  loadWork,
  prepareWork,
  toggleFameHall,
  toggleLike,
  /* eslint-disable react/prop-types */
  // map state work fields
  commentsNumber,
  likesNumber,
  alreadyLiked,
  status,
  type,
  text,
  isShowOnCourse,
  imageFull,
  user,
  course,
  flow,
  lesson,
  exercise,
  /* eslint-enable react/prop-types */
}) {
  const [modalAnimateShown, setModalAnimateShow] = useState(false);
  const [isBodyContentLoaded, setBodyContentLoadedState] = useState(false);

  useEffect(() => {
    loadWork({ id, reduxCatalogKey });

    return () => prepareWork({ id, reduxCatalogKey });
  }, []);

  useEffect(() => {
    if (loaded && isBodyContentLoaded) {
      setModalAnimateShow(true);
    }
  }, [loaded, isBodyContentLoaded]);

  const handleToggleLike = () => {
    if (!currentUserId) {
      history.push('/login');
      return;
    }

    toggleLike({ itemId: id, entity: "homeworks" });
  };

  const handleShowOnCourse = () => toggleFameHall(id);

  const renderContent = () => {
    if (!loaded) {
      return <Spinner />;
    }

    return (
      <div
        className={cn(
          "modal-content",
          "modal-hw-content",
          "new-modal-hw-content",
          {
            "animate-shown": modalAnimateShown,
          },
        )}
        style={{ paddingBottom: status === "accepted" ? 0 : "26px" }}
      >
        <StudentWorkViewHeader
          isTeacher={isTeacher}
          type={type}
          imageFull={imageFull}
          user={user}
          course={course}
          flow={flow}
          lesson={lesson}
          exercise={exercise}
        />
        <StudentWorkViewBody
          id={id}
          text={text}
          type={type}
          imageFull={imageFull}
          onContentLoaded={() => setBodyContentLoadedState(true)}
        />

        {status === 'accepted' && (
          <footer>
            <hr style={{ marginTop: "10px" }} />
            <StudentWorkViewActions
              isTeacher={isTeacher}
              likesNumber={likesNumber}
              alreadyLiked={alreadyLiked}
              onToggleLike={handleToggleLike}
              onShowOnCourse={handleShowOnCourse}
              type={type}
              text={text}
              isShowOnCourse={isShowOnCourse}
            />
            <SharedComments
              entity="homeworks"
              commentableId={id}
              commentsNumber={commentsNumber}
              insideModal
            />
          </footer>
        )}
      </div>
    );
  };

  return (
    <Modal closeModal={closeModal}>
      {renderContent()}
    </Modal>
  );
}

StudentWorkView.propTypes = {
  // OWN PROPS
  id: number.isRequired,
  closeModal: func.isRequired,
  reduxCatalogKey: string.isRequired,
  // MAP STATE TO PROPS
  loaded: bool.isRequired,
  currentUserId: number,
  isTeacher: bool,
  // MAP DISPATCH TO PROPS
  loadWork: func.isRequired,
  prepareWork: func.isRequired,
  toggleFameHall: func.isRequired,
  toggleLike: func.isRequired,
};

StudentWorkView.defaultProps = {
  isTeacher: false,
  currentUserId: null,
};

function mapStateToProps(state, { reduxCatalogKey: catKey }) {
  const catEntity = state.entities.catalog[catKey];
  const homeworkId = catEntity ? catEntity.activeModal : null;

  if (!homeworkId) return { loaded: false };

  return {
    loaded: true,
    currentUserId: state.local.currentUser.id,
    isTeacher: !!state.local.currentUser.is_teacher,
    ...getStudentWorkFields(state, homeworkId),
  };
}

export default connect(mapStateToProps, {
  loadWork: loadStudentWork,
  prepareWork: prepareStudentWork,
  toggleFameHall: showOnCourse,
  toggleLike: toggleLikeCatalogItem,
})(StudentWorkView);
