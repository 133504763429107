import { buffers, eventChannel, END } from "redux-saga";

export function createUploadFilesChannel(endpoint, files) {
  return eventChannel((emitter) => {
    const xhr = new XMLHttpRequest();

    const onProgress = (e) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };

    const onFailure = (message) => {
      emitter({ err: new Error(message || "Upload failed") });
      emitter(END);
    };

    xhr.upload.addEventListener("progress", onProgress);
    xhr.upload.addEventListener("error", onFailure);
    xhr.upload.addEventListener("abort", onFailure);

    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;

      if (readyState === 4) {
        if (status === 200) {
          const response = JSON.parse(xhr.response);

          if ("error" in response) {
            onFailure(response.error);
          } else {
            emitter({ success: true });
            emitter(END);
          }
        } else {
          onFailure(null);
        }
      }
    };

    xhr.open("POST", endpoint, true);
    xhr.setRequestHeader("X-CSRF-Token", AUTH_TOKEN);
    xhr.setRequestHeader("Accept", "application/json");

    const formData = new FormData();
    [...files].map(file => formData.append("file", file));
    xhr.send(formData);

    return () => {
      xhr.upload.removeEventListener("progress", onProgress);
      xhr.upload.removeEventListener("error", onFailure);
      xhr.upload.removeEventListener("abort", onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}
