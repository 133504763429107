import React from 'react';
import { func, bool, string, oneOf } from 'prop-types';

export default function Checkbox(props) {
  const { onChange, checkedType, checked, disabled } = props;

  return (
    <div className={`pixel-checkbox ${checked ? '--selected' : ''} mb-3`} onClick={disabled ? null : onChange}>
      { !checked && <i className="fa fa-square-o" /> }
      { checked && checkedType === 'correct' && <i className="fa fa-check-square" /> }
      { checked && checkedType === 'wrong' && <i className="fa fa-times-square" /> }
      { checked && checkedType === 'checked' && <i className="fa fa-square" /> }
      <div>
        <span>{props.label}</span>
        {
          checked && props.hint && <div className={`--${checkedType}`}>{props.hint}</div>
        }
      </div>
    </div>
  );
}

Checkbox.propTypes = {
  onChange: func.isRequired,
  label: string,
  checkedType: oneOf(['correct', 'wrong', 'checked']),
  checked: bool,
  disabled: bool,
  hint: string,
};

Checkbox.defaultProps = {
  checkedType: 'checked',
  checked: false,
  disabled: false,
  label: '',
  hint: '',
};
