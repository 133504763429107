import React, { Component } from "react";
import { func, string, object, bool } from "prop-types";
import isNull from 'lodash/isNull';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Modal from "Main/components/layouts/modals/Modal";
import { authorize, timeleftConfirmValue } from "Core/actions";

class SmsConfirm extends Component {
  constructor(props) {
    super(props);

    const { login, getTimeleftConfirmValue } = this.props;
    const stateLogin = login || localStorage.getItem("login") || "";

    this.state = {
      login: stateLogin,
      smscode: "",
      timeUpCounter: null,
      timeUpInt: null,
    };

    // eslint-disable-next-line no-underscore-dangle
    getTimeleftConfirmValue(stateLogin).then(value => this._startTimeUpCounter(value));
  }

  handleRenewConfirm = (e) => {
    const { login } = this.state;
    if (isNull(this.state.timeUpCounter)) return;

    this.props.reset_error();

    this.props
      .auth({ login })
      // eslint-disable-next-line no-underscore-dangle
      .then(() => this._startTimeUpCounter())
      .catch(() => {});

    e.preventDefault();
    return false;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.props.reset_error();
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { login, smscode = "" } = this.state;
    this.props.reset_error();

    this.props
      .auth({ login, smscode })
      .then((response) => {
        if ("userId" in response) {
          clearInterval(this.state.timeUpInt);
          const afterSignInPath = response.afterSignInPath || '/courses/my';
          this.props.history.push(afterSignInPath);
          setTimeout(() => { document.location.href = afterSignInPath; }, 250);
        }
      })
      .catch(() => {});
  };

  // eslint-disable-next-line no-underscore-dangle
  _startTimeUpCounter(initial = 60) {
    this.setState({ timeUpCounter: initial });
    clearInterval(this.state.timeUpInt);

    const timeUpInt = setInterval(() => {
      this.setState(state => ({ timeUpCounter: state.timeUpCounter - 1 }));

      if (!this.state.timeUpCounter) {
        clearInterval(timeUpInt);
      }
    }, 1000);

    this.setState({ timeUpInt });
  }

  render() {
    const { login, smscode } = this.state;

    return (
      <Modal closeModal={() => {}} showCross={false}>
        <div className="auth-popup-form confirm-input-step">
          <h3>Введите код из СМС</h3>
          <header className="d-flex flex-column text-center">
            <span>
              Мы отправили код на
              {' '}
              {login}
            </span>
          </header>
          <form
            onSubmit={this.handleSubmit}
            className="d-flex flex-column"
            autoComplete="off"
          >
            <div className="component-wrapper">
              <input
                type="text"
                name="smscode"
                placeholder="Код"
                defaultValue={smscode}
                onChange={this.handleChange}
                onFocus={this.handleChange}
                autoComplete="off"
              />
            </div>
            <div className="component-wrapper">
              <button
                className="ui button text-center"
                placeholder="submit"
                type="submit"
              >
                Войти
              </button>
            </div>
            <div className="component-wrapper text-center">
              {this.state.timeUpCounter > 0 ? (
                <span className="renew-confirm-note">
                  Получить код повторно можно через
                  {' '}
                  {this.state.timeUpCounter}
                  {" "}
                  секунд
                </span>
              ) : (
                <span className="navigate-btn" onClick={this.handleRenewConfirm}>
                  Получить новый код
                </span>
              )}
            </div>
          </form>
          {this.props.error && <p className="error">{this.props.error}</p>}
          {this.props.loading && (
            <div className="load-indicator">
              <div className="loadersmall modal-hw-loader" />
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

SmsConfirm.propTypes = {
  login: string,
  getTimeleftConfirmValue: func.isRequired,
  reset_error: func.isRequired,
  auth: func.isRequired,
  history: object.isRequired,
  error: string,
  loading: bool,
};

SmsConfirm.defaultProps = {
  login: "",
  error: false,
  loading: false,
};

const mapStateToProps = state => ({
  loading: state.local.userAuthorizeReducer.loading,
  error: state.local.userAuthorizeReducer.error,
});

const SmsConfirmConnected = connect(mapStateToProps, {
  auth: params => dispatch => new Promise((resolve, reject) => {
    dispatch(authorize.request({ ...params, resolve, reject }));
  }),
  getTimeleftConfirmValue: login => dispatch => new Promise((resolve) => {
    dispatch(timeleftConfirmValue(login, { resolve }));
  }),
  reset_error: () => authorize.reset_error(),
})(SmsConfirm);

export default withRouter(SmsConfirmConnected);
