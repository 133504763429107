import React from "react";
import { bool, string, number } from "prop-types";

const ExerciseInfo = ({
  hasLink,
  flowId,
  flowTitle,
  exerciseTitle,
  lessonId,
  lessonTitle,
}) => {
  const title = `${flowTitle} / ДЗ: ${exerciseTitle}`;

  if (!hasLink) {
    return (
      <div className="modal-hw-lesson" title={title}>
        <span>{title}</span>
      </div>
    );
  }

  return (
    <div className="modal-hw-lesson" title={title}>
      <a href={`/flow/${flowId}`}>
        {flowTitle}
      </a>
      <span> / </span>
      <a href={`/flow/${flowId}/lesson/${lessonId}`}>{lessonTitle}</a>
      <span>
        {' '}
        / ДЗ:
        {exerciseTitle}
      </span>
    </div>
  );
};

ExerciseInfo.propTypes = {
  hasLink: bool,
  flowId: number,
  flowTitle: string.isRequired,
  lessonId: number,
  lessonTitle: string,
  exerciseTitle: string.isRequired,
};

ExerciseInfo.defaultProps = {
  hasLink: false,
  flowId: null,
  lessonId: null,
  lessonTitle: null,
};

export default ExerciseInfo;
