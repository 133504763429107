import React from 'react';
import { string } from 'prop-types';

const InputAreaBlocked = ({ nameOfTyping }) => (
  <div id="chat_input_area_blocked">
    <div className="chat_input_area_blocked_icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM10.1035 13.5249H11.8384L12.0376 5H9.9126L10.1035 13.5249ZM10.1616 15.3511C9.95687 15.5558 9.85449 15.8159 9.85449 16.1313C9.85449 16.4357 9.9541 16.6903 10.1533 16.895C10.3525 17.0998 10.6348 17.2021 11 17.2021C11.3652 17.2021 11.6475 17.0998 11.8467 16.895C12.0459 16.6903 12.1455 16.4357 12.1455 16.1313C12.1455 15.8159 12.0431 15.5558 11.8384 15.3511C11.6336 15.1408 11.3542 15.0356 11 15.0356C10.6458 15.0356 10.3664 15.1408 10.1616 15.3511Z" fill="#F57A09" />
      </svg>
    </div>
    <span className="chat_input_area_blocked_name">Отвечает { nameOfTyping }</span>
  </div>
);

InputAreaBlocked.propTypes = {
  nameOfTyping: string.isRequired,
};

export default InputAreaBlocked;
