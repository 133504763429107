import React from "react";
import { any, bool, func, string } from "prop-types";
import styled, { css } from "styled-components";

function UploadImage({
  label,
  children,
  disabled,
  onChange,
  ...props
}) {
  const onChangeWrapper = (e) => {
    const allowedTypes = ["jpeg", "jpg", "png"];

    if (e.target.files.length) {
      const file = e.target.files[0];
      const isMatch = allowedTypes.some((type) => {
        const typeRx = new RegExp(
          type.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
        );

        return typeRx.test(file.type);
      });

      if (file.size > 1024 * 1024 * 5 || file.size < 10240) {
        // eslint-disable-next-line no-alert
        alert(`Выберите файл объемом от 10Кбайт до 5Мбайт`);
        return false;
      }

      if (!isMatch) {
        alert("Недопустимый тип файла");
        return false;
      }

      const checkingReader = new FileReader();
      const allowedHeaders = [
        "89504e47",
        "ffd8ffe0",
        "ffd8ffe1",
        "ffd8ffe2",
        "ffd8ffe3",
        "ffd8ffe8",
      ];

      checkingReader.readAsArrayBuffer(file.slice(0, 4));

      checkingReader.onloadend = (onloadendEvent) => {
        const arr = new Uint8Array(onloadendEvent.target.result).subarray(0, 4);
        let header = "";

        for (let i = 0; i < arr.length; i += 1) {
          header += arr[i].toString(16);
        }

        if (allowedHeaders.includes(header)) {
          onChange(file);
        } else {
          alert("Недопустимый тип файла");
        }
      };
    }
  };

  const [rand, date] = [Math.random(), Date.now()];
  const inputId = (rand.toString(36) + date.toString(36)).substr(2, 10);

  return (
    <UploadImageComponent className="upload-file-component" disabled={disabled}>
      <InputComponent
        {...props}
        disabled={disabled}
        id={`input-component-${inputId}`}
        onChange={e => onChangeWrapper(e)}
      />
      <label htmlFor={`input-component-${inputId}`}>
        {children}
        {!disabled && label && (
          <div className="label">
            <span>{label}</span>
          </div>
        )}
      </label>
    </UploadImageComponent>
  );
}

UploadImage.propTypes = {
  children: any,
  label: string,
  disabled: bool,
  onChange: func,
};

UploadImage.defaultProps = {
  label: 'Загрузить изображение',
  disabled: false,
  onChange: () => {},
  children: null,
};

export default UploadImage;

const UploadImageComponent = styled.div`
  position: relative;
  color: #2f80ed;
  font: 15px/1 Roboto;
  text-align: center;

  label {
    outline: none 0;
    margin: 0;
    cursor: pointer;
  }

  .label {
    margin-top: 12px;

    span {
      border-bottom: transparent 1px dashed;
      transition: 0.2s all;

      &:hover {
        border-color: #2f80ed;
      }
    }
  }

  ${props => props.disabled
    && css`
      label {
        cursor: default;
      }
    `}
`;

const InputComponent = styled.input.attrs(props => ({
  type: "file",
  disabled: props.disabled,
  name: props.name,
}))`
  display: none;
`;
