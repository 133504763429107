import React, { Component } from "react";
import { Provider } from "react-redux";

import initializeStore from "Core/store/initializeStore";
import SharedComments from "./SharedComments";

const getStore = initializeStore();

class SharedCommentsRoot extends Component {
  render() {
    return (
      <Provider store={getStore()}>
        <SharedComments {...this.props} />
      </Provider>
    );
  }
}

export default SharedCommentsRoot;
