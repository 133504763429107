import merge from "lodash/merge";

import messageHandlers from './message-handlers';
import chatHandlers from './chat-handlers';
import homeworkHandlers from './homework-handlers';
import commentHandlers from './comment-handlers';
import catalogHandlers from './catalog-handlers';

const defaultState = {
  users: {},
  chats: {},
  homeworks: {},
  messages: {},
  versions: {},
  flows: {},
  courses: {},
  exercises: {},
  lessons: {},
  comments: {},
  commentsPagination: {},
  articles: {},
  contestants: {},
  catalog: {},
};

const handlers = {
  ...messageHandlers,
  ...chatHandlers,
  ...homeworkHandlers,
  ...commentHandlers,
  ...catalogHandlers,
  default: state => state,
};

const entitiesReducer = (state = defaultState, action) => {
  // если имеются нормализованные данные в action.response.entities
  // то мержим их с текущим стэйтом
  if (action.response && action.response.entities) {
    return merge({}, state, action.response.entities);
  }

  // иначе вычисчитываем стэйт
  const calcState = handlers[action.type] || handlers.default;

  return calcState(state, action);
};

export default entitiesReducer;
