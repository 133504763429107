import React from "react";
import { bool } from "prop-types";

import NotificationCenter from "Main/containers/NotificationCenterRoot";

function NotificationCenterModule({ hots }) {
  return <NotificationCenter hots={hots} />;
}

NotificationCenterModule.propTypes = {
  hots: bool,
};

NotificationCenterModule.defaultProps = {
  hots: true,
};

export default NotificationCenterModule;
