import React, { Component } from 'react';
import { connect } from "react-redux";
import { func, object, array, string } from 'prop-types';
import get from 'lodash/get';

import { getStudentWorkCoversFields } from "Core/reducers/selectors";

import { StudentWork } from "../components/StudentWork";
import BestWorksList from '../components/IndexPage/BestWorksList';
import Spinner from '../components/layouts/Spinner';

import { loadCatalogItems, toggleLikeCatalogItem, prepareCatalog } from '../../core/actions';

const reduxCatalogKey = 'indexBestWorks';
const entity = 'homeworks';

class IndexBestWorksCatalog extends Component {
  componentDidMount() {
    const { pagination, customParams, tag } = this.props;

    if (pagination.firstItemsLoaded) return;

    const defaultPagination = { sortType: 2, category: tag };
    this.props.prepareCatalog({
      entity, reduxCatalogKey, pagination: defaultPagination, customParams,
    });
  }

  handleLoadItems = (options) => {
    const { pagination, tag, customParams } = this.props;

    this.props.loadItems({
      sortType: 2,
      category: tag,
      page: pagination.page,
      randNumber: pagination.randNumber,
      entity,
      reduxCatalogKey,
      ...options,
      customParams,
    });
  }

  onLoadMoreItems = () => {
    const { page } = this.props.pagination;
    this.handleLoadItems({ page: page + 1 });
  }

  onChangeSortType = sortType => this.handleLoadItems({ sortType, page: 1 });

  handleToggleLike = (itemId) => {
    this.props.toggleLike({ itemId, entity });
  }

  renderContent = () => {
    const { items, pagination } = this.props;

    const compItems = items.map((item, index) => (
      <div key={item.id} className="catalog-item cell cell_33">
        <StudentWork
          onToggleLike={this.handleToggleLike}
          reduxCatalogKey={reduxCatalogKey}
          indexOrder={index}
          {...item}
        />
      </div>
    ));

    return (
      <div className="catalog__items ctg-content my-3">
        <BestWorksList
          items={compItems}
          loading={pagination.loading}
          hasMore={pagination.hasMore}
          loadMore={this.onLoadMoreItems}
        />
      </div>
    );
  }

  render() {
    const { pagination: { firstItemsLoaded } } = this.props;

    return (
      <div className="catalog">
        {firstItemsLoaded ? this.renderContent() : <Spinner className="my-5 py-5" />}
      </div>
    );
  }
}

IndexBestWorksCatalog.propTypes = {
  // OWN props
  tag: string,
  // MAP STATE PROPS
  customParams: object.isRequired,
  pagination: object.isRequired,
  items: array,
  toggleLike: func.isRequired,
  loadItems: func.isRequired,
  prepareCatalog: func.isRequired,
};

IndexBestWorksCatalog.defaultProps = {
  tag: 'all',
  items: [],
};

const mapStateToProps = (state) => {
  const defaultState = { pagination: {}, items: [] };
  const { pagination, items } = get(state.entities.catalog, reduxCatalogKey, defaultState);

  return {
    pagination,
    items: getStudentWorkCoversFields(state, items),
    customParams: {
      indexBestWorks: 1,
    },
  };
};

export default connect(mapStateToProps, {
  loadItems: loadCatalogItems,
  toggleLike: toggleLikeCatalogItem,
  prepareCatalog,
})(IndexBestWorksCatalog);
