import React from 'react';
import { string } from 'prop-types';

export default function StickySpinner({ className }) {
  return (
    <div className={`sticky-loader-container ${className}`}>
      <div className="loadersmall" />
    </div>
  );
}

StickySpinner.propTypes = {
  className: string,
};

StickySpinner.defaultProps = {
  className: '',
};
