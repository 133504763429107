import React, { Component } from "react";
import initializeStore from "Core/store/initializeStore";
import { Provider } from "react-redux";
import { history } from "Core/services";
import { Router } from "react-router-dom";
import ncenterSaga from "Core/sagas/ncenter";
import NotificationCenterHots from "Main/containers/NotificationCenterHots";
import NotificationCenterPage from "Main/containers/NotificationCenterPage";
import { bool } from "prop-types";

const sagas = [ncenterSaga];
const getStore = initializeStore(sagas);

class NotificationCenterRoot extends Component {
  render() {
    const { hots } = this.props;

    return (
      <Provider store={getStore()}>
        <Router history={history}>
          {hots ? <NotificationCenterHots /> : <NotificationCenterPage />}
        </Router>
      </Provider>
    );
  }
}

NotificationCenterRoot.propTypes = {
  hots: bool,
};

NotificationCenterRoot.defaultProps = {
  hots: false,
};

export default NotificationCenterRoot;
