import { AUTHORIZE, REQUEST, SUCCESS, FAILURE, AUTHORIZE_RESET_ERROR, AUTHORIZE_SET_AFTER_SIGN_IN_PATH } from "../../actions";

const defaultState = {
  loading: false,
  error: null,
  afterSignInPath: '',
};

const handlers = {
  [AUTHORIZE[REQUEST]]: state => ({
    ...state,
    loading: true,
    error: null,
  }),

  [AUTHORIZE[SUCCESS]]: state => ({
    ...state,
    loading: false,
    error: null,
    afterSignInPath: '',
  }),

  [AUTHORIZE[FAILURE]]: (state, action) => ({
    ...state,
    loading: false,
    error: action.exception.error,
    afterSignInPath: '',
  }),

  [AUTHORIZE_RESET_ERROR]: state => ({
    ...state,
    loading: false,
    error: null,
  }),

  [AUTHORIZE_SET_AFTER_SIGN_IN_PATH]: (state, action) => ({
    ...state,
    afterSignInPath: action.path,
  }),

  default: state => state,
};

const userAuthorize = (state = defaultState, action) => {
  const calcState = handlers[action.type] || handlers.default;

  return calcState(state, action);
};

export default userAuthorize;
