import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { bool, func, number, string } from 'prop-types';

const ArticleCover = ({
  id,
  alreadyLiked,
  likesNumber,
  url,
  title,
  description,
  cover,
  indexOrder,
  onToggleLike,
}) => {
  const isWide = (indexOrder + 1) % 3 === 0;

  const currentUserId = useSelector(state => state.local.currentUser.id);

  return (
    <div className={`article_cover cell ${isWide ? 'cell_100' : 'cell_50'}`}>
      <a
        className="p-card medium"
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        <div className={`article_card${isWide ? '_wide' : ''}`}>
          <img className="article_card_img" src={cover} alt="" />
          <div className="article_cover_body">
            <div className="p-card-title">{title}</div>
            <div className="article_cover_description -short">
              {description}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className={cn('like-toggle', {
              active: alreadyLiked,
              'like-toggle-hover': currentUserId,
            })}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onToggleLike(id);
            }}
          >
            <i className={`fa fa-heart${alreadyLiked ? "" : "-o"}`} />
            &nbsp;
            <span>{likesNumber || ""}</span>
          </button>
        </div>
      </a>
    </div>
  );
};

ArticleCover.propTypes = {
  id: number.isRequired,
  alreadyLiked: bool,
  likesNumber: number.isRequired,
  url: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  cover: string.isRequired,
  indexOrder: number.isRequired,
  onToggleLike: func.isRequired,
};

ArticleCover.defaultProps = {
  alreadyLiked: false,
};

export default ArticleCover;
