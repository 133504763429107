import React, { useEffect } from 'react';
import { array, bool, func, string } from 'prop-types';

import Homework from './Homework';
import LoadWorkArea from './LoadWorkArea';

const LessonWorks = ({
  exercisesWithWorks,
  isLessonStarted,
  handleLoadWorkClick,
  handleLoadWork,
  reduxCatalogKey,
}) => {
  useEffect(() => {
    window.initPopoverTooltip();
  }, []);

  if (exercisesWithWorks.length === 0) return null;

  const renderExercises = () => exercisesWithWorks.map((exercise) => {
    const { homework } = exercise;

    if (homework) {
      return (
        <div key={exercise.id} className="cell cell_33">
          <Homework
            id={homework.id}
            status={homework.status}
            image={homework.image}
            type={homework.type}
            text={homework.text}
            reduxCatalogKey={reduxCatalogKey}
          />
        </div>
      );
    }

    return (
      <div key={exercise.id} className="cell cell_33">
        <LoadWorkArea
          exercise={exercise}
          isLessonStarted={isLessonStarted}
          handleLoadWorkClick={handleLoadWorkClick}
          handleLoadWork={handleLoadWork}
        />
      </div>
    );
  });

  return (
    <div className="d-flex flex-wrap">
      {renderExercises()}
    </div>
  );
};

LessonWorks.propTypes = {
  exercisesWithWorks: array.isRequired,
  isLessonStarted: bool.isRequired,
  handleLoadWorkClick: func.isRequired,
  handleLoadWork: func.isRequired,
  reduxCatalogKey: string.isRequired,
};

export default LessonWorks;
