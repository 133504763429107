import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { formatTime } from 'Core/services/tools';
import {
  HomeworkFameHallSent,
  HomeworkLiked,
  HomeworkCommented,
  HomeworkStatusChangedUnverified,
  HomeworkStatusChangedAccepted,
  HomeworkStatusChangedRejected,
  HomeworkStatusChangedWaitingNextStage,
} from "Main/components/layouts/icons/NotificationCenterIcons";

const types = {
  homework_unverified: () => ({
    message: <span>Ваше домашнее задание проверяется</span>,
    icon: <HomeworkStatusChangedUnverified />,
  }),
  homework_rejected: () => ({
    message: <span>Ваше домашнее задание нужно доработать</span>,
    icon: <HomeworkStatusChangedRejected />,
  }),
  homework_waiting_next_stage: () => ({
    message: <span>По вашему домашнему заданию ожидается следующий этап</span>,
    icon: <HomeworkStatusChangedWaitingNextStage />,
  }),
  homework_accepted: () => ({
    message: <span>Ваше домашнее задание принято</span>,
    icon: <HomeworkStatusChangedAccepted />,
  }),
  homework_fame_hall_sent: () => ({
    message: (
      <span>
        Поздравляем! Ваша работа добавлена на страницу
        {' '}
        <a href={`${window.location.origin}/student-works`}>Работы учеников</a>
      </span>
    ),
    icon: <HomeworkFameHallSent />,
  }),
  homework_liked: ({ initiator: { id, name } }) => ({
    message: (
      <span>
        <a href={`/user/${id}`}>{name}</a>
        {' '}
        поставил(-а) лайк вашей работе
      </span>
    ),
    icon: <HomeworkLiked />,
  }),
  homework_commented: ({ initiator: { id, name, text } }) => ({
    message: (
      <span>
        <a href={`/user/${id}`}>{name}</a>
        {' '}
        оставил
        комментарий к вашей работе:
        <i>{text}</i>
      </span>
    ),
    icon: <HomeworkCommented />,
  }),
};

const generateOnClickFunc = (id) => {
  const history = useHistory();
  const location = useLocation();

  return () => {
    if (history.location.pathname === `/student-work/${id}`) return;

    history.push({
      pathname: `/student-work/${id}`,
      state: {
        fromPath: location.pathname,
      },
    });
  };
};

const renderThumbView = ({ image, is_code, notifiable_id, relatable_id }) => {
  if (!image && !is_code) return null;
  const linkId = relatable_id || notifiable_id;

  if (is_code) {
    return (
      <div className="notify-preview -code" onClick={generateOnClickFunc(linkId)}>{'</>'}</div>
    );
  }

  return (
    <div
      className="notify-preview"
      style={{
        backgroundImage: `url("${image}")`,
      }}
      onClick={generateOnClickFunc(linkId)}
    />
  );
};

export const getNotifyItemOptions = (event_type, opt) => {
  const shared = {
    image: renderThumbView(opt),
    time: formatTime(opt.time),
  };

  return {
    ...shared,
    ...types[event_type](opt),
  };
};
