import React from "react";
import moment from "moment";

export default function MessageManage(props) {
  const {
    message: { homework_id, user, time },
    me,
    message_id,
    onEditingMessage,
    onDeleteMessage,
    formatMessageTextToCode,
    is_teacher_message,
    is_teacher,
    isCodeSnippet,
    course: { category }
  } = props;

  const isMessageMine = me === user;

  // разрешаеи редактровать только свои сообщения
  // также если учитель, то разрешаем редактировать сообщения других учителей
  if (!isMessageMine && !is_teacher_message && !is_teacher) return null;

  const isEditable = !homework_id;
  const duringLastDay = moment().unix() - time <= 86400;
  const teacherGroupExpression = is_teacher_message && is_teacher && isEditable && duringLastDay;
  const mineExpression = isMessageMine && isEditable && duringLastDay;
  const isRenderEditing = teacherGroupExpression || mineExpression;

  return (
    <div className="message_title_manage">
      {!isCodeSnippet && !homework_id && category === 'unity' && (
        <i
          style={{ fontWeight: 600 }}
          className="fa fa-code"
          onClick={formatMessageTextToCode}
        />
      )}
      {isRenderEditing && (
        <>
          <i
            className="fa fa-pencil"
            onClick={() => onEditingMessage(true, message_id)}
          />
          <i
            className="fa fa-close"
            onClick={() => onDeleteMessage(message_id)}
          />
        </>
      )}
    </div>
  );
}
