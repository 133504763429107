import get from "lodash/get";
import filter from "lodash/filter";
import findLast from "lodash/findLast";

export const getChatLastMessage = (state, id) => (
  findLast(filter(state.entities.messages, { chat: id }))
);

// export const getChatFirstUnanswMessageTime = (state, id) => {
//   const chat = filter(state.entities.chats, { id })[0];

//   if ("first_unanswered_message_time" in chat) {
//     return chat.first_unanswered_message_time;
//   }

//   return 0;
// };

const getSocWorkFields = work => ({
  commentsNumber: work.comments_number,
  likesNumber: work.likes_number,
  alreadyLiked: work.already_liked,
});

export const getStudentWorkFields = (state, homeworkId) => {
  const homework = state.entities.homeworks[homeworkId];

  return {
    id: homework.id,
    isShowOnCourse: homework.show_on_course,
    imageFull: homework.full_image,
    type: homework.type,
    status: homework.status,
    text: homework.text,
    flow: { id: homework.flow_id },
    course: state.entities.courses[homework.course_id],
    exercise: state.entities.exercises[homework.exercise_id],
    lesson: state.entities.lessons[homework.lesson_id],
    user: state.entities.users[homework.user_id],
    ...getSocWorkFields(homework),
  };
};

export const getStudentWorkCoversFields = (state, ids) => ids.map((id) => {
  const homework = state.entities.homeworks[id];

  return {
    id: homework.id,
    isShowOnCourse: homework.show_on_course,
    status: homework.status,
    type: homework.type,
    lessonId: homework.lesson_id || null,
    image: homework.image,
    text: homework.text,
    user: state.entities.users[homework.user_id],
    ...getSocWorkFields(homework),
  };
});

export const getContestantCoversFields = (state, isWinnerSelection) => {
  // если процесс выбора побеителей не закончен, то не показываем секцию победителей
  if (isWinnerSelection) {
    return {
      winners: [],
      contestants: Object.values(state.entities.contestants),
    };
  }

  let winners = [];
  const contestants = [];

  Object.values(state.entities.contestants).forEach((contestant) => {
    if (contestant.isWinner) {
      return winners.push(contestant);
    }

    contestants.push(contestant);
  });

  winners = winners.sort((e1, e2) => e1.prizePosition - e2.prizePosition);

  return {
    winners,
    contestants,
  };
};

const getArticleCoversFields = (state, ids) => ids.map((id) => {
  const article = state.entities.articles[id];

  return {
    id: article.id,
    title: article.title,
    url: article.url,
    description: article.description,
    cover: article.cover,
    ...getSocWorkFields(article),
  };
});

const getCompletedContestWorkCoversFields = (state, ids) => ids.map((id) => {
  const contestWork = state.entities.contestants[id];

  return {
    id: contestWork.id,
    userAvatar: contestWork.user.avatar,
    userName: contestWork.user.nickname,
    cover: contestWork.cover,
    commentsCount: contestWork.commentsNumber,
    likesCount: contestWork.likesNumber,
    alreadyLiked: contestWork.alreadyLiked,
    prizePlaceIcon: contestWork.prizePlaceIcon,
    isCompleted: true,
  };
});

export const getCatalogCoversFields = (state, ids, entity) => {
  if (entity === 'homeworks') {
    return getStudentWorkCoversFields(state, ids);
  }

  if (entity === 'articles') {
    return getArticleCoversFields(state, ids);
  }

  if (entity === 'contestants') {
    return getCompletedContestWorkCoversFields(state, ids);
  }

  throw Error(`Catalog entity "${entity} is not exists!"`);
};

export const getMyExerciseWorksCoversFields = (state, userId, courseId) => {
  const exerciseWorks = {};
  const lessonOldWorks = {};

  const works = filter(state.entities.homeworks, h => (
    h.final && h.user_id === userId && h.course_id === courseId
  ));

  works.forEach((work) => {
    const wordData = {
      id: work.id,
      type: work.type,
      image: work.image,
      text: work.text,
      status: work.status,
      user: state.entities.users[work.user_id],
      ...getSocWorkFields(work),
    };

    if (work.flow_id) {
      exerciseWorks[work.exercise_id] = wordData;

      return;
    }

    const lessonId = work.lesson_id || 'old';

    if (!lessonOldWorks[lessonId]) {
      lessonOldWorks[lessonId] = [];
    }

    lessonOldWorks[lessonId].push(wordData);
  });

  return { exerciseWorks, lessonOldWorks };
};

export const getHomeworkVersions = (state, homeworkId) => {
  const homework = state.entities.homeworks[homeworkId];

  if (!Array.isArray(homework.versions)) return [];

  const versions = homework.versions.map((hwId) => {
    const version = state.entities.homeworks[hwId];

    return {
      id: version.id,
      isShowOnCourse: version.show_on_course,
      imageFull: version.full_image,
      image: version.image,
      type: version.type,
      final: version.final,
      status: version.status,
      text: version.text,
      time: version.time,
      chatWorkTime: homework.chat_work_time,
      chatId: homework.chat_id,
      flow: state.entities.flows[homework.flow_id],
      course: state.entities.courses[homework.course_id],
      exercise: state.entities.exercises[homework.exercise_id],
      lesson: state.entities.lessons[homework.lesson_id],
      user: state.entities.users[homework.user_id],
      ...getSocWorkFields(version),
    };
  });

  return versions;
};

export const getIsFetching = (state, name, id = "all") => get(state, `meta.pagination.${name}.['${id}'].isFetching`, false);
export const getIsError = (state, name, id = "all") => get(state, `meta.pagination.${name}.['${id}'].isError`, false);
export const getIsRequested = (state, name, id = "all") => get(state, `meta.pagination.${name}.['${id}'].isRequested`, false);
export const getIsLoaded = (state, name, id = "all") => !getIsFetching(state, name, id) && getIsRequested(state, name, id);

export const getChatsByContext = (state) => {
  const {
    entities: { chats },
    local: {
      chats: { context },
    },
    meta: { chatsByContext },
  } = state;
  const key = JSON.stringify(context, (k, value) => (k === "page" ? undefined : value),
  );
  if (!chatsByContext[key]) {
    return [];
  }

  return filter(
    chats,
    ({ id }) => chatsByContext[key].ids.indexOf(id) !== -1,
  );
};

export const getChatsStateByContext = (state) => {
  const {
    local: {
      chats: { context },
    },
    meta: { chatsByContext },
  } = state;
  const key = JSON.stringify(context, (k, value) => (k === "page" ? undefined : value),
  );
  if (!chatsByContext[key]) {
    return { is_fetching: false, is_loaded: false };
  }
  return chatsByContext[key];
};

export const getHomeworkStatusForChat = (state, chat) => {
  const {
    entities: { exercises, homeworks, flows, lessons },
  } = state;

  if (!chat.homeworks || !chat.lessons) return;

  const sortedlessons = chat.lessons
    .map(id => lessons[id])
    .sort((a, b) => a.position - b.position);
  const userHomeworks = chat.homeworks.map(
    homework_id => homeworks[homework_id],
  );
  const { lessonStartDates } = flows[chat.flow];
  const progress = [];

  sortedlessons.forEach((lesson) => {
    lesson.exercises.forEach((exercise_id) => {
      const progress_item = {
        startLessonDate: lessonStartDates[lesson.id],
        ...exercises[exercise_id],
      };

      const homework = userHomeworks
        .filter(h => h.exercise_id === exercise_id)
        .pop();

      if (homework) {
        progress_item.status = homework.status;
        progress_item.homework_id = homework.id;
      }

      progress.push(progress_item);
    });
  });

  return progress;
};
