import React, { useEffect, useState } from "react";
import { func, number, string } from "prop-types";

import parse from "html-react-parser";
import { pluralizeByNumber } from "Core/helpers/pluralize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function DeadlineCountdowner({ startFrom, txtColor, onFinish }) {
  const [deadline, setDeadline] = useState(0);
  const [countdowner, setCountdownValues] = useState({});

  const timer = (seconds) => {
    let secs = seconds;

    const days = parseInt(secs / 86400, 10);
    secs -= days * 86400;

    const hours = parseInt(secs / 3600, 10);
    secs -= hours * 3600;

    const minutes = parseInt(Math.ceil(secs / 60), 10);
    secs -= minutes * 60;

    const newCountdownerOpts = {
      days: pluralizeByNumber(days, ["день", "дня", "дней"]),
      hours: pluralizeByNumber(hours, ["час", "часа", "часов"]),
      minutes: pluralizeByNumber(minutes, ["минута", "минуты", "минут"]),
    };

    const newCountdowner = Object.keys(newCountdownerOpts).reduce((acc, key) => {
      acc[key] = parse(newCountdownerOpts[key].replace(/(\d+)/g, "<b>$1</b>"));
      return acc;
    }, {});

    setCountdownValues(newCountdowner);
  };

  useEffect(() => {
    if (startFrom > 0) {
      setDeadline(startFrom);
    } else {
      timer(0);
    }
  }, [startFrom]);

  useEffect(() => {
    if (deadline < 0) return;

    const intervalId = setInterval(() => {
      timer(deadline);
      setDeadline(oldDeadline => oldDeadline - 1);

      if (deadline === 0) {
        setDeadline(-1);
        onFinish();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [deadline]);

  const loading = <FontAwesomeIcon icon={faSpinner} pulse size="2x" color={txtColor} />;

  return (
    <div className="deeadline">
      <span>{countdowner.days || loading}</span>
      <span>{countdowner.hours || loading}</span>
      <span>{countdowner.minutes || loading}</span>
    </div>
  );
}

DeadlineCountdowner.propTypes = {
  startFrom: number,
  txtColor: string,
  onFinish: func,
};

DeadlineCountdowner.defaultProps = {
  startFrom: 0,
  txtColor: "#000",
  onFinish: () => {},
};
