import React, { Component } from 'react';
import { func } from 'prop-types';

export default class UploadTextHomeworkModal extends Component {
  state = {
    saveCodeButtonDisabled: true,
  }

  onChangeTextarea = () => {
    this.setState({ saveCodeButtonDisabled: !this.textarea.value });
  }

  handleUploadTextVersion = () => {
    this.props.onTextVersionUpload(this.textarea.value);
    this.props.closeHomework();
  }

  render() {
    return (
      <div className="modal-content text-center modal-upload-code-homework">
        <div className="modal-header">
          <div onClick={this.props.backToHomework} className="pixel_back_btn modal-upload-code-homework-header-back">
            <i className="fa fa-angle-left" />
            <div>Назад</div>
          </div>
          <div className="container text-center">Скопируйте и вставьте код</div>
        </div>

        <div className="modal-body">
          <form action="" method="post">
            <div className="form-group">
              <textarea ref={(el) => { this.textarea = el; }} onChange={this.onChangeTextarea} className="form-control modal-upload-code-homework-textarea" name="homework[text]" placeholder="Ваш код" rows="20" />
            </div>
          </form>
        </div>

        <div className="modal-footer">
          <div className="container text-center">
            <button type="button" onClick={this.handleUploadTextVersion} disabled={this.state.saveCodeButtonDisabled} className="btn btn-primary modal-upload-code-homework-btn-submit">Сохранить</button>
          </div>
        </div>
      </div>
    );
  }
}

UploadTextHomeworkModal.propTypes = {
  // exerciseId: number.isRequired,
  backToHomework: func.isRequired,
  onTextVersionUpload: func.isRequired,
  closeHomework: func.isRequired,
};
