import React from "react";
import moment from "moment";

export default function DateLine({ time }) {
  if (time.isSame(moment(), "day")) {
    return (
      <div className="dateTimeLine">
        <span>Сегодня</span>
      </div>
    );
  }

  return (
    <div className="dateTimeLine">
      <span>{time.format("D MMMM")}</span>
    </div>
  );
}
