import { string } from 'prop-types';
import React from 'react';

export default function CourseBanner({ banner }) {
  return (
    <div id="course-banner" className="lozad" data-background-image={banner} />
  );
}

CourseBanner.propTypes = {
  banner: string.isRequired,
};
