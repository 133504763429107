import React from "react";
import { func, string } from "prop-types";
import moment from "moment";
import Modal from "Main/components/layouts/modals/Modal";

export default function PaymentSuccessModal(props) {
  const { consultation_last_date, closeModal } = props;
  const extendDate = `${moment(consultation_last_date).format("D MMMM YYYY")}`;

  return (
    <Modal closeModal={closeModal} showCross={false}>
      <div className="extend_consultation_success">
        <i onClick={closeModal} className="fa fa-times" aria-hidden="true" />
        <img src="/images/chat/paid_consultations_cat.png" alt="" />
        <h2>Оплачено!</h2>
        <p>
          <span>Теперь вы можете получать консультации по</span>
          <span>
            домашним работам до
            {extendDate}
            {' '}
            года.
          </span>
        </p>
        <button type="button" onClick={closeModal}>OK</button>
      </div>
    </Modal>
  );
}

PaymentSuccessModal.propTypes = {
  closeModal: func.isRequired,
  consultation_last_date: string.isRequired,
};
