import React from "react";
import { Router } from "react-router-dom";
import { history } from "Core/services";
import { fetchWebinars, toggleLikeCatalogItem } from 'Core/services/api';

import WebinarsSharedRouter from './WebinarsSharedRouter';

const WebinarClientRoot = props => (
  <Router history={history}>
    <WebinarsSharedRouter
      {...props}
      fetchWebinars={fetchWebinars}
      toggleLike={toggleLikeCatalogItem}
      history={history}
    />
  </Router>
);

export default WebinarClientRoot;
