import React from "react";
import { element, oneOfType, bool } from "prop-types";

function Layout(props) {
  const {
    header,
    messages,
    consultationWarning,
    workTimeIsUpWarning,
    chatBlockedByCourse,
    input,
  } = props;

  return (
    <div id="chat_wrapper">
      <div id="p_chat">
        {header}
        {messages}
        {consultationWarning}
        {!consultationWarning && workTimeIsUpWarning}
        {input}
        {chatBlockedByCourse}
      </div>
    </div>
  );
}

Layout.propTypes = {
  input: oneOfType([element, bool]),
  messages: element.isRequired,
  header: element.isRequired,
  consultationWarning: oneOfType([element, bool]),
  workTimeIsUpWarning: oneOfType([element, bool]),
  chatBlockedByCourse: oneOfType([element, bool]),
};

Layout.defaultProps = {
  consultationWarning: false,
  workTimeIsUpWarning: false,
  chatBlockedByCourse: false,
  input: false,
};

export default Layout;
