import React, { Component } from "react";
import { any, bool, func, number, string, object } from "prop-types";
import isEqual from "lodash/isEqual";

import InputFile from "Main/components/layouts/forms/InputFile";
import InputAreaBlocked from "./InputAreaBlocked";
import InputAttachmentZone from "./InputAttachmentZone";
import Textarea from "./Textarea";

import "emoji-mart/css/emoji-mart.css";

const fileTypes = ["image/png", "image/jpeg", "image/gif", "image/jpg"];

export default class InputArea extends Component {
  state = {
    isUserTyping: false,
    imageBlob: null, // это image, загруженный через textarea или через кнопку "скрепка"
    isImageBlobUrl: false, // если сообщение редактируется, и тип файла - изображение
    isFileUrl: false, // если сообщение редактируется, и тип файла - не изображение
    value: "",
    displayUploadError: true,
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.onTypingChatListener);
    if (this.props.isInputAreaBlocked) return;
    this.input.focus();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // PIX-115 fix bug with text selection
    const isStateChanged = !isEqual(this.state, nextState);
    const isPropsChanged = !isEqual(this.props, nextProps);

    return isStateChanged || isPropsChanged;
  }

  componentDidUpdate(prevProps) {
    if (this.props.isInputAreaBlocked) return;

    if (!this.props.isPersonalRemarkOpened) {
      this.input.focus();
    }

    if (this.props.isEditing && !prevProps.isEditing) {
      // activate editing mode
      const message = this.props.editingMessage;

      if (message.image_preview) {
        const isImage = fileTypes.includes(message.image_content_type);
        this.setState(isImage ? { isImageBlobUrl: true } : { isFileUrl: true });
      }
      this.setState({ value: this.props.editingMessage.text });
    }
  }

  componentWillUnmount() {
    this.props.onTypingChat(false);
    window.removeEventListener("beforeunload", this.onTypingChatListener);
  }

  onTypingChatListener = () => this.props.onTypingChat(false);

  handlePaste = (e) => {
    const { items } = e.clipboardData;

    for (let i = 0; i < items.length; i += 1) {
      // Skip content if not image
      // eslint-disable-next-line no-continue
      if (items[i].type.indexOf("image") === -1) continue;
      // Retrieve image on clipboard as blob
      const imageBlob = items[i].getAsFile();

      this.setState({
        isImageBlobUrl: false,
        isFileUrl: false,
        imageBlob,
      });
      this.props.onUploadImage(imageBlob);
    }
  };

  handleFileChange = (e) => {
    // fix error from sentry: size undefined
    if (!e.target.files[0] || !e.target.files[0].size) return;
    this.setState({ displayUploadError: true });
    if (e.target.files[0].size / 1024 / 1024 > 70) {
      this.props.onExceededLimit();
      return;
    }

    this.setState({
      isImageBlobUrl: false,
      isFileUrl: false,
      imageBlob: this.fileInput.files[0],
    });
    this.props.onUploadImage(this.fileInput.files[0]);
  };

  handleRemoveAttachment = () => {
    this.props.onRemoveAttachment();
    this.fileInput.value = "";
    this.setState({
      isImageBlobUrl: false,
      isFileUrl: false,
      imageBlob: null,
    });
  };

  handleSend = () => {
    this.setState({ rows: 1 });
    this.fileInput.value = "";
    this.sendMessage();
  };

  handleKeyDown = (e) => {
    // 13 - enter
    if (e.keyCode === 13 && this.state.value === "" && !this.state.imageBlob) {
      e.preventDefault();
      return false;
    }
    if (!e.shiftKey && e.keyCode === 13) {
      e.preventDefault();
      this.setState({ rows: 1 });

      if (!this.props.isEditing) this.sendMessage();
      else this.updateMessage();

      return false;
    }
  };

  handleCancelEditing = () => {
    this.props.onEditingMessage(false, 0);
    this.setState({
      isImageBlobUrl: false,
      isFileUrl: false,
      value: "",
      rows: 1,
    });
  };

  sendMessage() {
    this.props.onTypingChat(false);
    this.props.onSendMessage(this.state.value.trim(), this.props.uploadImageId);
    this.setState({
      imageBlob: null,
      value: "",
      isUserTyping: false,
    });
  }

  handleUpdate = () => {
    this.setState({ rows: 1 });
    this.fileInput.value = "";
    this.updateMessage();
  };

  updateMessage() {
    this.props.onUpdateMessage(
      this.props.editingMessageId,
      this.state.value.trim(),
      this.props.uploadImageId,
    );
    this.setState({
      isImageBlobUrl: false,
      isFileUrl: false,
      imageBlob: null,
      value: "",
    });
  }

  handleChange = (messageText) => {
    this.setState({ value: messageText });

    if (this.props.isEditing) return;

    if (messageText && !this.state.isUserTyping) {
      this.setState({ isUserTyping: true });
      this.props.onTypingChat(true);
    }
    if (!messageText && this.state.isUserTyping) {
      this.setState({ isUserTyping: false });
      this.props.onTypingChat(false);
    }
  };

  hideUploadError = () => {
    this.fileInput.value = "";
    this.setState({ displayUploadError: false, imageBlob: null });
  };

  renderInputArea() {
    const {
      imageUploadingStatus: { status },
    } = this.props;
    const textareaDisabled = status === "loading";
    const buttonDisabled = textareaDisabled || (this.state.value === "" && !this.state.imageBlob);

    return (
      <div id="chat_input_area">
        {this.props.isEditing && (
          <div className="chat_input_editing_actions">
            <div>Редактирование сообщения</div>
            <i className="fa fa-times" onClick={this.handleCancelEditing} />
          </div>
        )}

        {((status && this.state.imageBlob)
          || (status === "exceeded_limit" && this.state.displayUploadError)
          || this.state.isImageBlobUrl
          || this.state.isFileUrl) && (
          <div className="chat_input_attachment_zone">
            <InputAttachmentZone
              status={status}
              onUploadImage={() => this.props.onUploadImage(this.state.imageBlob)}
              displayUploadError={this.state.displayUploadError}
              hideUploadError={this.hideUploadError}
              imageBlob={this.state.imageBlob}
              isFileUrl={this.state.isFileUrl}
              isImageBlobUrl={this.state.isImageBlobUrl}
              editingMessage={this.props.editingMessage}
              onRemoveAttachment={this.handleRemoveAttachment}
            />
          </div>
        )}

        <div className="chat_input_main_zone">
          <InputFile
            className="px-3"
            disabled={status === "loading"}
            onChange={this.handleFileChange}
            setRef={(fileInput) => {
              this.fileInput = fileInput;
            }}
          />

          <Textarea
            disabled={textareaDisabled}
            onChange={this.handleChange}
            onPaste={this.handlePaste}
            onKeyDown={this.handleKeyDown}
            value={this.state.value}
            forwardRef={(el) => {
              this.input = el;
            }}
          />

          {!this.props.isEditing && (
            <button
              type="button"
              className="chat_input_submit"
              onClick={this.handleSend}
              disabled={buttonDisabled}
            >
              Отправить
            </button>
          )}
          {this.props.isEditing && (
            <button
              type="button"
              className="chat_input_submit"
              onClick={this.handleUpdate}
              disabled={buttonDisabled}
            >
              Сохранить
            </button>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { isInputAreaBlocked, nameOfTyping } = this.props;

    if (isInputAreaBlocked) {
      return <InputAreaBlocked nameOfTyping={nameOfTyping} />;
    }
    return this.renderInputArea();
  }
}

InputArea.propTypes = {
  uploadImageId: number,
  onSendMessage: func.isRequired,
  onUpdateMessage: func.isRequired,
  onUploadImage: func.isRequired,
  onRemoveAttachment: func.isRequired,
  imageUploadingStatus: object,
  onExceededLimit: func.isRequired,
  isEditing: bool.isRequired,
  onEditingMessage: func.isRequired,
  editingMessage: any.isRequired,
  editingMessageId: number,
  isInputAreaBlocked: bool.isRequired,
  nameOfTyping: string.isRequired,
  onTypingChat: func.isRequired,
  isPersonalRemarkOpened: bool.isRequired,
};

InputArea.defaultProps = {
  // по дефолту -2, для удаления картинки -1
  uploadImageId: -2,
  imageUploadingStatus: {},
  editingMessageId: null,
};
