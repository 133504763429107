import React, { Component } from "react";
import { func, bool } from "prop-types";
import { connect } from "react-redux";
import {
  loadChats,
  navigate,
  onListContext,
  toggleChatAnswered,
} from "Core/actions";
import { selectors } from "Core/reducers";
import { Item } from "Main/components/Chats/Item/index";

import {
  chat as ChatType,
  flow as FlowType,
  course as CourseType,
  message as MessageType,
  homework as HomeworkType,
  user as UserType,
} from "Main/types";

class ChatsItemContainer extends Component {
  render() {
    const {
      chat,
      user,
      message,
      course,
      homework,
      flow,
      is_teacher,
      me,
      toggleChatAnswered,
    } = this.props;

    return (
      <Item
        key={chat.id}
        chat={chat}
        user={user}
        course={course}
        flow={flow}
        message={message}
        homework={homework}
        is_teacher={is_teacher}
        me={me}
        onOpenChat={this.props.navigate}
        updateChatAnswered={toggleChatAnswered}
      />
    );
  }
}

ChatsItemContainer.propTypes = {
  chat: ChatType.isRequired,
  user: UserType.isRequired,
  course: CourseType.isRequired,
  flow: FlowType.isRequired,
  message: MessageType,
  homework: HomeworkType,
  is_teacher: bool.isRequired,
  navigate: func.isRequired,
};

const mapStateToProps = (state, { chat_id }) => {
  const {
    entities: { chats, flows, courses, users, homeworks },
    local: {
      is_teacher,
      me,
      chats: { context },
    },
  } = state;
  const chat = chats[chat_id];
  const user = users[chat.user];
  const message = selectors.getChatLastMessage(state, chat.id);
  const homework = message ? homeworks[message.homework] : undefined;

  return {
    me,
    chat,
    user,
    course: courses[chat.course],
    flow: flows[chat.flow],
    message,
    homework,
    is_teacher: !!is_teacher,
  };
};

export default connect(mapStateToProps, {
  loadChats,
  navigate,
  onListContext,
  toggleChatAnswered,
})(ChatsItemContainer);
