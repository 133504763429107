import React from "react";
import { array, func } from "prop-types";
import delay from "lodash/delay";
import cn from 'classnames';

const ELEMENT_COUNT = 23;

export default function Statusbar({ onChatContext, items }) {
  const hrefProps = (item) => {
    const attrs = {};

    if (item.homework_id) {
      attrs.href = `#homework-${item.homework_id}`;
    }

    return attrs;
  };

  const gotoHash = href => delay(() => {
    window.location.hash = href;
  }, 100);

  const handleGotoHash = (e) => {
    e.preventDefault();
    const href = e.target.getAttribute("href");

    if (href) {
      const hwEl = document.getElementById(href.replace(/#/, ""));

      if (!hwEl) {
        onChatContext().then(() => gotoHash(href));
      } else {
        gotoHash(href);
      }
    }

    return false;
  };

  const sectionCount = Math.ceil(items.length / ELEMENT_COUNT);

  return (
    <div className="d-flex flex-column justify-content-center">
      {[...Array(sectionCount).keys()].map(sectionNumber => (
        <div key={sectionNumber} className="homework_statusbar homework_statusbar_small ml-2">
          {items.slice(
            sectionNumber * ELEMENT_COUNT, (sectionNumber + 1) * ELEMENT_COUNT,
          ).map(item => (
            <div
              className={cn('homework_statusbar_item', {
                [`-${item.status}`]: item.status,
                '-last-opened-lesson': item.last,
              })}
              key={item.id}
              title={item.title}
            >
              <span className="line-btw-cicles" />
              <a onClick={e => handleGotoHash(e)} {...hrefProps(item)} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

Statusbar.propTypes = {
  items: array.isRequired,
  onChatContext: func.isRequired,
};
