import React, { Component } from "react";

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
export default function SomethingWentWrong() {
  return (
    <div className="chat_somefing_went_wrong_wrapper">
      <div className="chat_somefing_went_wrong_content">
        <div className="chat_somefing_went_wrong_img">
          <img src="/images/chat/pix_error2.jpg" alt="somefing went wrong" />
        </div>
        <h2>Что-то пошло не так</h2>
        <p>
          Проверьте подключение к интернету и{" "}
          <a href="javascript:window.location.reload(true);">
            перезагрузите страницу
          </a>
        </p>
      </div>
    </div>
  );
}
