/* eslint-disable no-constant-condition */
import { take, put, call, fork, select, all } from "redux-saga/effects";
import mapKeys from "lodash/mapKeys";
import snakeCase from "lodash/snakeCase";
import { api } from "../services";
import * as actions from "../actions";

function* watchLoadEduCertificateTemplate() {
  while (true) {
    const options = yield take(actions.EDU_CERTIFICATE_TEMPLATE_LOAD);
    const { response } = yield call(
      api.fetchEduCertificateTemplate,
      mapKeys(options, (v, k) => snakeCase(k)),
    );

    if (response) {
      yield put({
        type: actions.EDU_CERTIFICATE_TEMPLATE_LOAD_DONE,
        response,
      });
    }
  }
}

function* watchIssuanceEduCertificate() {
  while (true) {
    const { fullname } = yield take(actions.EDU_CERTIFICATE_ISSUANCE);
    const { courseId, userId } = yield select(
      state => state.local.eduCertificateReducer,
    );
    let apiOptions = { courseId, userId };

    if (fullname) {
      apiOptions = {
        ...apiOptions,
        fullname,
      };
    }

    const { response } = yield call(
      api.issuanceEduCertificate,
      mapKeys(apiOptions, (v, k) => snakeCase(k)),
    );

    if (response) {
      yield put({
        type: actions.EDU_CERTIFICATE_ISSUANCE_DONE,
        response,
      });
    }
  }
}

export default function* root() {
  yield all([
    fork(watchLoadEduCertificateTemplate),
    fork(watchIssuanceEduCertificate),
  ]);
}
