import React, { Component } from "react";
import { func, string, object, bool } from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward } from "@fortawesome/free-solid-svg-icons";
import {
  enableServiceWorker,
  enableSubscription,
} from "core/services/notifications";
import Statusbar from "Main/components/layouts/Statusbar";
import { EduCertificateIssuance } from "Main/components/EduCertificate";
import {
  DoubleCheckIcon,
  RemarkBlueIcon,
  BellIcon,
  EnvelopeWithClockIcon,
} from "../layouts/icons";
import PersonalRemarkModal from "./PersonalRemarkModal";

export default class Header extends Component {
  state = {
    notificationsEnabled:
      "Notification" in window ? Notification.permission === "granted" : false,
    eduCertificateIssuanceModalShown: false,
  };

  handleToggleAnswered = () => {
    this.props.toggleChatAnswered({
      chat_id: this.props.chat_id,
      answered: !this.props.chat_answered,
    });
  };

  handleEnableNotifications = () => {
    enableServiceWorker();
    if (!("Notification" in window)) {
      console.error("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // Let's check whether notification permissions have already been granted
      console.log("Permission to receive notifications has been granted");
      this.setState({
        notificationsEnabled: true,
      });
    } else if (Notification.permission !== "denied") {
      // Otherwise, we need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          console.log("Permission to receive notifications has been granted");
          this.setState({
            notificationsEnabled: true,
          });
        }
      });
    }
    enableSubscription();
  };

  /*
  * для тестирования уведомлений - выключение serviceWorkers
  *
  disableNotifications = () => {
    console.log('clicked disabling');
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((reg) => {
          console.log('disabling... ', reg);
          reg.unregister();
        })
        .catch(e => console.log('SERVICE WORKER error:', e));;
    } else {
      console.log('Service workers are not supported.');
    }
  } */

  render() {
    const {
      cover,
      title,
      personal_remark,
      subtitle,
      onBack,
      is_teacher,
      chat,
      chat_answered,
      updatePersonalRemark,
      completing_progress,

      open_personal_remark_modal,
      // openPersonalRemarkModal,
      // closePersonalRemarkModal,
    } = this.props;

    return (
      <div className="chat_header">
        <div className="chat_header_body">
          <div className="chat_header_main">
            <div className="pixel_back_btn chat_header_back" onClick={onBack}>
              <i className="fa fa-angle-left" />
              <div>Назад</div>
            </div>
            {is_teacher && (
              <a
                href={`${window.location.origin}/flow/${this.props.flow_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="chat_header_avatar">
                  <img
                    src={cover || "/images/thumb/missing.png"}
                    className={
                      cover.endsWith("_cat_avatar.png")
                        ? "border_radius_none"
                        : ""
                    }
                    alt={title}
                  />
                </div>
              </a>
            )}

            {!is_teacher && (
              <div className="chat_header_avatar">
                <img
                  src={cover || "/images/thumb/missing.png"}
                  className={
                    cover.endsWith("_cat_avatar.png")
                      ? "border_radius_none"
                      : ""
                  }
                  alt={title}
                />
              </div>
            )}

            {is_teacher && (
              <div className="chat_header_title">
                <div className="d-flex">
                  <a
                    className="chat_header_title_primary"
                    href={`${window.location.origin}/admin/users/${chat.user}/edit`}
                    target="blank"
                    title={title}
                  >
                    {title}
                  </a>
                  {completing_progress && (
                    <Statusbar
                      items={completing_progress}
                      onChatContext={this.props.onChatContext}
                    />
                  )}
                </div>
                <a
                  href={`${window.location.origin}/flow/${this.props.flow_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="chat_header_title_second">{subtitle}</div>
                </a>
              </div>
            )}

            {!is_teacher && (
              <div className="chat_header_title">
                <div title={title}>{title}</div>
                <div className="chat_header_title_second">{subtitle}</div>
              </div>
            )}
          </div>

          <div className="chat_header_actions">
            {/* ACTION FOR CREATE/UPDATE PERSONAL REMARK */}
            {is_teacher && (
              <div className="chat_header_icon_remark">
                <RemarkBlueIcon
                  onClick={this.props.openPersonalRemarkModal}
                  tooltip={`${
                    personal_remark ? "Редактировать" : "Добавить"
                  } заметку об ученике`}
                />
              </div>
            )}

            {/* ACTION FOR ENABLE/DISABLE NOTIFICATIONS */}
            {!is_teacher
              && (this.state.notificationsEnabled ? (
                <BellIcon
                  className="chat_header_notifications_on"
                  tooltip="Уведомления включены"
                />
              ) : (
                <BellIcon
                  className="chat_header_notifications_off"
                  tooltip="Включить уведомления о новых сообщениях"
                  onClick={this.handleEnableNotifications}
                />
              ))}

            {/* MARK ANSWERED */}
            {is_teacher && chat_answered === false && (
              <AnsweredButton>
                <DoubleCheckIcon
                  tooltip="Отметить как отвеченный"
                  onClick={this.handleToggleAnswered}
                />
              </AnsweredButton>
            )}

            {/* MARK ANSWERED */}
            {is_teacher && chat_answered && (
              <UnansweredButton>
                <EnvelopeWithClockIcon
                  tooltip="Отметить как неотвеченный"
                  onClick={this.handleToggleAnswered}
                />
              </UnansweredButton>
            )}

            {is_teacher && (
              <AwardButton
                onClick={() => this.setState({
                  eduCertificateIssuanceModalShown: true,
                })}
              >
                <div className="tooltip_icon">
                  <FontAwesomeIcon icon={faAward} />
                  <span className="tooltiptext_icon">
                    Выдать сертификат
                  </span>
                </div>
              </AwardButton>
            )}
          </div>
        </div>
        {/* REMARK ABOUT STUDENT */}
        {is_teacher && personal_remark && (
          <div className="chat_student_remark">
            <i
              onClick={() => updatePersonalRemark({
                personal_remark: "",
                user_id: chat.user,
              })}
              className="fa fa-trash"
              aria-hidden="true"
            />
            {personal_remark}
          </div>
        )}
        {is_teacher && open_personal_remark_modal && (
          <PersonalRemarkModal
            user_id={chat.user}
            personal_remark={personal_remark}
            updatePersonalRemark={updatePersonalRemark}
            closeModal={this.props.closePersonalRemarkModal}
          />
        )}
        {this.state.eduCertificateIssuanceModalShown && (
          <EduCertificateIssuance
            courseId={chat.course}
            userId={chat.user}
            closeModal={() => this.setState({
              eduCertificateIssuanceModalShown: false,
            })}
          />
        )}
      </div>
    );
  }
}

Header.propTypes = {
  cover: string,
  title: string,
  personal_remark: string,
  subtitle: string,
  onBack: func.isRequired,
  chat: object,

  open_personal_remark_modal: bool.isRequired,
  openPersonalRemarkModal: func.isRequired,
  closePersonalRemarkModal: func.isRequired,
  updatePersonalRemark: func.isRequired,
  toggleChatAnswered: func.isRequired,
};

Header.defaultProps = {
  subtitle: "",
  title: "",
  personal_remark: "",
  cover: "",
  chat: {},
};

const AwardButton = styled.div`
  font-size: 20px;
  color: #2196f3;
  cursor: pointer;
  margin-right: 12px;
`;

const AnsweredButton = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 20px;
`;

const UnansweredButton = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 20px;
  margin-top: -3px;
`;
