import React from "react";
import { FieldArray } from "formik";
import styled from "styled-components";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import Dropdown, { DropdownItem } from "Main/components/ui/Dropdown";
import Input from "UI/Input";

export default function SocialProfilesInputs({ authProvider }) {
  const authProviders = {
    vkontakte: {
      "vk.com": {
        name: "VK",
        profileLinkTmpl: /(^(https?:\/\/)?(w{3}?\.)?vk\.com\/([\w\d\-\_\.]+){3}$)/,
        placeholder: "Пример: https://vk.com/ivanov",
        template: "https://vk.com/",
      },
    },
    facebook: {
      "facebook.com": {
        name: "Facebook",
        profileLinkTmpl: /(^(https?:\/\/)?(w{3}?\.)?facebook\.com\/([\w\d\-\_\.]+){3}$)/,
        placeholder: "Пример: https://facebook.com/ivanov",
        template: "https://facebook.com/",
      },
    },
  };

  const links = {
    ...authProviders[authProvider],
    "behance.net": {
      name: "Behance",
      profileLinkTmpl: /(^(https?:\/\/)?(w{3}?\.)?behance\.net\/([\w\d\-\_\.]+){3}$)/,
      placeholder: "Пример: https://behance.net/ivanov",
      template: "https://behance.net/",
    },
    "dribbble.com": {
      name: "Dribbble",
      profileLinkTmpl: /(^(https?:\/\/)?(w{3}?\.)?dribbble\.com\/([\w\d\-\_\.]+){3}$)/,
      placeholder: "Пример: https://dribbble.com/ivanov",
      template: "https://dribbble.com/",
    },
    "youtube.com": {
      name: "YouTube",
      profileLinkTmpl: /(^(https?:\/\/)?(w{3}?\.)?youtube\.com\/(user|channel)\/([\w\d\-\_\.]+){3}$)/,
      placeholder: "Пример: https://youtube.com/user/ivanov",
      template: "https://youtube.com/",
    },
    "artstation.com": {
      name: "Artstation",
      profileLinkTmpl: /(^(https?:\/\/)?(w{3}?\.)?artstation\.com\/([\w\d\-\_\.]+){3}$)/,
      placeholder: "Пример: https://artstation.com/ivanov",
      template: "https://artstation.com/",
    },
    "instagram.com": {
      name: "Instagram",
      profileLinkTmpl: /(^(https?:\/\/)?(w{3}?\.)?instagram\.com\/([\w\d\-\_\.]+){3}$)/,
      placeholder: "Пример: https://instagram.com/ivanov",
      template: "https://instagram.com/",
    },
  };

  return (
    <FieldArray name="socials">
      {({ form, ...arrayHelpers }) => {
        const linkNamesUnused = Object.keys(links).filter((link) => {
          const linkRx = new RegExp(
            link.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
          );
          return !form.values.socials.some(item => linkRx.test(item));
        });

        return (
          <SocialProfilesInputsComponent>
            {form.values.socials.length ? (
              form.values.socials.map((social, index) => {
                const linkName = Object.keys(links)
                  .filter((link) => {
                    const linkRx = new RegExp(
                      link.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
                    );

                    return linkRx.test(social);
                  })
                  .pop();

                const linkValue = links[linkName];
                const label = linkValue ? linkValue.name : "";
                const placeholder = linkValue ? linkValue.placeholder : "";
                const template = linkValue ? linkValue.template : "";

                const disabled = linkName
                  && ["vkontakte", "facebook"].includes(authProvider)
                  && ["vk.com", "facebook.com"].some((item) => {
                    const linkRx = new RegExp(
                      linkName.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
                    );

                    return linkRx.test(item);
                  });

                return (
                  <div key={index} className="input-wrapper">
                    <Input
                      name={`socials[${index}]`}
                      disabled={disabled}
                      label={label}
                      placeholder={placeholder}
                      onChange={(e) => {
                        let value = e.target.value || "";
                        const templateRx = new RegExp(
                          template.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
                        );

                        if (!templateRx.test(value)) {
                          value = template;
                        }

                        form.setFieldValue(`socials[${index}]`, value);
                      }}
                    />
                    {disabled ? (
                      <button
                        type="button"
                        className={cn("auth-social-show", {
                          shown: form.values.authSocialLinkShown,
                        })}
                        onClick={() => form.setFieldValue(
                          "authSocialLinkShown",
                          !form.values.authSocialLinkShown,
                        )}
                        title="Скрывать/показывать ссылку на профиль"
                      >
                        <FontAwesomeIcon
                          icon={
                            form.values.authSocialLinkShown ? faEye : faEyeSlash
                          }
                        />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="social-remove-item"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        -
                      </button>
                    )}
                  </div>
                );
              })
            ) : (
              <NoProfilesWarning>
                Ссылки на профайлы не указаны
              </NoProfilesWarning>
            )}
            {linkNamesUnused.length > 0 && (
              <Dropdown
                useValidationOnClick={false}
                onSelect={
                  ({ attrs: { linkTempl } }) => arrayHelpers.push(linkTempl)
                }
                label="Добавить новую ссылку"
              >
                {linkNamesUnused.map(link => (
                  <DropdownItem key={links[link].name} linkTempl={links[link].template}>
                    {links[link].name}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
          </SocialProfilesInputsComponent>
        );
      }}
    </FieldArray>
  );
}

const SocialProfilesInputsComponent = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  margin: -9px 0;

  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  select {
    margin-bottom: 15px;
  }

  button {
    position: relative;
    text-align: center;
    border: none 0 !important;
    outline: none 0 !important;
    font: 500 15px/20px Roboto;
    background: transparent;
    padding: 0;
    margin: 0;

    &.social-remove-item {
      position: absolute;
      left: 100%;
      margin-left: 10px;
      background: #3190ed;
      color: #fff;
      transition: 0.2s all;
      width: 20px;
      height: 20px;
      border-radius: 4px;

      &:hover {
        filter: contrast(130%);
      }
    }

    &.auth-social-show {
      position: absolute;
      left: 100%;
      margin-left: 10px;
      font-size: 18px;
      color: #a6a6a6;

      &.shown {
        color: #3190ed;
      }
    }
  }

  .input-component {
    margin: 9px 0;

    &.readonly {
      input {
        color: #94bff9;
      }
    }

    input {
      color: #2f80ed;
    }
  }
`;

const NoProfilesWarning = styled.div`
  font: 17px/1 Roboto;
  text-align: center;
  align-self: center;
  margin: 0 0 10px;
  color: #bfbfbf;
`;
