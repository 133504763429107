import React, { Component } from "react";
import { Picker } from "emoji-mart";

export default class Textarea extends Component {
  state = {
    rows: 1,
    showEmoji: false,
  }

  minRows = 1

  maxRows = 8

  emojiSettings = {
    showSkinTones: false,
    emojiTooltip: true,
    showPreview: false,
    native: true,
    exclude: ['search', 'recent', 'flags', 'symbols'],
    sheetSize: 32,
    perLine: 6,
    i18n: {
      categories: {
        smileys: 'Эмоции',
        people: 'Жесты и люди',
        nature: 'Животные и растения',
        foods: 'Еда и напитки',
        activity: 'Спорт',
        places: 'Путешествия и транспорт',
        objects: 'Предметы',
      },
    },
    onSelect: (e) => {
      const sym = e.unified.split('-');
      const codesArray = [];
      sym.forEach(el => codesArray.push(`0x${el}`));
      const emoji = String.fromCodePoint(...codesArray);
      this.setState({ showEmoji: false });
      this.props.onChange(this.props.value + emoji);
    },
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setInputAreaRows(nextProps.value);
    }
    return true;
  }

  setInputAreaRows = (inputValue) => {
    this.input.value = inputValue;

    this.input.rows = this.minRows;

    const currentRows = Math.floor(this.input.scrollHeight / 20); // 20 - textarea line height

    if (currentRows === this.state.rows) {
      this.input.rows = currentRows;
    }

    if (currentRows >= this.maxRows) {
      this.input.rows = this.maxRows;
      this.input.scrollTop = this.input.scrollHeight;
    }

    this.setState({
      rows: currentRows < this.maxRows ? currentRows : this.maxRows,
    });
  }

  forwardRef = (el) => {
    this.input = el;
    this.props.forwardRef(el);
  }

  handleKeyDown = (e) => {
    this.setState({ showEmoji: false });
    this.props.onKeyDown(e);
  }

  // close emoji picker window by clicking outside the picker
  handleDocumentClick = (e) => {
    if (!this.state.showEmoji) return;
    const emojiBtn = document.getElementsByClassName('emoji-button')[0];

    if (emojiBtn) {
      if (emojiBtn !== e.target && !emojiBtn.contains(e.target)) {
        this.setState({ showEmoji: false });
      }
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  handleChange = (e) => {
    e.preventDefault();
    this.props.onChange(e.target.value);
  }

  render() {
    const { disabled, onPaste, value } = this.props;
    return (
      <div className="chat_input_textarea">
        <textarea
          disabled={disabled}
          id="chat_input"
          ref={this.forwardRef}
          onChange={this.handleChange}
          onPaste={onPaste}
          onKeyDown={this.handleKeyDown}
          placeholder="Ваше сообщение"
          rows={this.state.rows}
          value={value}
        />

        <div className="emoji-button">
          <i className="fa fa-smile-o" aria-hidden="true" onClick={() => this.setState({ showEmoji: !this.state.showEmoji })} />
          { this.state.showEmoji && <Picker {...this.emojiSettings} />}
        </div>
      </div>
    );
  }
}
