import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import { closePassLessonTestModal, openLessonTestModal } from 'Core/actions';
import Modal from "Main/components/layouts/modals/Modal";

class PassLessonTestModal extends Component {
  closeModal = () => {
    this.props.closePassLessonTestModal();
  }

  startTest = () => {
    this.closeModal();
    this.props.openLessonTestModal({
      lessonId: this.props.passLessonTestModal.lessonId,
      reduxCatalogKey: this.props.passLessonTestModal.reduxCatalogKey,
    });
  }

  render() {
    const title = this.props.passLessonTestModal.lessonTitle;
    const lessonNumber = parseInt(title, 10);
    let lessonTitle = 'уроку';
    let testTitle = 'Начать тест';

    if (!Number.isNaN(lessonNumber)) {
      lessonTitle = `${lessonNumber} ${lessonTitle}`;
      testTitle = `Тест №${lessonNumber}`;
    }

    const text = `Чтобы загрузить домашнюю работу, пройдите тест по ${lessonTitle}`;

    return (
      <Modal showCross={false} showTimes closeModal={this.closeModal}>
        <div className="modal-content pass-lesson-test-modal">
          <img src="/images/lesson_test/course_cat.png" alt="" />
          <h5 className="lesson-test-modal-header">Пройдите тест по уроку</h5>
          <p className="m-auto">
            {text}
          </p>
          <button
            type="button"
            className="pixel-btn m-auto"
            onClick={this.startTest}
          >
            <i className="fa fa-file-text-o" />
            {testTitle}
          </button>
        </div>
      </Modal>
    );
  }
}

PassLessonTestModal.propTypes = {
  passLessonTestModal: object.isRequired,
  openLessonTestModal: func.isRequired,
  closePassLessonTestModal: func.isRequired,
};

const mapStateToProps = state => ({
  passLessonTestModal: state.local.passLessonTestModal,
});

export default connect(mapStateToProps, {
  closePassLessonTestModal,
  openLessonTestModal,
})(PassLessonTestModal);
