import { take, put, call, fork, select } from "redux-saga/effects";
import { watchEvents } from './helpers';

function* websocketSaga(faye, channel) {
  const channelAction = yield call(watchEvents, faye, channel);
  while (true) {
    const action = yield take(channelAction);
    yield put(action);
  }
}

export default function* websocketSagas() {
  const { faye } = window.CONF;

  try {
    const {
      local: {
        contestReducer: { id },
      },
    } = yield select();

    if (id) {
      yield fork(websocketSaga, faye, `/contest/${id}`);
    }
  } catch (e) {
    // do nothing
  }
}
