import React, { useState, useEffect } from "react";
import { array, bool, func, number } from "prop-types";
import { connect } from "react-redux";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { loadNotificationsCenterData } from "Core/actions";
import NotifyItem from "Main/components/NotificationCenter/NotifyItem";

function NotificationCenterHots({ loading, loaded, unreadCount, items, loadHotsNotifications }) {
  const [popupActive, setPopupActive] = useState(false);

  const handleAnyClick = () => {
    setPopupActive(false);
    document.removeEventListener("click", handleAnyClick);
  };

  useEffect(() => () => window.removeEventListener("click", handleAnyClick), []);

  const handleOnClick = (e) => {
    e.cancelBubble = true;

    if (!loaded) {
      const callback = () => {
        setPopupActive(true);
        document.addEventListener("click", handleAnyClick);
      };

      return loadHotsNotifications({ callback });
    }

    if (!popupActive) {
      setPopupActive(true);
      document.addEventListener("click", handleAnyClick);
    }
  };

  return (
    <div className="notification-hots">
      <div
        className={cn(
          'notification-hots__icon',
          { event: unreadCount > 0 && !popupActive },
          { pressed: popupActive },
        )}
        onClick={e => handleOnClick(e)}
      >
        <div className="wrapper">
          {loading === false ? (
            <FontAwesomeIcon icon={faBell} />
          ) : (
            <FontAwesomeIcon icon={faSpinner} pulse />
          )}
        </div>
        <div className={cn(
          'notification-hots__unreads',
          { shown: unreadCount > 0 && !popupActive },
        )}
        >
          {unreadCount}
        </div>
      </div>
      {loading === false && popupActive && (
        <div className="notification-hots__popout">
          {items.length ? (
            items.map(item => <NotifyItem key={item.id} {...item} />)
          ) : (
            <div className="notifications-empty">Нет новых уведомлений</div>
          )}
          <div className="notification-hots__footer">
            <a href="/notifications">Показать все уведомления</a>
          </div>
        </div>
      )}
    </div>
  );
}

NotificationCenterHots.propTypes = {
  loading: bool.isRequired,
  unreadCount: number,
  items: array,
  loadHotsNotifications: func.isRequired,
  loaded: bool.isRequired,
};

NotificationCenterHots.defaultProps = {
  unreadCount: 0,
  items: [],
};

const mapStateToProps = (state) => {
  const reduxNotifOptions = state.local.notificationCenterReducer;
  const { unread_notifications_number } = state.local.currentUser;

  return {
    ...reduxNotifOptions,
    unreadCount: unread_notifications_number,
  };
};

export default connect(mapStateToProps, {
  loadHotsNotifications: loadNotificationsCenterData,
})(NotificationCenterHots);
