import React, { useState } from "react";
import moment from "moment";
import Item from "./AdminSidebarItem";

export default function AdminSidebarCourses(props) {
  const { show_counters, flows, courses } = props;
  const [drag_item, setDragItem] = useState(null);
  const [drag_over, setDragOver] = useState(null);

  const changeCoursesOrder = (course1_id, course2_id) => {
    const course1_idx = courses.findIndex(course => course.id === course1_id);
    const course2_idx = courses.findIndex(course => course.id === course2_id);

    const ids = courses.map(c => c.id);
    const temp = ids[course1_idx];
    ids[course1_idx] = ids[course2_idx];
    ids[course2_idx] = temp;

    props.setCoursesOrder(ids);
  };

  const handleDragStart = course_id => setDragItem(course_id);

  const handleDragOver = (course_id) => {
    if (drag_over !== course_id) {
      setDragOver(course_id);

      if (drag_item !== course_id) {
        changeCoursesOrder(drag_item, course_id);
      }
    }
  };

  const handleDragEnd = () => {
    setDragItem(null);
    setDragOver(null);
  };

  return courses.map((course) => {
    const course_flows = flows
      .filter(flow => flow.course_id === course.id && flow.free === false)
      .sort((a, b) => b.date.localeCompare(a.date));
    const idle_flows_counter = course_flows
      .slice(4)
      .reduce((acc, flow) => acc + flow.unread_chats_count, 0);

    const dragProps = {
      draggable: true,
      onDragStart: () => handleDragStart(course.id),
      onDragEnd: () => handleDragEnd(course.id),
      onDragOver: () => handleDragOver(course.id),
    };

    return (
      <Item
        key={course.id}
        text={course.title}
        badge={course.unanswered_chats_count}
        {...dragProps}
      >
        <Item
          text="Все"
          badge={show_counters ? course.chats_count : null}
          {...props.contextProps({ course: course.id })}
        />
        <Item
          text="Непрочитаные"
          badge={course.unread_chats_count}
          {...props.contextProps({ course: course.id, unread: true })}
        />
        <Item
          text="Неотвеченные"
          badge={course.unanswered_chats_count}
          {...props.contextProps({ course: course.id, unanswered: true })}
        />
        <Item
          text="Бесплатный поток"
          badge={null}
          {...props.contextProps({ course: course.id, free_flow: true })}
        />
        {course_flows.slice(0, 4).map(flow => (
          <Item
            key={flow.id}
            text={moment(flow.date).format("Поток с D MMMM")}
            title={moment(flow.date).format("Поток с D MMMM YYYY")}
            badge={
              show_counters
                ? `${course.unread_chats_count}/${course.chats_count}`
                : null
            }
            {...props.contextProps({ course: course.id, flow: flow.id })}
          />
        ))}
        <Item
          text="Архив"
          badge={idle_flows_counter}
          {...props.contextProps({ course: course.id, idle: true })}
        />
      </Item>
    );
  });
}
