import React from "react";
import { array, func, number, oneOfType, string } from "prop-types";

import Dropdown, { DropdownItem } from "Main/components/ui/Dropdown";

// filters --> [['all', 'Все'], ...]
const CatalogDropdownFilters = ({ filters, onSwitch, activeFilter }) => {
  const onSwitchHandle = (index) => {
    const [nextFilter] = filters[index];

    if (activeFilter === nextFilter) return;

    onSwitch(nextFilter);
  };

  return (
    <Dropdown onSelect={({ index }) => onSwitchHandle(index)}>
      {filters.map(([filterKey, filterValue]) => (
        <DropdownItem key={filterKey} selected={activeFilter === filterKey}>
          {filterValue}
        </DropdownItem>
      ))}
    </Dropdown>
  );
};

CatalogDropdownFilters.propTypes = {
  activeFilter: oneOfType([string, number]).isRequired,
  onSwitch: func.isRequired,
  filters: array.isRequired,
};

export default CatalogDropdownFilters;
