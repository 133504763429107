import React from "react";
import { func, bool, object } from "prop-types";

import { message as MessageType, homework as HomeworkType } from "Main/types";
import SyntaxHighlighter from "Main/components/layouts/SyntaxHighlighter";
import Homework from "Main/components/Homework/Homework";
import MessageWithBreaks from "Main/components/Chat/MessageWithBreaks";
import { QuickHomeworkAssessment } from "Main/components/Chat/QuickHomeworkAssessment";
import MessageFileAttachment from "Main/components/Chat/Message/MessageFileAttachment";

export default function MessageContent(props) {
  const { message, homework, is_teacher,
    onUpdateReview, isCodeSnippet, patterMessageWithCode } = props;

  if (isCodeSnippet) {
    const codeMessageParts = message.text.match(patterMessageWithCode);
    const [, textBefore, , language, snippet, , textAfter] = codeMessageParts;

    return (
      <div>
        {textBefore && (
          <div className="message_text">
            <MessageWithBreaks>{textBefore}</MessageWithBreaks>
          </div>
        )}

        <SyntaxHighlighter
          code={snippet}
          language={language}
          elementClass="message_text_code"
        />

        {textAfter && (
          <div className="message_text">
            <MessageWithBreaks>{textAfter}</MessageWithBreaks>
          </div>
        )}

        <MessageFileAttachment {...props} />
      </div>
    );
  }

  if (!message.homework_id) {
    return (
      <div>
        {message.text && (
          <div className="message_text">
            <MessageWithBreaks>{message.text}</MessageWithBreaks>
          </div>
        )}

        <MessageFileAttachment {...props} />
      </div>
    );
  }

  let captionElement;

  if (homework.lesson && homework.exercise) {
    captionElement = (
      <div className="message_homework_caption">
        Домашнее задание
        &laquo;
        {homework.exercise.title}
        &raquo;,
        {' '}
        {homework.lesson.title}
      </div>
    );
  }

  return (
    <div className="message_homework" id={`homework-${homework.id}`}>
      {captionElement}

      <Homework
        id={homework.id}
        status={homework.status}
        image={homework.image}
        type={homework.type}
        text={homework.text}
        reduxCatalogKey="chatHomeworks"
      />

      <QuickHomeworkAssessment
        homework={homework}
        is_teacher={is_teacher}
        onUpdateReview={onUpdateReview}
      />
    </div>
  );
}

MessageContent.propTypes = {
  message: MessageType,
  homework: HomeworkType,
  onUpdateReview: func.isRequired,
  is_teacher: bool,
  isCodeSnippet: bool.isRequired,
  patterMessageWithCode: object.isRequired,
};

MessageContent.defaultProps = {
  homework: null,
  message: null,
  is_teacher: false,
};
