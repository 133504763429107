/* eslint-disable no-constant-condition */
import { take, put, call, fork, all, select } from "redux-saga/effects";
import { loadCatalogItems, toggleLikeCatalogItem } from "../services/api";
import {
  CATALOG_PREPARE, CATALOG_ITEMS, CATALOG_LIKE_TOGGLE,
  REQUEST, SUCCESS, FAILURE,
  loadCatalogItems as loadCatalogItemsAction,
} from "../actions";

function* watchCatalogPrepare() {
  while (true) {
    const { payload } = yield take(CATALOG_PREPARE);
    const catKey = payload.reduxCatalogKey;
    const pagination = yield select(({ entities: { catalog } }) => catalog[catKey].pagination);

    yield put(loadCatalogItemsAction({
      ...pagination,
      ...payload,
    }));
  }
}

function* watchLoadCatalogItems() {
  while (true) {
    const { payload } = yield take(CATALOG_ITEMS[REQUEST]);

    const { response, error } = yield call(loadCatalogItems, payload);

    if (response) {
      yield put({
        type: CATALOG_ITEMS[SUCCESS],
        payload: { ...payload, response },
      });
    } else {
      yield put({
        type: CATALOG_ITEMS[FAILURE],
        error,
        payload,
      });
      console.error(error);
    }
  }
}

function* watchToggleLikeCatalogItem() {
  while (true) {
    const { payload } = yield take(CATALOG_LIKE_TOGGLE[REQUEST]);

    const { response, error } = yield call(toggleLikeCatalogItem, payload);

    if (response) {
      yield put({
        type: CATALOG_LIKE_TOGGLE[SUCCESS],
        payload: { ...payload, response },
      });
    } else {
      yield put({
        type: CATALOG_LIKE_TOGGLE[FAILURE],
        error,
        payload,
      });
      console.error(error);
    }
  }
}

export default function* root() {
  yield all([
    fork(watchCatalogPrepare),
    fork(watchLoadCatalogItems),
    fork(watchToggleLikeCatalogItem),
  ]);
}
