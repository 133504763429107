import React from "react";
import { Provider } from "react-redux";

import initializeStore from "Core/store/initializeStore";
import TeacherHomeworksCatalog from "./TeacherHomeworksCatalog";

const getStore = initializeStore();

const TeacherHomeworksCatalogRoot = props => (
  <Provider store={getStore()}>
    <TeacherHomeworksCatalog {...props} />
  </Provider>
);

export default TeacherHomeworksCatalogRoot;
