import React from "react";
import PropTypes from "prop-types";

export default function List(props) {
  const { hasMorePages, items, renderItem, isFetching, onLoadMore } = props;

  return (
    <div id="chats">
      {items.map(renderItem)}
      {hasMorePages && (
        <button
          type="button"
          onClick={onLoadMore}
          disabled={isFetching}
          className="chats_load_more"
        >
          {isFetching ? "Загружаем..." : "Загрузить еще"}
        </button>
      )}
    </div>
  );
}

List.propTypes = {
  hasMorePages: PropTypes.bool.isRequired,
  renderItem: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  isFetching: PropTypes.bool,
  onLoadMore: PropTypes.func.isRequired,
};

List.defaultProps = {
  isFetching: false,
};
