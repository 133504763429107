import React, { useRef } from 'react';
import { bool, func, object } from 'prop-types';
import cn from 'classnames';

const textTypes = {
  image: "Загрузить картинку",
  video: "Загрузить видео",
  gif: "Загрузить GIF",
  text: "Загрузить код",
};

const LoadWorkArea = ({
  exercise,
  isLessonStarted,
  handleLoadWorkClick,
  handleLoadWork,
}) => {
  const inputRef = useRef();

  return (
    <div
      className={cn("card_with_border homework_not_exists", {
        "-not-started": !isLessonStarted,
      })}
    >
      <div className="-title">
        {exercise.title}
        <i
          className="fa fa-question-circle-o -title_tooltip"
          data-toggle="popover"
          data-content={exercise.description}
        />
      </div>

      <div
        className={cn("hidden_upload_label", { disabled: !isLessonStarted })}
        onClick={() => handleLoadWorkClick(inputRef.current, exercise)}
      >
        {isLessonStarted && textTypes[exercise.type]}

        <input
          type="file"
          style={{ display: 'none' }}
          ref={inputRef}
          onChange={e => handleLoadWork(e.target.files[0])}
          disabled={!isLessonStarted}
        />
      </div>
    </div>
  );
};

LoadWorkArea.propTypes = {
  exercise: object.isRequired,
  isLessonStarted: bool.isRequired,
  handleLoadWorkClick: func.isRequired,
  handleLoadWork: func.isRequired,
};

export default LoadWorkArea;
