import { humps } from 'Core/services/tools';
import {
  CONTEST_LOAD_DONE, CONTEST_WORK_ADD_IN_PROGRESS,
  CONTEST_WORK_REMOVE_IN_PROGRESS, CONTEST_WORK_PRIZE_PLACE_SELECT_DONE,
} from "../../actions";

const defaultState = {
  loaded: false,
};

const handlers = {
  // конкурс закончил загрузку
  [CONTEST_LOAD_DONE]: (state, action) => {
    const { winners, ...newState } = humps(action.response);

    return {
      ...state,
      ...newState,
      loaded: true,
    };
  },

  [CONTEST_WORK_ADD_IN_PROGRESS]: state => ({
    ...state,
    contestWorkAddInProgress: true,
  }),

  [CONTEST_WORK_REMOVE_IN_PROGRESS]: state => ({
    ...state,
    contestWorkAddInProgress: false,
    firstUpload: false,
  }),

  [CONTEST_WORK_PRIZE_PLACE_SELECT_DONE]: (state, action) => {
    const contest = humps(action.response.contest);

    return {
      ...state,
      ...contest,
    };
  },

  default: state => state,
};

const contestReducer = (state = defaultState, action) => {
  const calcState = handlers[action.type] || handlers.default;

  return calcState(state, action);
};

export default contestReducer;
