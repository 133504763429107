import React from "react";
import { string } from "prop-types";
import Linkify from "react-linkify";

export default function MessageWithBreaks(props) {
  const { children: text } = props;

  return (
    <div>
      {text.split("\n").map((s, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>
          <Linkify properties={{ target: "_blank", rel: "nofollow   noopener" }}>
            {s}
          </Linkify>
        </div>
      ))}
    </div>
  );
}

MessageWithBreaks.propTypes = {
  children: string,
};

MessageWithBreaks.defaultProps = {
  children: "",
};
