import React, { Component } from "react";
import { func, bool, object } from "prop-types";
import { connect } from "react-redux";
import sortBy from "lodash/sortBy";
import findIndex from "lodash/findIndex";
import { onListContext } from "Core/actions";
import { AdminSidebar } from "Main/components/Chats/AdminSidebar/";

class AdminSidebarContainer extends Component {
  state = {
    order: null,
  };

  componentDidMount() {
    const storedCourses = localStorage.getItem("public_courses");

    if (storedCourses) {
      this.setState({
        order: JSON.parse(storedCourses),
      });
    }
  }

  setCoursesOrder = (order) => {
    this.setState({ order });
    localStorage.setItem("public_courses", JSON.stringify(order));
  };

  render() {
    const {
      courses,
      flows,
      context,
      total,
      unread,
      is_admin,
      unanswered,
    } = this.props;

    let public_courses = courses.filter(course => !course.hide_in_public);
    if (this.state.order) {
      public_courses = sortBy(public_courses, x => findIndex(this.state.order, y => x.id === y),
      );
    }

    return (
      <AdminSidebar
        context={context}
        setContext={this.props.onListContext}
        setCoursesOrder={this.setCoursesOrder}
        courses={public_courses}
        flows={flows}
        total={total}
        unread={unread}
        show_counters={is_admin}
        unanswered={unanswered}
      />
    );
  }
}

AdminSidebarContainer.propTypes = {
  onListContext: func.isRequired,
  is_admin: bool.isRequired,
  context: object.isRequired,
};

function mapStateToProps(state) {
  const {
    entities: { flows, courses },
    local: {
      is_admin,
      chats: { context },
    },
    meta: { chatsCounters },
  } = state;

  return {
    context,
    is_admin,
    courses: Object.values(courses),
    flows: Object.values(flows),
    total: chatsCounters.total,
    unread: chatsCounters.unread,
    unanswered: chatsCounters.unanswered,
  };
}

export default connect(mapStateToProps, {
  onListContext,
})(AdminSidebarContainer);
