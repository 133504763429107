/* eslint-disable no-constant-condition */
import { take, put, call, fork, all } from "redux-saga/effects";
import isString from "lodash/isString";
import assign from "lodash/assign";
import { api } from "../services";
import * as actions from "../actions";

function* watchGetTimeleftConfirmValue() {
  while (true) {
    const { login, resolve } = yield take(actions.GET_TIMELEFT_CONFIRM_VALUE);
    const { response } = yield call(api.getTimeleftConfirmValue, {
      user: { login },
    });

    if (response && "timeleft" in response) {
      resolve(response.timeleft);
    } else {
      resolve(0);
    }
  }
}

function* watchUserAuthorize() {
  while (true) {
    const { login, password, smscode, resolve, reject } = yield take(
      actions.AUTHORIZE[actions.REQUEST],
    );
    const user = { login };

    if (isString(password)) {
      assign(user, { password });
    }

    if (isString(smscode)) {
      assign(user, { smscode });
    }

    const { response, error } = yield call(api.userAuthorize, { user });

    if (error) {
      reject({ error });
      yield put({
        type: actions.AUTHORIZE[actions.FAILURE],
        exception: { error },
      });
    } else if ("error" in response) {
      reject(response);
      yield put({
        type: actions.AUTHORIZE[actions.FAILURE],
        exception: response,
      });
    } else {
      resolve(response);
      yield put({
        type: actions.AUTHORIZE[actions.SUCCESS],
        response,
      });
    }
  }
}

function* watchChangeUserPasswd() {
  while (true) {
    const {
      login,
      password_old,
      password_new,
      password_new_confirm,
      smscode,
      resolve,
      reject,
    } = yield take(actions.CHANGE_USER_PASSWD[actions.REQUEST]);

    const user = {
      login,
      password_old,
      password_new,
      password_new_confirm,
      smscode,
    };

    if (!password_old) {
      assign(user, { password_old: "123456" });
    }

    const { response, error } = yield call(api.userPasswordChange, { user });

    if (error) {
      reject({ error });
      yield put({
        type: actions.CHANGE_USER_PASSWD[actions.FAILURE],
        exception: { error },
      });
    } else if ("error" in response) {
      reject(response);
      yield put({
        type: actions.CHANGE_USER_PASSWD[actions.FAILURE],
        exception: response,
      });
    } else {
      resolve(response);
      yield put({
        type: actions.CHANGE_USER_PASSWD[actions.SUCCESS],
        response,
      });
    }
  }
}

export default function* root() {
  yield all([
    fork(watchUserAuthorize),
    fork(watchChangeUserPasswd),
    fork(watchGetTimeleftConfirmValue),
  ]);
}
