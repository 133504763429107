import React, { Component } from 'react';
import { connect } from "react-redux";
import { func, object, array, string, number, bool } from 'prop-types';
import get from 'lodash/get';

import { getStudentWorkCoversFields } from "Core/reducers/selectors";

import { StudentWork } from "../components/StudentWork";
import Spinner from '../components/layouts/Spinner';
import CatalogFilters from '../components/Catalog/CatalogFilters';
import ListWithInfinityScroll from '../components/layouts/ListWithInfinityScroll';

import { loadCatalogItems, toggleLikeCatalogItem, prepareCatalog } from '../../core/actions';

const entity = 'homeworks';

class MyFlowWorksCatalog extends Component {
  state = { activeLessonId: 'all' }

  componentDidMount() {
    this.filters = this.getFilters();

    const { pagination, reduxCatalogKey } = this.props;

    if (pagination.firstItemsLoaded) return;

    this.props.prepareCatalog({ entity, reduxCatalogKey, customParams: this.getCustomParams() });
  }

  getFilters = () => {
    let lessonNumber = 0;

    return [['all', 'Все уроки']].concat(this.props.lessons.map(([id, title]) => {
      const filter = title || '';

      if (filter.match(/^[0-9]/)) {
        lessonNumber = parseInt(title, 10);

        return [id, lessonNumber];
      }

      if (filter.match(/^[а-яА-ЯA-Za-z]/)) {
        return [id, filter];
      }

      lessonNumber += 1;

      return [id, lessonNumber];
    }));
  }

  getCustomParams = (lessonId = null) => ({
    myFlowWorks: 1,
    isDemoLessons: this.props.isDemoLessons,
    courseId: this.props.courseId,
    flowId: this.props.flowId,
    activeLessonId: lessonId || this.state.activeLessonId,
  });

  handleLoadItems = (options) => {
    const { pagination, reduxCatalogKey } = this.props;
    const { customParams = null, ...nextOptions } = options;

    this.props.loadItems({
      ...pagination,
      page: pagination.page,
      entity,
      reduxCatalogKey,
      customParams: customParams || this.getCustomParams(),
      ...nextOptions,
    });
  }

  onLoadMoreItems = () => {
    const { page } = this.props.pagination;
    this.handleLoadItems({ page: page + 1 });
  }

  onChangeFilter = (lessonId) => {
    this.handleLoadItems({ page: 1, customParams: this.getCustomParams(lessonId) });
    this.setState({ activeLessonId: lessonId });
  }

  onChangeSortType = sortType => this.handleLoadItems({ sortType, page: 1 });

  handleToggleLike = (itemId) => {
    this.props.toggleLike({ itemId, entity });
  }

  renderContent = () => {
    const { items, pagination, reduxCatalogKey } = this.props;

    const compItems = items.map((item, index) => (
      <div key={item.id} className="catalog-item cell cell_33">
        <StudentWork
          onToggleLike={this.handleToggleLike}
          reduxCatalogKey={reduxCatalogKey}
          indexOrder={index}
          {...item}
        />
      </div>
    ));

    return (
      <div className="catalog__items ctg-content my-3">
        <ListWithInfinityScroll
          items={compItems}
          loading={pagination.loading}
          error={pagination.error}
          spinner={<Spinner className="mt-3" />}
          hasMore={pagination.hasMore}
          loadMore={this.onLoadMoreItems}
          placeholder="small"
          noItemsText={pagination.responseMessage}
        />
      </div>
    );
  }

  render() {
    const { pagination: { firstItemsLoaded } } = this.props;

    if (!firstItemsLoaded) return <Spinner className="p-5" />;

    return (
      <div className="catalog">
        <div className="cell pix-h2 mt-5">
          Работы учеников
        </div>

        <CatalogFilters
          filters={this.filters || []}
          activeFilter={this.state.activeLessonId}
          onSwitch={this.onChangeFilter}
        />

        {this.renderContent()}
      </div>
    );
  }
}

MyFlowWorksCatalog.propTypes = {
  // OWN props
  flowId: number,
  courseId: number,
  lessons: array.isRequired,
  isDemoLessons: bool,
  // MAP STATE PROPS
  pagination: object.isRequired,
  items: array,
  toggleLike: func.isRequired,
  loadItems: func.isRequired,
  prepareCatalog: func.isRequired,
  reduxCatalogKey: string.isRequired,
};

MyFlowWorksCatalog.defaultProps = {
  items: [],
  isDemoLessons: false,
  flowId: 0,
  courseId: null,
};

const mapStateToProps = (state, ownProps) => {
  const reduxCatalogKey = `myFlow${ownProps.flowId}Works`;

  const defaultState = { pagination: {}, items: [] };
  const { pagination, items } = get(state.entities.catalog, reduxCatalogKey, defaultState);

  return {
    pagination,
    reduxCatalogKey,
    items: getStudentWorkCoversFields(state, items),
  };
};

export default connect(mapStateToProps, {
  loadItems: loadCatalogItems,
  toggleLike: toggleLikeCatalogItem,
  prepareCatalog,
})(MyFlowWorksCatalog);
