import React, { Component } from "react";
import { func, bool } from "prop-types";
import { connect } from "react-redux";
import { openImageModal, updateReview, updateMessage } from "Core/actions";
import { Message } from "Main/components/Chat";
import moment from "moment";
import DateLine from "Main/components/Chat/DateLine";

import {
  chat as ChatType,
  message as MessageType,
  homework as HomeworkType,
  user as UserType,
  course as CourseType,
} from "Main/types";

class MessageContainer extends Component {
  handleUpdateReview = (status) => {
    const {
      chat: { id: chat_id },
      homework: { id: homework_id },
    } = this.props;
    this.props.updateReview(homework_id, { status, chat_id });
  }

  formatMessageTextToCode = () => {
    const { message, chat } = this.props;
    const newMessageText = `\`\`\`\n${message.text}\n\`\`\``;

    this.props.updateMessage({
      message_id: message.id,
      message: newMessageText,
      chat_id: chat.id,
      channel: chat.channel,
    });
  }

  renderDateLine = () => {
    const { prev_message, message } = this.props;
    const time = moment.unix(message.time);
    if (prev_message != null) {
      const prevTime = moment.unix(prev_message.time);
      const isNewDay = time.isAfter(prevTime, "day");
      if (isNewDay) {
        return <DateLine time={time} />;
      }
    } else {
      return null;
    }
  }

  render() {
    const {
      user,
      message,
      homework,
      course,
      is_teacher_message,
      is_teacher,
    } = this.props;

    return (
      <div>
        {this.renderDateLine()}
        <Message
          key={message.id}
          message={message}
          user={user}
          homework={homework}
          course={course}
          is_teacher_message={is_teacher_message}
          is_teacher={is_teacher}
          openImageModal={this.props.openImageModal}
          onDeleteMessage={this.props.onDeleteMessage}
          onEditingMessage={this.props.onEditingMessage}
          formatMessageTextToCode={this.formatMessageTextToCode}
          message_id={this.props.message_id}
          me={this.props.me}
          onUpdateReview={this.handleUpdateReview}
        />
      </div>
    );
  }
}

MessageContainer.propTypes = {
  chat: ChatType.isRequired,
  user: UserType.isRequired,
  message: MessageType.isRequired,
  prev_message: MessageType,
  homework: HomeworkType,
  course: CourseType.isRequired,
  is_teacher_message: bool,
  is_teacher: bool,

  openImageModal: func.isRequired,

  onEditingMessage: func.isRequired,
  onDeleteMessage: func.isRequired,
  updateReview: func.isRequired,
  updateMessage: func.isRequired,
};

MessageContainer.defaultProps = {
  prev_message: null,
  homework: HomeworkType.defaults,
  is_teacher_message: false,
  is_teacher: false,
};

const mapStateToProps = (state, { message_id, prev_message_id }) => {
  const {
    entities: {
      users,
      messages,
      homeworks,
      lessons,
      exercises,
      chats,
      courses,
    },
    local: { me, is_teacher },
  } = state;
  const message = messages[message_id];
  const prev_message = messages[prev_message_id];
  const user = users[message.user];
  const chat = chats[message.chat_id];
  const course = courses[chat.course];

  const homework = homeworks[message.homework_id];
  if (homework) {
    homework.lesson = lessons[homework.lesson_id];
    homework.exercise = exercises[homework.exercise_id];
  }

  return {
    me,
    chat,
    user,
    message,
    homework,
    course,
    prev_message,
    is_teacher_message: message.user !== chat.user,
    is_teacher: !!is_teacher,
  };
};

export default connect(mapStateToProps, {
  openImageModal,
  updateReview,
  updateMessage,
})(MessageContainer);
