import { subscribeToNotifications } from './api.js';

export const enableServiceWorker = () => {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.register('/serviceworker.js')
      .then((reg) => {
        console.log('Service worker change, registered the service worker');
      })
      .catch(e => console.log('SERVICE WORKER error:', e));
  }
  // Otherwise, no push notifications :(
  else {
    console.error('Service worker is not supported in this browser');
  }
};

export const enableNotifications = () => {
  if (!('Notification' in window)) {
    console.error('This browser does not support desktop notification');
    return false;
  } if (Notification.permission === 'granted') {
    // Let's check whether notification permissions have already been granted
    console.log('Permission to receive notifications has been granted');
    return true;
  } if (Notification.permission !== 'denied') {
    // Otherwise, we need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        console.log('Permission to receive notifications has been granted');
        return true;
      }
      return false;
    })
      .catch(e => console.log('SERVICE WORKER error:', e));
  }
};

export const enableSubscription = () => {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.ready
      .then((serviceWorkerRegistration) => {
        console.log('Service worker change, registered the service worker');
        console.log('Service worker registration succeeded:', serviceWorkerRegistration);
        serviceWorkerRegistration.pushManager
          .getSubscription()
          .then((subscription) => {
            console.info('HELLO SUBSCRIPTION FIRST', subscription);
            // If a subscription was found, return it.
            if (subscription) {
              return subscription;
            }

            // Otherwise, subscribe the user (userVisibleOnly allows to specify
            // that we don't plan to send notifications that don't have a
            // visible effect for the user).
            return serviceWorkerRegistration.pushManager.subscribe({
              userVisibleOnly: true,
            });
          })
          .then((subscription) => {
            console.info('HELLO SUBSCRIPTION', subscription);
            const subs = subscription.toJSON();
            subscribeToNotifications({
              subscription: {
                endpoint: subs.endpoint,
                p256dh: subs.keys.p256dh,
                auth: subs.keys.auth,
              },
            });
          })
          .catch((error) => {
            // registration failed
            console.log(`Registration failed with ${error}`);
          });
      })
      .catch(e => console.log('SERVICE WORKER error:', e));
  } else { // Otherwise, no push notifications :(
    console.error('Service worker is not supported in this browser');
  }
};

/* if (navigator.serviceWorker) {
  navigator.serviceWorker.ready
    .then((serviceWorkerRegistration) => {
      console.log('Service worker is ready:', serviceWorkerRegistration);
      serviceWorkerRegistration.pushManager
        .getSubscription()
        .then((subscription) => {
          console.info('HELLO SUBSCRIPTION FIRST', subscription);
          // If a subscription was found, return it.
          if (subscription) {
            return subscription;
          }

          // Otherwise, subscribe the user (userVisibleOnly allows to specify
          // that we don't plan to send notifications that don't have a
          // visible effect for the user).
          return serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
          });
        })
        .then((subscription) => {
          console.info('HELLO SUBSCRIPTION', subscription);
          const subs = subscription.toJSON();
          subscribeToNotifications({
            subscription: {
              endpoint: subs.endpoint,
              p256dh: subs.keys.p256dh,
              auth: subs.keys.auth,
            },
          });
        })
        .catch((error) => {
          // registration failed
          console.log(`Registration failed with ${error}`);
        });
    })
    .catch(e => console.log('SERVICE WORKER error:', e));
} else { // Otherwise, no push notifications :(
  console.error('Service worker is not supported in this browser');
} */
