/* eslint-disable react/no-danger */
import React from 'react';
import { array, string } from 'prop-types';

const CoursePracticeServer = ({ descr1, descr2, workExamples }) => (
  <section id="course-practice">
    <div className="wide-block-wrapper">
      <div className="wrapper">
        <div className="course-page-title">
          Самая важная часть обучения — практика и обратная связь
        </div>

        {descr1 && (
          <div className="practice-descr" dangerouslySetInnerHTML={{ __html: descr1 }} />
        )}

        {workExamples.length > 0 && (
          <>
            <div className="work-examples">
              <div className="ui carousel no-pagination">
                <div className="carousel-wrapper">
                  <div className="swiper-button-prev" />
                  <div className="swiper-button-next" />
                </div>
              </div>

            </div>
            <div className="d-flex flex-column">
              <a
                className="ui button practice-see"
                href={workExamples[0].article_link}
                target="_blank"
                rel="noreferrer"
              >
                Посмотреть процесс доработки
              </a>
            </div>
          </>
        )}

        {descr2 && (
          <>
            <hr />
            <div className="row align-items-center no-gutters mb-n3">
              <div className="col" dangerouslySetInnerHTML={{ __html: descr2 }} />
              <div className="col-auto mr-n5 ml-4 mb-5 mt-n4">
                <img data-src="/images/course_cat.png" alt="" className="lozad" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  </section>
);

CoursePracticeServer.propTypes = {
  descr1: string,
  descr2: string,
  workExamples: array.isRequired,
};

CoursePracticeServer.defaultProps = {
  descr1: '',
  descr2: '',
};

export default CoursePracticeServer;
