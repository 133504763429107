import moment from 'moment';

export const groupWith = (list, fn) => {
  const res = [];
  let idx = 0;
  const { length } = list;

  while (idx < length) {
    let nextidx = idx + 1;
    while (nextidx < length && fn(list[nextidx - 1], list[nextidx])) {
      nextidx += 1;
    }
    res.push(list.map(el => el.id).slice(idx, nextidx));
    idx = nextidx;
  }

  return res;
};

export const getOffset = (el) => {
  const rect = el.getBoundingClientRect();

  const obj = {
    top: rect.top + document.body.scrollTop,
    left: rect.left + document.body.scrollLeft,
  };

  return obj;
};

export const getIconInfoByHomeworkStatus = (status) => {
  switch (status) {
    case 'unverified': return { className: 'fa-clock-o', text: 'Проверяется' };
    case 'rejected': return { className: 'fa-pencil', text: 'Нужно доработать' };
    case 'accepted': return { className: 'fa-check', text: 'Принято' };
    case 'waiting_next_stage': return { className: 'fa-flag', text: 'Ждем следующий этап' };
    case 'processing': return { className: 'fa-cog', text: 'Обрабатывается' };
    case 'error_processing': return { className: 'fa-times', text: 'Ошибка обработки' };
    default: return {};
  }
};

export const formatTimeForWorkThumb = (seconds) => {
  if (!seconds) return null;

  const time = seconds * 1000;
  const day = moment(time).calendar().slice(0, 5);
  const year = moment(time).format('YYYY');

  if (day === 'Сегод') {
    return 'сегодня';
  } if (day === 'Вчера') {
    return 'вчера';
  } if (year === moment().format('YYYY')) {
    return moment(time).format('D MMMM');
  }

  return moment(time).format('D MMMM Y');
};
