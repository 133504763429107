import React, { useState, useEffect } from "react";
import styled from "styled-components";
import map from "lodash/map";
import cn from "classnames";

export default function TabsSwitcher(props) {
  const { items = {}, defaultActive, onSwitch = () => {} } = props;
  const [currItem, setCurrItem] = useState();

  useEffect(() => {
    if (defaultActive) {
      setCurrItem(defaultActive);
    }
  }, [defaultActive]);

  const handleSwitchTab = idx => setCurrItem(idx) & onSwitch(idx);

  return (
    <Tabs className="tabs-switcher-component">
      {map(items, (item, idx) => (
        <Tab
          className={cn({ active: currItem === idx })}
          key={`tab-${idx}`}
          onClick={() => handleSwitchTab(idx)}
        >
          {item}
        </Tab>
      ))}
    </Tabs>
  );
}

const Tab = styled.div`
  margin-right: 10px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 19px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
  color: #2f80ed;
  font-weight: 500;
  cursor: pointer;
  transition: 0.2s all;

  &:hover,
  &.active {
    border: 1px solid #2196f3;
    background: #2196f3;
    color: white;
  }

  &:last-child {
    margin: 0;
  }
`;

const Tabs = styled.div`
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  user-select: none;
`;
