import React, { Component } from "react";
import { arrayOf, func, bool, number, object } from "prop-types";
import { connect } from "react-redux";
import sortBy from "lodash/sortBy";
import { onListContext } from "Core/actions";
import { selectors } from "Core/reducers";
import List from "Main/components/Chats/List";
import Loading from "Main/components/layouts/Loading";
import SomethingWentWrong from "Main/components/layouts/SomethingWentWrong";
import { chat as ChatType } from "Main/types";
import AdminSidebar from "./AdminSidebar";
import ChatsItem from "./ChatsItem";
import MassSender from "./MassSender";

class ChatList extends Component {
  state = {
    sender_open: false,
  };

  constructor(props) {
    super(props);
    this.props.onListContext(this.props.list_context);
  }

  handleSearchChange = (e) => {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    const search = e.target.value;
    this.searchTimeout = setTimeout(() => {
      this.props.onListContext({ search, page: 0 });
    }, 500);
  };

  handleLoadMore = () => {
    this.props.onListContext({
      ...this.props.list_context,
      page: (this.props.list_context.page || 0) + 1,
    });
  };

  handleCloseSender = () => {
    this.setState({
      sender_open: false,
    });
  };

  renderChatItem = ({ id: chat_id }) => <ChatsItem key={chat_id} chat_id={chat_id} />;

  renderControls() {
    const {
      total,
      is_admin,
      list_context: { course, flow },
    } = this.props;
    const totalCount = total || 0;
    return (
      <div className="admin_chats_layout_controls">
        <div className="admin_chats_controls">
          <div className="">{is_admin ? `Сообщений ${totalCount}` : null}</div>
          <div className="admin_chats_controls_search">
            <span>
              <i className="fa fa-search" />
            </span>
            <input
              onChange={this.handleSearchChange}
              placeholder="Поиск по имени или фамилии"
              className="admin_chats_controls_input"
            />
          </div>

          <button
            type="button"
            className={`icon-btn ${!(course && flow) ? "-disabled" : ""}`}
            onClick={() => this.setState({ sender_open: true })}
          >
            <i className="fa fa-envelope" />
          </button>
        </div>
      </div>
    );
  }

  renderChatList() {
    const { chats, isFetching, error, total } = this.props;

    if (isFetching && !chats.length) {
      return <Loading />;
    } if (error) {
      return <SomethingWentWrong />;
    }
    return (
      <List
        items={chats}
        isFetching={isFetching}
        hasMorePages={total > chats.length}
        renderItem={this.renderChatItem}
        onLoadMore={this.handleLoadMore}
      />
    );
  }

  renderAdmin() {
    const {
      list_context: { course, flow },
    } = this.props;
    return (
      <div>
        {course && flow && this.state.sender_open && (
          <MassSender
            course_id={course}
            flow_id={flow}
            onClose={this.handleCloseSender}
          />
        )}
        <div className="admin_chats_layout">
          <AdminSidebar />
          <div className="admin_chats_layout_content">
            {this.renderControls()}
            <div className="admin_chats_layout_list">
              {this.renderChatList()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderUser() {
    return <div>{this.renderChatList()}</div>;
  }

  render() {
    return this.props.is_teacher ? this.renderAdmin() : this.renderUser();
  }
}

ChatList.propTypes = {
  onListContext: func.isRequired,
  chats: arrayOf(ChatType.isRequired),
  total: number,
  isFetching: bool.isRequired,
  error: bool,
  is_teacher: bool,
  is_admin: bool,
  list_context: object.isRequired,
};

ChatList.defaultProps = {
  chats: [],
  is_admin: false,
  is_teacher: false,
  error: false,
  total: 0,
};

function mapStateToProps(state) {
  const {
    local: {
      is_teacher,
      is_admin,
      chats: { context },
    },
  } = state;
  let chats;
  const currentContextIsUnread = typeof context.unread !== "undefined";
  const currentContextIsUnanswered = typeof context.unanswered !== "undefined";

  if (currentContextIsUnread || currentContextIsUnanswered) {
    chats = sortBy(
      selectors.getChatsByContext(state, context),
      c => +c.last_message_time,
    );
  } else {
    chats = sortBy(
      selectors.getChatsByContext(state, context),
      c => -c.last_message_time,
    );
  }

  const {
    is_loaded,
    is_fetching,
    error,
    total,
  } = selectors.getChatsStateByContext(state, context);

  return {
    chats,
    isFetching: is_fetching,
    isLoaded: is_loaded,
    error,
    is_teacher,
    is_admin,
    list_context: context,
    total,
  };
}

export default connect(mapStateToProps, {
  onListContext,
})(ChatList);
