import React, { useState } from "react";

export default function ChatWorkTimeIsUp(props) {
  const { title, description, timeDescription, image, is_teacher } = props;
  const [collapsed, setCollapsed] = useState(false);

  if (is_teacher) {
    return (
      <div id="chat_work_time_is_up" className={is_teacher ? "is-teacher" : ""}>
        <div
          className="chat_work_time_is_up_time_description"
          style={{ marginTop: 0 }}
        >
          {timeDescription}
        </div>
      </div>
    );
  }

  return (
    <div id="chat_work_time_is_up" className={collapsed ? "collapsed" : ""}>
      <header>
        <div className="chat_work_time_is_up_collapse_title">{title}</div>
        <i
          onClick={() => setCollapsed(!collapsed)}
          className={`fa chat_work_time_is_up_collapse_sw fa-angle-${
            collapsed ? "up" : "down"
          }`}
        />
      </header>
      <section>
        {image && <img src={image} alt="work_time_is_up.png" />}

        <div className="chat_work_time_is_up_info">
          <div className="chat_work_time_is_up_title">{title}</div>
          <div className="chat_work_time_is_up_description">{description}</div>
          {!!timeDescription && (
            <div className="chat_work_time_is_up_time_description">
              {timeDescription}
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
