import React, { Component } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

import Loading from "../layouts/Loading";
import SomethingWentWrong from "../layouts/SomethingWentWrong";

export default class List extends Component {
  state = {
    scrollHeight: 0,
    buttonToBottomIsVisible: false,
    buttonDateIsVisible: false,
    buttonDateText: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      isExistsMessageId: !!this.props.query.message_id,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const isStateChanged = !isEqual(this.state, nextState);
    const isPropsChanged = !isEqual(this.props, nextProps);

    return isStateChanged || isPropsChanged;
  }

  componentDidUpdate(prevProps, prevState) {
    const { items } = this.props;
    const { buttonToBottomIsVisible } = this.state;

    const isMessagesCountChanged = !isEqual(items, prevProps.items);
    const isBtnStatusChanged = buttonToBottomIsVisible !== prevState.buttonToBottomIsVisible;

    if (isMessagesCountChanged) {
      this.dateLines = [].slice.call(
        document.getElementsByClassName("dateTimeLine"),
      );
    }

    // если изменилось кол-во сообщений и кнопка не показана (мы где то внизу)
    // то опускаемя к последнему сообщению
    if (isMessagesCountChanged && !buttonToBottomIsVisible) {
      this.updateScroll();
    }

    let scrollerIsDown = !!this.scroller;
    if (scrollerIsDown) {
      const { scrollHeight, scrollTop, clientHeight } = this.scroller;
      const scrollerDiff = scrollHeight - scrollTop;
      scrollerIsDown = !(scrollerDiff !== clientHeight);
    }

    const isLoading = this.props.isLoading === prevProps.isLoading;

    if (isBtnStatusChanged || scrollerIsDown || isLoading) {
      return;
    }

    if (
      !this.state.isExistsMessageId
      && this.props.context.page > 0
      && this.scroller
    ) {
      this.scroller.scrollTop = this.scroller.scrollHeight - this.state.scrollHeight;
    } else {
      const { context: { page } } = this.props;
      this.updateScroll({ saveScrollPosition: isMessagesCountChanged && page !== 0 });
    }
  }

  componentWillUnmount() {
    this.props.onChatComponentUnmount();
  }

  updateScroll = (opt = {}) => {
    if (!this.scroller) return;

    if (this.state.isExistsMessageId) {
      const messNode = document.getElementById(this.props.query.message_id);
      if (messNode) messNode.scrollIntoView({ behavior: "smooth" });
      this.setState({ isExistsMessageId: false });

      return;
    }

    // сохраняем позицию скрола после подгрузки новых сообщений
    if (opt.saveScrollPosition) {
      this.scroller.scrollTop = this.state.scrollHeight;
    } else {
      this.scroller.scrollTop = this.scroller.scrollHeight;
    }

    this.handleScroll(); // TODO: maybe need to refac, add to check in Safari
  };

  toggleDateButton = () => {
    if (!this.dateLines || !this.dateLines.length) return;

    const isAnyDatelineVisible = this.dateLines.some(el => this.isElementInViewport(el),
    );

    if (isAnyDatelineVisible && this.state.buttonDateIsVisible) {
      this.setState({ buttonDateIsVisible: false });
    }
    if (!isAnyDatelineVisible && !this.state.buttonDateIsVisible) {
      const closestEl = this.dateLines.reduce((acc, el) => {
        const rect = el.getBoundingClientRect();
        if (!acc.el) return { el, y: rect.y };
        if (rect.y < 0 && rect.y > acc.y) return { el, y: rect.y };
        return acc;
      }, {});

      this.setState({
        buttonDateIsVisible: true,
        buttonDateText: closestEl.el.textContent,
      });
    }
  };

  handleScroll = () => {
    if (!this.scroller) return;

    this.toggleDateButton();

    const {
      context: { page },
      total,
      items,
    } = this.props;
    const currLenOfItems = items.reduce((sum, acc) => sum.concat(acc), [])
      .length;

    const { scrollHeight, scrollTop, clientHeight } = this.scroller;
    const scrollerPosition = scrollHeight - (scrollTop + clientHeight);
    const scrollerIsDown = scrollHeight - scrollTop === clientHeight;
    const scrollerIsUp = scrollTop === 0;

    if (scrollerIsDown) {
      this.setState({ buttonToBottomIsVisible: false });
    }
    if (!this.state.buttonToBottomIsVisible && scrollerPosition > 850) {
      this.setState({
        scrollHeight,
        buttonToBottomIsVisible: true,
      });
    }
    if (!this.props.isLoading && scrollerIsUp && currLenOfItems !== total) {
      this.props.onChatContext({ page: page + 1 });
      this.setState({ scrollHeight });
    }
  };

  isElementInViewport(el) {
    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0
      && rect.left >= 0
      && rect.bottom
        <= (window.innerHeight || document.documentElement.clientHeight)
      && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  render() {
    const { items, renderItem, isLoading, isError } = this.props;
    const {
      buttonToBottomIsVisible,
      buttonDateText,
      buttonDateIsVisible,
    } = this.state;

    if (isLoading) {
      return <Loading />;
    } if (isError) {
      return <SomethingWentWrong />;
    }

    return (
      <div
        id="chat_history"
        onScroll={this.handleScroll}
        onWheel={this.handleScroll} // TODO : check method
        ref={(scroller) => {
          this.scroller = scroller;
        }}
      >
        {buttonDateIsVisible && (
          <span className="chat-date-badge">
            {' '}
            {buttonDateText}
            {' '}
          </span>
        )}
        {items.map(renderItem)}
        {buttonToBottomIsVisible && (
          <button type="button" id="move-to-bottom" onClick={() => this.updateScroll()}>
            <i className="fa fa-arrow-down" />
            {' '}
            Перейти в конец истории
          </button>
        )}
      </div>
    );
  }
}

List.propTypes = {
  renderItem: PropTypes.func.isRequired,
  onChatContext: PropTypes.func.isRequired,
  onChatComponentUnmount: PropTypes.func.isRequired,
  total: PropTypes.number,
  items: PropTypes.array.isRequired,
  query: PropTypes.object,
  context: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
};

List.defaultProps = {
  query: {},
  context: {},
  total: 0,
};
