/* eslint-disable no-constant-condition */
import { take, put, call, fork, all } from "redux-saga/effects";
import { api } from "../services";
import * as actions from "../actions";

function* watchLoadUserProfile() {
  while (true) {
    const { userId } = yield take(actions.USER_PROFILE_LOAD);
    let response;

    if (userId) {
      ({ response } = yield call(api.fetchUserProfile, userId));
    } else {
      ({ response } = yield call(api.fetchMyProfile));
    }

    if (response) {
      yield put({
        type: actions.USER_PROFILE_LOAD_DONE,
        response,
      });
    }
  }
}

function* watchUpdateUserProfile() {
  while (true) {
    const { resolve, reject, ...data } = yield take(
      actions.USER_PROFILE_UPDATE,
    );
    const { response, error } = yield call(api.updateUserProfile, data);

    if (error) {
      reject({ error });
    } else if ("error" in response) {
      reject(response);
    } else {
      resolve(response);
      yield put({
        type: actions.USER_PROFILE_UPDATE_DONE,
        response,
      });
    }
  }
}

export default function* root() {
  yield all([
    fork(watchLoadUserProfile),
    fork(watchUpdateUserProfile),
  ]);
}
