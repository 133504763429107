import React, { Component } from "react";
import {
  arrayOf,
  number,
  string,
  any,
  func,
  shape,
  bool,
  object,
} from "prop-types";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { history } from "Core/services";
import size from "lodash/size";
import moment from "moment";
import { selectors } from "Core/reducers";
import {
  loadChat,
  sendMessage,
  updateMessage,
  deleteMessage,
  updateChatUnread,
  uploadImage,
  onRemoveAttachment,
  closeImageModal,
  onExceededLimit,
  onTypingChat,
  onChatContext,
  onChatComponentUnmount,
  openConsultationPaymentModal,
  closeConsultationPaymentModal,
  closeConsultationSuccessPaymentModal,
} from "Core/actions";
import ImageModal from "Main/components/layouts/modals/ImageModal";
import Header from "Main/containers/ChatHeader";
import { groupWith } from "Core/services/chat";
import { chat as ChatType } from "Main/types";

import {
  List,
  Layout,
  InputArea,
  ChatWorkTimeIsUp,
  WelcomeMessage,
  PaymentModal,
  PaymentSuccessModal,
  ConsultationEnded,
} from "../components/Chat";

import MessageGroup from "./MessageGroup";

class Chat extends Component {
  constructor(props) {
    super(props);
    const { search } = this.props.location;
    const params = new URLSearchParams(search);
    const queryParsed = {
      show_success_modal: params.get('show_success_modal'),
      show_payment_tariffs: params.get('show_payment_tariffs'),
      message_id: params.get('message_id'),
    };

    this.state = {
      isEditingMessage: false,
      editingMessageId: 0,
      queryParsed,
    };
    const { show_success_modal, show_payment_tariffs } = queryParsed;

    if (show_success_modal || show_payment_tariffs) {
      history.push(this.props.location.pathname);
    }
  }

  componentDidMount() {
    const { channel, id } = this.props.chat;

    this.props.loadChat(
      id,
      channel,
      this.state.queryParsed.message_id,
      this.state.queryParsed.show_success_modal,
    );
  }

  handleSendMessage = (message, image_id) => {
    const { channel, id } = this.props.chat;
    this.props.sendMessage({ chat_id: id, message, image_id, channel });
  };

  handleUpdateMessage = (message_id, message, imageId) => {
    const { channel, id } = this.props.chat;
    this.props.updateMessage({
      message_id,
      message,
      chat_id: id,
      channel,
      image_id: imageId,
    });
    this.handleEditingMessage(false, null);
  };

  handleDeleteMessage = (message_id) => {
    this.props.deleteMessage(message_id);
  };

  handleImageUpload = (payload) => {
    const form = new FormData();
    form.append("image[image]", payload);
    this.props.uploadImage(form);
  };

  handleEditingMessage = (isEditingMessage, editingMessageId) => {
    this.setState({
      isEditingMessage,
      editingMessageId,
    });
  };

  handleOnTypingChat = (status) => {
    const { chat, me, nickname, is_teacher } = this.props;
    const payload = {
      chat_id: chat.id,
      user_id: me,
      name: nickname,
      status,
    };
    if (!is_teacher) return;

    this.props.onTypingChat(payload);
  };

  handeleOnChatContext = (page) => {
    const { chat_id, channel } = this.props;
    this.props.onChatContext({ chat_id, channel, context: { page } });
  };

  renderMessage = (message_group_ids, index, arr) => {
    const prev_message_group_ids = index !== 0 ? arr[index - 1] : undefined;
    return (
      <MessageGroup
        key={index}
        message_group_ids={message_group_ids}
        prev_message_group_ids={prev_message_group_ids}
        onEditingMessage={this.handleEditingMessage}
        onDeleteMessage={this.handleDeleteMessage}
      />
    );
  };

  renderImageModal = () => {
    if (this.props.open_image_modal) {
      return (
        <ImageModal
          image_src={this.props.open_image_modal}
          closeImageModal={this.props.closeImageModal}
        />
      );
    }
  };

  renderConsultationPaymentModal() {
    if (this.props.open_consultation_success_payment_modal) {
      return (
        <PaymentSuccessModal
          closeModal={this.props.closeConsultationSuccessPaymentModal}
          consultation_last_date={this.props.consultation_last_date}
        />
      );
    }
    if (this.props.open_consultation_payment_modal) {
      return (
        <PaymentModal
          chat_id={this.props.chat.id}
          closeModal={this.props.closeConsultationPaymentModal}
        />
      );
    }
  }

  renderMessagesArea() {
    const {
      messages_ids,
      isLoaded,
      isFetching,
      isError,
      chatContext,
      chat: { total },
    } = this.props;
    const isLoading = !isLoaded || isFetching;
    const isNoMessagesYet = !messages_ids.length;

    if (isLoading) {
      //   return <Loading />;
    } else if (isNoMessagesYet) {
      const { title, description, image } = this.props.course.welcome_message || {};
      return (
        <WelcomeMessage title={title} description={description} image={image} />
      );
    }
    return (
      <List
        renderItem={this.renderMessage}
        items={messages_ids}
        onChatContext={this.props.onChatContext}
        isLoading={isLoading}
        isError={isError}
        context={chatContext}
        total={total}
        onChatComponentUnmount={this.props.onChatComponentUnmount}
        query={this.state.queryParsed}
      />
    );
  }

  render() {
    const {
      chat: { id: chat_id, workTime },
      isFreeFlow,
      consultation_last_date,
      blocked_chats,
      me,
      is_teacher,
      isPersonalRemarkOpened,
      course,
      course: { block_sending_messages, block_sending_messages_info },
    } = this.props;
    const isConsultationEnded = moment(consultation_last_date) < moment().startOf("day");
    const { editingMessageId: messageId } = this.state;
    const editingMessage = messageId ? this.props.messages[messageId] : "";
    const blockSendingMessagesByCourse = block_sending_messages && !is_teacher;

    let nameOfTyping = "";
    let isInputAreaBlocked = false;

    if (
      is_teacher
      && typeof blocked_chats[chat_id] !== "undefined"
      && blocked_chats[chat_id].user_id !== me
    ) {
      isInputAreaBlocked = true;
      nameOfTyping = blocked_chats[chat_id].name;
    }

    return (
      <div>
        {this.renderImageModal()}
        {this.renderConsultationPaymentModal()}
        <Layout
          input={
            !blockSendingMessagesByCourse
            && !workTime.blockSendingMessages && (
              <InputArea
                onSendMessage={this.handleSendMessage}
                onUploadImage={this.handleImageUpload}
                uploadImageId={this.props.uploadImageId}
                onRemoveAttachment={this.props.onRemoveAttachment}
                imageUploadingStatus={this.props.imageUploadingState}
                onExceededLimit={this.props.onExceededLimit}
                isEditing={this.state.isEditingMessage}
                onEditingMessage={this.handleEditingMessage}
                editingMessage={editingMessage}
                editingMessageId={this.state.editingMessageId}
                onUpdateMessage={this.handleUpdateMessage}
                onTypingChat={this.handleOnTypingChat}
                isInputAreaBlocked={isInputAreaBlocked}
                nameOfTyping={nameOfTyping}
                is_teacher={is_teacher}
                chatWorkTime={workTime}
                isPersonalRemarkOpened={isPersonalRemarkOpened}
              />
            )
          }
          messages={this.renderMessagesArea()}
          header={<Header key={chat_id} chat_id={chat_id} />}
          consultationWarning={
            !isFreeFlow
            && course.use_consultations_feature
            && course.available_after_finishing
            && !blockSendingMessagesByCourse
            && isConsultationEnded && (
              <ConsultationEnded
                openConsultationPaymentModal={
                  this.props.openConsultationPaymentModal
                }
                is_teacher={is_teacher}
              />
            )
          }
          workTimeIsUpWarning={
            !blockSendingMessagesByCourse
            && workTime.isUp && (
              <ChatWorkTimeIsUp
                title={workTime.info.title}
                description={workTime.info.description}
                timeDescription={workTime.info.timeDescription}
                image={workTime.info.image}
                is_teacher={is_teacher}
              />
            )
          }
          chatBlockedByCourse={
            blockSendingMessagesByCourse && (
              <ChatWorkTimeIsUp
                title={block_sending_messages_info.title}
                description={block_sending_messages_info.description}
                timeDescription={block_sending_messages_info.time_description}
                image={block_sending_messages_info.image}
                is_teacher={false}
              />
            )
          }
        />
      </div>
    );
  }
}

Chat.propTypes = {
  uploadImage: func.isRequired,
  messages_ids: arrayOf(shape),
  messages: any.isRequired,
  loadChat: func.isRequired,
  openConsultationPaymentModal: func.isRequired,
  closeConsultationPaymentModal: func.isRequired,
  closeConsultationSuccessPaymentModal: func.isRequired,
  sendMessage: func.isRequired,
  updateMessage: func.isRequired,
  onRemoveAttachment: func.isRequired,
  onExceededLimit: func.isRequired,
  chat: ChatType.isRequired,
  isFetching: bool.isRequired,
  isError: bool.isRequired,
  isLoaded: bool.isRequired,
  uploadImageId: number,
  is_teacher: bool.isRequired,
  imageUploadingState: object.isRequired,
  onTypingChat: func.isRequired,
  blocked_chats: object.isRequired,
  open_image_modal: string,
  open_consultation_payment_modal: bool.isRequired,
  open_consultation_success_payment_modal: bool.isRequired,
  closeImageModal: func.isRequired,
  onChatContext: func.isRequired,
  onChatComponentUnmount: func.isRequired,
  isPersonalRemarkOpened: bool.isRequired,
  consultation_last_date: string,
  location: object.isRequired,
  chatContext: object,
  me: number.isRequired,
  channel: string,
  chat_id: number.isRequired,
  nickname: string.isRequired,
  course: object,
  isFreeFlow: bool.isRequired,
  deleteMessage: func.isRequired,
};

Chat.defaultProps = {
  messages_ids: [],
  // по дефолту -2, для удаления картинки -1
  uploadImageId: -2,
  open_image_modal: null,
  consultation_last_date: null,
  chatContext: null,
  channel: null,
  course: {},
};

function mapStateToProps(state) {
  const { id: id_s, channel } = state.router.params;

  const id = parseInt(id_s, 10);
  const {
    entities: { messages, flows, chats, courses, users },
    local: {
      me,
      nickname,
      is_teacher,
      blocked_chats,
      open_consultation_payment_modal,
      message_upload_image_id,
      imageUploadingState,
      open_consultation_success_payment_modal,
      current_chat: { context },
      open_image_modal,
      open_personal_remark_modal: isPersonalRemarkOpened,
    },
  } = state;

  const chat = chats[id] || { id, channel };
  let isFreeFlow = false;

  if (chat.flow) {
    isFreeFlow = flows[chat.flow].free;
  }

  if (!chat.workTime) {
    chat.workTime = {};
  }
  let consultation_last_date = null;

  if (size(users) > 0 && chat.user) {
    ({ consultation_last_date } = users[chat.user]);
  }
  const course = courses[chat.course];
  const filtered_messages = Object.values(messages).filter(m => m.chat_id === id);
  const groupByTimeInterval = (a, b) => b.time - a.time < 300 && b.user === a.user;

  return {
    messages_ids: groupWith(filtered_messages, groupByTimeInterval),
    messages,
    chat,
    chat_id: id,
    me,
    isFreeFlow,
    nickname,
    isPersonalRemarkOpened,
    consultation_last_date,
    blocked_chats,
    is_teacher,
    open_consultation_payment_modal,
    open_consultation_success_payment_modal,
    open_image_modal,
    isFetching: selectors.getIsFetching(state, "chats", id),
    isError: selectors.getIsError(state, "chats", id),
    isLoaded: selectors.getIsLoaded(state, "chats", id),
    uploadImageId: message_upload_image_id,
    course,
    imageUploadingState,
    chatContext: context,
  };
}

const ChatConnected = connect(mapStateToProps, {
  loadChat,
  sendMessage,
  openConsultationPaymentModal,
  closeConsultationPaymentModal,
  closeConsultationSuccessPaymentModal,
  updateMessage,
  deleteMessage,
  updateChatUnread,
  uploadImage,
  onRemoveAttachment,
  closeImageModal,
  onExceededLimit,
  onTypingChat,
  onChatContext,
  onChatComponentUnmount,
})(Chat);

export default withRouter(ChatConnected);
