import React from 'react';
import { func } from 'prop-types';

import FreeSubscribeButton from './FreeSubscribeButton';

export default function FreeSubscribeBlock({ freeSubscribeClick }) {
  return (
    <div id="course-free-start-block" className="d-flex flex-column align-items-center">
      <span className="title">
        Начните учиться прямо сейчас!
      </span>
      <p className="content">
        Посмотрите вводные уроки, загрузите домашнюю работу и
        доделайте её до конца с помощью кураторов.
      </p>

      <div className="mt-1">
        <FreeSubscribeButton onClick={freeSubscribeClick} />
      </div>
    </div>
  );
}

FreeSubscribeBlock.propTypes = {
  freeSubscribeClick: func.isRequired,
};
