import React from "react";
import Profile from "Main/containers/ProfileRoot";

class ProfileModule extends React.Component {
  render() {
    return <Profile />;
  }
}

export default ProfileModule;
