import React from 'react';
import { bool, func } from 'prop-types';
import cn from 'classnames';

const ShowMoreButton = ({ previousFilter, onClick, userExists }) => (
  <button
    type="button"
    className={cn('show-more mt-3', { 'mb-3': !userExists })}
    onClick={onClick}
  >
    Показать
    {' '}
    {previousFilter ? 'предыдущие' : 'следующие'}
    {' '}
    комментарии
  </button>
);

ShowMoreButton.propTypes = {
  previousFilter: bool,
  userExists: bool.isRequired,
  onClick: func.isRequired,
};

ShowMoreButton.defaultProps = {
  previousFilter: false,
};

export default ShowMoreButton;
