import React, { useState } from 'react';
import { array, object, string } from 'prop-types';

import { onlyUnique } from 'Core/services/tools';
import CoursesLayout from '../components/Courses/Layout';
import CourseItems from '../components/Courses/Items';

const Courses = ({ images: { banner, ...icons }, items, title }) => {
  const categories = items.map(el => el.category).filter(onlyUnique);
  const filters = [['all', 'Все категории'], ...categories.map(el => [el, el])];
  const [activeFilter, setActiveFilter] = useState('all');
  const [filteredItems, setFilteredItems] = useState(items);

  const onSwitchCategory = (category) => {
    setActiveFilter(category);

    if (category === 'all') {
      return setFilteredItems(items);
    }

    setFilteredItems(items.filter(i => i.category === category));
  };

  return (
    <CoursesLayout
      title={title}
      banner={banner}
      filters={filters}
      onSwitchCategory={onSwitchCategory}
      activeFilter={activeFilter}
    >
      <CourseItems items={filteredItems} icons={icons} />
    </CoursesLayout>
  );
};

Courses.propTypes = {
  images: object.isRequired,
  items: array.isRequired,
  title: string.isRequired,
};

export default Courses;
