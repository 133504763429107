import "@babel/polyfill";
import ReactOnRails from "react-on-rails";

// Chat
import ChatModule from '../modules/ChatModule';

// Authorization
import AuthModule from "../modules/AuthModule";

// Notifications
import NotificationCenterModule from "../modules/NotificationCenterModule";

// User profile
import UserPageModule from "../modules/UserPageModule";
import ProfileModule from "../modules/ProfileModule";

// Webinar
import WebinarsModule from "../modules/WebinarsClientModule";

// Student works
import StudentWorksModule from "../modules/StudentWorksModule";
import StudentWorkModule from "../modules/StudentWorkModule";

// Articles
import ArticleModule from "../modules/ArticleModule";

// Tests
import LessonTestModule from "../modules/LessonTestModule";
import StudentTestModule from "../modules/StudentTestModule";

// Contests
import ContestsModule from "../modules/ContestsModule";
import ContestWorkModule from "../modules/ContestWorkModule";

// Courses
import CoursesModule from "../modules/CoursesClientModule";
import CourseModule from '../modules/CourseClientModule';

// Shared comments
import SharedComments from '../modules/SharedCommentsModule';

// index best works catalog
import IndexBestWorksCatalogModule from '../modules/IndexBestWorksCatalogModule';

// student works on my flow page
import MyFlowWorksCatalogModule from "../modules/MyFlowWorksCatalogModule";

// my homeworks page
import MyHomeworksModule from "../modules/MyHomeworksModule";

// teacher homeworks page
import TeacherHomeworksModule from '../modules/TeacherHomeworksModule';

import VkFreeSubscribeModule from '../modules/VkFreeSubscribeModule';

ReactOnRails.register({
  WebinarsModule,
  ChatModule,
  AuthModule,
  NotificationCenterModule,
  ArticleModule,
  StudentWorksModule,
  StudentWorkModule,
  UserPageModule,
  ProfileModule,
  ContestsModule,
  ContestWorkModule,
  LessonTestModule,
  StudentTestModule,
  CoursesModule,
  CourseModule,
  SharedComments,
  IndexBestWorksCatalogModule,
  MyFlowWorksCatalogModule,
  MyHomeworksModule,
  TeacherHomeworksModule,
  VkFreeSubscribeModule,
});
