export const REQUEST = "REQUEST";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";

function createRequestTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
}

export const USER = createRequestTypes("USER");
export const CHAT = createRequestTypes("CHAT");
export const STARRED = createRequestTypes("STARRED");
export const HOMEWORK = createRequestTypes("HOMEWORK");
export const CHATS = createRequestTypes("CHATS");
export const COUNTERS = createRequestTypes("COUNTERS");
export const MULTISEND = createRequestTypes("MULTISEND");
export const CHANGE_USER_PASSWD = createRequestTypes("CHANGE_USER_PASSWD");
export const CHANGE_USER_PASSWD_RESET_ERROR = "CHANGE_USER_PASSWD_RESET_ERROR";
export const GET_TIMELEFT_CONFIRM_VALUE = "GET_TIMELEFT_CONFIRM_VALUE";

// COMMENTS
export const COMMENTS_LOAD = createRequestTypes("COMMENTS_LOAD");
export const COMMENT_UPDATE = createRequestTypes("COMMENTS_UPDATE");
export const COMMENT_CREATE = createRequestTypes('COMMENT_CREATE');
export const COMMENT_DESTROY = createRequestTypes('COMMENT_DESTROY');

// CHAT
export const UPDATE_ROUTER_STATE = "UPDATE_ROUTER_STATE";
export const NAVIGATE = "NAVIGATE";
export const LOAD_USER_PAGE = "LOAD_USER_PAGE";
export const LOAD_CHATS = "LOAD_CHATS";
export const LOAD_CHAT = "LOAD_CHAT";
export const LOAD_HOMEWORK = "LOAD_HOMEWORK";
export const LOAD_HOMEWORK_DONE = "LOAD_HOMEWORK_DONE";
export const CHATS_SET_CONTEXT = "CHATS_SET_CONTEXT";
export const CHAT_SET_CONTEXT = "CHAT_SET_CONTEXT";
export const CHAT_TEACHER_PROGRESS_BAR_LOADED = 'CHAT_TEACHER_PROGRESS_BAR_LOADED';

export const MESSAGE_SEND = "MESSAGE_SEND";
export const MESSAGE_SEND_DONE = "MESSAGE_SEND_DONE";
export const MESSAGE_CREATED = "MESSAGE_CREATED";
export const MESSAGE_CREATED_PROCEED = "MESSAGE_CREATED_PROCEED";
export const MESSAGE_UPDATE = "MESSAGE_UPDATE";
export const MESSAGE_UPDATE_DONE = "MESSAGE_UPDATE_DONE";
export const MESSAGE_UPDATED = "MESSAGE_UPDATED";
export const MESSAGE_UPDATED_DONE = "MESSAGE_UPDATED_DONE";
export const MESSAGE_DELETE = "MESSAGE_DELETE";
export const MESSAGE_DELETE_DONE = "MESSAGE_DELETE_DONE";
export const MESSAGE_DELETED = "MESSAGE_DELETED";
export const MESSAGE_DELETED_DONE = "MESSAGE_DELETED_DONE";
export const HOMEWORK_UPDATE_REVIEW = "HOMEWORK_UPDATE_REVIEW";
export const HOMEWORK_UPDATE_REVIEW_DONE = "HOMEWORK_UPDATE_REVIEW_DONE";
export const HOMEWORK_TOGGLE_FAILURE = "HOMEWORK_TOGGLE_FAILURE";
export const HOMEWORK_SHOW_ON_COURSE = "HOMEWORK_SHOW_ON_COURSE";
export const HOMEWORK_SHOW_ON_COURSE_DONE = "HOMEWORK_SHOW_ON_COURSE_DONE";
export const UPDATE_UNREAD = "UPDATE_UNREAD";
export const UPDATE_UNREAD_DONE = "UPDATE_UNREAD_DONE";
export const UPDATE_TOGGLE_ANSWERED = "UPDATE_TOGGLE_ANSWERED";
export const UPDATE_TOGGLE_ANSWERED_DONE = "UPDATE_TOGGLE_ANSWERED_DONE";
export const UPDATE_TOGGLE_ANSWERE_FAILURE = "UPDATE_TOGGLE_ANSWERE_FAILURE";
export const UPDATE_PERSONAL_REMARK = "UPDATE_PERSONAL_REMARK";
export const UPDATE_PERSONAL_REMARK_DONE = "UPDATE_PERSONAL_REMARK_DONE";
export const MESSAGE_READED = "MESSAGE_READED";
export const MESSAGE_READED_PROCEED = "MESSAGE_READED_PROCEED";
export const CHAT_COMPONENT_UNMOUNT = "CHAT_COMPONENT_UNMOUNT";

// AUTORIZE
export const AUTHORIZE = createRequestTypes("AUTHORIZE");
export const AUTHORIZE_RESET_ERROR = "AUTHORIZE_RESET_ERROR";
export const AUTHORIZE_SET_AFTER_SIGN_IN_PATH = 'AUTHORIZE_SET_AFTER_SIGN_IN_PATH';

// CONTESTS
export const CONTESTS_LOAD = "CONTESTS_LOAD";
export const CONTESTS_LOAD_DONE = "CONTESTS_LOAD_DONE";
export const CONTEST_LOAD = "CONTEST_LOAD";
export const CONTEST_LOAD_DONE = "CONTEST_LOAD_DONE";
export const OLD_CONTESTS_LOAD = createRequestTypes('OLD_CONTESTS_LOAD');

// CONTEST_WORK
export const CONTEST_WORK_LOAD = "CONTEST_WORK_LOAD";
export const CONTEST_WORK_LOAD_DONE = "CONTEST_WORK_LOAD_DONE";
export const CONTEST_WORK_PREPARE = "CONTEST_WORK_PREPARE";
export const CONTEST_WORK_ADD_IN_PROGRESS = "CONTEST_WORK_ADD_IN_PROGRESS";
export const CONTEST_WORK_REMOVE_IN_PROGRESS = "CONTEST_WORK_REMOVE_IN_PROGRESS";
export const CONTEST_WORK_PUBLISHED = "CONTEST_WORK_PUBLISHED";
export const CONTEST_WORK_PRIZE_PLACE_SELECT = "CONTEST_WORK_PRIZE_PLACE_SELECT";
export const CONTEST_WORK_PRIZE_PLACE_SELECT_DONE = "CONTEST_WORK_PRIZE_PLACE_SELECT_DONE";
export const CONTEST_WORKS = createRequestTypes('CONTEST_WORKS');

// NOTIFICATION_CENTER
export const NOTIFICATION_CENTER_LOAD = "NOTIFICATION_CENTER_LOAD";
export const NOTIFICATION_CENTER_UPDATE = "NOTIFICATION_CENTER_UPDATE";
export const NOTIFICATION_CENTER_READY = "NOTIFICATION_CENTER_READY";

// USER_PROFILE
export const USER_PROFILE_LOAD = "USER_PROFILE_LOAD";
export const USER_PROFILE_LOAD_DONE = "USER_PROFILE_LOAD_DONE";
export const USER_PROFILE_UPDATE = "USER_PROFILE_UPDATE";
export const USER_PROFILE_UPDATE_DONE = "USER_PROFILE_UPDATE_DONE";
export const USER_PROFILE_UPDATE_ERROR = "USER_PROFILE_UPDATE_ERROR";

// EDU_CERTIFICATE
export const EDU_CERTIFICATE_TEMPLATE_LOAD = "EDU_CERTIFICATE_TEMPLATE_LOAD";
export const EDU_CERTIFICATE_TEMPLATE_LOAD_DONE = "EDU_CERTIFICATE_TEMPLATE_LOAD_DONE";
export const EDU_CERTIFICATE_ISSUANCE = "EDU_CERTIFICATE_ISSUANCE";
export const EDU_CERTIFICATE_ISSUANCE_DONE = "EDU_CERTIFICATE_ISSUANCE_DONE";
export const EDU_CERTIFICATE_ISSUANCED = "EDU_CERTIFICATE_ISSUANCED";

// STUDENT_WORK
export const STUDENT_WORK_LOAD = "STUDENT_WORK_LOAD";
export const STUDENT_WORK_LOAD_DONE = "STUDENT_WORK_LOAD_DONE";
export const STUDENT_WORK_PREPARE = "STUDENT_WORK_PREPARE";

// CATALOG
export const CATALOG_ITEMS = createRequestTypes('CATALOG_ITEMS');
export const CATALOG_LIKE_TOGGLE = createRequestTypes('CATALOG_LIKE_TOGGLE');
export const CATALOG_PREPARE = 'CATALOG_PREPARE';

// CHAT
export const OPEN_CONSULTATION_PAYMENT_MODAL = "OPEN_CONSULTATION_PAYMENT_MODAL";
export const CLOSE_CONSULTATION_PAYMENT_MODAL = "CLOSE_CONSULTATION_PAYMENT_MODAL";
export const OPEN_CONSULTATION_SUCCESS_PAYMENT_MODAL = "OPEN_CONSULTATION_SUCCESS_PAYMENT_MODAL";
export const CLOSE_CONSULTATION_SUCCESS_PAYMENT_MODAL = "CLOSE_CONSULTATION_SUCCESS_PAYMENT_MODAL";

export const OPEN_IMAGE_MODAL = "OPEN_IMAGE_MODAL";
export const CLOSE_IMAGE_MODAL = "CLOSE_IMAGE_MODAL";

export const OPEN_PASS_LESSON_TEST_MODAL = "OPEN_PASS_LESSON_TEST_MODAL";
export const CLOSE_PASS_LESSON_TEST_MODAL = "CLOSE_PASS_LESSON_TEST_MODAL";

export const OPEN_LESSON_TEST_MODAL = "OPEN_LESSON_TEST_MODAL";
export const CLOSE_LESSON_TEST_MODAL = "CLOSE_LESSON_TEST_MODAL";

export const REQUEST_LESSON_TEST = "REQUEST_LESSON_TEST";
export const SUCCESS_LESSON_TEST = "SUCCESS_LESSON_TEST";
export const FAILURE_LESSON_TEST = "FAILURE_LESSON_TEST";

export const LESSON_TEST_COMPLETE = createRequestTypes("LESSON_TEST_COMPLETE");

export const OPEN_PERSONAL_REMARK_MODAL = "OPEN_PERSONAL_REMARK_MODAL";
export const CLOSE_PERSONAL_REMARK_MODAL = "CLOSE_PERSONAL_REMARK_MODAL";

export const UPLOAD_TEXT_HOMEWORK_VERSION = "UPLOAD_TEXT_HOMEWORK_VERSION";
export const UPLOAD_TEXT_HOMEWORK_VERSION_DONE = "UPLOAD_TEXT_HOMEWORK_VERSION_DONE";
export const UPLOAD_TEXT_HOMEWORK_VERSION_FAILURE = "UPLOAD_TEXT_HOMEWORK_VERSION_FAILURE";

export const IMAGE_UPLOAD = "IMAGE_UPLOAD";
export const IMAGE_UPLOAD_REQUEST = "IMAGE_UPLOAD_REQUEST";
export const IMAGE_UPLOAD_SUCCESS = "IMAGE_UPLOAD_SUCCESS";
export const IMAGE_UPLOAD_FAILURE = "IMAGE_UPLOAD_FAILURE";
export const IMAGE_UPLOAD_PROGRESS = "IMAGE_UPLOAD_PROGRESS";
export const IMAGE_UPLOAD_REMOVE = "IMAGE_UPLOAD_REMOVE";
export const IMAGE_EXCEEDED_LIMIT = "IMAGE_EXCEEDED_LIMIT";
export const IMAGE_UPLOAD_TYPE_ERROR = "IMAGE_UPLOAD_TYPE_ERROR";

export const UPLOAD_REQUEST = "UPLOAD_REQUEST";
export const UPLOAD_PROGRESS = "UPLOAD_PROGRESS";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_FAILURE = "UPLOAD_FAILURE";

export const CHAT_TYPING = "CHAT_TYPING";
export const TEACHER_STARTED_TYPING = "TEACHER_STARTED_TYPING";
export const TEACHER_STOPPED_TYPING = "TEACHER_STOPPED_TYPING";

export const START_CONVERTING_MP4_TO_GIF = createRequestTypes('START_CONVERTING_MP4_TO_GIF');
export const DOWNLOAD_CONVERTED_GIF = createRequestTypes('DOWNLOAD_CONVERTED_GIF');

function action(type, payload = {}) {
  return { type, ...payload };
}

export const user = {
  request: login => action(USER[REQUEST], { login }),
  success: (login, response) => action(USER[SUCCESS], { login, response }),
  failure: (login, error) => action(USER[FAILURE], { login, error }),
};

export const timeleftConfirmValue = (
  login, { resolve = () => {} },
) => action(GET_TIMELEFT_CONFIRM_VALUE, { login, resolve });

export const authorize = {
  request: ({ resolve = () => {}, reject = () => {}, ...params }) => (
    action(AUTHORIZE[REQUEST], { ...params, resolve, reject })
  ),
  success: ({ login, password }, response) => (
    action(AUTHORIZE[SUCCESS], { login, password, response })
  ),
  failure: ({ login, password }, exception) => (
    action(AUTHORIZE[FAILURE], { login, password, exception })
  ),
  reset_error: payload => action(AUTHORIZE_RESET_ERROR, { payload }),
};

export const changeUserPasswd = {
  request: ({ resolve = () => {}, reject = () => {}, ...params }) => (
    action(CHANGE_USER_PASSWD[REQUEST], { ...params, resolve, reject })
  ),
  success: (params, response) => action(CHANGE_USER_PASSWD[SUCCESS], { params, response }),
  failure: (params, exception) => action(CHANGE_USER_PASSWD[FAILURE], { params, exception }),
  reset_error: payload => action(CHANGE_USER_PASSWD_RESET_ERROR, { payload }),
};

export const chats = {
  request: options => action(CHATS[REQUEST], { options }),
  success: (options, response) => action(CHATS[SUCCESS], { options, response }),
  failure: (options, error) => action(CHATS[FAILURE], { options, error }),
};

export const counters = {
  request: options => action(COUNTERS[REQUEST], { options }),
  success: (options, response) => action(COUNTERS[SUCCESS], { options, response }),
  failure: (options, error) => action(COUNTERS[FAILURE], { options, error }),
};

export const chat = {
  request: ({ id, channel, options }) => action(CHAT[REQUEST], { id, channel, options }),
  success: ({ id, channel, options }, response) => (
    action(CHAT[SUCCESS], { id, channel, response, options })
  ),
  failure: ({ id, channel }, error) => action(CHAT[FAILURE], { id, channel, error }),
};

export const starred = {
  request: login => action(STARRED[REQUEST], { login }),
  success: (login, response) => action(STARRED[SUCCESS], { login, response }),
  failure: (login, error) => action(STARRED[FAILURE], { login, error }),
};

export const homework = {
  request: id => action(HOMEWORK[REQUEST], { id }),
  success: (id, response) => action(HOMEWORK[SUCCESS], { id, response }),
  failure: (id, error) => action(HOMEWORK[FAILURE], { id, error }),
};

export const onListContext = context => action(CHATS_SET_CONTEXT, { value: context });
export const onChatContext = (
  { resolve = () => {}, ...context },
) => action(CHAT_SET_CONTEXT, { value: context, resolve });
export const updateRouterState = state => action(UPDATE_ROUTER_STATE, { state });
export const navigate = pathname => action(NAVIGATE, { pathname });
export const loadChats = () => action(LOAD_CHATS);
export const loadChat = (
  id, channel, message_id, show_success_modal,
) => action(LOAD_CHAT, { id, channel, message_id, show_success_modal });

export const sendMessage = ({
  message, chat_id, channel, image_id,
}) => action(MESSAGE_SEND, { payload: { message, chat_id, channel, image_id } });

export const updateMessage = ({
  message_id,
  message,
  chat_id,
  channel,
  image_id,
}) => action(MESSAGE_UPDATE, {
  payload: { message_id, message, chat_id, channel, image_id },
});

export const deleteMessage = message_id => action(MESSAGE_DELETE, { message_id });

export const updateChatUnread = ({
  message_id, chat_id, channel,
}) => action(UPDATE_UNREAD, { payload: { message_id, chat_id, channel } });

export const toggleChatAnswered = ({
  chat_id, answered,
}) => action(UPDATE_TOGGLE_ANSWERED, { payload: { chat_id, answered } });

export const updatePersonalRemark = ({
  personal_remark, user_id,
}) => action(UPDATE_PERSONAL_REMARK, { payload: { personal_remark, user_id } });

export const updateReview = (
  id, payload, chat_id,
) => action(HOMEWORK_UPDATE_REVIEW, { id, payload, chat_id });

export const openConsultationPaymentModal = () => action(OPEN_CONSULTATION_PAYMENT_MODAL);
export const closeConsultationPaymentModal = () => action(CLOSE_CONSULTATION_PAYMENT_MODAL);

export const openConsultationSuccessPaymentModal = () => (
  action(OPEN_CONSULTATION_SUCCESS_PAYMENT_MODAL)
);
export const closeConsultationSuccessPaymentModal = () => (
  action(CLOSE_CONSULTATION_SUCCESS_PAYMENT_MODAL)
);

// AUTORIZE
export const setAfterSignInPath = path => action(AUTHORIZE_SET_AFTER_SIGN_IN_PATH, { path });

// SHOW HOMEWORK ON FAME HALL
export const showOnCourse = id => action(HOMEWORK_SHOW_ON_COURSE, { id });

// CONTESTS
export const loadContests = () => action(CONTESTS_LOAD);
export const loadMoreOldContest = () => action(OLD_CONTESTS_LOAD[REQUEST]);
export const setContestWorkAddInProgress = () => action(CONTEST_WORK_ADD_IN_PROGRESS);
export const loadContest = id => action(CONTEST_LOAD, { id });

// CONTEST_WORK
export const loadContestWork = payload => action(CONTEST_WORK_LOAD, { payload });
export const prepareContestWork = payload => action(CONTEST_WORK_PREPARE, { payload });
export const assignPrizeToContestant = ({
  prizeId = null, contestantId,
}) => action(CONTEST_WORK_PRIZE_PLACE_SELECT, { payload: { prizeId, contestantId } });

export const loadContestWorks = payload => action(CONTEST_WORKS[REQUEST], { payload });

// LESSON TEST
export const openPassLessonTestModal = payload => action(OPEN_PASS_LESSON_TEST_MODAL, { payload });
export const closePassLessonTestModal = () => action(CLOSE_PASS_LESSON_TEST_MODAL);
export const openLessonTestModal = payload => action(OPEN_LESSON_TEST_MODAL, { payload });
export const closeLessonTestModal = () => action(CLOSE_LESSON_TEST_MODAL);
export const onCompleteLessonTest = payload => (
  action(LESSON_TEST_COMPLETE[REQUEST], { payload })
);

// NOTIFICATION_CENTER
export const loadNotificationsCenterData = (options = {}) => (
  action(NOTIFICATION_CENTER_LOAD, options)
);

// USER_PROFILE
export const loadUserProfile = userId => action(USER_PROFILE_LOAD, { userId });
export const updateUserProfile = ({
  resolve = () => {},
  reject = () => {},
  ...data
}) => action(USER_PROFILE_UPDATE, { ...data, resolve, reject });

// STUDENT_WORK
export const loadHomework = payload => action(LOAD_HOMEWORK, { payload });
export const loadStudentWork = payload => action(STUDENT_WORK_LOAD, { payload });
export const prepareStudentWork = payload => action(STUDENT_WORK_PREPARE, { payload });

// EDU_CERTIFICATE
export const loadEduCertificateTemplate = (params = {}) => (
  action(EDU_CERTIFICATE_TEMPLATE_LOAD, params)
);
export const issuanceEduCertificate = (params = {}) => action(EDU_CERTIFICATE_ISSUANCE, params);
export const setEduCertificateIssuanced = () => action(EDU_CERTIFICATE_ISSUANCED);

// CATALOG
export const loadCatalogItems = payload => action(CATALOG_ITEMS[REQUEST], { payload });
export const toggleLikeCatalogItem = payload => action(CATALOG_LIKE_TOGGLE[REQUEST], { payload });
export const prepareCatalog = payload => action(CATALOG_PREPARE, { payload });

// SHARED COMMENTS
export const loadComments = payload => action(COMMENTS_LOAD[REQUEST], { payload });
export const createComment = payload => action(COMMENT_CREATE[REQUEST], { payload });
export const deleteComment = payload => action(COMMENT_DESTROY[REQUEST], { payload });
export const updateComment = payload => action(COMMENT_UPDATE[REQUEST], { payload });

// CHAT
export const openImageModal = src => action(OPEN_IMAGE_MODAL, { src });
export const closeImageModal = () => action(CLOSE_IMAGE_MODAL);

export const openPersonalRemarkModal = () => action(OPEN_PERSONAL_REMARK_MODAL);
export const closePersonalRemarkModal = () => action(CLOSE_PERSONAL_REMARK_MODAL);

export const uploadTextHomeworkVersion = (
  text,
  opt,
) => action(UPLOAD_TEXT_HOMEWORK_VERSION, { text, opt });

export const uploadImage = payload => action(IMAGE_UPLOAD, { payload });
export const uploadImageProgress = payload => action(IMAGE_UPLOAD_PROGRESS, { payload });
export const uploadImageSuccess = response => action(IMAGE_UPLOAD_SUCCESS, { response });
export const uploadImageFailure = payload => action(IMAGE_UPLOAD_FAILURE, { payload });
export const onRemoveAttachment = () => action(IMAGE_UPLOAD_REMOVE);
export const onExceededLimit = () => action(IMAGE_EXCEEDED_LIMIT);
export const uploadTypeError = () => action(IMAGE_UPLOAD_TYPE_ERROR);

export const onTypingChat = payload => action(CHAT_TYPING, { payload });

export const sendMultiMessage = (
  course_id, flow_id, message,
) => action(MULTISEND[REQUEST], { course_id, flow_id, message });

export const onChatComponentUnmount = () => action(CHAT_COMPONENT_UNMOUNT);

export const uploadRequest = (file, opt) => ({
  type: UPLOAD_REQUEST,
  payload: { file, opt },
});

export const uploadProgress = (file, progress) => ({
  type: UPLOAD_PROGRESS,
  payload: progress,
  meta: { file },
});
export const uploadSuccess = file => ({
  type: UPLOAD_SUCCESS,
  meta: { file },
});
export const uploadFailure = (file, err) => ({
  type: UPLOAD_FAILURE,
  payload: err,
  error: true,
  meta: { file },
});

export const loadLessonTest = id => action(REQUEST_LESSON_TEST, { payload: id });

export const startConvertingMp4ToGif = id => action(START_CONVERTING_MP4_TO_GIF[REQUEST], { id });
