import React from 'react';
import { string, element, oneOfType } from 'prop-types';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faExclamationTriangle,
  faInfoCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const colors = {
  error: "#f00",
  warning: "#f7af07",
  info: "#2f80ed",
  success: "#17c109",
};

const icons = {
  error: faTimesCircle,
  warning: faExclamationTriangle,
  info: faInfoCircle,
  success: faCheckCircle,
};

const Toast = ({ appearance, children }) => (
  <div
    className="toast-comp"
    style={{ borderTop: `border-top: ${appearance} 2px solid` }}
  >
    <FontAwesomeIcon icon={icons[appearance]} color={colors[appearance]} />
    <div className="text-content">{children}</div>
  </div>
);

Toast.propTypes = {
  appearance: string,
  children: oneOfType([element, string]).isRequired,
};

Toast.defaultProps = {
  appearance: 'info',
};

export default Toast;
