import React, { Component } from 'react';
import { array, bool, func, number, object, string } from 'prop-types';
import { connect } from 'react-redux';
import reject from 'lodash/reject';
import cn from 'classnames';

import { loadComments, createComment, deleteComment, updateComment } from '../../core/actions';
import { sortByIdAsc, sortByIdDesc } from '../../core/services/tools';

import Dropdown, { DropdownItem } from "../components/ui/Dropdown";
import Spinner from "../components/layouts/Spinner";
import { CommentsList, ShowMoreButton, AddCommentArea } from '../components/SharedComments';

const OLD_FIRST = 0;
const NEW_FIRST = 1;

const sortLabels = {
  [OLD_FIRST]: 'Старые',
  [NEW_FIRST]: 'Новые',
};

class SharedCommentsContailner extends Component {
  componentDidMount() {
    // не делаем запрос если комменты уже были подгруженны
    if (this.props.pagination.firstItemsLoaded) return;

    this.wrapAndCallFunc({
      passedFunc: this.handleLoadComments,
      argsObject: { sortId: 0, sortType: OLD_FIRST },
    });
  }

  componentDidUpdate(prevProps) {
    const {
      commentableId: comId,
      pagination: { firstItemsLoaded },
    } = this.props;
    const prevComId = prevProps.commentableId;

    if (comId === prevComId || firstItemsLoaded) return;

    this.wrapAndCallFunc({
      passedFunc: this.handleLoadComments,
      argsObject: { sortId: 0, sortType: OLD_FIRST },
    });
  }

  wrapAndCallFunc = ({ passedFunc, argsObject = {} }) => {
    const { entity, commentableId } = this.props;

    passedFunc({
      entity,
      commentableId,
      ...argsObject,
    });
  }

  handleLoadComments = (options = {}) => {
    const { pagination: { sortId, sortType } } = this.props;

    this.props.loadComments({ sortId, sortType, ...options });
  }

  handleDeleteComment = (id) => {
    this.wrapAndCallFunc({
      passedFunc: this.props.deleteComment,
      argsObject: { id },
    });
  }

  handleUpdateComment = ({ id, text }) => {
    this.wrapAndCallFunc({
      passedFunc: this.props.updateComment,
      argsObject: { id, text },
    });
  }

  toggleFilter = (nextFilter) => {
    if (nextFilter === this.props.pagination.sortType) return;

    this.wrapAndCallFunc({
      passedFunc: this.handleLoadComments,
      argsObject: { sortId: 0, sortType: nextFilter },
    });
  }

  handleShowMore = () => {
    this.wrapAndCallFunc({ passedFunc: this.handleLoadComments });
  }

  handleShowMorePrevious = () => {
    this.wrapAndCallFunc({
      passedFunc: this.handleLoadComments,
      argsObject: { revertSortType: NEW_FIRST },
    });
  }

  handleCreateComment = (text) => {
    const { pagination: { sortType } } = this.props;

    this.wrapAndCallFunc({
      passedFunc: this.props.createComment,
      argsObject: { text, sortType, revertSortType: NEW_FIRST },
    });
  }

  renderCounter = () => {
    const { commentsNumber, pagination: { sortType } } = this.props;
    if (commentsNumber === 0) return null;

    return (
      <div className="comments-component__header d-flex align-items-center">
        <div className="comments-component__header-counter">
          Комментарии
          <span>{commentsNumber}</span>
        </div>

        {commentsNumber > 1 && (
          <div className="comments-component__header-filters">
            <Dropdown
              label={`Сначала ${sortLabels[sortType].toLocaleLowerCase()}`}
              onSelect={({ attrs: { filter } }) => this.toggleFilter(filter)}
              useChevronDown
            >
              {[OLD_FIRST, NEW_FIRST].map(filter => (
                <DropdownItem
                  key={filter}
                  filter={filter}
                  selected={sortType === filter}
                >
                  {sortLabels[filter]}
                  {/* <div className="d-flex justify-content-between">
                    <div>{sortLabels[filter]}</div>
                    {sortType === filter && <FontAwesomeIcon color="#1a6dd3fc" icon={faCheck} />}
                  </div> */}
                </DropdownItem>
              ))}
            </Dropdown>
          </div>
        )}
      </div>
    );
  }

  renderAreaInput = () => {
    const { currentUser } = this.props;
    if (!currentUser.id) return null;

    return (
      <AddCommentArea
        createComment={this.handleCreateComment}
        userAvatar={currentUser.avatar}
      />
    );
  }

  render() {
    const {
      insideModal,
      comments,
      pagination: { loading, hasMore, showPreviousComments, sortId },
      currentUser,
    } = this.props;

    return (
      <div className={
        cn('comments-component', { '--inside-modal': insideModal })
      }
      >
        <div className="comments-component__body">
          {this.renderCounter()}

          {!loading && hasMore && showPreviousComments && (
            <ShowMoreButton
              previousFilter
              userExists={!!currentUser.id}
              onClick={this.handleShowMorePrevious}
            />
          )}
          {loading && sortId !== 0 && showPreviousComments && <Spinner className="my-3" />}

          <CommentsList
            comments={comments}
            deleteComment={this.handleDeleteComment}
            updateComment={this.handleUpdateComment}
          />

          {!loading && hasMore && !showPreviousComments && (
            <ShowMoreButton
              userExists={!!currentUser.id}
              onClick={this.handleShowMore}
            />
          )}

          {loading && sortId !== 0 && !showPreviousComments && <Spinner className="my-3" />}
        </div>

        {this.renderAreaInput()}
      </div>
    );
  }
}

SharedCommentsContailner.propTypes = {
  entity: string.isRequired,
  commentableId: number.isRequired,
  insideModal: bool,
  comments: array.isRequired,
  commentsNumber: number,
  loadComments: func.isRequired,
  createComment: func.isRequired,
  deleteComment: func.isRequired,
  updateComment: func.isRequired,
  pagination: object.isRequired,
  currentUser: object.isRequired,
};

SharedCommentsContailner.defaultProps = {
  insideModal: false,
  commentsNumber: 0,
};

const mapStateToProps = (state, ownProps) => {
  const paginationKey = `${ownProps.entity}_${ownProps.commentableId}`;
  const pagination = state.entities.commentsPagination[paginationKey] || {
    loading: false, sortType: OLD_FIRST, sortId: 0, commentsNumber: 0,
  };

  let comments = reject(state.entities.comments, comment => (
    comment.commentable_id !== ownProps.commentableId
  )).map(comment => ({
    ...comment,
    user: state.entities.users[comment.user],
  }));

  if (pagination.sortType === OLD_FIRST) {
    comments = sortByIdAsc(comments);
  } else if (pagination.sortType === NEW_FIRST) {
    comments = sortByIdDesc(comments);
  }

  let resultCommentsNUmber = ownProps.commentsNumber;

  if (!resultCommentsNUmber) {
    resultCommentsNUmber = pagination.commentsNumber;
  }

  return {
    currentUser: state.local.currentUser,
    pagination,
    comments,
    commentsNumber: resultCommentsNUmber,
  };
};

export default connect(mapStateToProps, {
  loadComments,
  createComment,
  deleteComment,
  updateComment,
})(SharedCommentsContailner);
