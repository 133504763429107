import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import { any, array, func, number, oneOfType, string } from "prop-types";
import { useClickAway } from "../../../core/hooks/useClickAway";

export default function MultiSelect({
  children, onSelect, label, onCollapse,
}) {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!expanded) onCollapse();
  }, [expanded]);

  const handleOnSelect = (index, nextLabel, attrs) => {
    onSelect({ index, label: nextLabel, attrs });
  };

  const handleItemClick = (e, idx, attrs) => {
    const { target: { textContent = "" } } = e;
    const nextLabel = textContent.replace(/\s+/, "");

    handleOnSelect(idx, nextLabel, { itemRef: e.target, ...attrs });
  };

  const selectRef = useRef(null);

  useClickAway(selectRef, () => {
    // хак, чтобы функция onOpenPopap не вызывалась дважды после уставки в false
    if (expanded) setTimeout(() => setExpanded(false), 300);
  });

  const onOpenPopap = () => {
    if (expanded) return;

    setExpanded(true);
  };

  return (
    <div className="dropdown-comp" onClick={onOpenPopap}>
      Выбрано курсов:&nbsp;
      <div className="dropdown-comp__label">{label}</div>

      <div className="dropdown-comp__icon">
        <FontAwesomeIcon icon={faCaretDown} color="#4f4f4f" />
      </div>
      <div ref={selectRef} className={`dropdown-comp__list ${expanded ? 'dropdown-comp__list_expanded' : ''}`} expanded={expanded.toString()}>
        {children.map((item, idx) => {
          const { selected, children: itemChildren, ...attrs } = item.props;

          return (
            <div
              className="dropdown-comp__list-item"
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              onClick={e => handleItemClick(e, idx, attrs)}
            >
              <div className="pr-4 w-100">
                {item}
              </div>

              {selected && <FontAwesomeIcon color="#1a6dd3fc" icon={faCheck} />}
            </div>
          );
        })}
      </div>
    </div>
  );
}

MultiSelect.propTypes = {
  onCollapse: func.isRequired,
  children: array,
  onSelect: func,
  label: oneOfType([string, number]),
};

MultiSelect.defaultProps = {
  children: [],
  onSelect: () => {},
  label: '',
};

export function MultiSelectItem(props) {
  return <div className="dropdown-comp__item">{props.children}</div>;
}

MultiSelectItem.propTypes = {
  children: any,
};

MultiSelectItem.defaultProps = {
  children: "",
};
