import moment from "moment";
import camelCase from 'lodash/fp/camelCase';
import snakeCase from 'lodash/fp/snakeCase';
import createHumps from './createHumps';

export const shuffle = (array) => {
  let currentIndex = array.length; let temporaryValue; let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    /* eslint-disable no-param-reassign */
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
    /* eslint-enable no-param-reassign */
  }

  return array;
};

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export const sortByIdDesc = elems => elems.sort((e1, e2) => e2.id - e1.id);
export const sortByIdAsc = elems => elems.sort((e1, e2) => e1.id - e2.id);

export const getRandomInt = (minVal, maxVal) => {
  const min = Math.ceil(minVal);
  const max = Math.floor(maxVal);

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const formatTime = (time) => {
  const mTime = moment(time * 1000);
  const day = mTime.calendar().slice(0, 5);
  const year = mTime.format("YYYY");

  if (day === "Сегод") {
    return mTime.format("LT");
  }

  if (year === moment().format("YYYY")) {
    return mTime.format("D MMMM hh:mm");
  }

  return mTime.format("D MMMM Y");
};

export const formatCommentTime = (time) => {
  const mTime = moment(time * 1000);
  const day = mTime.calendar().slice(0, 5);
  const year = mTime.format("YYYY");

  if (day === "Сегод") {
    return `сегодня в ${mTime.format("LT")}`;
  }

  if (day === "Вчера") {
    return `вчера в ${mTime.format("LT")}`;
  }

  if (year === moment().format("YYYY")) {
    return mTime.format("D MMMM hh:mm");
  }

  return mTime.format("D MMMM Y");
};

export const humps = createHumps(camelCase);
export const snakes = createHumps(snakeCase);

export const getUniqueArrayElements = arr => [...new Set(arr)];

const getScrollTop = () => (
  window.scrollY || document.body.scrollTop || document.documentElement.scrollTop || 0
);

const setScrollTop = (value) => {
  window.scrollY = value;
  document.body.scrollTop = value;
  document.documentElement.scrollTop = value;
};

export const hasVerticalScrollbar = () => {
  if (window.innerWidth > document.documentElement.clientWidth) {
    return true;
  }

  const scrollTopPrev = getScrollTop();
  setScrollTop(scrollTopPrev + 1);
  const scrollTopNext = getScrollTop();
  setScrollTop(scrollTopPrev);

  return scrollTopPrev !== scrollTopNext;
};
