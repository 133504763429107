import React, { useEffect, Fragment } from "react";
import { any, func, string } from "prop-types";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { Line } from "rc-progress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { uploadFilesRequest, cleanStore } from "Core/gears/uploadFile/actions";

function UploadFile({
  label,
  children,
  uploadPath,
  onChange,
  onStart,
  onProgress,
  onSuccess = () => {},
  onError = () => {},
  disabled = false,
  allowedTypes = [],
  maxSizeMb = 70,
  mini = false,
  ...props
}) {
  const STATUS_IN_PROGRESS = 10;
  const STATUS_SUCCESS = 20;
  const STATUS_FAILURE = 30;

  const onChangeWrapper = (e) => {
    if (e.target.files.length || !uploadPath) {
      const { files } = e.target;
      const totalFilesSize = Object.keys(files)
        .map(idx => files[idx].size)
        .reduce((total, size) => total + size);

      if (totalFilesSize > 1024 * 1024 * maxSizeMb) {
        return onError(`Выберите файл объемом до ${maxSizeMb}Мбайт`);
      }

      for (const file of files) {
        const isMatch = !allowedTypes.length
          || allowedTypes.some((type) => {
            const typeRx = new RegExp(
              type.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
            );

            return typeRx.test(file.type);
          });

        if (!isMatch) {
          return onError("Недопустимый тип файла");
        }
      }

      onChange(files);
      props.uploadFile({ files, uploadPath });
    }
  };

  useEffect(() => () => props.cleanStore(), []);

  useEffect(() => {
    switch (props.status) {
      case STATUS_IN_PROGRESS:
        onStart();
        break;
      case STATUS_SUCCESS:
        onSuccess();
        break;
      case STATUS_FAILURE:
        const { message } = props.error;
        onError(message);
        break;
    }
  }, [props.status]);

  useEffect(() => {
    if (props.progress > 0) {
      onProgress(props.progress);
    }
  }, [props.progress]);

  const [rand, date] = [Math.random(), Date.now()];
  const inputId = (rand.toString(36) + date.toString(36)).substr(2, 10);
  const completedProg = Math.round(props.progress * 100);

  return (
    <UploadFileComponent className="upload-file-component" disabled={disabled}>
      {props.status === STATUS_IN_PROGRESS ? (
        <ProgressBar mini={mini}>
          <div className="progress-icon">
            <FontAwesomeIcon
              icon={faSpinner}
              pulse
              size={mini ? "1x" : "2x"}
              color="#2f80ed"
            />
            <span>
              Загрузка (
              {completedProg}
              %)
            </span>
          </div>
          <Line
            percent={completedProg}
            strokeWidth="5"
            trailWidth="5"
            strokeColor="#2f80ed"
            trailColor="#aed0ff"
          />
        </ProgressBar>
      ) : (
        <>
          <InputComponent
            {...props}
            disabled={disabled}
            id={`input-component-${inputId}`}
            onChange={e => onChangeWrapper(e)}
          />
          <label htmlFor={`input-component-${inputId}`}>
            {children}
            {!disabled && label && (
              <div className="label">
                <span>{label}</span>
              </div>
            )}
          </label>
        </>
      )}
    </UploadFileComponent>
  );
}

UploadFile.propTypes = {
  label: string,
  children: any,
  uploadPath: string,
  onChange: func,
  onStart: func,
  onProgress: func,
};

UploadFile.defaultProps = {
  label: "Загрузить файл",
  children: null,
  uploadPath: null,
  onChange: () => {},
  onStart: () => {},
  onProgress: () => {},
};

const mapStateToProps = (state) => {
  const props = state.utils.uploadFileReducer;
  return props;
};

const UploadFileConnected = connect(mapStateToProps, {
  uploadFile: ({
    uploadPath, files, ...params
  }) => dispatch => new Promise((resolve, reject) => dispatch(
    uploadFilesRequest({
      files,
      uploadPath,
      resolve,
      reject,
      ...params,
    }),
  )),
  cleanStore: () => dispatch => dispatch(cleanStore()),
})(UploadFile);

export default UploadFileConnected;

const ProgressBar = styled.div`
  width: 200px;

  .progress-icon {
    display: flex;
    margin-bottom: 20px;
    color: #434343;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    grid-gap: 0 16px;
  }

  ${props => props.mini
    && css`
      display: flex;
      width: 180px;
      align-items: center;

      .progress-icon {
        margin: 0 20px;
      }

      span {
        display: none;
      }
    `}
`;

const UploadFileComponent = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  color: #2f80ed;
  font: 15px/1 Roboto;

  label {
    outline: none 0;
    margin: 0;
    cursor: pointer;
  }

  .label {
    margin-top: 12px;

    span {
      border-bottom: transparent 1px dashed;
      transition: 0.2s all;

      &:hover {
        border-color: #2f80ed;
      }
    }
  }

  ${props => props.disabled
    && css`
      label {
        cursor: default;
      }
    `}
`;

const InputComponent = styled.input.attrs(props => ({
  type: "file",
  disabled: props.disabled,
  name: props.name,
}))`
  display: none;
`;
