import {
  FILES_UPLOAD_REQUEST,
  FILES_UPLOAD_PROGRESS,
  FILES_UPLOAD_SUCCESS,
  FILES_UPLOAD_FAILURE,
  FILES_UPLOAD_CLEAN_STORE,
} from "./actionTypes";

const STATUS_FILES_UPLOAD_IN_PROGRESS = 10;
const STATUS_FILES_UPLOAD_SUCCESS = 20;
const STATUS_FILES_UPLOAD_FAILURE = 30;

const uploadFileReducer = (
  state = {
    status: false,
    error: false,
    progress: 0,
    files: [],
  },
  action,
) => {
  switch (action.type) {
    case FILES_UPLOAD_REQUEST:
      const files = [...action.payload.files].map(file => file.name);
      return {
        ...state,
        files: state.files.concat(files),
        status: STATUS_FILES_UPLOAD_IN_PROGRESS,
      };
    case FILES_UPLOAD_SUCCESS:
      return {
        ...state,
        status: STATUS_FILES_UPLOAD_SUCCESS,
      };
    case FILES_UPLOAD_PROGRESS:
      return {
        ...state,
        progress: action.payload.progress,
      };
    case FILES_UPLOAD_FAILURE:
      return {
        ...state,
        status: STATUS_FILES_UPLOAD_FAILURE,
        error: action.payload.error,
      };
    case FILES_UPLOAD_CLEAN_STORE:
      return {
        ...state,
        status: false,
        error: false,
        progress: 0,
        files: [],
      };

    default:
      return state;
  }
};

export default uploadFileReducer;
