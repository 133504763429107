import merge from 'lodash/merge';

import { HOMEWORK_SHOW_ON_COURSE_DONE } from "../../actions";

const homeworkHandlers = {
  // переключение опции отвеченный у чата
  [HOMEWORK_SHOW_ON_COURSE_DONE]: (state, action) => {
    const { id, show_on_course } = action.response;

    const entities = merge({}, state);
    entities.homeworks[id].show_on_course = show_on_course;

    return entities;
  },
};

export default homeworkHandlers;
