import merge from 'lodash/merge';

import { UPDATE_TOGGLE_ANSWERED_DONE } from "../../actions";

const chatHandlers = {
  // переключение опции отвеченный у чата
  [UPDATE_TOGGLE_ANSWERED_DONE]: (state, action) => {
    const { id, answered } = action.response;

    const entities = merge({}, state);
    entities.chats[id].answered = answered;

    return entities;
  },
};

export default chatHandlers;
