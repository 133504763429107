import React, { Component } from 'react';
import { func, object } from 'prop-types';

import Modal from "Main/components/layouts/modals/Modal";
import Spinner from 'Main/components/layouts/Spinner';
import LessonTestQuestion from './LessonTestQuestion';
import LessonTestCompleted from './LessonTestCompleted';

class LessonTestModal extends Component {
  state = {
    progress: 0,
    answers: [],
    isShowResults: false,
    isCompleteRequested: false,
  };

  componentDidMount() {
    this.props.loadLessonTest(this.props.lessonTestModal.lessonId);
  }

  componentDidUpdate() {
    const { questions, loading } = this.props.lessonTests;
    const { isCompleteRequested, progress, answers } = this.state;

    if (isCompleteRequested || loading) return;

    const questionIsAnswered = answers.length === (progress + 1);
    const isLastQuestion = questions.length === (progress + 1);

    if (questionIsAnswered && isLastQuestion) {
      const { lessonId, reduxCatalogKey } = this.props.lessonTestModal;
      const testPassed = !answers.includes(false);

      if (testPassed) {
        this.props.onCompleteLessonTest({ lessonId, reduxCatalogKey });
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ isCompleteRequested: true });

        const testBtnNode = document.querySelector('.lesson-test-not-passed');
        const testPassedNode = document.querySelector('.lesson-test-passed');

        if (testBtnNode) testBtnNode.remove();
        if (testPassedNode) testPassedNode.style.removeProperty('display');
      }
    }
  }

  onAnswer = (isCorrect) => {
    this.setState(prevState => ({ ...prevState, answers: [...prevState.answers, isCorrect] }));
  }

  nextQuestion = () => {
    if (this.isTestFinished()) {
      this.setState({ isShowResults: true });
    } else {
      this.setState(prevState => ({ ...prevState, progress: prevState.progress + 1 }));
    }
  }

  isTestFinished = () => this.props.lessonTests.questions.length === (this.state.progress + 1)

  render() {
    const { questions, loading } = this.props.lessonTests;
    const current = questions[this.state.progress];
    const questionIsAnswered = this.state.answers.length === (this.state.progress + 1);
    const isLastQuestion = questions.length === (this.state.progress + 1);

    if (this.state.isShowResults) {
      return (
        <LessonTestCompleted
          answers={this.state.answers}
          onClose={() => this.props.closeLessonTestModal()}
        />
      );
    }

    return (
      <Modal
        showCross={false}
        showTimes
        closeModal={() => this.props.closeLessonTestModal()}
      >
        <div className="modal-content lesson-test-modal p-5">
          <div className="text-muted text-center">
            {this.state.progress + 1}
            /
            {questions.length}
          </div>
          { loading && <div className="mx-auto"><Spinner /></div> }
          { !loading && !!current && (
            <div>
              <LessonTestQuestion question={current} onAnswer={this.onAnswer} />
              {
                questionIsAnswered
                  && (
                    <div className="lesson-test-modal-next-question-btn d-flex justify-content-center">
                      <button
                        type="button"
                        className="pixel-btn"
                        onClick={this.nextQuestion}
                      >
                        {isLastQuestion ? 'Просмотреть результаты' : 'Следующий вопрос'}
                      </button>
                    </div>
                  )
              }
            </div>
          )}
          { !loading && !current && <div>Что-то пошло не так... мы не нашли вопросов.</div> }
        </div>
      </Modal>
    );
  }
}

LessonTestModal.propTypes = {
  onCompleteLessonTest: func.isRequired,
  closeLessonTestModal: func.isRequired,
  loadLessonTest: func.isRequired,
  lessonTests: object.isRequired,
  lessonTestModal: object.isRequired,
};

export default LessonTestModal;
