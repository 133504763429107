import { schema, normalize } from "normalizr";
import { snakes } from 'Core/services/tools';

const API_ROOT = document.location.origin;

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
function callApi(endpoint, selectedSchema) {
  /* eslint no-debugger: 0 */
  const fullUrl = endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;

  return fetch(fullUrl, {
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(json);
      }

      if (selectedSchema) {
        return normalize(json, selectedSchema);
      }

      return json;
    })
    .then(
      response => ({ response }),
      err => ({ error: err.message || err.error || "Something bad happened" }),
    )
    .catch(e => console.log("FETCH error:", e));
}

function callApiPost(endpoint, data, selectedSchema, method = "POST") {
  /* eslint no-debugger: 0 */
  const fullUrl = endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;
  const headers = {
    Accept: "application/json",
    "X-CSRF-Token": window.AUTH_TOKEN,
  };
  if (!(data instanceof FormData)) {
    headers["Content-Type"] = "application/json";
  }

  return fetch(fullUrl, {
    method,
    credentials: "same-origin",
    headers,
    body: data instanceof FormData ? data : JSON.stringify(data),
  })
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(json);
      }

      if (selectedSchema) {
        return normalize(json, selectedSchema);
      }

      return json;
    })
    .then(
      response => ({ response }),
      err => ({ error: err.message || err.error || "Something bad happened" }),
    )
    .catch(e => console.log("FETCH error:", e));
}

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/gaearon/normalizr

// Schemas for Github API responses.
const userSchema = new schema.Entity("users", {
  idAttribute: "id",
});

const courseSchema = new schema.Entity("courses");
const flowSchema = new schema.Entity("flows", {});

const exerciseSchema = new schema.Entity("exercises", {});

const lessonSchema = new schema.Entity("lessons", {
  exercises: new schema.Array(exerciseSchema),
});

export const articleSchema = new schema.Entity("articles", {
  idAttribute: "id",
});

export const contestantSchema = new schema.Entity("contestants", {
  idAttribute: "id",
});

export const homeworkSchema = new schema.Entity("homeworks", {
  exercise: exerciseSchema,
  lesson: lessonSchema,
  user: userSchema,
  course: courseSchema,
  flow: flowSchema,
});

const chatSchema = new schema.Entity("chats", {
  idAttribute: "id",
  user: userSchema,
  flow: flowSchema,
  course: courseSchema,
});

homeworkSchema.define({
  versions: new schema.Array(homeworkSchema),
  chat: chatSchema,
});

const commentSchema = new schema.Entity("comments", {
  idAttribute: "id",
  user: userSchema,
});

export const commentsSchema = [commentSchema];

const messageSchema = new schema.Entity("messages", {
  user: userSchema,
  homework: homeworkSchema,
  chat: chatSchema,
});

const homeworksSchema = [homeworkSchema];

chatSchema.define({ messages: new schema.Array(messageSchema) });

const chatInfoSchema = new schema.Entity("chats", {
  idAttribute: "id",
  messages: new schema.Array(messageSchema),
  flow: flowSchema,
  course: courseSchema,
  user: userSchema,
  // schema for teachers progress bar
  homeworks: homeworksSchema,
  lessons: new schema.Array(lessonSchema),
});
const chatsSchemaArray = new schema.Object({
  ids: new schema.Array(chatSchema),
});

// const countersEntitySchema = new schema.Entity('counters', {
//   idAttribute: (value, parent, key) => key,
//   processStrategy: (value, parent, key) => ({count: value})
// });
const flowsSchema = new schema.Array(flowSchema);
const countersSchema = new schema.Object({
  flows: flowsSchema,
  courses: new schema.Array(courseSchema),
});

// repoSchema.define({
//   owner: userSchema,
// })

// api services
const buildChatsContextString = context => [
  "course", "flow", "unread", "unanswered",
  "free_flow", "idle", "search", "page",
].reduce(
  (result, type) => {
    if (context[type]) {
      return `${result}/${type}/${context[type]}`;
    }
    return result;
  },
  "",
);

// Contests
export const fetchContests = () => callApiPost("/contests", {});
export const fetchContest = id => callApiPost(`/contest/${id}`, {});
export const fetchContestWork = id => callApiPost(`/contest-work/${id}`, {});
export const assignPrizeToContestant = (id, payload) => callApiPost(`/contest-work/${id}/vote`, payload);
export const loadMoreOldContests = (sortTime, ids) => callApiPost('/contests', { sort_time: sortTime, without_ids: ids });

// NotificationCenter
export const loadUserNotifications = payload => callApiPost("/notifications", payload);

// UserProfile
export const fetchUserProfile = userId => callApi(`/profile/${userId}`);
export const fetchMyProfile = () => callApi("/profile");
export const updateUserProfile = payload => callApiPost("/profile", payload);

// Educational Certificate
export const fetchEduCertificateTemplate = payload => callApiPost("/edu-cert/template", payload);
export const issuanceEduCertificate = payload => callApiPost("/edu-cert/issuance", payload);

// CatalogItems
const catalogUrlMainPrefix = 'front-catalog-api';

export const loadCatalogItems = ({
  entity, page, sortType: sort_type, randNumber: rand_number,
  category, filters = null, customParams = {},
}) => callApiPost(`/${catalogUrlMainPrefix}/paginate`, {
  entity, page, sort_type, category, rand_number, filters, ...snakes(customParams),
});

export const toggleLikeCatalogItem = ({
  entity, itemId,
}) => callApiPost(`/${catalogUrlMainPrefix}/toggle-like`, {
  entity, item_id: itemId,
});

// Authorize
export const userAuthorize = payload => callApiPost("/users/sign_in.json", payload);
export const userPasswordChange = payload => callApiPost("/users/change_password", payload);
export const getTimeleftConfirmValue = payload => callApiPost("/users/timeleft_confirm", payload);

// Shared Comments
export const loadComments = ({
  entity, sortId, sortType, revertSortType = null, commentableId,
}) => callApi(`/${entity}/${commentableId}/shared_comments?sort_id=${sortId}&sort_type=${revertSortType || sortType}`);

export const createComment = ({
  entity, sortType, commentableId, text,
}) => callApiPost(`/${entity}/${commentableId}/shared_comments`, { sort_type: sortType, text });

export const deleteComment = id => callApiPost(`/shared_comments/${id}`, {}, null, 'DELETE');

export const updateComment = ({ id, text }) => callApiPost(`/shared_comments/${id}`, { text }, null, 'PUT');

// Chat
export const fetchChats = context => callApi(
  `/messages/list${buildChatsContextString(context || {})}`,
  chatsSchemaArray,
);
export const fetchChatsCounters = () => callApi("/messages/list/counters", countersSchema);
export const fetchChat = ({
  id,
  channel,
  options,
  message_id,
  show_success_modal,
}) => {
  if (show_success_modal) {
    return callApi(
      `/messages/${channel}/${id}${buildChatsContextString(
        options || {},
      )}?show_success_modal=${true}`,
      chatInfoSchema,
    );
  }
  if (!message_id) {
    return callApi(
      `/messages/${channel}/${id}${buildChatsContextString(options || {})}`,
      chatInfoSchema,
    );
  }

  return callApi(
    `/messages/${channel}/${id}${buildChatsContextString(
      options || {},
    )}?message_id=${message_id}`,
    chatInfoSchema,
  );
};
export const fetchTeacherProgressBar = (id, channel) => (
  callApi(`/messages/${channel}/${id}/teacher-progress-bar`, chatInfoSchema)
);
export const fetchHomework = id => callApi(`/homeworks/${id}`);
export const fetchStudentWork = id => callApiPost(`/student-work/${id}`, {});
export const sendMessage = payload => callApiPost("/messages/add", payload);
export const updateMessage = payload => callApiPost("/messages/update", payload, messageSchema);
export const deleteMessage = payload => callApiPost("/messages/delete", payload, messageSchema);
export const uploadImage = payload => callApiPost("/image/upload", payload);
export const updateChatUnread = ({ channel, chat_id, message_id }) => callApiPost(`/messages/${channel}/${chat_id}/unread`, { message_id });
export const updateChatToggleAnswered = ({ chat_id, answered }) => callApiPost(`/chat/${chat_id}/toggle_answered`, { answered });
export const updatePersonalRemark = payload => callApiPost("/user/remark", payload, userSchema);
export const updateReview = (id, payload) => callApiPost(`/homework/${id}/review`, payload, homeworkSchema);
export const uploadTextHomeworkVersion = (
  text,
  {
    flow_id,
    lesson_id,
    exercise_id,
  },
) => callApiPost(
  `/flow/${flow_id}/lesson/${lesson_id}/exercise/${exercise_id}/homework/upload`,
  { homework: { text } },
  homeworkSchema,
);

export const showOnCourse = id => callApiPost(`/homework/${id}/show-on-course`, {}, homeworkSchema);
export const sendMultiMessage = ({ course_id, flow_id, message }) => callApiPost("/messages/multisend", { course_id, flow_id, message });

export const receiveMessage = payload => normalize(payload, messageSchema);
export const messageReaded = payload => normalize(payload, chatSchema);

export const subscribeToNotifications = payload => callApiPost("/messages/subscribe", payload);

export const fetchLessonTest = lesson_id => callApi(`/lesson/${lesson_id}/test`);
export const sendLessonTestResult = lesson_id => callApiPost(`/lesson/${lesson_id}/test-complete`);

export const fetchStudentTest = testId => callApi(`/student-test/${testId}/show-api`);

export const chatTyping = ({ user_id, chat_id, name, status }) => callApiPost(`/chat/${chat_id}/typing/${status}`, { user_id, name });

export const fetchWebinars = context => callApiPost(`/webinars/api`, context);

// convert mp4 to gif
export const startConvertingMp4ToGif = id => callApi(`/homeworks/${id}/start-gif-convertion`);
// download converted gif
export const downloadConvertedGif = () => new Promise((resolve) => {
  $.ajax({
    url: `${API_ROOT}/download-converted-gif`,
    dataType: "binary",
    xhrFields: {
      responseType: "blob",
    },
    success(data, status, xhr) {
      if (status !== "success") {
        return resolve({ error: data.error || 'Произошла ошибка' });
      }

      const blob = new Blob([data], {
        type: xhr.getResponseHeader("Content-Type"),
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "download.gif";
      link.click();
      resolve({ response: { ok: true } });
    },
    error(err) {
      resolve({ error: err });
    },
  });
});

export const freeSubscribeToCourse = (courseId) => {
  $.ajax({
    url: `/course/${courseId}/restrict-subscribe-api`,
    type: 'post',
    success(response) {
      if (response.ok && response.link) {
        document.location = response.link;
        return;
      }

      if (response.message) {
        return alert(response.message);
      }

      alert('Произошла ошибка');
    },
    error(request) {
      console.error('AJAX error in request:', JSON.stringify(request, null, 2));

      if (request.responseJSON && request.responseJSON.error) {
        return alert(request.responseJSON.error);
      }
      alert('Произошла ошибка');
    },
  });
};
