import React from "react";
import { Provider } from "react-redux";
import initializeStore from "Core/store/initializeStore";
import authSaga from "Core/sagas/auth";
import { history } from "Core/services";
import { Router } from "react-router-dom";

import AuthSwitch from './AuthSwitch';

const getStore = initializeStore(authSaga);

const AuthRoot = () => (
  <Provider store={getStore()}>
    <Router history={history}>
      <AuthSwitch />
    </Router>
  </Provider>
);

export default AuthRoot;
