import React, { Component } from 'react';
import { bool, func } from 'prop-types';

export default class ShowOnCourseButton extends Component {
  render() {
    const { showOnCourse, onShowOnCourse } = this.props;
    const myClass = 'modal-hw-show-on-course-wrapper';

    if (showOnCourse) {
      return (
        <div onClick={onShowOnCourse} className={`${myClass} -remove`} style={{ marginRight: 0 }} >
          <i className="fa fa-minus-circle" />
          <span>Низвергнуть в Нифльхейм</span>
        </div>
      );
    }

    return (
      <div onClick={onShowOnCourse} className={myClass} style={{ marginRight: 0 }} >
        <i className="fa fa-star-o" />
        <span>Отправить в зал славы</span>
      </div>
    );
  }
}

ShowOnCourseButton.propTypes = {
  showOnCourse: bool.isRequired,
  onShowOnCourse: func.isRequired,
};
