/* eslint-disable no-constant-condition */
import { take, put, call, fork, all } from "redux-saga/effects";
import { api } from "../services";
import * as actions from "../actions";

function* watchLoadStudentWork() {
  while (true) {
    const { payload } = yield take(actions.STUDENT_WORK_LOAD);
    const { response } = yield call(api.fetchStudentWork, payload.id);

    if (response) {
      yield put({
        type: actions.STUDENT_WORK_LOAD_DONE,
        response,
        payload,
      });
    }
  }
}

function* watchLoadhomeworks() {
  while (true) {
    const { payload } = yield take(actions.LOAD_HOMEWORK);
    const { response } = yield call(api.fetchHomework, payload.id);

    if (response) {
      yield put({
        type: actions.LOAD_HOMEWORK_DONE,
        response,
        payload,
      });
    }
  }
}

function* watchshowOnCourse() {
  while (true) {
    const { id } = yield take(actions.HOMEWORK_SHOW_ON_COURSE);

    const { response } = yield call(api.showOnCourse, id);
    if (response) {
      yield put({ type: actions.HOMEWORK_SHOW_ON_COURSE_DONE, response });
    }
  }
}

function* watchUpdateReview() {
  while (true) {
    const { id, payload } = yield take(actions.HOMEWORK_UPDATE_REVIEW);

    const { response } = yield call(api.updateReview, id, payload);
    if (response) {
      yield put({ type: actions.HOMEWORK_UPDATE_REVIEW_DONE, response });
    }
  }
}

function* watchUloadTextHomeworkVersion() {
  while (true) {
    const { text, opt } = yield take(
      actions.UPLOAD_TEXT_HOMEWORK_VERSION,
    );

    const { response, error } = yield call(
      api.uploadTextHomeworkVersion,
      text,
      opt,
    );
    if (response) {
      yield put({
        type: actions.UPLOAD_TEXT_HOMEWORK_VERSION_DONE,
        response,
      });
    } else {
      yield put({
        type: actions.UPLOAD_TEXT_HOMEWORK_VERSION_FAILURE,
        error,
      });
    }
  }
}

export default function* root() {
  yield all([
    fork(watchLoadStudentWork),
    fork(watchLoadhomeworks),
    fork(watchshowOnCourse),
    fork(watchUpdateReview),
    fork(watchUloadTextHomeworkVersion),
  ]);
}
