import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useToasts } from "react-toast-notifications";
import UploadFile from "UI/UploadFile";

export default function ContestWorkViewHeader({
  user,
  prizePlaceIcon,
  prizePlaceTitle,
  contest: { id, isCompleted, title },
  allowedWorkTypes,
  contestWorkAddInProgress,
  uploadsExceeded,
  currentUserId,
  setInPropgress,
}) {
  let addToast = () => {};

  if (!isCompleted) {
    ({ addToast } = useToasts());
  }

  const renderFileUploader = () => {
    if (isCompleted) {
      return (
        <PrizePlaceIndicator>
          {prizePlaceIcon && <img src={prizePlaceIcon} alt="" />}
          {prizePlaceTitle && <span>{prizePlaceTitle}</span>}
        </PrizePlaceIndicator>
      );
    }

    if (contestWorkAddInProgress) {
      return (
        <AddInProgressWarn>
          <FontAwesomeIcon icon={faSpinner} pulse color="#2f80ed" />
          <span>Обрабатываем вашу работу</span>
        </AddInProgressWarn>
      );
    }

    if (uploadsExceeded || currentUserId !== user.id) {
      return null;
    }

    return (
      <UploadFile
        mini
        label=""
        name="file"
        uploadPath={`/contest/${id}/upload-work`}
        allowedTypes={allowedWorkTypes}
        onStart={setInPropgress}
        onError={message => addToast(message, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        })}
      >
        <Button>Загрузить новую версию</Button>
      </UploadFile>
    );
  };

  return (
    <HeaderComponent>
      <a href={`/user/${user.id}`} className="author">
        <img src={user.avatar} alt="" />
        <div className="wrapper">
          <div className="name">{user.nickname}</div>
          <div className="contest">
            <b>Конкурс:</b>
            {' '}
            &quot;
            {title}
            &quot;
          </div>
        </div>
      </a>
      {renderFileUploader()}
    </HeaderComponent>
  );
}

const AddInProgressWarn = styled.div`
  display: flex;
  align-items: center;
  font: 16px/1.25 Roboto;

  span {
    margin-left: 12px;
  }
`;

const HeaderComponent = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;

  .author {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
      width: 50px;
      height: 50px;
    }

    .name {
      font-weight: 500;
    }

    .contest {
      margin-top: 3px;
      color: #000;
    }
  }
`;

const PrizePlaceIndicator = styled.div`
  span {
    font-size: 18px;
    margin-left: 13px;
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  color: #2f80ed;
  border: #2f80ed solid 1px;
  border-radius: 4px;
  height: 28px;
  padding: 0 7px;
  font: 15px/1 Roboto;
  cursor: pointer;
  transition: all 100ms;
  transition-property: background-color, color;
  user-select: none;

  &:hover {
    background: #2f80ed;
    color: #fff;
  }
`;
