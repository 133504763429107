/*
  Редактируя этот файл не забудь внести соответствующие изменения в CoursePracticeServer,
  это необходимо для синхронизация серверного рендеринга с клиентским (react on rails)
*/

/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react';
import { array, string } from 'prop-types';

import SwiperCore, { Navigation, Lazy } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation, Lazy]);

const CoursePracticeClient = ({ descr1, descr2, workExamples }) => {
  const [activeWorkIndex, setActiveWorkIndex] = useState(0);
  const [mounted, setMounted] = useState(false);
  const swiperNext = useRef(null);
  const swiperPrev = useRef(null);

  useEffect(() => {
    setMounted(true);
  }, []);

  const renderSwiper = () => (
    <Swiper
      navigation={{
        nextEl: swiperNext.current,
        prevEl: swiperPrev.current,
      }}
      loop
      onSlideChange={({ realIndex }) => setActiveWorkIndex(realIndex)}
      mousewheel={false}
      watchOverflow
      lazy
    >
      {workExamples.map(work => (
        <SwiperSlide key={work.id}>
          <div className="work-example d-flex justify-content-center">
            <img className="swiper-lazy" data-src={work.work_progress_url} alt="" />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );

  return (
    <section id="course-practice">
      <div className="wide-block-wrapper">
        <div className="wrapper">
          <div className="course-page-title">
            Самая важная часть обучения — практика и обратная связь
          </div>

          {descr1 && (
            <div className="practice-descr" dangerouslySetInnerHTML={{ __html: descr1 }} />
          )}

          {workExamples.length > 0 && (
            <>
              <div className="work-examples">
                <div className="ui carousel no-pagination">
                  <div className="carousel-wrapper">
                    {mounted && renderSwiper()}

                    <div ref={swiperPrev} className="swiper-button-prev" />
                    <div ref={swiperNext} className="swiper-button-next" />
                  </div>
                </div>

              </div>
              <div className="d-flex flex-column">
                <a
                  className="ui button practice-see"
                  href={workExamples[activeWorkIndex].article_link}
                  rel="noreferrer"
                  target="_blank"
                >
                  Посмотреть процесс доработки
                </a>
              </div>
            </>
          )}

          {descr2 && (
            <>
              <hr />
              <div className="row align-items-center no-gutters mb-n3">
                <div className="col" dangerouslySetInnerHTML={{ __html: descr2 }} />
                <div className="col-auto mr-n5 ml-4 mb-5 mt-n4">
                  <img data-src="/images/course_cat.png" alt="" className="lozad" />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

CoursePracticeClient.propTypes = {
  descr1: string,
  descr2: string,
  workExamples: array.isRequired,
};

CoursePracticeClient.defaultProps = {
  descr1: '',
  descr2: '',
};

export default CoursePracticeClient;
