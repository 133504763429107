import React, { Component } from "react";
import initializeStore from "Core/store/initializeStore";
import { Provider } from "react-redux";
import UserPage from "Main/containers/UserPage";

const getStore = initializeStore();

class UserPageRoot extends Component {
  render() {
    return (
      <Provider store={getStore()}>
        <UserPage {...this.props} />
      </Provider>
    );
  }
}

export default UserPageRoot;
