import configureStore from './configureStore';
import { joinSagas } from '../sagas/helpers';
import sharedSagas from '../sagas/shared-sagas';
import uploadFileSaga from "../gears/uploadFile/sagas";
import uploadSaga from "../sagas/upload";

const allSagas = [];

export default function initializeStore(sagas) {
  // every module adds own sagas
  if (typeof sagas === 'function') {
    allSagas.push(sagas);
  } else if (typeof sagas === 'object') {
    allSagas.push(...sagas);
  }

  return () => {
    // используем общий смерженный начальный стэйт
    // eslint-disable-next-line no-underscore-dangle
    const store = window.store || configureStore(window.__INITIAL_STATE__);

    // после всех зарегистрированных модулей
    // запускаем все саги разом
    if (!window.store) {
      window.store = store;
      store.runSaga(sharedSagas);
      store.runSaga(uploadFileSaga);
      store.runSaga(uploadSaga);
      store.runSaga(joinSagas(allSagas));
    }

    return store;
  };
}
