import React, { useEffect, useState } from "react";
import { bool, func, number, string } from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import cn from "classnames";
import delay from "lodash/delay";

import {
  loadEduCertificateTemplate,
  issuanceEduCertificate,
  setEduCertificateIssuanced,
} from "Core/actions";

import Input from "UI/Input";
import Modal from "Main/components/layouts/modals/Modal";

function EduCertificateIssuance(props) {
  const {
    courseId,
    userId,
    closeModal,
    loaded,
    issuancing,
    imageTemplateUrl,
    imageIssuancedUrl,
    fullname,
  } = props;

  const [timeoutId, setTimeoutId] = useState(null);
  const [modalShown, setModalShow] = useState(false);
  const [isBodyContentLoaded, setBodyContentLoadedState] = useState(false);

  useEffect(() => {
    props.loadEduCertificateTemplate({ courseId, userId });
    return () => props.setEduCertificateIssuanced();
  }, [courseId]);

  useEffect(() => {
    if (loaded && isBodyContentLoaded) {
      setTimeoutId(delay(() => setModalShow(true), 200));
      return () => setModalShow(false);
    }
  }, [loaded, isBodyContentLoaded]);

  useEffect(() => {
    if (timeoutId) {
      return () => clearTimeout(timeoutId);
    }
  }, [timeoutId]);

  return (
    <Modal closeModal={closeModal}>
      {!loaded ? (
        <div className="load-indicator d-flex justify-content-center my-3">
          <div className="loadersmall modal-hw-loader" />
        </div>
      ) : (
        <div className="modal-base-content">
          {!imageTemplateUrl ? (
            <NoCertWarn>
              Для данного курса пока не загружен сертификат
            </NoCertWarn>
          ) : (
            <>
              {issuancing && (
                <IssuancingIndicator>
                  <div className="load-indicator d-flex justify-content-center my-3">
                    <div className="loadersmall modal-hw-loader" />
                  </div>
                </IssuancingIndicator>
              )}
              <ImageContainer className={cn({ shown: modalShown })}>
                {imageIssuancedUrl ? (
                  <img alt="" src={imageIssuancedUrl} />
                ) : (
                  <img
                    alt=""
                    src={imageTemplateUrl}
                    onLoad={() => setBodyContentLoadedState(true)}
                  />
                )}
                {imageIssuancedUrl ? (
                  <>
                    <div className="cert-issuanced">Сертификат выдан</div>
                    <button type="button" className="close-btn" onClick={closeModal}>
                      Закрыть
                    </button>
                  </>
                ) : (
                  <Formik
                    initialValues={{
                      fullname,
                    }}
                    validationSchema={Yup.object({
                      fullname: Yup.string()
                        .min(5, "Имя должно быть не менее 5 символов")
                        .matches(
                          /^[а-яА-Яё-\s]+$/,
                          "Имя может состоять только из русских букв",
                        )
                        .required("Имя обязательно для заполнения"),
                    })}
                    validateOnChange
                    validateOnBlur={false}
                    validateOnMount
                    onSubmit={(values) => {
                      if (values.fullname !== fullname) {
                        return props.issuanceEduCertificate({
                          fullname: values.fullname,
                        });
                      }

                      return props.issuanceEduCertificate();
                    }}
                  >
                    {formikProps => (
                      <FormikForm
                        onReset={formikProps.handleReset}
                        onSubmit={formikProps.handleSubmit}
                        className="student-cert-form"
                      >
                        <Input
                          className="fullname-field"
                          name="fullname"
                          placeholder="Введите имя ученика"
                          value={formikProps.values.fullname}
                          onChange={formikProps.handleChange}
                        />
                        <button className="issuance-btn" type="submit">
                          Выдать сертификат
                        </button>
                      </FormikForm>
                    )}
                  </Formik>
                )}
              </ImageContainer>
            </>
          )}
        </div>
      )}
    </Modal>
  );
}

function mapStateToProps(state) {
  const props = state.local.eduCertificateReducer;
  return props;
}

const EduCertificateIssuanceConnected = connect(mapStateToProps, {
  loadEduCertificateTemplate,
  issuanceEduCertificate,
  setEduCertificateIssuanced,
})(EduCertificateIssuance);

EduCertificateIssuance.propTypes = {
  courseId: number.isRequired,
  userId: number.isRequired,
  closeModal: func,
  loaded: bool.isRequired,
  issuancing: bool.isRequired,
  loadEduCertificateTemplate: func.isRequired,
  setEduCertificateIssuanced: func.isRequired,
  imageTemplateUrl: string,
  imageIssuancedUrl: string,
  fullname: string,
  issuanceEduCertificate: func.isRequired,
};

EduCertificateIssuance.defaultProps = {
  closeModal: () => {},
  imageTemplateUrl: null,
  imageIssuancedUrl: null,
  fullname: '',
};

export default EduCertificateIssuanceConnected;

const NoCertWarn = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  font: 500 20px/1 Roboto;
  box-shadow: 0 0 10px 3px rgba(255, 255, 255, 0.6);
`;

const IssuancingIndicator = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.75);
`;

const ImageContainer = styled.div`
  position: relative;
  visibility: hidden;
  opacity: 0;
  margin-top: 60px;

  img {
    width: 880px;
  }

  .student-cert-form {
    position: absolute;
    left: 50%;
    top: 555px;
  }

  .fullname-field {
    transform: translateX(-50%);
  }

  .close-btn,
  .issuance-btn {
    position: fixed;
    right: 50px;
    bottom: 20px;
  }

  .cert-issuanced {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    font: 500 20px/1 Roboto;
    top: -74px;
    box-shadow: 0 0 10px 3px rgba(255, 255, 255, 0.6);
  }

  &.shown {
    visibility: visible;
    opacity: 1;
  }

  button {
    border: none 0 !important;
    outline: none 0 !important;
    height: 40px;
    background: #2196f3;
    color: #fff;
    border-radius: 4px;
    padding: 0 20px;

    &:hover {
      background: #0d6ab5;
    }
  }
`;
