import React, { useState } from 'react';
import { array, bool, func } from 'prop-types';

import Spinner from '../layouts/Spinner';

const BestWorksList = ({ items, loadMore, loading, hasMore }) => {
  const [loadCounter, setLoadCounter] = useState(1);

  const onLoadMore = () => {
    setLoadCounter(loadCounter + 1);
    loadMore();
  };

  const renderLoadMoreButton = () => {
    if (loading) {
      return <Spinner className="mt-3 w-100" />;
    }

    if (!hasMore) {
      return null;
    }

    let btnText;
    let onClick;

    if (loadCounter < 3) {
      btnText = 'Показать ещё';
      onClick = onLoadMore;
    } else {
      btnText = 'Смотреть все работы';
      onClick = () => { document.location.href = '/student-works'; };
    }

    return (
      <div className="mx-auto mt-4">
        <button onClick={onClick} type="button" className="btn btn-outline-primary">
          {btnText}
        </button>
      </div>
    );
  };

  return (
    <div className="d-flex flex-wrap">
      {items}

      {renderLoadMoreButton()}
    </div>
  );
};

BestWorksList.propTypes = {
  items: array.isRequired,
  loading: bool.isRequired,
  hasMore: bool.isRequired,
  loadMore: func.isRequired,
};

BestWorksList.defaultProps = {
};

export default BestWorksList;
