import _DoubleCheckIcon from './DoubleCheckIcon';
import _EnvelopeWithClockIcon from './EnvelopeWithClockIcon';
import _BellIcon from './BellIcon';
import _RemarkBlueIcon from './RemarkBlueIcon';
import _RemarkGrayIcon from './RemarkGrayIcon';

export const DoubleCheckIcon = _DoubleCheckIcon;
export const EnvelopeWithClockIcon = _EnvelopeWithClockIcon;
export const BellIcon = _BellIcon;
export const RemarkBlueIcon = _RemarkBlueIcon;
export const RemarkGrayIcon = _RemarkGrayIcon;
