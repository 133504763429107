import React, { Component } from 'react';
import { func, object, string, array, number, bool } from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { getMyExerciseWorksCoversFields } from "Core/reducers/selectors";
import {
  loadCatalogItems, prepareCatalog, openPassLessonTestModal,
  uploadTextHomeworkVersion, uploadRequest,
} from '../../core/actions';

import Modal from "../components/layouts/modals/Modal";
import PaymentModal from "../components/Chat/PaymentModal";
import UploadTextHomeworkModal from "../components/Homework/UploadTextHomeworkModal";
import LessonWorks from '../components/Homework/LessonWorks';
import CatalogFilters from '../components/Catalog/CatalogFilters';
import Spinner from '../components/layouts/Spinner';
import StickySpinner from '../components/layouts/StickySpinner';
import Homework from '../components/Homework/Homework';

const entity = 'homeworks';

class MyHomeworksCatalog extends Component {
  state = { textModalShown: false, paymentModalSHown: false }

  componentDidMount() {
    const { pagination: { sortType: flowId, firstItemsLoaded }, lessonId } = this.props;

    if (firstItemsLoaded) {
      this.onLoadItems(flowId, lessonId);
    }

    const { customParams, reduxCatalogKey } = this.props;

    this.props.prepareCatalog({
      entity,
      reduxCatalogKey,
      pagination: { sortType: flowId },
      customParams: {
        ...customParams,
        flowId,
        lessonId,
      },
    });
  }

  onLoadItems = (flowId, lessonId = null) => {
    const { pagination, customParams, reduxCatalogKey } = this.props;

    this.props.loadItems({
      ...pagination,
      sortType: flowId,
      entity,
      reduxCatalogKey,
      customParams: {
        ...customParams,
        flowId,
        lessonId,
      },
    });
  }

  getLessonById = (id) => {
    const { customOptions: { lessons } } = this.props;

    return lessons.find(lesson => lesson.id === id);
  }

  handleLoadWorkClick = (inputRef, exercise) => {
    const { customOptions: { isAvalableFlow, chatWorkTime, showTimeUpModal } } = this.props;

    if (!isAvalableFlow) {
      // eslint-disable-next-line no-alert
      return alert("Извините, курс завершен");
    }

    if (showTimeUpModal) {
      return this.setState({ paymentModalSHown: true });
    }

    if (chatWorkTime.isUp && chatWorkTime.blockHomeworkLoading) {
      // eslint-disable-next-line no-alert
      return alert("Загружать домашние работы можно в рабочее время чата");
    }

    const lesson = this.getLessonById(exercise.lessonId);

    if (lesson.isShowTest) {
      return this.props.openTestModal({
        lessonId: lesson.id,
        lessonTitle: lesson.title,
        reduxCatalogKey: this.props.reduxCatalogKey,
      });
    }

    this.exercise = exercise;

    if (exercise.type === "text") {
      return this.setState({ textModalShown: true });
    }

    inputRef.click();
  }

  handleWorkUpload = (file) => {
    const {
      customParams: { courseId },
      pagination: { sortType },
    } = this.props;

    this.props.uploadVersion(file, {
      course_id: courseId,
      flow_id: sortType,
      lesson_id: this.exercise.lessonId,
      exercise_id: this.exercise.id,
    });
  }

  handleTextWorkUpload = (text) => {
    const {
      customParams: { courseId },
      pagination: { sortType },
    } = this.props;

    this.props.uploadTextVersion(text, {
      course_id: courseId,
      flow_id: sortType,
      lesson_id: this.exercise.lessonId,
      exercise_id: this.exercise.id,
    });
  };

  closeTextWorkModal = () => this.setState({ textModalShown: false });

  renderMyWorks = () => {
    const { exerciseWorks, customOptions, lessonId, reduxCatalogKey } = this.props;
    let lessonNumber = 0;
    let prevWorksAccepted = customOptions.prematureWorkLoading;

    return customOptions.lessons.map((lessonData) => {
      const { exercises, ...lesson } = lessonData;
      const prevLessonWorksAccepted = prevWorksAccepted;

      const exercisesWithWorks = exercises.map((exercise) => {
        const homework = exerciseWorks[exercise.id];

        if (prevWorksAccepted) {
          prevWorksAccepted = lesson.hasFreeAccess || (!!homework && homework.status === "accepted");
        }

        return {
          ...exercise,
          homework,
        };
      });

      lessonNumber += 1;
      const title = lesson.title || `${lessonNumber} урок`;

      return (
        <div key={lesson.id}>
          {!lessonId && (
            <div className="line_header">
              <div className="line_header_text line_header_text_lines">
                <span>{title}</span>
              </div>
            </div>
          )}

          <LessonWorks
            exercisesWithWorks={exercisesWithWorks}
            isLessonStarted={lesson.started || prevLessonWorksAccepted}
            handleLoadWorkClick={this.handleLoadWorkClick}
            handleLoadWork={this.handleWorkUpload}
            reduxCatalogKey={reduxCatalogKey}
          />
        </div>
      );
    });
  }

  renderMyOldWorks = () => {
    if (this.props.lessonId) return null;

    const { lessonOldWorks } = this.props;

    if (Object.keys(lessonOldWorks).length === 0) return null;

    let { oldLessons } = this.props.customOptions;

    if (lessonOldWorks.old) {
      oldLessons = [...oldLessons, { id: 'old', name: 'Устаревший урок' }];
    }

    return (
      <>
        <hr />
        <div className="line_header" style={{ color: '#2196f3' }}>
          <div className="line_header_text">Предыдущие домашние задания</div>
        </div>

        {oldLessons.map((oldLesson) => {
          if (!lessonOldWorks[oldLesson.id]) return null;

          return (
            <div key={oldLesson.id}>
              <div className="line_header">
                <div className="line_header_text line_header_text_lines">
                  <span>{oldLesson.name}</span>
                </div>
              </div>

              <div className="d-flex flex-wrap">

                {lessonOldWorks[oldLesson.id].map(oldHomework => (
                  <div key={oldHomework.id} className="cell cell_33">
                    <Homework
                      id={oldHomework.id}
                      status={oldHomework.status}
                      image={oldHomework.image}
                      type={oldHomework.type}
                      text={oldHomework.text}
                      reduxCatalogKey={this.props.reduxCatalogKey}
                      useHomeworkOldComponent
                    />
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </>
    );
  }

  renderContent = () => {
    const { pagination, filters } = this.props;

    if (!pagination.firstItemsLoaded) {
      return <div className="py-5"><Spinner className="py-5" /></div>;
    }

    return (
      <>
        {filters.length > 0 && (
          <CatalogFilters
            filters={filters}
            activeFilter={pagination.sortType}
            onSwitch={this.onLoadItems}
          />
        )}

        {this.renderMyWorks()}

        {this.renderMyOldWorks()}
      </>
    );
  }

  render() {
    return (
      <div className="catalog pb-3">
        {this.props.workLoading && <StickySpinner /> }

        {this.state.textModalShown && (
          <Modal closeModal={this.closeTextWorkModal}>
            <UploadTextHomeworkModal
              backToHomework={this.closeTextWorkModal}
              onTextVersionUpload={this.handleTextWorkUpload}
              closeHomework={this.closeTextWorkModal}
            />
          </Modal>
        )}

        {this.state.paymentModalSHown && (
          <PaymentModal
            chat_id={this.props.customOptions.chatId}
            closeModal={() => this.setState({ paymentModalSHown: false })}
          />
        )}

        {!this.props.lessonId && (
          <header className="catalog__header ctg-header">
            <h1 className="ctg-header__title">Домашние задания</h1>
          </header>
        )}

        {this.renderContent()}
      </div>
    );
  }
}

MyHomeworksCatalog.propTypes = {
  // OWN PROPS
  flowId: number.isRequired, // eslint-disable-line react/no-unused-prop-types
  lessonId: number,
  filters: array,
  // MAP STATE PROPS
  customParams: object.isRequired,
  pagination: object.isRequired,
  reduxCatalogKey: string.isRequired,
  exerciseWorks: object,
  lessonOldWorks: object,
  customOptions: object,
  workLoading: bool,
  // MAP DISPATCH PROPS
  loadItems: func.isRequired,
  prepareCatalog: func.isRequired,
  openTestModal: func.isRequired,
  uploadTextVersion: func.isRequired,
  uploadVersion: func.isRequired,
};

MyHomeworksCatalog.defaultProps = {
  lessonId: null,
  filters: [],
  customOptions: {},
  exerciseWorks: {},
  lessonOldWorks: {},
  workLoading: false,
};

const mapStateToProps = (state, { flowId, lessonId }) => {
  const defaultState = { pagination: { sortType: flowId }, customOptions: null };
  let reduxCatalogKey = 'myHomeworks';

  if (lessonId) {
    reduxCatalogKey += lessonId;
  }

  const {
    pagination, customOptions,
  } = get(state.entities.catalog, reduxCatalogKey, defaultState);

  if (!customOptions) {
    return {
      pagination,
      reduxCatalogKey,
      customParams: {
        myHomeworks: 1,
      },
    };
  }

  const userId = state.local.currentUser.id;
  const { courseId } = customOptions;

  const {
    exerciseWorks,
    lessonOldWorks,
  } = getMyExerciseWorksCoversFields(state, userId, courseId);

  return {
    pagination,
    exerciseWorks,
    lessonOldWorks,
    reduxCatalogKey,
    customOptions,
    workLoading: state.utils.uploadReducer.loading,
    customParams: {
      myHomeworks: 1,
    },
  };
};

export default connect(mapStateToProps, {
  prepareCatalog,
  loadItems: loadCatalogItems,
  openTestModal: openPassLessonTestModal,
  uploadTextVersion: uploadTextHomeworkVersion,
  uploadVersion: uploadRequest,
})(MyHomeworksCatalog);
