import React, { Component } from 'react';
import { number, func } from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Modal from 'react-modal';
import cl from 'classnames';

import { sendMultiMessage } from 'Core/actions';

import { flow as FlowType, course as CourseType } from 'Main/types';

const customStyles = {
  overlay: {
    display: 'block',
    background: 'rgba(0,0,0, 0.4)',
  },
  content: {
    width: 720,
    height: 332,
  },
};

class MassSender extends Component {
  constructor(props) {
    super(props);
    this.handleSend = this.handleSend.bind(this);
  }

  handleSend() {
    const message = this.input.value.trim();
    if (message.length) {
      this.props.sendMessage(this.props.course.id, this.props.flow.id, message);
      this.props.onClose();
    }
  }

  render() {
    const { counter, course, flow, onClose } = this.props;
    return (
      <Modal
        isOpen
        style={customStyles}
        onRequestClose={onClose}
        className="modal-dialog modal-pixel modal_mass_sender"
        overlayClassName={{
          base: 'modal modal-open',
          afterOpen: 'show fade',
          beforeClose: '',
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-content">
          <div className="modal-header">
            Рассылка сообщения
          </div>
          <div className="modal-body">
            <p>
              Сообщение отправится
              {' '}
              {counter}
              {' '}
              ученикам группы «
              {course.title}
              » (поток с
              {' '}
              {moment(flow.date).format('D MMMM')}
              )
            </p>
            <textarea className="mass_sender_textarea" ref={(input) => { this.input = input; }} />
            <div className={cl('p-btn', { '-disabled': this.input && this.input.value.length })} onClick={this.handleSend}>Отправить</div>
          </div>
        </div>
      </Modal>
    );
  }
}

MassSender.propTypes = {
  course: CourseType.isRequired,
  flow: FlowType.isRequired,
  counter: number.isRequired,
  onClose: func.isRequired,
  sendMessage: func.isRequired,
};

function mapStateToProps(state, { course_id, flow_id }) {
  const {
    entities: { flows, courses },
    local: { is_admin },
  } = state;
  const flow = flows[flow_id];
  const course = courses[course_id];
  return {
    course,
    flow,
    counter: is_admin ? flow.chats_count : null,
  };
}

export default connect(mapStateToProps, {
  sendMessage: sendMultiMessage,
})(MassSender);
