import React from 'react';
import { bool, func, number, object, string } from 'prop-types';

import CatalogDropdownFiltersRouter from '../Catalog/CatalogDropdownFiltersRouter';
import CatalogDropdownOrderFilters from '../Catalog/CatalogDropdownOrderFilters';
import { CatalogFiltersWrapper } from '../Catalog/CatalogFiltersWrapper';

const WebinarsLayout = ({
  children, activeFilter, pageInfo: { banner, filters },
  onSwitchCategory, onSwitchSort, loading, getRef, activeSort,
}) => (
  <div className="catalog" ref={getRef}>
    <header className="catalog__header ctg-header">
      {/* Header title + Banner */}
      <div>
        <h1 className="ctg-header__title">{filters[activeFilter].headerH1}</h1>
        <img className="ctg-header__banner" src={banner} alt="" />
      </div>

      {/* Filters + Sorts */}
      <div className="ctg-header__actions">
        <CatalogFiltersWrapper>
          <CatalogDropdownFiltersRouter
            filters={filters}
            activeFilter={activeFilter}
            onSwitch={onSwitchCategory}
          />

          <CatalogDropdownOrderFilters
            onSwitch={onSwitchSort}
            activeFilter={activeSort}
          />
        </CatalogFiltersWrapper>
      </div>
    </header>
    {loading ? (
      <div className="load-indicator d-flex justify-content-center mt-5">
        <div className="loadersmall modal-hw-loader" />
      </div>
    ) : children}
  </div>
);

WebinarsLayout.propTypes = {
  loading: bool.isRequired,
  onSwitchCategory: func.isRequired,
  onSwitchSort: func.isRequired,
  children: object,
  pageInfo: object.isRequired,
  activeFilter: string.isRequired,
  activeSort: number.isRequired,
  getRef: object.isRequired,
};

WebinarsLayout.defaultProps = {
  children: null,
};

export default WebinarsLayout;
