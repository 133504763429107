import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadUserProfile } from "Core/actions";
import styled from "styled-components";
import cn from "classnames";
import { SocialProfiles } from "UI/SocialProfiles";
import TabsSwitcher from "UI/TabsSwitcher";

import ImageModal from "../components/layouts/modals/ImageModal";
import { StudentWork } from "../components/StudentWork";
import { ContestWorkView } from "../components/ContestWorkView";
import CardWork from "../components/CardWork/CardWork";
import CatalogPlaceholder from "../components/Catalog/CatalogPlaceholder";
import Spinner from '../components/layouts/Spinner';
import {
  CoursesCountIcon,
  WorksCountIcon,
} from "../components/layouts/icons/ProfileIcons";
import {
  UserProfilePage,
  UserAvatar,
  SectionTitle,
  AchievementCounters,
  MyCerts,
} from "./Profile";
import CatalogRoot from './CatalogRoot';

function UserPage(props) {
  const {
    loading,
    avatarUrl,
    fullname,
    worksCount,
    coursesCount,
    socials,
    userId,
    eduCerts = [],
  } = props;

  const [activeTab, setActiveTab] = useState("works");
  const [contestWorkViewId, setContestWorkViewId] = useState(null);
  const [contestTabWasShowed, setContestTabWasShowed] = useState(false);
  const [certificateFullsized, setCertificateFullsized] = useState(null);

  useEffect(() => {
    document.getElementById("main_wrapper").style.background = "#fcfcfc";
    props.loadUserProfile(userId);
  }, []);

  useEffect(() => {
    // рендерим (подгружаем) конкурсные работы, только после перехода
    if (activeTab === 'contest-works' && !contestTabWasShowed) {
      setContestTabWasShowed(true);
    }
  }, [activeTab]);

  const StudentWorkWrapper = workProps => (
    <div className="catalog-item cell cell_33">
      <StudentWork {...workProps} />
    </div>
  );

  const ContestWorkWrapper = workProps => (
    <div className="catalog-item cell cell_33">
      <CardWork
        {...workProps}
        onClick={setContestWorkViewId}
        showPrize
      />
    </div>
  );

  const renderContent = () => {
    if (loading) {
      return <Spinner className="py-5" />;
    }

    return (
      <>
        <MyProfileSection>
          <UserAvatar image={avatarUrl} />
          <div className="user-info">
            <SectionTitle>{fullname}</SectionTitle>
            <AchievementCounters>
              {coursesCount && (
                <div className="achievement-counter">
                  <CoursesCountIcon />
                  <span>{coursesCount}</span>
                </div>
              )}
              {worksCount && (
                <div className="achievement-counter">
                  <WorksCountIcon />
                  <span>{worksCount}</span>
                </div>
              )}
            </AchievementCounters>
            {socials && socials.length > 0 && (
              <div className="social-profiles">
                <SocialProfiles links={socials} />
              </div>
            )}
          </div>
        </MyProfileSection>
        <MyResultsSection>
          <TabsSwitcher
            defaultActive="works"
            items={{ works: "Работы", certs: "Сертификаты", 'contest-works': 'Конкурсы' }}
            onSwitch={idx => setActiveTab(idx)}
          />
          {/* User works */}
          <div
            className={cn("wrapper", {
              shown: activeTab === "works",
            })}
          >
            <CatalogRoot
              options={{
                header: {
                  categoriesShown: true,
                },
              }}
              defaultAllFilterName="Все работы"
              entity="homeworks"
              reduxCatalogKey="userPageWorks"
              ItemComponent={StudentWorkWrapper}
              customParams={{ userProfileId: parseInt(userId, 10) }}
            />
          </div>

          {/* Sertificates */}
          <div
            className={cn("wrapper", {
              shown: activeTab === "certs",
            })}
          >
            {eduCerts.length ? (
              <>
                <MyCerts>
                  {eduCerts.map((item, idx) => (
                    <div className="cert" key={idx}>
                      <img
                        alt=""
                        src={item.url}
                        onClick={() => setCertificateFullsized(item.url)}
                      />
                    </div>
                  ))}
                </MyCerts>
                {certificateFullsized && (
                  <ImageModal
                    image_src={certificateFullsized}
                    closeImageModal={() => setCertificateFullsized(null)}
                  />
                )}
              </>
            ) : (
              <div className="my-4">
                <CatalogPlaceholder text="Сертификаты отсутствуют" />
              </div>
            )}
          </div>

          {/* Contest works */}
          <div
            className={cn("wrapper", {
              shown: activeTab === "contest-works",
            })}
          >
            {contestWorkViewId && (
              <ContestWorkView
                id={contestWorkViewId}
                reduxCatalogKey="userPageContestWorks"
                closeModal={() => setContestWorkViewId(null)}
              />
            )}

            {/* не рендерим каталог, до тех пор, пока не кликнут на вкладку */}
            {contestTabWasShowed && (
              <CatalogRoot
                entity="contestants"
                reduxCatalogKey="userPageContestWorks"
                ItemComponent={ContestWorkWrapper}
                customParams={{
                  userId: parseInt(userId, 10),
                  contestsUserPage: 1,
                }}
              />
            )}
          </div>
        </MyResultsSection>
      </>
    );
  };

  return (
    <UserProfilePage>
      {renderContent()}
    </UserProfilePage>
  );
}

const mapStateToProps = (state) => {
  const props = state.local.userProfileReducer;
  return props;
};

const UserPageConnected = connect(mapStateToProps, {
  loadUserProfile,
})(UserPage);

export default UserPageConnected;

const Panel = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
`;

const MyProfileSection = styled(Panel)`
  position: relative;
  flex: 1;
  padding: 20px 0;
  background: transparent;
  border: none 0;

  h5 {
    margin: 0;
  }

  .achievements,
  .social-profiles {
    margin: 16px 0 0;
  }

  .user-info {
    position: relative;
    margin-left: 37px;
  }
`;

const MyResultsSection = styled(Panel)`
  flex-flow: column;
  flex: auto;
  width: 100%;
  margin: 20px 0 0;
  padding: 30px 0;

  .wrapper {
    display: none;

    &.shown {
      display: block;
    }
  }
`;
