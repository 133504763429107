import React, { Component } from "react";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";

import initializeStore from "Core/store/initializeStore";

import Profile from "./Profile";
import Toast from '../components/layouts/Toast';

const getStore = initializeStore();

class ProfileRoot extends Component {
  render() {
    return (
      <Provider store={getStore()}>
        <ToastProvider components={{ Toast }}>
          <Profile />
        </ToastProvider>
      </Provider>
    );
  }
}

export default ProfileRoot;
