import React, { useState, useEffect } from "react";
import delay from "lodash/delay";
import findIndex from "lodash/findIndex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faChevronDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import { any, array, bool, func, string } from "prop-types";

export default function Dropdown({
  children, onSelect, hoverMode, label, useValidationOnClick, useChevronDown,
}) {
  const [expanded, setExpanded] = useState(false);
  const [timderId, setTimerId] = useState(false);

  const handleOnSelect = (index, nextLabel, attrs) => {
    setExpanded(false);
    onSelect({ index, label: nextLabel, attrs });
  };

  const handleItemClick = (e, idx, attrs) => {
    const { target: { textContent = "" } } = e;
    const nextLabel = textContent.replace(/\s+/, "");

    const selectedItemIndex = findIndex(children, item => item.props.selected);
    if (useValidationOnClick && idx === selectedItemIndex) return;

    handleOnSelect(idx, nextLabel, { itemRef: e.target, ...attrs });
  };

  const handleAnyClick = () => {
    setExpanded(false);
    document.removeEventListener("click", handleAnyClick);
  };

  const manageEvents = hoverMode
    ? {
      onMouseEnter: () => clearTimeout(timderId) && setExpanded(true),
      onMouseLeave: () => setTimerId(delay(() => setExpanded(false), 500)),
    }
    : {
      onClick: (e) => {
        e.cancelBubble = true;

        if (!expanded) {
          setExpanded(true);
          document.addEventListener("click", handleAnyClick);
        }
      },
    };

  useEffect(() => () => {
    document.removeEventListener("click", handleAnyClick);

    return () => {
      window.removeEventListener("click", handleAnyClick);
    };
  }, []);

  return (
    <div className="dropdown-comp" {...manageEvents}>
      {label ? (
        <div className="dropdown-comp__label">{label}</div>
      ) : (
        children.find(item => item.props.selected)
      )}
      <div className="dropdown-comp__icon">
        <FontAwesomeIcon icon={useChevronDown ? faChevronDown : faCaretDown} color="#4f4f4f" />
      </div>
      <div className={`dropdown-comp__list ${expanded ? 'dropdown-comp__list_expanded' : ''}`} expanded={expanded.toString()}>
        {children.map((item, idx) => {
          const { selected, children: itemChildren, ...attrs } = item.props;

          return (
            <div
              className="dropdown-comp__list-item"
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              onClick={e => handleItemClick(e, idx, attrs)}
            >
              <div className="pr-4 w-100">
                {item}
              </div>

              {selected && <FontAwesomeIcon color="#1a6dd3fc" icon={faCheck} />}
            </div>
          );
        })}
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  useValidationOnClick: bool,
  useChevronDown: bool,
  children: array,
  onSelect: func,
  hoverMode: bool,
  label: string,
};

Dropdown.defaultProps = {
  useValidationOnClick: true,
  useChevronDown: false,
  children: [],
  onSelect: () => {},
  hoverMode: false,
  label: null,
};

export function DropdownItem(props) {
  return <div className="dropdown-comp__item">{props.children}</div>;
}

DropdownItem.propTypes = {
  children: any,
};

DropdownItem.defaultProps = {
  children: "",
};
