import React from "react";
import { func, bool, number } from "prop-types";
import cl from "classnames";

import {
  user as UserType,
  message as MessageType,
  homework as HomeworkType,
  course as CourseType,
} from "Main/types";
import MessageContent from "./MessageContent";
import MessageManage from "./MessageManage";

const patterMessageWithCode = /^((.|\n)*)```([a-z]*)\n((.|\n)*)\n```((.|\n)*)$/;

export default function Message(props) {
  const { message } = props;
  const isCodeSnippet = patterMessageWithCode.test(message.text);

  return (
    <div
      className={cl("message-content", { "-unread": message.unread })}
      id={message.id}
    >
      <div className={cl("instant_message", { "-unread": message.unread })}>
        <div className="instant_message_content">
          <MessageContent {...{ isCodeSnippet, patterMessageWithCode, ...props }} />
        </div>
        <MessageManage {...{ isCodeSnippet, ...props }} />
      </div>
    </div>
  );
}

Message.propTypes = {
  user: UserType.isRequired,
  message: MessageType.isRequired,
  homework: HomeworkType,
  is_teacher: bool,
  is_teacher_message: bool,
  course: CourseType,
  onEditingMessage: func.isRequired,
  onUpdateReview: func.isRequired,
  openImageModal: func.isRequired,
  message_id: number.isRequired,
  me: number.isRequired,
  formatMessageTextToCode: func.isRequired,
};

Message.defaultProps = {
  homework: HomeworkType.defaults,
  is_teacher: false,
  is_teacher_message: false,
  course: false,
};
