import React from 'react';
import { bool, func, object } from 'prop-types';

import Like from './Like';
import CopyCodeButton from './CopyCodeButton';
import ShowOnCourseButtom from './ShowOnCourseButton';

const HomeworkOldActions = ({
  activeWork,
  isTeacher,
  isShowOnCourse,
  onShowOnCourse,
  onToggleLike,
}) => (
  <div className="modal-hw-actions">
    {activeWork.status === 'accepted' && (
      <Like
        onToggleLike={onToggleLike}
        likesNumber={activeWork.likesNumber}
        alreadyLiked={activeWork.alreadyLiked}
      />
    )}

    <div className="modal-hw-actions-right">
      {activeWork.text && <CopyCodeButton text={activeWork.text} />}

      {isTeacher && activeWork.status === 'accepted' && (
        <ShowOnCourseButtom
          onShowOnCourse={onShowOnCourse}
          showOnCourse={isShowOnCourse}
        />
      )}
    </div>
  </div>
);

HomeworkOldActions.propTypes = {
  activeWork: object.isRequired,
  isTeacher: bool.isRequired,
  isShowOnCourse: bool.isRequired,
  onShowOnCourse: func.isRequired,
  onToggleLike: func.isRequired,
};

export default HomeworkOldActions;
