/* eslint-disable no-constant-condition */
import { take, put, call, fork, select, all } from "redux-saga/effects";

import { api } from "../services";
import {
  NOTIFICATION_CENTER_LOAD, NOTIFICATION_CENTER_UPDATE, NOTIFICATION_CENTER_READY,
} from "../actions";

function* watchNotificationCenter() {
  while (true) {
    const { callback } = yield take([NOTIFICATION_CENTER_LOAD, NOTIFICATION_CENTER_UPDATE]);

    const { lastMinId, hots } = yield select(state => state.local.notificationCenterReducer);

    const { response } = yield call(api.loadUserNotifications, {
      hots,
      last_min_id: lastMinId,
    });

    if (response) {
      yield put({
        type: NOTIFICATION_CENTER_READY,
        response,
      });

      if (typeof callback === 'function') callback();
    }
  }
}

export default function* root() {
  yield all([
    fork(watchNotificationCenter),
  ]);
}
