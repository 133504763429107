import {
  UPLOAD_REQUEST,
  UPLOAD_PROGRESS,
  UPLOAD_SUCCESS,
  UPLOAD_FAILURE,
} from "../../actions";

const defaultState = {
  loading: false,
  progress: 0,
};

const handlers = {
  [UPLOAD_REQUEST]: state => ({
    ...state,
    loading: true,
  }),

  [UPLOAD_PROGRESS]: (state, action) => ({
    ...state,
    progress: action.payload,
  }),

  [UPLOAD_SUCCESS]: () => ({ ...defaultState }),

  [UPLOAD_FAILURE]: () => ({ ...defaultState }),

  default: state => state,
};

const uploadReducer = (state = defaultState, action) => {
  const calcState = handlers[action.type] || handlers.default;

  return calcState(state, action);
};

export default uploadReducer;
