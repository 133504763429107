import React from "react";
import PropTypes from "prop-types";

function ConsultationEnded(props) {
  let text;

  if (props.is_teacher) {
    text = `Ученик не сможет загружать домашние работы.
        В случае продления у ученика автоматически появится возможность загрузки.`;
  } else {
    text = `В консультации входит возможность загружать домашние работы и получать по ним обратную связь.
        У вас остается постоянный доступ к видеоурокам курса.`;
  }

  return (
    <div className="consultation_ended">
      <div className="consultation_ended_info">
        <div className="consultation_ended_title">
          Закончился период консультаций
        </div>
        <div className="consultation_ended_text">{text}</div>
        {!props.is_teacher && (
          <button
            type="button"
            className="consultation_ended_btn"
            onClick={props.openConsultationPaymentModal}
          >
            Продлить консультации
          </button>
        )}
      </div>
    </div>
  );
}

ConsultationEnded.propTypes = {
  is_teacher: PropTypes.bool.isRequired,
  openConsultationPaymentModal: PropTypes.func.isRequired,
};

export default ConsultationEnded;
