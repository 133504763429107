import React from 'react';
import { array, bool, func } from 'prop-types';

import FreeSubscribeBlock from './FreeSubscribeBlock';

const CourseDetailsServer = ({ lessons, hasSubscribeAbility, freeSubscribeClick }) => {
  let lessonNumber = 0;

  const renderLessonNumber = (lesson) => {
    if (lesson.landing_image) {
      if (lessonNumber !== 0) lessonNumber += 1;

      return <div className="course-lesson_image lozad" data-background-image={lesson.landing_image} />;
    }

    lessonNumber += 1;

    return <div className="course-lesson_num">{lessonNumber}</div>;
  };

  return (
    <section id="course-details">
      <div className="wide-block-wrapper">
        <div className="wrapper">
          {hasSubscribeAbility && (
            <div style={{ marginBottom: 50 }}>
              <FreeSubscribeBlock freeSubscribeClick={freeSubscribeClick} />
            </div>
          )}

          <div className="course-page-title">
            Программа курса
          </div>

          <div className="lessons-wrapper">
            {lessons.map(lesson => (
              <div key={lesson.id}>
                <div className="course-lesson d-flex align-items-center justify-content-start">
                  {renderLessonNumber(lesson)}

                  <div className="course-lesson_details">
                    <span className="title">
                      {lesson.title}
                    </span>
                    {lesson.tags.trim() && (
                      <div className="tags">
                        {lesson.tags.trim().split(',').map(tag => (
                          <div key={tag} className="tag">{tag.trim()}</div>
                        ))}
                      </div>
                    )}
                    <p>{lesson.description}</p>
                  </div>
                </div>

                <div className={`course-bestworks course-bestworks-${lesson.id} hidden`}>
                  <div className="ui carousel">
                    <div className="carousel-wrapper">
                      <div className="swiper-button-prev" />
                      <div className="swiper-button-next" />
                    </div>

                    <div className="swiper-pagination" />
                  </div>

                  <div className="course-bestworks-label">
                    Работы учеников по
                    {' '}
                    {lessons.landing_image ? 'этому уроку' : `уроку №${lessonNumber}`}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

CourseDetailsServer.propTypes = {
  lessons: array.isRequired,
  hasSubscribeAbility: bool.isRequired,
  freeSubscribeClick: func.isRequired,
};

export default CourseDetailsServer;
