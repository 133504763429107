import React from 'react';
import { Provider } from "react-redux";

import initializeStore from "Core/store/initializeStore";
import { history } from "Core/services";
import CourseContainer from './CourseClient';

const getStore = initializeStore();

const CourseRoot = props => (
  <Provider store={getStore()} history={history}>
    <CourseContainer {...props} />
  </Provider>
);

export default CourseRoot;
