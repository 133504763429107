import React from "react";
import { bool, number, string, object } from "prop-types";

import { getNotifyItemOptions } from './item-helper';

function NotifyItem({ event_type, ...restProps }) {
  const { icon, message, image, time } = getNotifyItemOptions(event_type, restProps);

  return (
    <div className="notify-item">
      <div className="notify-icon">{icon}</div>
      <div className="notify-message">
        {message}
        <div className="notify-time">{time}</div>
      </div>
      {image}
    </div>
  );
}

NotifyItem.propTypes = {
  notifiable_id: number.isRequired,
  relatable_id: number,
  initiator: object,
  event_type: string.isRequired,
  image: string,
  is_code: bool,
  time: number.isRequired,
};

NotifyItem.defaultProps = {
  is_code: false,
  image: null,
  initiator: null,
  relatable_id: null,
};

export default NotifyItem;
