import React from "react";
import styled from "styled-components";

import FontAwesomeBrandIcon from './FontAwesomeBrandIcon';

function SocialProfile({ link }) {
  return (
    <SocialProfileComponent link={link}>
      <FontAwesomeBrandIcon link={link} color="#fff" />
    </SocialProfileComponent>
  );
}

function SocialProfiles({ links = [] }) {
  return (
    <SocialProfilesComponent className="social-profiles-component">
      {links.map((link, key) => (
        <SocialProfile key={key} link={link} />
      ))}
    </SocialProfilesComponent>
  );
}

export { SocialProfile, SocialProfiles };

const SocialProfilesComponent = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
`;

const SocialProfileComponent = styled.a.attrs(props => ({
  href: props.link,
  target: "_blank",
  title: props.link,
}))`
  position: relative;
  display: flex;
  outline: none 0 !important;
  border: none 0 !important;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  background: #2196f3;
  color: #fff;
  width: 34px;
  height: 34px;
  margin-right: 10px;
  transition: 0.2s all;
  font-size: 16px;

  &:last-child {
    margin: 0;
  }

  &:hover {
    filter: contrast(130%);
  }
`;
