import React, { useState, useEffect, useRef } from "react";
import { func, string } from "prop-types";
import autosize from "autosize";
import cn from "classnames";

export default function AddCommentArea(props) {
  const [areaValue, setAreaValue] = useState('');
  const txtareaRef = useRef();
  const { userAvatar } = props;

  useEffect(() => {
    autosize(txtareaRef.current);
  }, []);

  const handleCreateComment = (e) => {
    e.preventDefault();

    props.createComment(areaValue.trim());
    setAreaValue("");

    return false;
  };

  const handleCommentChange = (e) => {
    const { value } = e.target;
    setAreaValue(value);
  };

  const handleTextareaKeydown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      handleCreateComment(e);
    }
  };

  return (
    <div className="comments-component__comment-add">
      <form onSubmit={handleCreateComment} autoComplete="off">
        <img
          className={cn("user-avatar", { "rounded-0": userAvatar.endsWith("_cat_avatar.png") })}
          src={userAvatar}
          alt=""
        />
        <textarea
          ref={txtareaRef}
          type="text"
          name="comment"
          placeholder="Написать комментарий..."
          value={areaValue}
          rows="1"
          onKeyDown={handleTextareaKeydown}
          onChange={handleCommentChange}
          onFocus={handleCommentChange}
          autoComplete="off"
        />
        <button type="submit" disabled={!areaValue.trim()}>
          Отправить
        </button>
      </form>
    </div>
  );
}

AddCommentArea.propTypes = {
  userAvatar: string.isRequired,
  createComment: func.isRequired,
};
