import React from "react";
import { func, bool } from "prop-types";
import cl from "classnames";
import moment from "moment";
import "moment/locale/ru";
import { Link } from "react-router-dom";

import {
  chat as ChatType,
  course as CourseType,
  message as MessageType,
  user as UserType,
  homework as HomeworkType,
  flow as FlowType,
} from "Main/types";

import { DoubleCheckIcon } from "Main/components/layouts/icons";
import ItemHeader from "./ItemHeader";
import ItemBody from "./ItemBody";
import ItemUserAvatar from "./ItemUserAvatar";

export default function Item(props) {
  const {
    chat: { id, channel, answered, user: chatUser, unread },
    is_teacher,
    message,
  } = props;

  let isUnreadMy = false;

  if (message) {
    isUnreadMy = message.user !== chatUser && is_teacher;
    isUnreadMy = isUnreadMy || (!(message.user !== chatUser) && !is_teacher);
  }

  const handleMakeAnswered = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.updateChatAnswered({
      chat_id: id,
      answered: true,
    });
  };

  const msgTime = () => {
    const time = moment(message.time * 1000);
    const messageDay = time.calendar().slice(0, 5);

    const messageYear = time.format("YYYY");

    if (messageDay === "Сегод") {
      return time.format("LT");
    } if (messageDay === "Вчера") {
      return "вчера";
    } if (messageYear === moment().format("YYYY")) {
      return time.format("D MMMM hh:mm");
    }

    return time.format("D MMMM Y");
  };

  return (
    <Link
      className={cl("chat", {
        "-unread": message ? message.unread && !isUnreadMy : false,
      })}
      to={`/messages/${channel}/${id}`}
    >
      <ItemUserAvatar {...props} />
      <div className="chat_content">
        <ItemHeader {...props} />
        <ItemBody {...props} />
      </div>
      <div className="chat_info">
        <div className="chat_info_counter">
          {parseInt(unread, 10) !== 0 && (
            <div className="chat_unread">
              {unread && <div className="chat_unread_counter">{unread}</div>}
            </div>
          )}
          {is_teacher && !answered && (
            <div className="chat_answered">
              <DoubleCheckIcon
                tooltip="Отметить как отвеченный"
                onClick={e => handleMakeAnswered(e)}
              />
            </div>
          )}
        </div>
        <div
          className={`chat_time ${
            message && message.unread && isUnreadMy ? "-unread_my" : ""
          }`}
        >
          {message && msgTime()}
        </div>
      </div>
    </Link>
  );
}

Item.propTypes = {
  chat: ChatType.isRequired,
  message: MessageType,
  course: CourseType.isRequired,
  flow: FlowType.isRequired,
  user: UserType.isRequired,
  homework: HomeworkType,
  is_teacher: bool,
  onOpenChat: func.isRequired,
  updateChatAnswered: func.isRequired,
};

Item.defaultProps = {
  homework: null,
  message: null,
  is_teacher: false,
};
