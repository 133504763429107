import React, { Component } from "react";
import { func, object, string } from "prop-types";
import { Link } from 'react-router-dom';

import Dropdown, { DropdownItem } from "Main/components/ui/Dropdown";

class CatalogDropdownFiltersRouter extends Component {
  handleFilterClick = (filter, itemRef) => {
    const tagName = itemRef.tagName.toLocaleLowerCase();
    const routerLinkNode = tagName === 'a' ? itemRef : itemRef.querySelector('a');

    if (routerLinkNode) {
      // trigger click and update history
      routerLinkNode.click();
    }
    this.props.onSwitch(filter);
  }

  renderLink = (filter, filterContent) => {
    if (this.props.activeFilter === filter) {
      return (
        <button key={filter} type="button">
          {filterContent.name}
        </button>
      );
    }

    return (
      <Link
        to={filterContent.url}
        href={filterContent.url}
        key={filter}
        // use trigger click
        onClick={(e) => { if (e.isTrusted) e.preventDefault(); }}
      >
        {filterContent.name}
      </Link>
    );
  }

  render() {
    const { filters } = this.props;

    return (
      <Dropdown
        onSelect={({ attrs: { filter, itemRef } }) => this.handleFilterClick(filter, itemRef)}
      >
        {Object.entries(filters).map(([filter, filterContent]) => (
          <DropdownItem key={filter} filter={filter} selected={this.props.activeFilter === filter}>
            {this.renderLink(filter, filterContent)}
          </DropdownItem>
        ))}
      </Dropdown>
    );
  }
}

CatalogDropdownFiltersRouter.propTypes = {
  onSwitch: func.isRequired,
  filters: object.isRequired,
  activeFilter: string.isRequired,
};

CatalogDropdownFiltersRouter.defaultProps = {
};

export default CatalogDropdownFiltersRouter;
