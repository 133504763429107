import React, { useEffect } from "react";
import { func, any, bool } from "prop-types";
import ReactModal from 'react-modal';

import cross from "App/assets/images/chat/cross.svg";

ReactModal.setAppElement('#main_wrapper');
ReactModal.defaultStyles = {};

export default function ModalWrapper(props) {
  const { closeModal, children, showCross, showTimes, ...restProps } = props;

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.removeAttribute("style");
    };
  }, []);

  return (
    <ReactModal
      isOpen
      onRequestClose={closeModal}
      {...restProps}
    >
      {showCross && (
        <div className="modal-base-cross" onClick={closeModal}>
          <img src={cross} alt="cross icon" />
        </div>
      )}

      <div className="modal-base-content">
        {showTimes && <i className="fa fa-times" onClick={closeModal} aria-hidden="true" />}
        {children}
      </div>
    </ReactModal>
  );
}

ModalWrapper.propTypes = {
  closeModal: func.isRequired,
  children: any.isRequired,
  showCross: bool,
  showTimes: bool,
};

ModalWrapper.defaultProps = {
  showCross: true,
  showTimes: false,
};
