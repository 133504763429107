import React from 'react';
import { object, bool } from 'prop-types';

const ChatItemIcon = ({ course, isFreeFlow, isTeacher, consultationTimeIsUp }) => {
  if (!isTeacher) return null;

  const isShowLockIcon = course.use_consultations_feature && consultationTimeIsUp;

  if (!isFreeFlow && !isShowLockIcon) return null;

  if (isFreeFlow) {
    return <i className="fa fa-unlock-alt" style={{ color: "#eb6f0f", marginRight: "5px" }} />;
  }

  return <i className="fa fa-lock" style={{ color: "red", marginRight: "5px" }} />;
};

ChatItemIcon.propTypes = {
  course: object.isRequired,
  isFreeFlow: bool.isRequired,
  isTeacher: bool.isRequired,
  consultationTimeIsUp: bool.isRequired,
};

export default ChatItemIcon;
