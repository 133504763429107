import size from 'lodash/size';
import merge from 'lodash/merge';

import { MESSAGE_READED, MESSAGE_DELETED_DONE } from "../../actions";

const messageHandlers = {
  // сообщения чата были прочитанны
  [MESSAGE_READED]: (state, action) => {
    if (size(state.messages) === 0) return state;

    const chatId = action.payload.id;
    const entities = merge({}, state);

    Object.keys(entities.messages).forEach((k) => {
      const m = entities.messages[k];
      if (m.chat_id === chatId) m.unread = false;
    });

    return entities;
  },

  // сообщение было удалено
  [MESSAGE_DELETED_DONE]: (state, action) => {
    const { deleted_msg_id } = action;
    let entities = merge({}, state);

    entities = merge(entities, action.payload.entities);
    delete entities.messages[deleted_msg_id];

    const payloadMsgs = action.payload.entities.messages;
    const payloadLastMsgId = Object.keys(payloadMsgs)[size(payloadMsgs) - 1];
    let payloadLastMsg = payloadMsgs[payloadLastMsgId];

    payloadLastMsg = {
      ...payloadLastMsg,
      chat: payloadLastMsg.chat_id,
      homework_id: payloadLastMsg.homework,
    };

    entities.messages[payloadLastMsgId] = payloadLastMsg;

    return entities;
  },
};

export default messageHandlers;
