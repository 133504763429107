import { buffers, eventChannel, END } from "redux-saga";

/* global AUTH_TOKEN */
export function createUploadFileChannel(endpoint, file) {
  return eventChannel((emitter) => {
    const xhr = new XMLHttpRequest();

    const onProgress = (e) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };

    const onFailure = (message) => {
      emitter({ err: new Error("Upload failed"), message });
      emitter(END);
    };

    xhr.upload.addEventListener("progress", onProgress);
    xhr.upload.addEventListener("error", onFailure);
    xhr.upload.addEventListener("abort", onFailure);

    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;
      if (readyState === 4) {
        if (status === 200) {
          emitter({ success: true });
          emitter(END);
        } else {
          let jsonRes = {};
          let message = "Ошибка загрузки";
          try {
            jsonRes = JSON.parse(xhr.response);
            if (jsonRes.error) message = jsonRes.error;
          } catch (e) {
            // do nothing
          }

          onFailure(message);
        }
      }
    };

    xhr.open("POST", endpoint, true);
    xhr.setRequestHeader("X-CSRF-Token", AUTH_TOKEN);
    xhr.setRequestHeader("Accept", "application/json");

    const formData = new FormData();

    formData.append("authenticity_token", AUTH_TOKEN); // число 123456 немедленно преобразуется в строку "123456"
    // Файл, выбранный пользователем
    formData.append("homework[image]", file);

    xhr.send(formData);

    return () => {
      xhr.upload.removeEventListener("progress", onProgress);
      xhr.upload.removeEventListener("error", onFailure);
      xhr.upload.removeEventListener("abort", onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}
