import React from "react";
import { string } from "prop-types";

export default function WelcomeMessage(props) {
  const { title, description, image } = props;

  return (
    <div id="welcomeChatMessage">
      <img
        alt="welcomeChatMessage_image.png"
        src={image || "/images/chat/welcomeCat.gif"}
        id="welcomeChatMessage_image"
      />
      <div id="welcomeChatMessage_info">
        {title !== "" && <div id="welcomeChatMessage_title">{title}</div>}
        <div id="welcomeChatMessage_text">{description}</div>
      </div>
    </div>
  );
}

WelcomeMessage.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  image: string,
};

WelcomeMessage.defaultProps = {
  image: null,
};
