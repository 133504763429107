import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { func, bool, string, number, array } from "prop-types";
import cn from "classnames";

import { history } from "Core/services";
import {
  loadHomework,
  prepareStudentWork,
  showOnCourse,
  toggleLikeCatalogItem,
  startConvertingMp4ToGif,
} from "Core/actions";
import { getHomeworkVersions } from "Core/reducers/selectors";
import SharedComments from "./SharedComments";

import Modal from "../components/layouts/modals/Modal";
import Spinner from "../components/layouts/Spinner";

import HomeworkOldHeader from "../components/Homework/HomeworkOldHeader";
import HomeworkBody from "../components/Homework/HomeworkBody";
import HomeworkOldActions from "../components/Homework/HomeworkOldActions";

const HomeworkOldView = ({
  // own props
  id,
  closeModal,
  reduxCatalogKey,
  // map dispatch functions
  loadWork,
  prepareWork,
  toggleFameHall,
  toggleLike,
  convertToGif,
  // map state work fields
  currentUserId,
  isTeacher,
  isGifConverting,
  loaded,
  versions,
}) => {
  const [activeVersionIndex, setActiveVersionIndex] = useState(0);

  const activeWork = versions[activeVersionIndex];

  const [modalAnimateShown, setModalAnimateShow] = useState(false);
  const [isBodyContentLoaded, setBodyContentLoadedState] = useState(false);

  // после монтирования загружаем работу
  useEffect(() => {
    loadWork({ id, reduxCatalogKey });

    return () => prepareWork({ id, reduxCatalogKey });
  }, []);

  // после подгрузки картинки показываем модалку
  useEffect(() => {
    if (!loaded || !isBodyContentLoaded) return;

    setModalAnimateShow(true);
  }, [loaded, isBodyContentLoaded]);

  const changeActiveVersion = index => setActiveVersionIndex(index);

  const handleToggleLike = () => {
    if (!currentUserId) {
      history.push('/login');
      return;
    }

    toggleLike({ itemId: activeWork.id, entity: "homeworks" });
  };

  const handleShowOnCourse = () => {
    toggleFameHall(activeWork.id);
  };

  const renderContent = () => {
    if (!loaded || activeVersionIndex === null) {
      return <Spinner />;
    }

    return (
      <div
        className={cn(
          "modal-content",
          "modal-hw-content",
          "new-modal-hw-content",
          {
            "animate-shown": modalAnimateShown,
          },
        )}
        style={{ paddingBottom: activeWork.status === "accepted" ? 0 : "26px" }}
      >
        <HomeworkOldHeader
          isTeacher={isTeacher}
          homework={activeWork}
          isGifConverting={isGifConverting}
          convertToGif={convertToGif}
        />

        <HomeworkBody
          versions={versions}
          activeVersionIndex={activeVersionIndex}
          changeActiveVersion={changeActiveVersion}
          onContentLoaded={() => setBodyContentLoadedState(true)}
        />

        <hr style={{ marginTop: "10px" }} />

        <HomeworkOldActions
          activeWork={activeWork}
          isTeacher={isTeacher}
          isShowOnCourse={activeWork.isShowOnCourse}
          onShowOnCourse={handleShowOnCourse}
          onToggleLike={handleToggleLike}
        />

        {activeWork.status === "accepted" && (
          <footer>
            <hr style={{ marginTop: "10px" }} />
            <SharedComments
              entity="homeworks"
              commentableId={activeWork.id}
              commentsNumber={activeWork.commentsNumber}
              insideModal
            />
          </footer>
        )}
      </div>
    );
  };

  return (
    <Modal closeModal={closeModal}>
      {renderContent()}
    </Modal>
  );
};

HomeworkOldView.propTypes = {
  // OWN PROPS
  id: number.isRequired,
  closeModal: func.isRequired,
  reduxCatalogKey: string.isRequired,
  // MAP STATE TO PROPS
  loaded: bool.isRequired,
  currentUserId: number,
  isTeacher: bool,
  isGifConverting: bool,
  versions: array.isRequired,
  // MAP DISPATCH TO PROPS
  loadWork: func.isRequired,
  prepareWork: func.isRequired,
  toggleFameHall: func.isRequired,
  toggleLike: func.isRequired,
  convertToGif: func.isRequired,
};

HomeworkOldView.defaultProps = {
  currentUserId: null,
  isTeacher: false,
  isGifConverting: false,
};

function mapStateToProps(state, { reduxCatalogKey: catKey }) {
  const catEntity = state.entities.catalog[catKey];
  const homeworkId = catEntity ? catEntity.activeModal : null;

  if (!homeworkId) return { loaded: false, versions: [] };

  return {
    loaded: true,
    currentUserId: state.local.currentUser.id,
    isGifConverting: !!state.local.currentUser.mp4ToGifConverting,
    isTeacher: !!state.local.currentUser.is_teacher,
    versions: getHomeworkVersions(state, homeworkId),
  };
}

export default connect(mapStateToProps, {
  loadWork: loadHomework,
  prepareWork: prepareStudentWork,
  toggleLike: toggleLikeCatalogItem,
  toggleFameHall: showOnCourse,
  convertToGif: startConvertingMp4ToGif,
})(HomeworkOldView);
