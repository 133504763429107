/* eslint-disable no-constant-condition */
import { take, put, call, fork, select, all } from "redux-saga/effects";
import some from "lodash/some";
import { api, history } from "../services";
import * as actions from "../actions";
import { fetchEntity } from './helpers';
import eduCertWathers from './edu-certificate';

// each entity defines 3 creators { request, success, failure }
const {
  counters: countersEntity, chat: chatEntity, chats: chatsEntity,
} = actions;

const fetchChats = fetchEntity.bind(null, chatsEntity, api.fetchChats);
const fetchChat = fetchEntity.bind(null, chatEntity, api.fetchChat);
const fetchChatsCounters = fetchEntity.bind(
  null,
  countersEntity,
  api.fetchChatsCounters,
);

// ------------------- CHAT ACTIONS ------------------- //
function* watchChatsSetContex() {
  while (true) {
    const { value: context } = yield take(actions.CHATS_SET_CONTEXT);
    yield call(fetchChats, context);

    // обновление счетчиков нужно только для учителей и если открыт чат лист
    const {
      local: { is_teacher },
      router: { pathname },
    } = yield select(state => state);
    if (is_teacher && pathname === "/messages") yield call(fetchChatsCounters);
  }
}

function* watchChatSetContex() {
  while (true) {
    const { value: context, resolve } = yield take(actions.CHAT_SET_CONTEXT);
    const { channel, id } = yield select(state => state.router.params);

    yield call(fetchChat, {
      options: context,
      channel,
      id,
    });

    resolve();
  }
}

// load repo unless it is cached
function* loadChats() {
  // const repo = yield select(getRepo)
  yield call(fetchChats, "all");
}
function* loadChat(id, channel, message_id, show_success_modal) {
  // const repo = yield select(getRepo)
  const {
    local: {
      current_chat: { context },
    },
  } = yield select();
  yield call(fetchChat, {
    options: context,
    channel,
    id,
    message_id,
    show_success_modal,
  });
}

// trigger router navigation via history
function* watchNavigate() {
  while (true) {
    const { pathname } = yield take(actions.NAVIGATE);
    yield history.push(pathname);
  }
}

function* watchLoadChats() {
  while (true) {
    yield take(actions.LOAD_CHATS);
    yield fork(loadChats);
  }
}

// Fetches more starred repos, use pagination data from getStarredByUser(login)
function* watchLoadChat() {
  while (true) {
    const { id, channel, message_id, show_success_modal } = yield take(
      actions.LOAD_CHAT,
    );
    yield call(loadChat, id, channel, message_id, show_success_modal);
  }
}
function* watchChatSuccess() {
  while (true) {
    const { options: { page } } = yield take(actions.CHAT[actions.SUCCESS]);

    const is_teacher = yield select(state => state.local.is_teacher);

    // если учитель, то подгружаем прогресс бар
    if (page === 0 && is_teacher) {
      const { channel, id } = yield select(state => state.router.params);
      const { response, error } = yield call(api.fetchTeacherProgressBar, id, channel);

      if (response) {
        yield put({
          type: actions.CHAT_TEACHER_PROGRESS_BAR_LOADED,
          response,
        });
      } else {
        console.error(error);
      }
    }
  }
}
function* watchChatSuccessOrMessageCreated() {
  while (true) {
    // Срабатывает при наступлении одного из экшенов
    const result = yield take([
      actions.CHAT[actions.SUCCESS],
      actions.MESSAGE_CREATED_PROCEED,
    ]);
    let { params } = yield select(state => state.router);
    params = params || {};

    const { channel, id } = params;

    if (result.type === actions.CHAT[actions.SUCCESS] && id) {
      // если пользователь попал в чат после оплаты по продлению, то показываем модалку
      const currChat = yield select(state => state.entities.chats[id]);
      if (currChat && currChat.show_success_modal) {
        yield put({ type: actions.OPEN_CONSULTATION_SUCCESS_PAYMENT_MODAL });
      }
    }

    const isDialogOpen = typeof channel !== "undefined" && typeof id !== "undefined";

    // запускаем update unread только если имеются
    // не прочитанные сообщение для текущего пользователя
    if (isDialogOpen) {
      const { is_teacher } = yield select(state => state.local);
      const messages = yield select(state => state.entities.messages);
      let isNeedUpdateUnread;
      const currChat = yield select(state => state.entities.chats[id]);

      if (is_teacher) {
        isNeedUpdateUnread = some(
          messages,
          m => m.chat_id === currChat.id && m.user_id === currChat.user && m.unread,
        );
      } else {
        isNeedUpdateUnread = some(
          messages,
          m => m.chat_id === currChat.id && m.user_id !== currChat.user && m.unread,
        );
      }
      const messId = (currChat.messages.length !== 0 && Math.max(...currChat.messages))
        || null;
      const lastMessage = messId && (yield select(state => state.entities.messages[messId]));

      if (lastMessage && isNeedUpdateUnread) {
        const payload = {
          message_id: lastMessage.id,
          channel,
          chat_id: id,
        };
        yield put({ type: actions.UPDATE_UNREAD, payload });
      }
    }
  }
}

function* watchUpdateUnread() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_UNREAD);
    yield put({
      type: actions.UPDATE_UNREAD_DONE,
      response: api.updateChatUnread(payload),
    });
  }
}

function* watchToggleAnswered() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_TOGGLE_ANSWERED);
    const { response, error } = yield call(
      api.updateChatToggleAnswered,
      payload,
    );
    if (response) {
      yield put({ type: actions.UPDATE_TOGGLE_ANSWERED_DONE, response });
    } else {
      yield put({ type: actions.UPDATE_TOGGLE_ANSWERE_FAILURE, error });
    }
  }
}

function* watchUpdateUnanswered() {
  while (true) {
    yield take(actions.UPDATE_TOGGLE_ANSWERED_DONE);

    const {
      local: { is_teacher },
      router: { pathname },
    } = yield select();

    // обновление счетчиков нужно только для учителей и если открыт чат лист
    if (is_teacher && pathname === "/messages") yield call(fetchChatsCounters);
  }
}

function* watchUpdatePersonalRemark() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_PERSONAL_REMARK);
    const { response } = yield call(api.updatePersonalRemark, payload);
    if (response) {
      yield put({ type: actions.UPDATE_PERSONAL_REMARK_DONE, response });
    }
  }
}

function* watchMessageReaded() {
  while (true) {
    const { payload } = yield take(actions.MESSAGE_READED);
    yield put({
      type: actions.MESSAGE_READED_PROCEED,
      response: api.messageReaded(payload),
    });
  }
}

function* watchChatTyping() {
  while (true) {
    // payload = { user_id, channel, chat_id }
    const { payload } = yield take(actions.CHAT_TYPING);

    yield fork(api.chatTyping, payload);
  }
}

// ------------------- MESSAGE ACTIONS ------------------- //
function* watchNewMessage() {
  while (true) {
    const { payload } = yield take(actions.MESSAGE_CREATED);
    yield put({
      type: actions.MESSAGE_CREATED_PROCEED,
      response: api.receiveMessage(payload),
    });
  }
}

function* watchNewMessageProceed() {
  while (true) {
    yield take(actions.MESSAGE_CREATED_PROCEED);

    const {
      local: { is_teacher },
      router: { pathname },
    } = yield select();

    // обновляем чат лист, только если находимся непосредственно в нем
    // if (pathname === '/messages') yield call(fetchChats, context);

    // обновление счетчиков нужно только для учителей и если открыт чат лист
    if (is_teacher && pathname === "/messages") yield call(fetchChatsCounters);
  }
}

function* watchSendMessage() {
  while (true) {
    const { payload } = yield take(actions.MESSAGE_SEND);
    yield put({
      type: actions.MESSAGE_SEND_DONE,
      response: api.sendMessage(payload),
    });
  }
}

function* watchUpdateMessage() {
  while (true) {
    const { payload } = yield take(actions.MESSAGE_UPDATE);
    const { response } = yield call(api.updateMessage, payload);

    if (response) {
      yield put({ type: actions.MESSAGE_UPDATE_DONE, response });
    }
  }
}

function* watchUpdateMessageRecipients() {
  while (true) {
    const { payload } = yield take(actions.MESSAGE_UPDATED);

    yield put({
      type: actions.MESSAGE_UPDATED_DONE,
      response: api.receiveMessage(payload),
    });
  }
}

function* watchDeleteMessage() {
  while (true) {
    const { message_id } = yield take(actions.MESSAGE_DELETE);
    const { response } = yield call(api.deleteMessage, {
      message_id,
    });

    if (response) {
      yield put({ type: actions.MESSAGE_DELETE_DONE });
    }
  }
}

function* watchDeleteMessageRecipients() {
  while (true) {
    const { payload, deleted_msg_id } = yield take(actions.MESSAGE_DELETED);

    yield put({
      type: actions.MESSAGE_DELETED_DONE,
      payload: api.receiveMessage(payload),
      deleted_msg_id,
    });
  }
}

function* watchSendMultiMessage() {
  while (true) {
    const payload = yield take(actions.MULTISEND[actions.REQUEST]);

    const { response, error } = yield call(api.sendMultiMessage, payload);
    if (response) {
      yield put({
        type: actions.MULTISEND[actions.SUCCESS],
        response,
      });
    } else {
      yield put({
        type: actions.MULTISEND[actions.FAILURE],
        error,
      });
    }
  }
}

// ------------------- HOMEWORK IN CHAT ACTIONS ------------------- //

export default function* root() {
  yield all([
    // CHAT
    fork(watchChatsSetContex),
    fork(watchChatSetContex),
    fork(watchNavigate),
    fork(watchLoadChats),
    fork(watchLoadChat),
    fork(watchChatSuccess),
    fork(watchChatSuccessOrMessageCreated),
    fork(watchUpdateUnread),
    fork(watchToggleAnswered),
    fork(watchUpdateUnanswered),
    fork(watchUpdatePersonalRemark),
    fork(watchMessageReaded),
    fork(watchChatTyping),

    // MESSAGE
    fork(watchNewMessage),
    fork(watchNewMessageProceed),
    fork(watchSendMessage),
    fork(watchUpdateMessage),
    fork(watchUpdateMessageRecipients),
    fork(watchDeleteMessage),
    fork(watchDeleteMessageRecipients),
    fork(watchSendMultiMessage),

    // EDU CERTIFICATE
    fork(eduCertWathers),
  ]);
}
