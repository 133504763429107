import React, { useEffect, useState } from "react";
import { array, bool, func, number, object, string } from "prop-types";
import { connect } from "react-redux";
import cn from "classnames";

import {
  loadContestWork,
  prepareContestWork,
  assignPrizeToContestant,
  setContestWorkAddInProgress,
  toggleLikeCatalogItem,
} from "Core/actions";
import { history } from "Core/services";

import Modal from "Main/components/layouts/modals/Modal";
import SharedComments from "Main/containers/SharedComments";
import ContestWorkViewHeader from "./ContestWorkViewHeader";
import ContestWorkViewBody from "./ContestWorkViewBody";
import ContestWorkViewActions from "./ContestWorkViewActions";
import Spinner from '../layouts/Spinner';

function ContestWorkView({
  // OWN PROPS
  id,
  closeModal,
  showNextWork,
  showPrevWork,
  sliderEnabled,
  reduxCatalogKey,
  // MAP STATE TO PROPS
  loaded,
  /* eslint-disable react/prop-types */
  contest,
  contestId,
  currentUserId,
  commentsNumber,
  contestWorkAddInProgress,
  user,
  allowedWorkTypes,
  uploadsExceeded,
  prizePlaceIcon,
  prizePlaceTitle,
  contestWorkType,
  contestWorkUrl,
  likesNumber,
  alreadyLiked,
  prizePlaces,
  contestJudge,
  isWinner,
  /* eslint-enable react/prop-types */
  // MAP DISPATCH TO PROPS
  toggleLike,
  loadWork,
  prepareWork,
  setInPropgress,
  assignPrize,
}) {
  const [modalAnimateShown, setModalAnimateShow] = useState(false);
  const [isBodyContentLoaded, setBodyContentLoadedState] = useState(false);
  const catalogOpt = { entity: 'contestants', reduxCatalogKey };

  const handleToggleLike = (itemId) => {
    if (!currentUserId) {
      history.push('/login');
      return;
    }

    toggleLike({ itemId, ...catalogOpt });
  };

  useEffect(() => {
    loadWork({ contestWorkId: id, reduxCatalogKey });

    return () => prepareWork({ contestId, reduxCatalogKey });
  }, [id]);

  useEffect(() => {
    if (loaded && isBodyContentLoaded) {
      setModalAnimateShow(true);
    }
  }, [loaded, isBodyContentLoaded]);

  const renderContent = () => {
    if (!loaded) {
      return <Spinner className="my-3" />;
    }

    return (
      <div
        className={cn(
          "modal-content",
          "modal-hw-content",
          "new-modal-hw-content",
          {
            "animate-shown": modalAnimateShown,
          },
        )}
      >
        <ContestWorkViewHeader
          user={user}
          contest={contest}
          contestWorkAddInProgress={contestWorkAddInProgress}
          prizePlaceIcon={prizePlaceIcon}
          prizePlaceTitle={prizePlaceTitle}
          allowedWorkTypes={allowedWorkTypes}
          uploadsExceeded={uploadsExceeded}
          currentUserId={currentUserId}
          setInPropgress={setInPropgress}
        />
        <ContestWorkViewBody
          contestWorkType={contestWorkType}
          contestWorkUrl={contestWorkUrl}
          showNextWork={showNextWork}
          showPrevWork={showPrevWork}
          sliderEnabled={sliderEnabled}
          onContentLoaded={() => setBodyContentLoadedState(true)}
        />

        {contest.isCompleted && (
          <footer>
            <ContestWorkViewActions
              id={id}
              likesNumber={likesNumber}
              alreadyLiked={alreadyLiked}
              prizePlaces={prizePlaces}
              contestJudge={contestJudge}
              isWinner={isWinner}
              toggleLike={handleToggleLike}
              assignPrize={assignPrize}
            />

            <div className="mt-4">
              <SharedComments
                entity="contestants"
                commentableId={id}
                commentsNumber={commentsNumber}
                insideModal
              />
            </div>
          </footer>
        )}
        {!contest.isCompleted && <div className="mt-3" />}
      </div>
    );
  };

  return (
    <Modal closeModal={closeModal}>
      {renderContent()}
    </Modal>
  );
}

ContestWorkView.propTypes = {
  reduxCatalogKey: string.isRequired,
  loaded: bool,
  id: number.isRequired,
  contestId: number,
  closeModal: func.isRequired,
  commentsNumber: number,
  loadWork: func.isRequired,
  prepareWork: func.isRequired,
  toggleLike: func.isRequired,
  setInPropgress: func.isRequired,
  assignPrize: func.isRequired,
  contestWorkAddInProgress: bool.isRequired,
  contest: object,
  currentUserId: number,
  uploadsExceeded: bool,
  allowedWorkTypes: array,
  showNextWork: func,
  showPrevWork: func,
  sliderEnabled: bool,
};

ContestWorkView.defaultProps = {
  commentsNumber: 0,
  contestId: null,
  loaded: false,
  contest: { isCompleted: false },
  currentUserId: null,
  uploadsExceeded: true,
  allowedWorkTypes: [],
  showNextWork: () => {},
  showPrevWork: () => {},
  sliderEnabled: false,
};

function mapStateToProps(state, { reduxCatalogKey: catKey }) {
  let activeModal = null;

  if (state.entities.catalog[catKey] && state.entities.catalog[catKey].activeModal) {
    activeModal = state.entities.catalog[catKey].activeModal;
  }

  if (!activeModal) {
    return {
      loaded: false,
      contestWorkAddInProgress: false,
    };
  }

  return {
    loaded: true,
    currentUserId: state.local.currentUser.id,
    ...state.entities.contestants[activeModal],
    contestWorkAddInProgress: !!state.local.contestReducer.contestWorkAddInProgress,
  };
}

const ContestWorkViewConnected = connect(mapStateToProps, {
  loadWork: loadContestWork,
  prepareWork: prepareContestWork,
  assignPrize: payload => assignPrizeToContestant(payload),
  setInPropgress: setContestWorkAddInProgress,
  toggleLike: toggleLikeCatalogItem,
})(ContestWorkView);

export default ContestWorkViewConnected;
