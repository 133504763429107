/* eslint-disable no-constant-condition */
import { take, put, call, fork, select, all } from "redux-saga/effects";
import { api } from "../services";
import {
  CONTEST_WORK_LOAD, CONTEST_WORK_LOAD_DONE, CONTEST_WORK_PUBLISHED,
  CONTEST_WORK_PRIZE_PLACE_SELECT, CONTEST_WORK_PRIZE_PLACE_SELECT_DONE,
  CONTEST_WORKS, REQUEST, SUCCESS, FAILURE, CONTEST_WORK_REMOVE_IN_PROGRESS,
} from "../actions";

function* watchLoadContestWork() {
  while (true) {
    const { payload } = yield take(CONTEST_WORK_LOAD);
    const { response } = yield call(api.fetchContestWork, payload.contestWorkId);

    if (response) {
      yield put({
        type: CONTEST_WORK_LOAD_DONE,
        response,
        payload,
      });
    }
  }
}

function* watchPublishedContestWork() {
  while (true) {
    const { response: { contestant } } = yield take(CONTEST_WORK_PUBLISHED);

    const me = yield select(({ local: { currentUser: { id } } }) => id);

    if (contestant.user.id === me) {
      yield put({ type: CONTEST_WORK_REMOVE_IN_PROGRESS });
    }
  }
}

function* watchSelectPrizeContestWork() {
  while (true) {
    const { payload: { prizeId, contestantId } } = yield take(CONTEST_WORK_PRIZE_PLACE_SELECT);
    const options = [contestantId, { prize_id: prizeId }];

    const { response } = yield call(api.assignPrizeToContestant, ...options);

    if (response) {
      yield put({
        type: CONTEST_WORK_PRIZE_PLACE_SELECT_DONE,
        response,
      });
    }
  }
}

function* watchLoadContestWorks() {
  while (true) {
    const { payload } = yield take(CONTEST_WORKS[REQUEST]);

    // используем для подгрузки метод каталога
    const { response, error } = yield call(api.loadCatalogItems, payload);

    if (response) {
      yield put({
        type: CONTEST_WORKS[SUCCESS],
        response,
        payload,
      });
    } else {
      yield put({ type: CONTEST_WORKS[FAILURE], error });
      console.error(error);
    }
  }
}

export default function* root() {
  yield all([
    fork(watchLoadContestWork),
    fork(watchPublishedContestWork),
    fork(watchSelectPrizeContestWork),
    fork(watchLoadContestWorks),
  ]);
}
