import React from "react";

export default function ItemMessage(props) {
  const {
    message: { text, image },
  } = props;

  return (
    <div className="chat_text">
      <div className="chat_text_main">{text}</div>
      {image && <div>Вложение</div>}
    </div>
  );
}
