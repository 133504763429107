import React, { useEffect, useRef } from "react";
import { bool, number, string, func } from "prop-types";
import cn from "classnames";

export default function CardWork(props) {
  const {
    id,
    userAvatar,
    userName,
    cover,
    commentsCount,
    likesCount,
    alreadyLiked,
    onClick,
    onToggleLike,
    isCompleted,
    prizePlaceIcon,
    showPrize,
  } = props;

  const imgRef = useRef();

  useEffect(() => {
    window.observer.observe();
  }, []);

  useEffect(() => {
    imgRef.current.src = cover;
  }, [cover]);

  return (
    <div className="card_work_comp">
      <div className="position-relative" onClick={() => onClick(id)}>
        <img ref={imgRef} className="lozad card_homework_img" data-src={cover} alt="" />
        {showPrize && prizePlaceIcon && (
          <img className="card_work_comp__prize" src={prizePlaceIcon} alt="" />
        )}
      </div>

      <footer>
        <div className="card_work_comp__info">
          <div className="card_work_comp__user">
            <img
              className={cn("lozad", { "rounded-0": userAvatar.endsWith("_cat_avatar.png") })}
              data-src={userAvatar}
              alt=""
            />
            <span>{userName.replace(/\s+/g, " ").replace(/\s/g, "\n")}</span>
          </div>

          <div className="card_work_comp__counters">
            {isCompleted && commentsCount > 0 && (
              <div className="counter comments">
                <i className="fa fa-comment-o" />
                <span>{commentsCount}</span>
              </div>
            )}

            {isCompleted && (
              <div
                className={cn("counter likes", { liked: alreadyLiked })}
                onClick={() => onToggleLike(id)}
              >
                <i className={`fa fa-heart${alreadyLiked ? "" : "-o"}`} />
                {likesCount > 0 && <span>{likesCount}</span>}
              </div>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
}

CardWork.propTypes = {
  id: number.isRequired,
  userAvatar: string.isRequired,
  userName: string.isRequired,
  cover: string.isRequired,
  commentsCount: number.isRequired,
  likesCount: number.isRequired,
  alreadyLiked: bool,
  isCompleted: bool,
  onClick: func,
  onToggleLike: func,
  prizePlaceIcon: string,
  showPrize: bool,
};

CardWork.defaultProps = {
  alreadyLiked: false,
  isCompleted: false,
  onClick: () => {},
  onToggleLike: () => {},
  prizePlaceIcon: null,
  showPrize: false,
};
