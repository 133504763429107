import React, { Component } from "react";
import { func, string, object, bool } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Modal from "Main/components/layouts/modals/Modal";
import { authorize, changeUserPasswd } from "Core/actions";

class PasswordChange extends Component {
  constructor(props) {
    super(props);

    const login = this.props.login || localStorage.getItem("login") || "";
    let ignorePasswordOld = !!this.props.ignorePasswordOld;

    if (this.props.location.state) {
      ignorePasswordOld = !!this.props.location.state.ignorePasswordOld;
    }

    this.state = {
      login,
      ignorePasswordOld,
      smscode: "",
      password_old: null,
      password_new: "",
      password_new_confirm: "",
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.props.reset_error();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      login,
      password_old,
      password_new,
      password_new_confirm,
      smscode,
    } = this.state;

    const { reset_error, passwdChange } = this.props;

    reset_error();
    passwdChange({
      login,
      password_old,
      password_new,
      password_new_confirm,
      smscode,
    }).then(() => this.props.auth({ login, password: password_new }))
      .then(() => {
        this.props.history.push("/");
        window.location = "/";
      })
      .catch(() => {});
  };

  render() {
    const {
      password_old,
      password_new,
      password_new_confirm,
      smscode,
      ignorePasswordOld,
    } = this.state;

    return (
      <Modal closeModal={() => this.props.history.push("/")} showCross={false}>
        <div className="auth-popup-form passwd-change-step">
          <h3>Пора сменить пароль!</h3>
          <header className="d-flex flex-column text-center">
            <span>
              Для изменения пароля введите SMS-код, который только что к Вам
              пришёл
            </span>
          </header>
          <hr />
          <form
            onSubmit={this.handleSubmit}
            className="d-flex flex-column"
            autoComplete="off"
          >
            <div className="component-wrapper">
              <input
                type="password"
                name="password_new"
                placeholder="Новый пароль"
                defaultValue={password_new}
                onChange={this.handleChange}
                onFocus={this.handleChange}
                autoComplete="off"
              />
            </div>
            <div className="component-wrapper">
              <input
                type="password"
                name="password_new_confirm"
                placeholder="Пвоторите новый пароль"
                defaultValue={password_new_confirm}
                onChange={this.handleChange}
                onFocus={this.handleChange}
                autoComplete="off"
              />
            </div>
            {!ignorePasswordOld && (
              <div className="component-wrapper">
                <input
                  type="password"
                  name="password_old"
                  placeholder="Старый пароль"
                  defaultValue={password_old}
                  onChange={this.handleChange}
                  onFocus={this.handleChange}
                  autoComplete="off"
                />
              </div>
            )}
            <div className="component-wrapper">
              <input
                type="text"
                name="smscode"
                placeholder="SMS-код"
                defaultValue={smscode}
                onChange={this.handleChange}
                onFocus={this.handleChange}
                autoComplete="off"
              />
            </div>
            <div className="component-wrapper">
              <button
                className="ui button text-center"
                placeholder="submit"
                type="submit"
              >
                Сменить пароль
              </button>
            </div>
          </form>
          {this.props.errorPasswdChange && (
            <p className="error">{this.props.errorPasswdChange}</p>
          )}
          {(this.props.loadingPasswdChange || this.props.loadingAuth) && (
            <div className="load-indicator">
              <div className="loadersmall modal-hw-loader" />
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

PasswordChange.propTypes = {
  login: string,
  passwdChange: func.isRequired,
  reset_error: func.isRequired,
  auth: func.isRequired,
  history: object.isRequired,
  location: object.isRequired,
  errorPasswdChange: string,
  loadingPasswdChange: bool,
  loadingAuth: bool,
  ignorePasswordOld: bool,
};

PasswordChange.defaultProps = {
  login: "",
  errorPasswdChange: false,
  loadingPasswdChange: false,
  loadingAuth: false,
  ignorePasswordOld: false,
};

const mapStateToProps = state => ({
  loadingAuth: state.local.userAuthorizeReducer.loading,
  loadingPasswdChange: state.local.changeUserPasswdReducer.loading,
  errorPasswdChange: state.local.changeUserPasswdReducer.error,
});

const PasswordChangeConnected = connect(mapStateToProps, {
  auth: params => dispatch => new Promise((resolve, reject) => {
    dispatch(authorize.request({ ...params, resolve, reject }));
  }),
  passwdChange: params => dispatch => new Promise((resolve, reject) => {
    dispatch(changeUserPasswd.request({ ...params, resolve, reject }));
  }),
  reset_error: () => changeUserPasswd.reset_error(),
})(PasswordChange);

export default withRouter(PasswordChangeConnected);
