import React from "react";
import SyntaxHighlighter from "Main/components/layouts/SyntaxHighlighter";
import PlyrPlayer from "Main/components/Homework/PlyrPlayer";
import { func, number, string } from "prop-types";

const StudentWorkViewBody = ({
  id,
  text,
  type,
  imageFull,
  onContentLoaded,
}) => {
  const renderWorkBody = () => {
    if (type === "text") {
      return (
        <div className="text-left">
          <SyntaxHighlighter
            code={text}
            elementClass="modal-hw-version-code"
            onCodeLoaded={onContentLoaded}
          />
        </div>
      );
    }

    if (type === "video") {
      return (
        <PlyrPlayer
          ref={(node) => {
            onContentLoaded();
            node.plyr.play();
          }}
          source={imageFull}
          versionId={parseInt(id, 10)}
        />
      );
    }

    return (
      <img
        alt=""
        className="modal-hw-version"
        src={imageFull}
        onLoad={onContentLoaded}
        onError={onContentLoaded}
      />
    );
  };

  return (
    <div className="modal-body modal-hw-body">
      <div className="modal-hw-gallery-container">
        <div className="modal-hw-gallery">
          <div className="gallery-item gallery-item-active">
            {renderWorkBody()}
          </div>
        </div>
      </div>
    </div>
  );
};

StudentWorkViewBody.propTypes = {
  id: number.isRequired,
  text: string,
  type: string.isRequired,
  imageFull: string.isRequired,
  onContentLoaded: func.isRequired,
};

StudentWorkViewBody.defaultProps = {
  text: null,
};

export default StudentWorkViewBody;
